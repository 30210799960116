import { useMutation } from '@apollo/client'
import { getApolloClient } from '@src/ApolloClient'
import { getDisplayName, setDisplayName } from '@src/app/common/util/util'
import AfterRedirect from '@src/app/layout/AfterRedirect'
import LoadingFullScreen from '@src/app/layout/LoadingFullScreen'
import NotFound from '@src/app/layout/NotFound'
import ScrollToTop from '@src/app/layout/ScrollToTop'
import UnauthenticatedRoute from '@src/app/layout/UnauthenticatedRoute'
import {
    setOnboardingComplete,
    UPDATE_USER_DISPLAY_NAME,
} from '@src/app/operations/user'
import { useAppSelector } from '@src/app/redux/store'
import { URLS } from '@src/constants/urls'
import AuthFlowWrapper from '@src/features/auth/AuthFlowWrapper'
import ForgotPasswordFlow from '@src/features/auth/ForgotPasswordFlow'
import Home from '@src/features/idols/pages/home/Home'
import Application from '@src/features/pages/application/Application'
import Interstitial from '@src/features/pages/interstitial/Interstitial'
import Launchpad from '@src/features/pages/launchpad/Launchpad'
import LaunchpadApplication from '@src/features/pages/launchpadApplication/LaunchpadApplication'
import { MarketingLayout } from '@src/features/pages/layout/MarketingLayout'
import { useCurrentUserProfile } from '@src/features/shared/useCurrentUserProfile'
import { CurrentUser } from '@src/types/users'
import { useIntercom } from '@src/utils/intercom'
import React, { Suspense, useEffect, useState } from 'react'
import { useFeatureFlag } from '@src/features/shared/useFeatureFlag'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import EmailAccountActivation from '../features/auth/EmailAccountActivation'

const DashboardRoutes = React.lazy(() => import('./DashboardRoutes'))
const IdolRoutes = React.lazy(() => import('./IdolRoutes'))
const Privacy = React.lazy(() => import('../features/pages/Privacy'))
const PhoneForm = React.lazy(() => import('../features/auth/PhoneForm'))
const CompetitionTerms = React.lazy(
    () => import('../features/pages/CompetitionTerms'),
)
const SignUpForm = React.lazy(() => import('../features/auth/SignUpForm'))
// const SignInForm = React.lazy(() => import('../features/auth/SignInForm'))
const FinishedOnboarding = React.lazy(
    () => import('../features/onboarding/FinishedOnboarding'),
)
const Offer = React.lazy(() => import('../features/offers/Offer'))

const routesWithoutLoading = [
    '/',
    '/apply',
    '/interstitial',
    '/launchpad',
    '/thesearch',
    '/thesearch/application',
]

function AppRoutes() {
    const { authenticated } = useAppSelector(state => state.auth)
    const [loading, setLoading] = useState(false)
    const { boot } = useIntercom()

    useEffect(() => {
        boot()
    }, [])

    const { currentUserProfile } = useCurrentUserProfile('network-only')

    const [updateUserDisplayName] = useMutation(UPDATE_USER_DISPLAY_NAME)

    async function handleUpdateName(id: any) {
        const displayName = getDisplayName()
        if (displayName) {
            setLoading(true)

            try {
                const reqObj = {
                    id: id,
                    display_name: displayName,
                    updated_at: 'now()',
                }

                await updateUserDisplayName({
                    variables: reqObj,
                })
                if (!currentUserProfile?.private?.portal_access) {
                    await setOnboardingComplete()
                }
                setDisplayName(null)

                await getApolloClient().refetchQueries({
                    include: ['GetUserById'],
                })

                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (currentUserProfile?.id) {
            handleUpdateName(currentUserProfile?.id)
        }
    }, [currentUserProfile?.id])

    // Wait for user profile to load, unless we're on
    // a page like root where we want to render
    // as fast as possible and don't need to know
    // user profile data.
    if (
        (routesWithoutLoading.indexOf(window.location.pathname) === -1 &&
            authenticated &&
            !currentUserProfile) ||
        loading
    ) {
        return <LoadingFullScreen />
    }

    const isPortalAccess = currentUserProfile?.private?.portal_access

    return (
        <div className="w-full bg-warm-gray-100 h-full">
            <div className="mx-auto h-full">
                <BrowserRouter>
                    <Suspense fallback={<LoadingFullScreen />}>
                        <ScrollToTop />
                        <AfterRedirect />
                        <Routes>
                            <Route
                                path={URLS.ROOT}
                                element={<MarketingLayout />}
                            >
                                <Route path={URLS.ROOT} element={<Home />} />
                            </Route>

                            <Route
                                path={URLS.ROOT}
                                element={<MarketingLayout />}
                            >
                                <Route
                                    path={URLS.APPLY}
                                    element={<Application />}
                                />
                                <Route
                                    path={URLS.INTERSTITIAL}
                                    element={<Interstitial />}
                                />
                                <Route
                                    path={URLS.LAUNCHPAD}
                                    element={<Launchpad />}
                                />
                                <Route
                                    path={URLS.LAUNCHPAD_APPLICATION}
                                    element={<LaunchpadApplication />}
                                />
                                <Route
                                    path={URLS.PRIVACY}
                                    element={<Privacy />}
                                ></Route>
                                <Route
                                    path={URLS.TERMS}
                                    element={<CompetitionTerms />}
                                ></Route>
                                <Route
                                    path={URLS.OFFER}
                                    element={<Offer />}
                                ></Route>
                                <Route
                                    path={URLS.PROSPECTIVE_MEMBER_SUBMITTED}
                                    element={<FinishedOnboarding />}
                                ></Route>
                            </Route>
                            {/*Email verification page is temporarily disable. Since the backend wont be ready for launch

                            <Route
                                path="/account-activation"
                                element={
                                    <EmailAccountActivation
                                        currentUserProfile={currentUserProfile}
                                    />
                                }
                            ></Route> */}
                            <Route
                                element={
                                    <UnauthenticatedRoute
                                        user={currentUserProfile}
                                    />
                                }
                            >
                                {/* <Route
                                    path="/cdaologin"
                                    element={<SignInForm />}
                                ></Route>
                                <Route
                                    path="/cdaosignup"
                                    element={<SignUpForm />}
                                ></Route> */}
                                <Route
                                    path={URLS.SIGN_UP}
                                    element={
                                        <AuthFlowWrapper
                                            currentUserProfile={
                                                currentUserProfile
                                            }
                                            isSignUp={true}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path={URLS.FORGOT_PASSWORD}
                                    element={
                                        <ForgotPasswordFlow
                                            currentUserProfile={
                                                currentUserProfile
                                            }
                                        />
                                    }
                                ></Route>

                                <Route
                                    path={URLS.LOGIN}
                                    element={
                                        <AuthFlowWrapper
                                            currentUserProfile={
                                                currentUserProfile
                                            }
                                        />
                                    }
                                ></Route>

                                <Route
                                    path={URLS.PHONE_LOGIN}
                                    element={<PhoneForm isSignUp={false} />}
                                ></Route>
                                <Route
                                    path={URLS.PHONE_SIGN_UP}
                                    element={<PhoneForm isSignUp={true} />}
                                ></Route>

                                <Route
                                    path={URLS.PROSPECTIVE_MEMBER}
                                    element={<SignUpForm />}
                                ></Route>
                            </Route>

                            {/* If the current user is signed in and we know they've completed
                                onboarding, then send them to the holding page, unless we've
                                unblocked the portal for development using WALL_OFF_PORTAL_ACCESS */}
                            {isPortalAccess &&
                                (!authenticated || currentUserProfile) && (
                                    <Route
                                        path="*"
                                        element={
                                            <DashboardRoutes
                                                currentUserProfile={
                                                    currentUserProfile as CurrentUser
                                                }
                                            />
                                        }
                                    />
                                )}

                            <Route
                                path="/thesearch/*"
                                element={
                                    <IdolRoutes
                                        currentUserProfile={
                                            currentUserProfile as CurrentUser
                                        }
                                    />
                                }
                            />

                            <Route element={<MarketingLayout />}>
                                <Route path="*" element={<NotFound />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </div>
        </div>
    )
}

export default AppRoutes
