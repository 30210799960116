import React from 'react'

import CountdownTimer from '@src/app/common/idol-components/CountdownTimer'
import Navbar from '@src/app/common/idol-components/Navbar'
import { getAuthSubTitle } from '@src/app/common/util/util'
import { BothCurrentUser } from '@src/types/users'
import {
    CREATE_ACCOUNT_FORM,
    CREATE_PHONE_ACCOUNT_FORM,
    LOGIN_FORM,
    SMS_VERIFY_FORM,
} from '../constants'

const getNavbarTitle = (currentStep?: string) => {
    switch (currentStep) {
        case CREATE_ACCOUNT_FORM:
        case CREATE_PHONE_ACCOUNT_FORM:
            return `Sign up ${getAuthSubTitle()}`

        case LOGIN_FORM:
            return `Log in ${getAuthSubTitle()}`

        case SMS_VERIFY_FORM:
            return `Verify ${getAuthSubTitle()}`

        default:
            return ''
    }
}

interface AuthCommonWrapperProps {
    currentUserProfile: BothCurrentUser
    currentStep?: string
    children: React.ReactNode
    isSignUp?: boolean
}

const AuthCommonWrapper: React.FC<AuthCommonWrapperProps> = ({
    currentUserProfile,
    currentStep,
    children,
    isSignUp,
}) => {
    return (
        <div className="bg-brandOffWhite-500 flex flex-col min-h-full items-stretch">
            <div>
                <Navbar
                    currentUserProfile={currentUserProfile}
                    showBackArrow={false}
                    title={getNavbarTitle(currentStep)}
                    isFromAuthPages={true}
                    isSignUp={isSignUp}
                />
                <div className="hiddden sm:block">
                    <CountdownTimer />
                </div>
            </div>
            <div className="flex-1 md:p-2   ">
                <div className="mx-auto h-full sm:h-auto sm:mt-10 mb-4  w-full sm:max-w-[422px] bg-white  p-4  sm:p-8 shadow sm:rounded-2xl">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default AuthCommonWrapper
