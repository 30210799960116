export default function validateSocialLinkIsNotAUrl(
    value: string,
    websiteCommonName: string,
) {
    const regex =
        /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/
    if (!regex.test(value) && !value.includes(websiteCommonName)) {
        return true
    } else {
        return false
    }
}
