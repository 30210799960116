import React from 'react'

interface ErrorMessageProps {
    isPortalError?: boolean
    meta: any
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
    isPortalError = false,
    meta,
}) => {
    if (meta.touched && meta.error)
        return isPortalError ? (
            <div className="text-sm text-brandPink-500 mt-1.5">
                {meta.error}
            </div>
        ) : (
            <span className="py-1 text-xs text-red-500 font-normal">
                {meta.error}
            </span>
        )

    return null
}

export default ErrorMessage
