import { Combobox } from '@headlessui/react'
import { Button } from '@src/app/common/components/Button'
import { closeModal } from '@src/app/redux/slices/modal.slice'
import { useAppDispatch } from '@src/app/redux/store'
import { isEmpty } from '@src/app/utils/retention-tool.util'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { BiLeftArrowAlt } from 'react-icons/bi'
import { HiCheck, HiSelector } from 'react-icons/hi'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { getApolloClient } from '../../../../ApolloClient'
import {
    useGetUserByIdLazyQuery,
    useGetUsersQuery,
    Users,
    Youtube_Channels,
} from '../../../../generated/graphql-operations'
import ContractVideoCard from './ContractVideoCard'
dayjs.extend(relativeTime)

const functions = getFunctions()

const youtubeContractType = [
    { id: 'channel', title: 'Youtube Channel' },
    { id: 'video', title: 'Youtube Videos' },
]

interface AddContractFormProps {}

const AddContractForm: React.FC<AddContractFormProps> = ({}) => {
    const dispatch = useAppDispatch()
    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedUser, setSelectedUser] = useState<Users | undefined>(
        undefined,
    )
    const [selectedPlatformAccount, setSelectedPlatformAccount] = useState<
        Youtube_Channels | undefined
    >(undefined)
    const [videoValidationResult, setVideoValidationResult] = useState<{
        isValid: boolean
        result: Array<{
            youtube_id: string
            title: string | null | undefined
            thumbnails: any
            published_at: string
            is_partner: boolean
        }>
    }>({ isValid: false, result: [] })

    const { data, loading } = useGetUsersQuery({
        variables: {
            orderBy: { created_at: 'asc' as any },
            limit: 100,
            where: {
                display_name: { _ilike: `%${searchValue}%` },
                portal_access: { _eq: true },
                // email: { _nilike: '%@creatordao.com%' },
            },
        },
    })

    const [getUser, { data: userData, loading: userDataLoading }] =
        useGetUserByIdLazyQuery()

    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={{
                userId: '',
                platform: 'Youtube',
                platformId: '',
                type: youtubeContractType[0].id,
                videoIds: '',
                term: '',
                startDate: dayjs().format('YYYY-MM-DD'),
                percentage: '',
            }}
            validationSchema={Yup.object({
                userId: Yup.string().required('User is required'),
                platform: Yup.string().required('Platform is required'),
                platformId: Yup.string().required(
                    'Platform account is required',
                ),
                type: Yup.string().when('platform', {
                    is: 'Youtube',
                    then: Yup.string().required('Contract type is required'),
                }),
                videoIds: Yup.string().when('type', {
                    is: 'video',
                    then: Yup.string()
                        .min(
                            11,
                            'The video ID is an 11-character alphanumeric string',
                        )
                        .required('At least 1 video id is required'),
                }),
                term: Yup.string()
                    .required('Term is required')
                    .matches(/^[0-9]+$/, 'Must be only digits'),
                startDate: Yup.string().required('Start date is required'),
                percentage: Yup.string()
                    .required('Percentage is required')
                    .matches(/^[0-9]+$/, 'Must be only digits'),
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                    if (
                        dayjs().diff(values.startDate, 'month') >
                        Number(values.term)
                    ) {
                        throw new Error(
                            'Difference between term and start date is not valid!',
                        )
                    }
                    const response: any = await httpsCallable(
                        functions,
                        'userContract',
                    )({
                        operation:
                            values.platform === 'Youtube' &&
                            values.type === 'video' &&
                            !videoValidationResult.isValid
                                ? 'VALIDATE_YOUTUBE_VIDEOS'
                                : 'CREATE',
                        contractForm: {
                            ...values,
                            startDate: dayjs(values.startDate).format(
                                'YYYY-MM',
                            ),
                        },
                    })

                    if (!isEmpty(response.data)) {
                        setVideoValidationResult(response.data as any)
                    } else {
                        setSubmitting(false)
                        await getApolloClient().refetchQueries({
                            include: ['GetUserContracts'],
                        })
                        dispatch(closeModal())
                    }
                } catch (error: any) {
                    toast.error(error.message)
                    setSubmitting(false)
                }
            }}
        >
            {({
                isSubmitting,
                isValid,
                errors,
                setFieldValue,
                values,
                touched,
            }) => (
                <Form className="space-y-4">
                    {videoValidationResult.isValid ? (
                        <>
                            <button
                                className="flex items-center gap-x-1 hover:bg-gray-50 rounded-md"
                                onClick={() =>
                                    setVideoValidationResult({
                                        isValid: false,
                                        result: [],
                                    })
                                }
                            >
                                <BiLeftArrowAlt
                                    className="h-7 w-8 cursor-pointer"
                                    aria-hidden="true"
                                />
                                <span className="pr-2">Back</span>
                            </button>
                            <p className="underline font-bold text-base">
                                Contract Video(s)
                            </p>
                            <span className="text-sm">
                                The invoice amount will be calculate based on
                                the revenue from these videos
                            </span>
                            <div className="flex flex-col pb-2 gap-y-2 overflow-auto h-[400px] max-h-[400px]">
                                {videoValidationResult.result.map(
                                    (video, idx) => (
                                        <ContractVideoCard
                                            key={idx}
                                            video={video}
                                        />
                                    ),
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <Combobox
                                as="div"
                                value={selectedUser}
                                onChange={(user: any) => {
                                    setSelectedUser(user)
                                    setFieldValue('userId', user.id)
                                    setSearchValue(user.display_name)
                                    getUser({ variables: { id: user.id } })
                                    setSelectedPlatformAccount(undefined)
                                    setFieldValue('platformId', '')
                                }}
                            >
                                <Combobox.Label className="block text-sm font-medium text-gray-700">
                                    User
                                </Combobox.Label>
                                <div className="relative mt-1">
                                    <Combobox.Input
                                        autoFocus
                                        autoComplete="off"
                                        className="w-full rounded-md border border-gray-200 bg-white py-2 pl-3 pr-10 shadow-sm 
                                        focus:outline-none focus:ring-0 focus:border-gray-200 sm:text-sm"
                                        onChange={event =>
                                            setSearchValue(event.target.value)
                                        }
                                        displayValue={(user: any) =>
                                            user?.display_name || ''
                                        }
                                        placeholder="Select User"
                                    />
                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <HiSelector
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </Combobox.Button>

                                    {!loading && data && data.users.length > 0 && (
                                        <Combobox.Options
                                            className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 
                                            text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                        >
                                            {data.users.map(user => (
                                                <Combobox.Option
                                                    key={user.id}
                                                    value={user}
                                                    className={({ active }) =>
                                                        `relative cursor-pointer select-none py-2 pl-3 pr-9
                                                    ${
                                                        active
                                                            ? 'bg-gray-100'
                                                            : 'text-gray-900'
                                                    }`
                                                    }
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <div className="flex items-center">
                                                                <img
                                                                    src={
                                                                        user.photo_url ||
                                                                        ''
                                                                    }
                                                                    alt=""
                                                                    className="inline-block h-6 w-6 rounded-full object-cover"
                                                                />
                                                                <span
                                                                    className={`
                                                                ml-3 truncate
                                                                ${
                                                                    selected &&
                                                                    'font-semibold'
                                                                }
                                                            `}
                                                                >
                                                                    {
                                                                        user.display_name
                                                                    }
                                                                </span>
                                                            </div>

                                                            {selected && (
                                                                <span
                                                                    className={`absolute inset-y-0 right-0 flex items-center pr-4 text-gray-800`}
                                                                >
                                                                    <HiCheck
                                                                        className="h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                        </Combobox.Options>
                                    )}
                                </div>
                                {touched.userId && errors.userId && (
                                    <p className="pt-1 text-xs text-red-500">
                                        {errors.userId}
                                    </p>
                                )}
                            </Combobox>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Platform
                                </label>
                                <div className="relative mt-1">
                                    <Field
                                        name="platform"
                                        type="text"
                                        className="shadow-sm block w-full sm:text-sm border border-gray-200 rounded-md 
                                focus:outline-none focus:ring-0 focus:border-gray-200"
                                        value="Youtube"
                                        autoComplete="off"
                                        disabled
                                    />
                                </div>
                                {touched.platform && errors.platform && (
                                    <p className="pt-1 text-xs text-red-500">
                                        {errors.platform}
                                    </p>
                                )}
                            </div>
                            <Combobox
                                as="div"
                                value={selectedPlatformAccount}
                                onChange={(channel: any) => {
                                    setSelectedPlatformAccount(channel)
                                    setFieldValue(
                                        'platformId',
                                        channel?.youtube_id,
                                    )
                                    setSearchValue(channel?.title || '')
                                }}
                                disabled={selectedUser === undefined}
                            >
                                <Combobox.Label className="block text-sm font-medium text-gray-700">
                                    Platform Account
                                </Combobox.Label>
                                <div className="relative mt-1">
                                    <Combobox.Input
                                        autoFocus
                                        autoComplete="off"
                                        className="w-full rounded-md border border-gray-200 bg-white py-2 pl-3 pr-10 shadow-sm 
                                        focus:outline-none focus:ring-0 focus:border-gray-200 sm:text-sm"
                                        onChange={event =>
                                            setSearchValue(event.target.value)
                                        }
                                        displayValue={(channel: any) =>
                                            channel?.title || ''
                                        }
                                        placeholder={
                                            selectedUser === undefined
                                                ? 'User is required'
                                                : userData?.users_by_pk
                                                      ?.youtube_channels
                                                      .length === 0
                                                ? 'No account connected'
                                                : 'Select channel'
                                        }
                                    />
                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <HiSelector
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </Combobox.Button>

                                    {!userDataLoading &&
                                        userData?.users_by_pk &&
                                        userData.users_by_pk.youtube_channels
                                            ?.length > 0 && (
                                            <Combobox.Options
                                                className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 
                                                text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                            >
                                                {userData.users_by_pk.youtube_channels.map(
                                                    channel => (
                                                        <Combobox.Option
                                                            key={channel.id}
                                                            value={channel}
                                                            className={({
                                                                active,
                                                                selected,
                                                            }) =>
                                                                `relative cursor-pointer select-none py-2 pl-3 pr-9
                                                    ${
                                                        active
                                                            ? 'bg-gray-100'
                                                            : 'text-gray-900'
                                                    }`
                                                            }
                                                        >
                                                            {({ selected }) => (
                                                                <>
                                                                    <div className="flex items-center">
                                                                        <img
                                                                            src={
                                                                                channel
                                                                                    .thumbnails
                                                                                    .medium
                                                                                    .url ||
                                                                                ''
                                                                            }
                                                                            alt=""
                                                                            className="inline-block h-6 w-6 rounded-full object-cover"
                                                                        />
                                                                        <span
                                                                            className={`
                                                                                ml-3 truncate
                                                                                ${
                                                                                    selected &&
                                                                                    'font-semibold'
                                                                                }
                                                                            `}
                                                                        >
                                                                            {
                                                                                channel.title
                                                                            }
                                                                        </span>
                                                                    </div>

                                                                    {selected && (
                                                                        <span
                                                                            className={`absolute inset-y-0 right-0 flex items-center pr-4 text-gray-800`}
                                                                        >
                                                                            <HiCheck
                                                                                className="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ),
                                                )}
                                            </Combobox.Options>
                                        )}
                                </div>
                                {touched.platformId && errors.platformId && (
                                    <p className="pt-1 text-xs text-red-500">
                                        {errors.platformId}
                                    </p>
                                )}
                            </Combobox>
                            {values.platform === 'Youtube' && (
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Contract Type
                                    </label>
                                    <fieldset className="mt-2">
                                        <legend className="sr-only">
                                            Contract Type
                                        </legend>
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                            {youtubeContractType.map(type => (
                                                <div
                                                    key={type.id}
                                                    className="flex items-center"
                                                >
                                                    <Field
                                                        id={type.id}
                                                        name="type"
                                                        type="radio"
                                                        className="h-4 w-4 border-gray-300 text-black focus:ring-1 focus:ring-black"
                                                        value={type.id}
                                                    />
                                                    <label
                                                        htmlFor={type.id}
                                                        className="ml-3 block text-sm font-medium text-gray-700"
                                                    >
                                                        {type.title}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </fieldset>
                                </div>
                            )}
                            {values.platform === 'Youtube' &&
                                values.type === 'video' && (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Video ID
                                            <span className="ml-1 text-xs text-gray-500">
                                                (comma separated for multiple
                                                ids)
                                            </span>
                                        </label>
                                        <div className="relative mt-1">
                                            <Field
                                                name="videoIds"
                                                type="text"
                                                className="shadow-sm block w-full sm:text-sm border border-gray-200 rounded-md 
                                                focus:outline-none focus:ring-0 focus:border-gray-200 placeholder:text-gray-300"
                                                placeholder="DqxEB0xp-Q0,h6H8lrBHWKk"
                                                autoComplete="off"
                                            />
                                        </div>
                                        {touched.videoIds &&
                                            errors.videoIds && (
                                                <p className="pt-1 text-xs text-red-500">
                                                    {errors.videoIds}
                                                </p>
                                            )}
                                    </div>
                                )}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Term
                                </label>
                                <div className="relative mt-1">
                                    <Field
                                        name="term"
                                        type="text"
                                        pattern="[0-9]*"
                                        className="shadow-sm block w-full sm:text-sm border border-gray-200 rounded-md 
                                        focus:outline-none focus:ring-0 focus:border-gray-200 placeholder:text-gray-300"
                                        placeholder="12"
                                        inputMode="numeric"
                                        autoComplete="off"
                                    />
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <span className="text-gray-500 sm:text-sm">
                                            Month
                                        </span>
                                    </div>
                                </div>
                                {touched.term && errors.term && (
                                    <p className="pt-1 text-xs text-red-500">
                                        {errors.term}
                                    </p>
                                )}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Start Date
                                </label>
                                <div className="mt-1">
                                    <DatePicker
                                        showMonthYearPicker
                                        minDate={dayjs()
                                            .subtract(
                                                Number(values.term || 0),
                                                'month',
                                            )
                                            .toDate()}
                                        dateFormat="MMMM, yyyy"
                                        selected={
                                            values.startDate
                                                ? dayjs(
                                                      values.startDate,
                                                  ).toDate()
                                                : dayjs().toDate()
                                        }
                                        onChange={(date: Date) => {
                                            setFieldValue(
                                                'startDate',
                                                dayjs(date),
                                            )
                                        }}
                                        popperPlacement="top"
                                        className="w-full rounded-md border border-gray-200 bg-white py-2 shadow-sm text-sm
                                        focus:outline-none focus:ring-0 focus:border-gray-200"
                                        placeholderText="Select Start Date"
                                    />
                                </div>
                                {touched.startDate && errors.startDate && (
                                    <p className="pt-1 text-xs text-red-500">
                                        {errors.startDate}
                                    </p>
                                )}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Percentage
                                </label>
                                <div className="relative mt-1">
                                    <Field
                                        name="percentage"
                                        type="text"
                                        pattern="[0-9]*"
                                        className="shadow-sm block w-full sm:text-sm border border-gray-200 rounded-md 
                                        focus:outline-none focus:ring-0 focus:border-gray-200 placeholder:text-gray-300"
                                        placeholder="10"
                                        inputMode="numeric"
                                        autoComplete="off"
                                    />
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <span className="text-gray-500 sm:text-sm">
                                            %
                                        </span>
                                    </div>
                                </div>
                                {touched.percentage && errors.percentage && (
                                    <p className="pt-1 text-xs text-red-500">
                                        {errors.percentage}
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                    <Button
                        className={`w-full shadow-sm text-sm sm:text-sm px-4 py-5 inline-flex ${
                            isValid ? 'bg-[#51E577]' : 'bg-[#F2F2F2]'
                        } text-black`}
                        type="submit"
                        loading={isSubmitting}
                    >
                        {values.type === 'video' &&
                        !videoValidationResult.isValid
                            ? 'Validate'
                            : 'Save'}
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

export default AddContractForm
