import Link from '@src/app/common/components/Link/Link'
import React from 'react'

interface HeroProps {}

const Hero = ({}: HeroProps) => {
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-0">
            <div className="text-center">
                <p className="max-w-6xl mx-auto mt-1 text-5xl font-black text-black sm:text-5xl lg:text-[96px] lg:leading-[104px] select-none">
                    Join Creator Launchpad
                </p>
                <p className="max-w-2xl mt-5 mx-auto font-normal text-base sm:text-xl text-black leading-[24px] leading-[28px]">
                    We want to hear all about how you’re taking the internet by
                    storm, and why you’re pumped to help other creators do the
                    same. We’ll read through your application and let you know
                    if you’re a good fit. Got questions about Creator Launchpad?{' '}
                    <Link to="/launchpad" className="underline">
                        {' '}
                        Learn more.
                    </Link>
                </p>
            </div>
        </div>
    )
}

export default Hero
