import React from 'react'
import clsx from 'clsx'
import Spinner from '@src/app/layout/Spinner'
import { isStillUploading } from '@src/app/common/util/util'
import {
    PhotoInputVariant,
    useCommonPhotoInput,
} from './hooks/useCommonPhotoInput'
import ImagePreview from './components/ImagePreview'
import { Button } from '../../components/Button'
import ErrorMessage from './components/ErrorMessage'

interface CommonPhotoInputProps {
    name: string
    label?: string
    showErrorBorder?: boolean
    variant?: PhotoInputVariant
    previewImageClassName?: string
    storagePath?: string
}

const CHANGE_PROFILE_TITLE = 'Change profile photo'

const CommonPhotoInput: React.FC<CommonPhotoInputProps> = ({
    name,
    label,
    showErrorBorder,
    variant = PhotoInputVariant.PORTAL,
    previewImageClassName,
    storagePath,
}) => {
    const { previewImgUrl, field, meta, handleChange, changeFile } =
        useCommonPhotoInput({
            name,
            variant,
            storagePath,
        })

    return (
        <div>
            {label && (
                <label className="appearance-none block text-base font-medium text-gray-700 select-none mb-2">
                    {label}
                </label>
            )}
            <div
                className={clsx(
                    'flex items-center',
                    variant === PhotoInputVariant.PORTAL
                        ? ''
                        : 'justify-center py-6 space-x-3',
                )}
            >
                <ImagePreview
                    isPortal={variant === PhotoInputVariant.PORTAL}
                    fieldValue={field.value}
                    imgSrc={previewImgUrl}
                    previewImageClassName={previewImageClassName}
                />
                <input {...field} name={name} type="hidden" />
                <input
                    type="file"
                    id="input_file"
                    style={{ display: 'none' }}
                    onChange={handleChange}
                    accept=".png,.jpg,.jpeg,.heic"
                />
                {variant === PhotoInputVariant.PORTAL && (
                    <Button
                        type="button"
                        className="rounded-md shadow-sm text-sm leading-4 font-medium border border-gray-300 px-3 ml-5"
                        onClick={changeFile}
                    >
                        {meta.initialValue
                            ? CHANGE_PROFILE_TITLE
                            : 'Choose profile photo'}
                    </Button>
                )}
            </div>
            {variant === PhotoInputVariant.PORTAL ? (
                <ErrorMessage isPortalError meta={meta} />
            ) : (
                <div className="flex flex-col items-center justify-center">
                    <span
                        className={`flex justify-center border border-black bg-white text-black 
                    font-bold py-3 px-6 rounded-[50px] w-56 cursor-pointer ${
                        showErrorBorder && meta.error && meta.touched
                            ? 'border-red-500'
                            : ''
                    }`}
                        onClick={changeFile}
                    >
                        {isStillUploading(field.value) ? (
                            <div className="flex space-x-2">
                                <Spinner size="h-5 w-5" />
                                <span>{field.value.slice(10)} %</span>
                            </div>
                        ) : meta.value ? (
                            CHANGE_PROFILE_TITLE
                        ) : (
                            'Upload profile photo'
                        )}
                    </span>
                    <ErrorMessage meta={meta} />
                </div>
            )}
        </div>
    )
}

export default CommonPhotoInput
