import React, { useCallback, useEffect, useState } from 'react'
import { useField } from 'formik'
import { useDropzone } from 'react-dropzone'
import { HiPlus, HiTrash } from 'react-icons/hi'
import { v4 as uuid } from 'uuid'
import { toast } from 'react-toastify'
import {
    getStorage,
    ref,
    uploadBytesResumable,
    deleteObject,
    getDownloadURL,
} from 'firebase/storage'
import { app } from '../../config/firebase'
import produce from 'immer'
import Spinner from '../../layout/Spinner'
import ReactPlayer from 'react-player'

interface MediaInputProps {
    label?: string
    accept?: unknown
    acceptLabel?: string
    fileLabel?: string
    storageDirectoryPath?: string
    context?: any
    onChange?: () => void
    [props: string]: any
}

export type FileType = {
    url: string
    name: string
    id: string
    local_name: string
    storage_bucket: string
    storage_directory_path: string
    type: string
    width: number
    height: number
    uploading: boolean
}

export const MEDIA_INPUT_CONTEXT = {
    accept: {
        'image/*': [
            '.jpeg',
            '.png',
            '.gif',
            '.svg',
            '.mp4',
            '.avi',
            '.mov',
            '.wmv',
        ],
        'video/*': ['.mp4', '.avi', '.mov', '.wmv'],
    },
    acceptLabel: 'MP4, AVI, MOV, WMV, JPG, PNG, GIF, SVG',
    fileLabel: 'images / videos',
    storageDirectoryPath: 'media',
    setContentDisposition: false,
}

export const FILE_INPUT_CONTEXT = {
    accept: {},
    fileLabel: 'files',
    storageDirectoryPath: 'files',
    setContentDisposition: true,
}

export default function MediaInput({
    label,
    context = FILE_INPUT_CONTEXT,
    ...props
}: MediaInputProps) {
    const [field, meta, helpers] = useField(props as any)
    const [files, setFiles] = useState(meta.initialValue)

    // When our local state variable changes as we
    // upload or remove images, we need to update the form
    // for validation.
    useEffect(() => {
        helpers.setValue(
            files.map((file: any) => ({
                url: file.url,
                name: file.name,
                id: file.id,
                local_name: file.local_name,
                storage_bucket: file.storage_bucket,
                storage_directory_path: file.storage_directory_path,
                type: file.type,
                width: file.width,
                height: file.height,
                uploading: file.uploading,
            })),
            true,
        )
    }, [files])

    const resolveUploadStatus = useCallback(
        (id: any, url: any, error: any) => {
            setFiles((prevData: any) => {
                const fileIndex = prevData.findIndex(
                    (file: any) => file.id == id,
                )

                if (fileIndex == -1) {
                    return prevData
                }

                const newFiles = produce(prevData, (draftState: any) => {
                    draftState[fileIndex].url = url
                    draftState[fileIndex].uploading = false
                    draftState[fileIndex].uploadingError = error
                })
                return newFiles
            })
        },
        [files, setFiles],
    )

    const updateUploadProgress = useCallback(
        (id: any, progress: any) => {
            setFiles((prevData: any) => {
                const fileIndex = prevData.findIndex(
                    (file: any) => file.id == id,
                )

                if (fileIndex == -1) {
                    return prevData
                }

                const newFiles = produce(prevData, (draftState: any) => {
                    draftState[fileIndex].progress = progress
                })
                return newFiles
            })
        },
        [files, setFiles],
    )

    const setMediaSize = useCallback(
        (id: any, height: any, width: any) => {
            setFiles((prevData: any) => {
                const fileIndex = prevData.findIndex(
                    (file: any) => file.id == id,
                )

                if (fileIndex == -1) {
                    return prevData
                }

                const newFiles = produce(prevData, (draftState: any) => {
                    draftState[fileIndex].height = height
                    draftState[fileIndex].width = width
                })
                return newFiles
            })
        },
        [files, setFiles],
    )

    const deleteFile = useCallback(
        (id: any) => {
            setFiles((prevData: any) => {
                const fileIndex = prevData.findIndex(
                    (file: any) => file.id == id,
                )
                const file = prevData[fileIndex]

                const newFiles = produce(prevData, (draftState: any) => {
                    draftState.splice(fileIndex, 1)
                })

                // If it's got a local preview, then it was just added, so we can
                // delete it from Firebase storage and revokeObjectURL
                if (file.preview) {
                    const storageRef = ref(
                        getStorage(app),
                        `${file.storage_directory_path}/${file.name}`,
                    )

                    deleteObject(storageRef)
                        .then(() => {
                            // File deleted successfully
                        })
                        .catch(error => {
                            // For some reason this is throwing an error,
                            // even when the delete it successful,
                            // as if the object is not found and
                            // its not clear why. This could be a permissions
                            // issue.
                            // console.log(error)
                        })

                    URL.revokeObjectURL(file.preview)
                }

                return newFiles
            })
        },
        [files, setFiles],
    )

    const onDrop = useCallback(
        async (acceptedFiles: any, fileRejections: any) => {
            if (fileRejections && fileRejections.length > 0) {
                const rejectionsString = fileRejections
                    .map((rejection: any) => rejection.file.name)
                    .join(', ')

                toast.error('File types not supported: ' + rejectionsString)
            }

            const newFiles: any = produce(files, (draftState: any) => {
                for (const file of acceptedFiles) {
                    const id = uuid()
                    const name = id + '.' + file.type.split('/')[1]

                    draftState.push({
                        id: id,
                        type: file.type,
                        name: name,
                        local_name: file.name,
                        storage_bucket: ref(getStorage(app)).bucket,
                        storage_directory_path: context.storageDirectoryPath,
                        preview: URL.createObjectURL(file),
                        url: null,
                        uploading: true,
                        uploadingError: null,
                        file: file,
                    })
                }
            })

            // Need to fire set the newFiles, then update the
            setFiles(newFiles)

            // Now that the initial files have been set,
            // you can compute and upload on the files
            // and the state variables will be updated
            // asynchronusly.
            for (const newFile of newFiles) {
                if (newFile.type.includes('image')) {
                    const imageForGettingSize = new Image()
                    imageForGettingSize.addEventListener('load', () => {
                        setMediaSize(
                            newFile.id,
                            imageForGettingSize.height,
                            imageForGettingSize.width,
                        )
                    })
                    imageForGettingSize.src = newFile.preview
                } else if (newFile.type.includes('video')) {
                    const video = document.createElement('video')
                    video.addEventListener('loadedmetadata', function () {
                        setMediaSize(
                            newFile.id,
                            this.videoHeight,
                            this.videoWidth,
                        )
                    })
                    video.src = newFile.preview
                }

                const storageRef = ref(
                    getStorage(app),
                    `${newFile.storage_directory_path}/${newFile.name}`,
                )

                const metaData = context.setContentDisposition && {
                    contentDisposition: `attachment; filename="${newFile.local_name}"`,
                }

                const uploadTask = uploadBytesResumable(
                    storageRef,
                    newFile.file,
                    metaData,
                )

                uploadTask.catch(error => {
                    try {
                        uploadTask.resume()
                    } catch (error) {
                        uploadTask.cancel()
                        resolveUploadStatus(newFile.id, null, error)
                    }
                })

                uploadTask.on(
                    'state_changed',
                    snapshot => {
                        snapshot.task.catch(error => {
                            try {
                                snapshot.task.resume()
                            } catch (error) {
                                snapshot.task.cancel()
                                resolveUploadStatus(newFile.id, null, error)
                            }
                        })

                        if (snapshot.state !== 'running') {
                            resolveUploadStatus(
                                newFile.id,
                                null,
                                'Error on uploading...',
                            )
                        } else {
                            const percent =
                                snapshot.totalBytes > 0
                                    ? Math.round(
                                          (snapshot.bytesTransferred /
                                              snapshot.totalBytes) *
                                              100,
                                      )
                                    : 0

                            updateUploadProgress(newFile.id, percent)
                        }
                    },
                    error => {
                        toast.error(error.message)
                        resolveUploadStatus(newFile.id, null, error)
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then(
                            downloadURL => {
                                resolveUploadStatus(
                                    newFile.id,
                                    downloadURL,
                                    null,
                                )
                            },
                        )
                    },
                )
            }
        },
        [files, setFiles],
    )

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        onDrop,
        noClick: true,
        multiple: true,
        accept: context.accept,
    })

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () =>
            files.forEach((file: any) => URL.revokeObjectURL(file.preview))
    }, [])

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div
                {...getRootProps()}
                className={
                    (isDragActive ? 'border-brand-500 ' : 'border-gray-300 ') +
                    'mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'
                }
            >
                <input {...getInputProps()} />
                {files && files.length > 0 && (
                    <div className="flex flex-wrap gap-4">
                        {files.map((file: any, index: number) => (
                            <div key={index} className="relative flex-shrink-0">
                                {file.uploading ? (
                                    <>
                                        <div className="top-0 left-0 w-full h-full absolute bg-white opacity-40	flex justify-center items-center text-xs text-gray-900 font-extrabold">
                                            <Spinner size="h-7 w-7" />
                                        </div>
                                        <div className="z-10 bottom-2 w-16 left-8 h-4 absolute bg-gray-600 text-white	flex justify-center items-center text-xs font-extrabold rounded-full">
                                            {file.progress ?? '0'}%
                                        </div>
                                    </>
                                ) : file.uploadingError ? (
                                    <div className="top-0 left-0 w-full h-full absolute bg-red-300 opacity-80	flex justify-center items-center text-xs text-gray-900 font-extrabold">
                                        Error uploading
                                    </div>
                                ) : (
                                    <button
                                        onClick={() => deleteFile(file.id)}
                                        type="button"
                                        className="z-10 top-2 left-2 w-8 h-8 rounded-full absolute bg-gray-700 flex justify-center items-center"
                                    >
                                        <HiTrash
                                            className="h-4 w-4 text-white"
                                            aria-hidden="true"
                                        />
                                    </button>
                                )}

                                {file.type.includes('video') ? (
                                    <div
                                        className={
                                            'object-contain border border-gray-300 w-32 h-32 rounded-md ' +
                                            (file.uploading
                                                ? 'opacity-40'
                                                : 'opacity-100')
                                        }
                                    >
                                        <ReactPlayer
                                            width={126}
                                            height={126}
                                            url={file.preview ?? file.url}
                                        />
                                    </div>
                                ) : (
                                    <img
                                        className={
                                            'object-contain border border-gray-300 w-32 h-32 rounded-md ' +
                                            (file.uploading
                                                ? 'opacity-40'
                                                : 'opacity-100')
                                        }
                                        src={file.preview ?? file.url}
                                    />
                                )}
                            </div>
                        ))}
                        <button
                            onClick={open}
                            type="button"
                            className="w-32 h-32 rounded-md border-2 border-gray-300 border-dashed flex justify-center items-center"
                        >
                            <HiPlus
                                className="h-16 w-16 text-gray-400"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                )}
                {(!files || files.length == 0) && (
                    <div className="space-y-1 text-center">
                        <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                            <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer bg-white rounded-md font-medium text-brand-600
                hover:text-brand-500 focus-within:outline-none focus-within:ring-0 focus-within:ring-offset-2 focus-within:ring-brand-500"
                            >
                                <span
                                    onClick={open}
                                    className=" cursor-pointer"
                                >
                                    Upload {context.fileLabel}
                                </span>
                            </label>
                            <p className="pl-1">or drag and drop</p>
                        </div>
                        {context.acceptLabel && (
                            <p className="text-xs text-gray-500">
                                {context.acceptLabel}
                            </p>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
