import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IModal } from '../../interfaces/modal.interface'

const initialState: IModal = {
    modalType: null,
    modalProps: null,
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action: PayloadAction<IModal>) => {
            state.modalType = action.payload.modalType
            state.modalProps = action.payload.modalProps
        },
        closeModal: state => {
            state.modalType = null
            state.modalProps = null
        },
    },
})

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer
