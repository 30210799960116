import { GetCompetitionQuery } from '@src/generated/graphql-operations'

export enum CURRENT_PHASE {
    APPLICATIONS_OPEN = 'applications',
    _1ST_GROUPING_BUFFER = 'first-buffer',
    FIRST_ROUND = 'first-round',
    _2ND_GROUPING_BUFFER = 'second-buffer',
    SECOND_ROUND = 'second-round',
    _3RD_GROUPING_BUFFER = 'third-buffer',
    SEMI_FINALS = 'semi-finals',
    _4TH_GROUPING_BUFFER = 'fourth-buffer',
    FINALS = 'finals',
    COMPETITION_OVER = 'competition-over',
}
export function useGetCurrentPhase(
    competition?: GetCompetitionQuery['competitions_by_pk'],
): CURRENT_PHASE {
    if (!competition) return CURRENT_PHASE.APPLICATIONS_OPEN

    const datetime_for_testing = competition.datetime_for_testing
    const time = datetime_for_testing
        ? new Date(datetime_for_testing)
        : new Date()

    const allEndDates: Date[] = []
    // identify which competition.phase we are in based on now being within phase.start_date and phase.end_date
    const currentCompetitionPhase = competition.phases.find((phase: any) => {
        const startDate = new Date(phase.start_date)

        const endDate = new Date(phase.end_date)
        allEndDates.push(endDate)
        return time > startDate && time <= endDate
    })

    if (currentCompetitionPhase) return currentCompetitionPhase.phase_id
    else if (time > allEndDates[allEndDates.length - 1])
        return CURRENT_PHASE.COMPETITION_OVER
    return CURRENT_PHASE.APPLICATIONS_OPEN
}
