import _ from 'lodash'

const _sortGroupByVotes = (group: any) => {
    return _.sortBy(group?.competition_group_memberships, (a: any, b: any) => {
        return (
            b?.competition_votes_aggregate?.aggregate?.count ||
            0 - a?.competition_votes_aggregate?.aggregate?.count ||
            0
        )
    })
}
export default _sortGroupByVotes
