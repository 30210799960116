import Link from '@src/app/common/components/Link/Link'
import React from 'react'

interface FooterProps {}

const Footer = ({}: FooterProps) => {
    return (
        <div className="bg-[#F6F9F0] pb-5 lg:pb-8 -mt-5 lg:-mt-8 pt-[80px]">
            <div className="px-5 lg:px-8">
                <div className="relative">
                    <div className="absolute bg-[#FFA767] h-52 w-full rounded-[48px]"></div>
                </div>
            </div>
            <div className="px-[32px] lg:px-[72px]">
                <div className="relative">
                    <div className="absolute bg-[#FC6ADC] h-52 w-full rounded-[48px]"></div>
                </div>
            </div>
            <div className="px-[44px] lg:px-[112px]">
                <div className="relative">
                    <div className="absolute bg-[#48C96C] h-52 w-full rounded-[48px]"></div>
                </div>
            </div>
            <div className="px-[56px] lg:px-[152px]">
                <div className="relative">
                    <div className="flex-1 z-30 bg-[#F6F9F0] h-52 w-full rounded-[48px] border-2 border-black px-10 xl:px-20">
                        <div className="hidden xl:grid grid-cols-3 w-full h-full items-center">
                            <div className="flex flex-col">
                                <Link
                                    to="/terms"
                                    className="text-base font-medium"
                                >
                                    Terms
                                </Link>
                                <Link
                                    to="/privacy"
                                    className="text-base font-medium"
                                >
                                    Privacy
                                </Link>
                                <a
                                    href="mailto:hello@creatordao.com"
                                    className="text-base font-medium"
                                >
                                    Contact
                                </a>
                            </div>

                            <div className="flex w-full h-20 justify-center">
                                <Link to="/">
                                    <img
                                        src="/images/cdao_logo.png"
                                        className="w-full h-full object-cover"
                                    />
                                </Link>
                            </div>
                            <div className="flex w-full justify-end">
                                <span className="text-base font-medium select-none">
                                    © 2022 CreatorDAO
                                </span>
                            </div>
                        </div>
                        <div className="xl:hidden flex flex-col w-full h-full justify-between items-center">
                            <div className="h-20 w-40 mt-8">
                                <img
                                    src="/images/cdao_logo.png"
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <div className="flex flex-col justify-center items-center mb-6 text-sm font-medium">
                                <div>
                                    <Link to="/terms">Terms</Link>
                                    &nbsp;·&nbsp;
                                    <Link to="/privacy">Privacy</Link>
                                    &nbsp;·&nbsp;
                                    <a href="mailto:hello@creatordao.com">
                                        Contact
                                    </a>
                                </div>
                                <div className="select-none">
                                    © 2022 CreatorDAO
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
