import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApplications } from '../../interfaces/applications.interface'

const initialState: IApplications = {
    isSignup: false,
    isCreated: false,
    isExist: false,
    isComplete: false,
    tempEmail: undefined,
}

export const applicationSlice = createSlice({
    name: 'applications',
    initialState,
    reducers: {
        setApplications: (
            state,
            action: PayloadAction<{
                isSignup?: boolean
                isCreated?: boolean
                isExist?: boolean
                isComplete?: boolean
                tempEmail?: string
            }>,
        ) => {
            state.isComplete = action.payload.isComplete || state.isComplete
            state.isCreated = action.payload.isCreated || state.isCreated
            state.isExist = action.payload.isExist || state.isExist
            state.isSignup = action.payload.isSignup || state.isSignup
            state.tempEmail = action.payload.tempEmail || state.tempEmail
        },
        resetApplications: state => {
            state.isComplete = false
            state.isCreated = false
            state.isExist = false
            state.isSignup = false
            state.tempEmail = undefined
        },
    },
})

export const { setApplications, resetApplications } = applicationSlice.actions

export default applicationSlice.reducer
