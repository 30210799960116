import { useFeatureFlag } from '@src/features/shared/useFeatureFlag'
import React from 'react'
import Follow from '../home/components/Follow'
import AboutProgram from './components/AboutProgram'
import Hero from './components/Hero'
import HeroDescription from './components/HeroDescription'
import JoinUsBanner from './components/JoinUsBanner'
import KeyExperience from './components/KeyExperience'
import LaunchpadFAQ from './components/LaunchpadFAQ'
import LaunchpadHero from './components/LaunchpadHero'
import OurCommunity from './components/OurCommunity'
import ReadyToJoin from './components/ReadyToJoin'
import Superboost from './components/Superboost'
import YouWillGet from './components/YouWillGet'

interface LaunchpadProps {}

const Launchpad = ({}: LaunchpadProps) => {
    const showNewLaunchpad = useFeatureFlag('show_new_launchpad')
    return (
        <div className="w-full">
            {showNewLaunchpad ? (
                <>
                    <Hero />
                    <Superboost />
                    <YouWillGet />
                    <OurCommunity />
                    <JoinUsBanner />
                    <AboutProgram />
                    <ReadyToJoin />
                </>
            ) : (
                <>
                    <LaunchpadHero />
                    <HeroDescription />
                    <KeyExperience />
                    <LaunchpadFAQ />
                    <div className="py-[100px] max-w-7xl mx-auto mb-[-200px] lg:mb-[-140px]">
                        <Follow />
                    </div>
                </>
            )}
        </div>
    )
}

export default Launchpad
