import React, { useMemo, useState } from 'react'
import useWindowSizeHook from '@src/app/hooks/useWindowSize.hook'
import { twMerge } from 'tailwind-merge'

interface ShowMoreTextProps {
    title: string
    deskTopMaxCharLength?: number
    mobileMaxCharLength?: number
    className?: string
    btnClassName?: string
}

const ShowMoreText: React.FC<ShowMoreTextProps> = ({
    title,
    deskTopMaxCharLength = 275,
    mobileMaxCharLength = 215,
    className = 'text-base font-normal relative -tracking-wider',
    btnClassName = '',
}) => {
    const { width } = useWindowSizeHook()
    const [showHideText, setShowHideText] = useState<boolean>(false)

    const maxCharLength = useMemo(
        () =>
            width && width < 768 ? mobileMaxCharLength : deskTopMaxCharLength,
        [width, mobileMaxCharLength, deskTopMaxCharLength],
    )

    const toggleShowHideText = () => setShowHideText(prev => !prev)

    if (!title) return null

    return (
        <p className={className}>
            {!showHideText && Number(title?.length) > maxCharLength
                ? `${title.slice(0, maxCharLength)}...`
                : title}

            {Number(title?.length) > maxCharLength && (
                <span
                    className={twMerge(
                        'ml-0.5 font-bold bg-white cursor-pointer',
                        btnClassName,
                        ` ${showHideText ? 'block' : ''} `,
                    )}
                    onClick={() => toggleShowHideText()}
                >
                    {!showHideText ? 'show more' : ' show less'}
                </span>
            )}
        </p>
    )
}

export default ShowMoreText
