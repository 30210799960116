import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { sentryTrack } from './utils/sentry-track'
import jwt from 'jsonwebtoken'

let apolloClient
let apolloClientIdToken

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            sentryTrack({ message: message, location: locations })
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            )
        })
    if (networkError) {
        console.log(`[Network error]: ${networkError}`)
        sentryTrack({ error: networkError })
    }
})

// This key is not sensitive and is just used for development purposes
const LOCAL_EMULATOR_SECRET = 'thiskeyneedstobe32characterslongatleasttobevalid'

// Necessary to get Hasura to play nicely with Firebase Auth
// emulator
// https://github.com/hasura/graphql-engine/issues/6338
// When using emulators, instead of having this be in HASURA_GRAPHQL_JWT_SECRET
// {
//     "audience": "downing-development-7d73c",
//     "allowed_skew": 86400,
//     "jwk_url": "https://www.googleapis.com/service_accounts/v1/jwk/securetoken@system.gserviceaccount.com",
//     "type": "RS256",
//     "issuer": "https://securetoken.google.com/downing-development-7d73c"
// }
// Use instead
//{"type":"HS256", "key": "thiskeyneedstobe32characterslongatleasttobevalid"}
const getLocallySignedToken = token => {
    return jwt.sign(jwt.decode(token), LOCAL_EMULATOR_SECRET)
}

function createApolloClient(idToken) {
    const url = `http${process.env.REACT_IS_LOCAL_HASURA ? '' : 's'}://${
        process.env.REACT_APP_HASURA_URL
    }`

    return new ApolloClient({
        link: from([
            errorLink,
            new HttpLink({
                uri: url,
                headers: idToken && {
                    Authorization: `Bearer ${
                        process.env.REACT_APP_USE_AUTH_EMULATOR === 'true'
                            ? getLocallySignedToken(idToken)
                            : idToken
                    }`,
                },
            }),
        ]),
        cache: new InMemoryCache({
            typePolicies: {
                reactions: {
                    keyFields: ['user_id', 'post_id'],
                },
                posts: {
                    fields: {
                        reactions_aggregate: {
                            merge(existing, incoming) {
                                return incoming
                            },
                        },
                    },
                },
            },
        }),
    })
}

export function clearApolloClient() {
    apolloClient = null
}

export function setApolloClientIfNecessary(idToken) {
    if (!apolloClient || (idToken != apolloClientIdToken && idToken)) {
        apolloClient = createApolloClient(idToken)
        apolloClientIdToken = idToken
        return apolloClient
    }

    return apolloClient
}

export function getApolloClient() {
    return apolloClient
}
