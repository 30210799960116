import React from 'react'
import { getAvailableTimeSlots } from '../../../../helper/bookSessionOfficeHours'
import DateTimeButton from './DateTimeButton'

interface BuildHourChipsProps {
    error: any
    setErrors: (any: any) => any
    userDate: string
    from: string
    to: string
    showTitle?: boolean
    limit?: number
    slots: Array<any>
    selectedId: string
    originalTz: string
    showMoreClicked: boolean
    currentTz: string
    duration: number
    previouslyBookedSessions: Array<object>
    clickedShowMore: string
    setClickedShowMore: (any: any) => any
    setUserSelectedDate: (any: any) => any
    setSelectedId: (any: any) => any
    setUserSelectedSlot: (any: any) => any
}

const BuildHourChips = ({
    error,
    setErrors,
    userDate,
    from,
    to,
    showTitle = true,
    limit = 0,
    slots,
    selectedId,
    originalTz,
    showMoreClicked,
    currentTz,
    duration,
    previouslyBookedSessions,
    clickedShowMore,
    setClickedShowMore,
    setUserSelectedDate,
    setSelectedId,
    setUserSelectedSlot,
}: BuildHourChipsProps) => {
    let shown = 0
    let timeSlots = slots

    if (timeSlots.length === 0) {
        timeSlots = getAvailableTimeSlots({
            formatedDate: userDate,
            startTime: from,
            endTime: to,
            originalTz,
            currentUserTz: currentTz,
            duration,
            previouslyBookedSessions,
        })
    }

    return (
        <div
            key={`${userDate}_${from}_${to}`}
            className={`col-span-2 sm:col-span-1 sm:gap-4 sm:items-start ${
                showTitle ? 'office-hours__wrapper' : ''
            } `}
        >
            {showTitle && (
                <p className="font-bold text-lg pt-4">
                    Select any of the available Time Slots
                </p>
            )}
            <div className=" grid grid-cols-2 gap-3 ml-1 mb-3">
                {timeSlots.map((slot, idx) => {
                    const slotId = `${userDate}_${slot}_${idx}`
                    shown++

                    return (
                        <DateTimeButton
                            key={slotId}
                            dateTime={`${userDate} ${slot}`}
                            className={`${
                                selectedId === slotId ? 'bg-gray-300' : ''
                            } ${
                                clickedShowMore === `show_more_${userDate}` ||
                                shown < 3 ||
                                showMoreClicked
                                    ? 'block'
                                    : 'hidden'
                            }`}
                            onClick={() => {
                                setUserSelectedDate(userDate)
                                setSelectedId(slotId)
                                setUserSelectedSlot(`${userDate} ${slot}`)

                                if (error) {
                                    setErrors({})
                                }
                            }}
                        />
                    )
                })}
                {limit > 0 &&
                    clickedShowMore !== `show_more_${userDate}` &&
                    (timeSlots.length > 0 ? (
                        <div
                            onClick={() => {
                                setClickedShowMore(`show_more_${userDate}`)
                            }}
                            className="cursor-pointer  px-3 py-2 rounded-full text-normal text-center font-normal text-gray-800 hover:bg-gray-300"
                        >
                            show more
                        </div>
                    ) : (
                        <p className="px-2.5 mb-3 text-sm font-normal text-gray-800">
                            No slots available
                        </p>
                    ))}
            </div>
        </div>
    )
}

export default BuildHourChips
