import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICompetitionApplications } from '../../interfaces/competition-application.interface'

const initialState: ICompetitionApplications = {
    isAgreeingToTerms: false,
    videoURL: {
        isValidating: false,
        isLoading: false,
        isError: false,
        isVerified: false,
    },
}

export const competitionApplicationSlice = createSlice({
    name: 'competition-application',
    initialState,
    reducers: {
        setIsAgreeingToTerms: (state, action: PayloadAction<boolean>) => {
            state.isAgreeingToTerms = action.payload || state.isAgreeingToTerms
        },
        resetCompetitionApplication: state => {
            state.isAgreeingToTerms = false
        },
        setVideoURLState: (
            state,
            action: PayloadAction<{
                isValidating: boolean
                isLoading: boolean
                isError: boolean
                isVerified: boolean
            }>,
        ) => {
            state.videoURL.isValidating = action.payload.isValidating
            state.videoURL.isLoading = action.payload.isLoading
            state.videoURL.isError = action.payload.isError
            state.videoURL.isVerified = action.payload.isVerified
        },
        resetVideoURLState: state => {
            state.videoURL.isValidating = false
            state.videoURL.isLoading = false
            state.videoURL.isError = false
            state.videoURL.isVerified = false
        },
    },
})

export const {
    setIsAgreeingToTerms,
    resetCompetitionApplication,
    setVideoURLState,
    resetVideoURLState,
} = competitionApplicationSlice.actions

export default competitionApplicationSlice.reducer
