import React from 'react'

const JudgingInfo: React.FC = () => {
    return (
        <div className="max-w-[1072px] mx-auto mt-7 md:mt-14 bg-brandOffWhite-500 px-5 md:px-6 py-6 md:py-10 rounded-2xl relative z-20">
            <p className="uppercase text-brandPink-1000 font-bold mb-6 text-center md:text-left">
                How Does Judging work?
            </p>
            <div className="md:pl-4 space-y-4">
                <p className="text-base leading-5">
                    Judge’s scoring is based on the following criteria:
                </p>
                <ul className="list-disc pl-5 space-y-2">
                    <li className="font-bold">
                        Fan Engagement Rating (number of votes on #TheSearch,
                        virality of Submission Content)
                    </li>
                    <li className="font-bold">
                        Creativity of Submission Content
                    </li>
                    <li className="font-bold">Quality of Submission Content</li>
                    <li className="font-bold">
                        Potential Future Success as a Creator
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default JudgingInfo
