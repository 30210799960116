import React from 'react'
import { useAppSelector } from '../../redux/store'
import FormModal from './FormModal'

import BookSessionModal from './BookSessionModal'
import ConfirmationModal from './ConfirmationModal'
import DealsModal from './DealsModal'
import VoteConfirmationModal from './VoteConfirmationModal'
import StripePaymentModal from './StripePaymentModal'
import AdminAddContractModal from '@src/features/dashboard/admin/contracts/AddContractModal'
import AdminAddPastInvoiceModal from '@src/features/dashboard/admin/user-contract/AddPastInvoiceModal'

export default function ModalManager() {
    const modalLookup: { [x: string]: (...props: any) => JSX.Element | null } =
        {
            FormModal,
            ConfirmationModal,
            BookSessionModal,
            DealsModal,
            VoteConfirmationModal,
            StripePaymentModal,
            AdminAddContractModal,
            AdminAddPastInvoiceModal,
        }
    const { modalType, modalProps } = useAppSelector(state => state.modals)
    let renderedModal

    if (modalType) {
        const ModalComponent = modalLookup[modalType]
        renderedModal = <ModalComponent {...modalProps} />
    }

    return <span>{renderedModal}</span>
}
