import Link from '@src/app/common/components/Link/Link'
import React from 'react'
import { getAuthSubTitle } from '../../../app/common/util/util'
import { EMAIL_AUTH, SMS_VERIFY_FORM } from '../constants'
import NewEmailForm from './NewEmailForm'
import NewPhoneForm from './NewPhoneForm'
import NewSocialLogin from './NewSocialLogin'

interface AuthFormProps {
    authFormCurrentStep: string
    userFormData: any
    redirectTo: string
    resendPhoneCode: boolean
    isSignUp?: boolean
    handleChangeUserFormData: (values: any) => any
    handleChangeStep: (values: any) => any
    handleToggleAuthForm: () => void
    setResendPhoneCode: (values: any) => any
}

const AuthForm: React.FC<AuthFormProps> = ({
    authFormCurrentStep,
    userFormData,
    redirectTo,
    resendPhoneCode,
    isSignUp,
    handleChangeUserFormData,
    handleChangeStep,
    handleToggleAuthForm,
    setResendPhoneCode,
}) => {
    const handleEmailFormSubmit = (values: any) => {
        handleChangeUserFormData(values)
        handleChangeStep(redirectTo)
    }

    const handlePhoneFormSubmit = (values: any) => {
        handleChangeUserFormData(values)
        handleChangeStep(SMS_VERIFY_FORM)
    }

    return (
        <React.Fragment>
            <h2 className="font-bold text-2xl leading-[22px] mb-4">
                {isSignUp ? 'Sign up' : 'Log in'} {getAuthSubTitle()}
            </h2>
            {authFormCurrentStep === EMAIL_AUTH ? (
                <NewEmailForm
                    initialValue={userFormData?.email}
                    handleToggleAuthForm={handleToggleAuthForm}
                    handleEmailFormSubmit={handleEmailFormSubmit}
                    isSignUp={isSignUp}
                />
            ) : (
                <NewPhoneForm
                    initialValue={{
                        countryCode: userFormData.countryCode,
                        phoneNumber: userFormData.phoneNumber,
                    }}
                    resendPhoneCode={resendPhoneCode}
                    handleToggleAuthForm={handleToggleAuthForm}
                    handlePhoneFormSubmit={handlePhoneFormSubmit}
                    setResendPhoneCode={setResendPhoneCode}
                />
            )}

            <div className="space-y-6">
                <NewSocialLogin isSignUp={isSignUp} />
                <p className="max-w-md text-xs leading-[18px]">
                    By continuing, you agree to CreatorDAO’s{' '}
                    <Link to="/terms" className="font-bold underline">
                        Terms of Services
                    </Link>{' '}
                    .
                </p>
            </div>
        </React.Fragment>
    )
}

export default AuthForm
