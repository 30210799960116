import * as React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './app/redux/store'
import { Provider } from 'react-redux'
import RedditPixel from 'react-reddit-pixel'
import { H } from 'highlight.run'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
})

const isProduction = process.env.REACT_APP_FOR_DEPLOY === 'true'
const container = document.getElementById('root')
const rootElement = createRoot(container as Element)

if (isProduction) {
    H.init(process.env.REACT_APP_HIGHLIGHT_PROJECT_ID, {
        environment: isProduction ? 'production' : 'development',
    })

    RedditPixel.init(process.env.REACT_APP_REDDIT_PIXEL_ID, {
        debug: false,
    })
}

// if (!isProduction) {
//     // @ts-ignore
//     import('@axe-core/react').then(axe => {
//         axe.default(React, ReactDOM, 1000)
//     })
// }

function render() {
    rootElement.render(
        <Provider store={store}>
            <App isProduction={isProduction} />
        </Provider>,
    )
}

if (module.hot) {
    module.hot.accept('./App', function () {
        setTimeout(render)
    })
}

render()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
