import * as React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'
import { ApolloProvider } from '@apollo/client'
import 'react-toastify/dist/ReactToastify.css'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { setApolloClientIfNecessary } from './ApolloClient'
import ModalManager from './app/common/modals/ModalManager'
import { useAppSelector } from './app/redux/store'
import AppRoutes from './routes/AppRoutes'
import { IntercomProvider } from './utils/intercom/index'
import { createClient, WagmiConfig } from 'wagmi'
import { ConnectKitProvider, getDefaultClient } from 'connectkit'
import * as Sentry from '@sentry/react'
import { ErrorFallback } from '@src/app/common/components/ErrorFallback'
import ConfirmationModalContextProvider from './app/common/confirm-dialog/ConfirmDialogProvider'
import { useEmailVerification } from './app/hooks/authentication.hooks'

posthog.init(process.env.REACT_APP_POSTHOG_PROJECT_API_KEY, {
    api_host: 'https://app.posthog.com',
})

const alchemyId = process.env.REACT_APP_ALCHEMY_ID

const wagmiClient = createClient(
    getDefaultClient({
        appName: 'CreatorDAO Portal',
        alchemyId,
    }),
)

function App({ isProduction }) {
    const { initialized, idToken } = useAppSelector(state => state.auth)

    useEmailVerification()

    if (!initialized) return <></>

    const client = setApolloClientIfNecessary(idToken)

    return (
        <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
            <PostHogProvider client={posthog}>
                <HelmetProvider>
                    <ApolloProvider client={client}>
                        <WagmiConfig client={wagmiClient}>
                            <ConnectKitProvider theme="rounded">
                                <IntercomProvider
                                    appId={
                                        process.env.REACT_APP_INTERCOM_APP_ID
                                    }
                                >
                                    <ModalManager />
                                    <ToastContainer
                                        position="top-center"
                                        autoClose={4000}
                                        hideProgressBar={true}
                                        theme={'dark'}
                                    />
                                    <ConfirmationModalContextProvider>
                                        <AppRoutes />
                                    </ConfirmationModalContextProvider>
                                </IntercomProvider>
                            </ConnectKitProvider>
                        </WagmiConfig>
                    </ApolloProvider>
                </HelmetProvider>
            </PostHogProvider>
        </Sentry.ErrorBoundary>
    )
}

export default App
