import { dFormatter } from '@src/app/utils/retention-tool.util'
import * as React from 'react'

interface ContractVideoCardProps {
    video: {
        youtube_id: string
        title: string | null | undefined
        thumbnails: any
        published_at: string
        is_partner: boolean
    }
}

const ContractVideoCard: React.FC<ContractVideoCardProps> = ({ video }) => {
    return (
        <div className="border rounded-lg bg-gray-50 shadow-sm px-3 py-2 flex flex-col justify-between">
            <div className="flex flex-row w-full">
                <img
                    className="h-16 w-auto sm:flex-shrink-0"
                    src={video?.thumbnails?.medium?.url || ''}
                    alt={video.title || ''}
                />
                <div className="ml-4 w-full flex-1 grid content-between">
                    <div className="flex flex-col mt-0 align-top">
                        <span className="text-xs font-medium text-black line-clamp-2">
                            {video.title}
                        </span>
                    </div>
                    <div className="text-xs text-gray-600 sm:flex flex-row justify-between">
                        <div className="mt-1">
                            {dFormatter(video.published_at.toString())}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContractVideoCard
