import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'

import TextInput from '@src/app/common/form/TextInput'
import { getFirebaseErrorString } from '@src/app/common/util/util'
import { ButtonRoundedFull } from '@src/features/profiles/components/custom-buttons'
import { RESET_PASSWORD_SMS_VERIFY_FORM, SMS_VERIFY_FORM } from '../constants'
import AuthSubmitButton from './AuthSubmitButton'
import CommonAuthHeader from './CommonAuthHeader'

interface NewSmsVerificationFormProps {
    currentStep: string
    userFormData: any
    handleChangeStep: any
    redirectTo?: string
    goBackTo: string
    isSignUp: boolean
    onResendPhoneCode?: () => void
}

const NewSmsVerificationForm: React.FC<NewSmsVerificationFormProps> = ({
    currentStep,
    userFormData,
    handleChangeStep,
    redirectTo,
    goBackTo,
    isSignUp,
    onResendPhoneCode,
}) => {
    const [seconds, setSeconds] = useState(60)

    useEffect(() => {
        const myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            }
            if (seconds === 0) {
                clearInterval(myInterval)
            }
        }, 1000)

        return () => {
            clearInterval(myInterval)
        }
    }, [seconds])

    const resendCodeOnEmail = () => {
        console.log('resetCodeOnEmail')
    }

    const handleResendSms = () => {
        if (currentStep === RESET_PASSWORD_SMS_VERIFY_FORM) {
            resendCodeOnEmail()
        } else {
            if (onResendPhoneCode) onResendPhoneCode()
        }
    }

    const handleFormSubmit = async (
        values: any,
        { setSubmitting, setErrors }: FormikHelpers<any>,
    ) => {
        try {
            if (
                currentStep === RESET_PASSWORD_SMS_VERIFY_FORM ||
                (currentStep === SMS_VERIFY_FORM && isSignUp)
            ) {
                await userFormData?.confirmationResult?.confirm(values.otp)
                handleChangeStep(redirectTo)
            } else {
                await userFormData?.confirmationResult?.confirm(values.otp)
            }
        } catch (error) {
            const location = `NewSmsVerificationForm, ${currentStep}, ${
                isSignUp ? 'Sign Up' : ''
            }`
            setErrors({ otp: getFirebaseErrorString(error, location) })
            setSubmitting(false)
        }
    }

    const handleGoBack = () => {
        handleChangeStep(goBackTo)
    }

    return (
        <React.Fragment>
            <div className="space-y-6">
                <CommonAuthHeader
                    emailOrPhone={
                        currentStep === RESET_PASSWORD_SMS_VERIFY_FORM
                            ? userFormData?.email
                            : userFormData?.phoneNumberWithCode
                    }
                    handleGoBack={handleGoBack}
                    formType={currentStep}
                    isSignUp={isSignUp}
                />
                <Formik
                    initialValues={{ otp: '' }}
                    validationSchema={Yup.object({
                        otp: Yup.string().required().label('6 digit code'),
                    })}
                    onSubmit={handleFormSubmit}
                    validateOnBlur={false}
                >
                    {({ isSubmitting, dirty, isValid, errors }) => (
                        <Form className="space-y-7">
                            <div className="flex items-end gap-4">
                                <div className="flex-1 ">
                                    <label
                                        htmlFor={'email'}
                                        className="block  font-bold text-brandBlack-500"
                                    >
                                        6-digit code
                                    </label>
                                    <TextInput
                                        name="otp"
                                        placeholder="6-digit code"
                                        maxLength="6"
                                        variant="fanx"
                                        autoFocus={true}
                                        isTrimRequired={true}
                                    />
                                </div>
                                <div className={errors?.otp ? 'mb-7' : ''}>
                                    {seconds > 0 ? (
                                        <div className="w-28 font-bold text-base text-center">
                                            <p>Resend code</p>
                                            <p>in {seconds}s</p>
                                        </div>
                                    ) : (
                                        <ButtonRoundedFull
                                            type="button"
                                            btnText="Resend code"
                                            className="h-[49px] grid place-content-center w-full text-base font-bold"
                                            customWidth={true}
                                            isSubmitting={false}
                                            onClick={() => {
                                                handleResendSms()
                                            }}
                                        />
                                    )}
                                </div>
                            </div>

                            <AuthSubmitButton
                                disabled={!isValid || isSubmitting}
                                isSubmitting={isSubmitting}
                                title={`Verify ${
                                    currentStep ===
                                    RESET_PASSWORD_SMS_VERIFY_FORM
                                        ? 'and sign in'
                                        : ''
                                }`}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    )
}

export default NewSmsVerificationForm
