import React from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import Link from '@src/app/common/components/Link/Link'

interface FAQProps {
    background: 'light' | 'dark'
}

const FaqLink = ({
    to,
    children,
}: {
    to: string
    children: React.ReactNode
}) => {
    return (
        <Link to={to} className={'cursor-pointer underline'}>
            {children}
        </Link>
    )
}
const faqs = [
    {
        question: 'What is #TheSearch?',
        answer: (
            <>
                #TheSearch is a competition to find the next big social media
                creator. Creators submit videos of why they should win, and fans
                will pick the winner by voting for their favorite creator on
                creatordao.com.
                <br />
                <br />
                There are four rounds of voting and the creator with the most
                votes at the end of #TheSearch will receive an investment of
                $1,000,000 in exchange for 20% of their creator earnings over
                the next 10 years.
            </>
        ),
    },
    {
        question: 'How will the winner be chosen?',
        answer: '#TheSearch is fan-voted with final approval from all CreatorDAO members. There are four rounds of voting, and the creator with the most votes at the end of #TheSearch will receive an investment.',
    },
    {
        question: 'How are groups determined?',
        answer: 'Applicants will be grouped into groups of 20 at random based on the time of entry.',
    },
    {
        question:
            'What happens if I apply after voting for round one has already started? Am I at a disadvantage?',
        answer: 'No. Applicants will be grouped based on when they apply.',
    },
    {
        question: 'What will the winner receive?',
        answer: (
            <>
                The winner will receive an investment of $1,000,000* in exchange
                for 20% of their creator earnings over the next 10 years.
                <br />
                <br />
                *The $1,000,000 will be received as follows:
                <br />
                <br />
                The use of $333,333.33 USD of the total investment shall be
                subject to the approval of the CreatorDAO’s Season 0 Creators
                (to be announced at a later date) and the use of $333,333.33 USD
                of the total investment shall be subject to the approval of The
                Search’s Final Creator’s fans through a public vote on
                CreatorDAO’s platform (such amounts, the “Community Funds”).
                Over the course of three (3) years, The Search’s Final Creator
                must appeal to the CreatorDAO community and fans to “approve”
                such creator’s use of the Community Funds (e.g. creator submits
                a proposal for the use of $x, and the majority of the CreatorDAO
                Season 0 Creators or fans must approve such proposed use).
                “CreatorDAO Creator Documentation” includes, at minimum, the
                Creator’s LLC operating agreement and the Creator’s Services
                Agreement. Further documentation may be required at the sole
                determination of Sponsor.
                <br />
                <br />
                All monetary amounts are stated in United States Dollars (USD).
            </>
        ),
    },
    {
        question: 'How do I submit? ',
        answer: (
            <>
                Just submit an application on the{' '}
                <FaqLink to="/thesearch/application">
                    CreatorDAO website
                </FaqLink>{' '}
                and include a 1-minute video showcasing your talents to the
                world.
            </>
        ),
    },
    {
        question: 'Can I submit multiple entries? ',
        answer: 'No. Multiple entries from the same creator will result in disqualification.',
    },
    {
        question: 'Can I update or add something to my entry?',
        answer: (
            <>
                If your entry was incomplete, you should delete your entry and
                re-enter. To delete your entry email us at{' '}
                <a href="mailto:hello@creatordao.com" className="underline">
                    hello@creatordao.com
                </a>
                .
            </>
        ),
    },
    {
        question: 'Do I need to be 18+ to win?',
        answer: 'Yes. One must be 18+ to enter #TheSearch. However, fans of all ages may vote.',
    },
    {
        question: 'Where can I find complete Terms & Conditions?',
        answer: <FaqLink to="/terms">Read our Terms & Conditions</FaqLink>,
    },
    {
        question: 'More questions?',
        answer: 'Email us at hello@creatordao.com.',
    },
]

const FAQ = ({ background }: FAQProps) => {
    return (
        <div
            className={`px-4 sm:px-6 py-12 sm:py-16 ${
                background === 'light' ? 'bg-brandBeige-500' : 'bg-black'
            }`}
        >
            <div className="max-w-2xl mx-auto">
                <h2
                    className={`text-center text-3xl leading-8 font-bold sm:text-[56px] sm:leading-[67.2px] sm:-tracking-[0.0425em] ${
                        background === 'light' ? 'text-black' : 'text-[#F6F9F0]'
                    }`}
                >
                    FAQ
                </h2>
                <hr
                    className={`mt-6 sm:mt-4 border ${
                        background === 'light'
                            ? 'border-black'
                            : 'border-[#F6F9F0]'
                    }`}
                />
                <dl
                    className={`space-y-6 divide-y-2 ${
                        background === 'light'
                            ? 'divide-black'
                            : 'divide-[#F6F9F0]'
                    }`}
                >
                    {faqs.map((faq, index) => (
                        <Disclosure
                            as="div"
                            key={index}
                            className="pt-6"
                            defaultOpen={index === 0}
                        >
                            {({ open }) => (
                                <>
                                    <dt className="text-lg">
                                        <Disclosure.Button className="text-left w-full flex justify-between items-start">
                                            <span
                                                className={`text-xl leading-[30px] font-bold ${
                                                    background === 'light'
                                                        ? 'text-black'
                                                        : 'text-[#F6F9F0]'
                                                }`}
                                            >
                                                {faq.question}
                                            </span>
                                            <span className="ml-6 h-7 flex items-center">
                                                {open ? (
                                                    <BiMinus
                                                        className={`h-9 w-9 ${
                                                            background ===
                                                            'light'
                                                                ? 'text-black'
                                                                : 'text-[#F6F9F0]'
                                                        }`}
                                                    />
                                                ) : (
                                                    <BiPlus
                                                        className={`h-9 w-9 ${
                                                            background ===
                                                            'light'
                                                                ? 'text-black'
                                                                : 'text-[#F6F9F0]'
                                                        }`}
                                                    />
                                                )}
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Transition
                                        show={open}
                                        className="overflow-hidden"
                                        enter="transition transition-all duration-200 ease-in"
                                        enterFrom="transform max-h-0"
                                        enterTo="transform max-h-screen"
                                        leave="transition transition-all duration-200 ease-out"
                                        leaveFrom="transform max-h-screen"
                                        leaveTo="transform max-h-0"
                                    >
                                        <Disclosure.Panel
                                            as="dd"
                                            className="my-4"
                                        >
                                            <p
                                                className={`text-xl leading-[30px] -tracking-[0.0425em] font-medium ${
                                                    background === 'light'
                                                        ? 'text-black'
                                                        : 'text-[#F6F9F0]'
                                                }`}
                                            >
                                                {faq.answer}
                                            </p>
                                        </Disclosure.Panel>
                                    </Transition>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </dl>
                <hr
                    className={`mt-6 border ${
                        background === 'light'
                            ? 'border-black'
                            : 'border-[#F6F9F0]'
                    }`}
                />
            </div>
        </div>
    )
}

export default FAQ
