import React, { SyntheticEvent, useState } from 'react'

export default function ImageWithFallback({
    src,
    fallbackSrc,
    children,
    ...props
}: any) {
    const [currentSrc, setCurrentSrc] = useState(src)
    const onError = ({ currentTarget }: SyntheticEvent) => {
        (currentTarget as HTMLImageElement).onerror = null
        setCurrentSrc(fallbackSrc)
    }

    return (
        // Not using contain here since that might lead to image leaving a black background around
        <img
            className="object-cover"
            src={currentSrc}
            onError={onError}
            {...props}
        >
            {children}
        </img>
    )
}
