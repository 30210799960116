import React, { useEffect, useRef, useState } from 'react'
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs'
import { useField } from 'formik'

export default function TextInput({
    testId,
    label,
    variant,
    isTrimRequired = false,
    ...props
}: any) {
    const inputRef: React.RefObject<HTMLElement> = useRef(null)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [field, meta, helpers] = useField(props)

    const handleToggleShowpassword = () => {
        setShowPassword(prev => !prev)
    }
    const isFanx = variant === 'fanx'

    const EyeIcon = showPassword ? BsEyeSlashFill : BsEyeFill

    useEffect(() => {
        if (props?.autoFocus && inputRef) {
            inputRef?.current?.focus()
        }
    }, [])

    return (
        <div className="relative">
            {label && (
                <label className="appearance-none block text-base font-medium text-gray-700 select-none mb-2">
                    {label}
                </label>
            )}
            <input
                {...field}
                {...props}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                    let value = (event.target as HTMLInputElement).value
                    if (isTrimRequired) {
                        value = value?.trim() || ''
                    }
                    helpers.setValue(value)
                }}
                type={
                    props?.type === 'password'
                        ? showPassword
                            ? 'text'
                            : 'password'
                        : props?.type
                }
                data-testid={testId}
                className={`${
                    isFanx
                        ? `bg-[#F2F2F2] border-none ${
                              props?.type === 'password' ? 'pr-11' : ''
                          }`
                        : 'border border-[#D1D5DB]'
                } w-full px-3 py-3 sm:text-sm focus:outline-none rounded-md`}
                ref={inputRef}
            ></input>
            {isFanx && props?.type === 'password' ? (
                <EyeIcon
                    className="w-6 h-6 absolute top-3 select-none right-5 cursor-pointer"
                    onClick={() => handleToggleShowpassword()}
                />
            ) : null}

            {meta.touched && meta.error ? (
                <div className="text-sm text-red-400 mt-1.5">{meta.error}</div>
            ) : null}
        </div>
    )
}
