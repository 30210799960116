import { AnySchema } from 'yup'
import { ObjectShape } from 'yup/lib/object'

export interface FieldType {
    name: string
    type?: string
    placeholder?: string
    displayName?: string
    isRequired?: boolean
    autoFocus?: boolean
    isMulti?: boolean
    badge?: string
    storagePath?: string
    initialValue: any
    validationSchema?: AnySchema
    hideField?: boolean
    [x: string]: any
}

export default (formFields: FieldType[]) => {
    const initialValues: { [key: string]: any } = {}
    const validationSchema: ObjectShape = {}

    for (const field of formFields) {
        initialValues[field.name] = field.initialValue
        if (field.validationSchema)
            validationSchema[field.name] = field.validationSchema
    }

    return { initialValues, validationSchema }
}
