import React from 'react'
import clsx from 'clsx'
import { isStillUploading } from '@src/app/common/util/util'
import Spinner from '@src/app/layout/Spinner'

interface ImagePreviewProps {
    isPortal: boolean
    imgSrc: string
    previewImageClassName?: string
    fieldValue: any
}

const ImagePreview: React.FC<ImagePreviewProps> = ({
    isPortal,
    imgSrc,
    previewImageClassName,
    fieldValue,
}) => {
    const Image = () => {
        return (
            <img
                className={clsx(
                    'rounded-full object-cover',
                    isPortal ? 'w-[72px] h-[72px] ' : 'h-[142px] w-[142px] ',
                    previewImageClassName,
                )}
                src={imgSrc}
                alt="Profile image"
                referrerPolicy="no-referrer"
            />
        )
    }

    return isPortal ? (
        <div className="relative">
            <Image />
            {isStillUploading(fieldValue) && (
                <>
                    <div className="top-0 left-0 w-full h-full absolute bg-white opacity-40	flex justify-center items-center text-xs text-gray-900 font-extrabold">
                        <Spinner size="h-7 w-7" />
                    </div>
                    <div className="z-10 bottom-[0px] w-[54px] left-[8px] h-4 absolute bg-gray-600 text-white flex justify-center items-center text-[11px] font-base rounded-full">
                        {fieldValue.slice(10)}%
                    </div>
                </>
            )}
        </div>
    ) : (
        <Image />
    )
}

export default ImagePreview
