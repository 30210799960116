import ShowMoreText from '@src/app/common/components/ShowMoreText'
import React from 'react'

interface ICongratulationCard {
    name: string
    about: string
}

const CongratulationCard: React.FC<ICongratulationCard> = ({ name, about }) => {
    return (
        <div className="md:max-w-[380px] lg:max-w-[530px] relative z-20 px-[14px] md:pr-0 md:pl-7 2xl:pl-9">
            <div className="flex items-center gap-1 md:mt-5 lg:mt-12">
                <p className="text-sm leading-5 md:text-base font-bold uppercase ">
                    Season 1 winner of{' '}
                </p>
                <img
                    className="max-w-[122px] md:max-w-[140px]"
                    src="/images/thesearch_bordered.png"
                    alt="logo"
                />
            </div>
            <p className="text-[35px] leading-[42px] lg:text-[60px] lg:leading-[72px] font-bold">
                Congratulations, <br /> {name}
            </p>
            <ShowMoreText
                title={about}
                className="text-[13px] leading-[18px] font-normal mt-1"
                btnClassName="bg-transparent"
            />
        </div>
    )
}

export default CongratulationCard
