import React from 'react'
import ImageWithFallback from '@src/features/shared/ImageWithFallback'
import parisHiltonImg from '@src/assets/images/dao-members/paris_hilton_b.png'
import chainsmokers from '@src/assets/images/dao-members/chainsmokers_b.png'
import jakePaul from '@src/assets/images/dao-members/jake_paul_b.png'
import andrewEast from '@src/assets/images/dao-members/andrew_east_b.png'
import austin from '@src/assets/images/dao-members/austin_b.png'
import nate from '@src/assets/images/dao-members/nate_b.png'
import natalie from '@src/assets/images/dao-members/natalie_b.png'
import kygoImg from '@src/assets/images/dao-members/kygo_b.png'
import liamPayneImg from '@src/assets/images/dao-members/liam_payne_b.png'

import a16z from '@src/assets/images/investors/a16z_crypto.png'
import initialized from '@src/assets/images/investors/initialized.png'
import antifund from '@src/assets/images/investors/antifund2.png'
import audacious from '@src/assets/images/investors/audacious.png'

interface IDaoMember {
    id: number
    imgUrl: string
    name: string
}

const DAOMemberList1 = [
    {
        id: 0,
        name: 'Paris Hilton',
        imgUrl: parisHiltonImg,
    },
    {
        id: 1,
        name: 'Jake Paul',
        imgUrl: jakePaul,
    },
    {
        id: 2,
        name: 'The Chainsmokers',
        imgUrl: chainsmokers,
    },
    {
        id: 3,
        imgUrl: andrewEast,
        name: 'Andrew East',
    },
    {
        id: 4,
        imgUrl: kygoImg,
        name: 'Kygo',
    },
    {
        id: 5,
        imgUrl: liamPayneImg,
        name: 'Liam Payne',
    },
]

const DAOMemberList2 = [
    {
        id: 6,
        imgUrl: austin,
        name: 'Austin Evans',
    },
    {
        id: 7,
        imgUrl: nate,
        name: "Nate O'Brien",
    },
    {
        id: 8,
        imgUrl: natalie,
        name: 'Natalie Barbu',
    },
]

const allInvestors = [
    {
        id: 0,
        imgUrl: audacious,
    },
    {
        id: 1,
        imgUrl: a16z,
    },
    {
        id: 2,
        imgUrl: initialized,
    },
    {
        id: 3,
        imgUrl: antifund,
    },
]

const DaoMembers: React.FC = () => {
    return (
        <div className="mt-7 md:mt-11 relative z-20">
            <div className="bg-black bg-opacity-30 grid place-content-center rounded-xl max-w-md mx-auto py-5 px-7">
                <p className="text-white text-lg leading-7 font-bold uppercase text-center">
                    Judged by our <br className="block sm:hidden" /> community
                    members
                </p>
            </div>
            <div className="flex flex-wrap items-center justify-center gap-2 md:gap-4 mt-7 md:mt-14">
                {DAOMemberList1.map((data: IDaoMember) => (
                    <div
                        key={data.id}
                        className="space-y-3 w-[163px] md:w-[178px] h-[189px] bg-black bg-opacity-40 rounded-2xl p-[6px]"
                    >
                        <ImageWithFallback
                            alt={'user'}
                            className="mx-auto"
                            src={data.imgUrl}
                            fallbackSrc={'/images/user.png'}
                        />

                        <p className="text-base leading-[19.2px] text-white text-center font-bold">
                            {data.name}
                        </p>
                    </div>
                ))}
            </div>
            <div className="flex flex-wrap items-center justify-center  gap-2 md:gap-4 mt-2 md:mt-4">
                {DAOMemberList2.map((data: IDaoMember) => (
                    <div
                        key={data.id}
                        className="space-y-3 w-[163px] md:w-[178px] h-[189px] bg-black bg-opacity-40 rounded-2xl p-[6px]"
                    >
                        <ImageWithFallback
                            alt={'user'}
                            className="mx-auto"
                            src={data.imgUrl}
                            fallbackSrc={'/images/user.png'}
                        />

                        <p className="text-base leading-[19.2px] text-white text-center font-bold">
                            {data.name}
                        </p>
                    </div>
                ))}
                {allInvestors.map((data: { id: number; imgUrl: string }) => (
                    <div
                        key={data.id}
                        className="space-y-3 w-[163px] md:w-[178px] h-[189px] bg-black bg-opacity-40 rounded-2xl p-[6px] grid place-content-center"
                    >
                        <div className="w-[141px] h-[141px] grid place-content-center bg-white rounded-2xl px-2">
                            <ImageWithFallback
                                alt={'user'}
                                className="mx-auto"
                                src={data.imgUrl}
                                fallbackSrc={'/images/user.png'}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DaoMembers
