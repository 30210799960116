import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface AfterRedirectProps {}

const AfterRedirect = ({}: AfterRedirectProps) => {
    const { pathname, search } = useLocation()
    const pathFromStorage = localStorage.getItem('redirectPath')
    const currentPath = pathname + search

    useEffect(() => {
        if (currentPath === pathFromStorage) {
            localStorage.removeItem('redirectPath')
        }
    }, [currentPath, pathFromStorage, pathname])

    return null
}

export default AfterRedirect
