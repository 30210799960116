import React from 'react'
import { HiArrowSmRight } from 'react-icons/hi'

import hashImg from '@src/assets/images/idols/hash.png'
import giftImg from '@src/assets/images/idols/gift.png'
import flameImg from '@src/assets/images/idols/flame.png'
import tropyImg from '@src/assets/images/idols/tropy.png'
import winImg from '@src/assets/images/idols/win.png'
import { Link } from 'react-router-dom'
import { CURRENT_PHASE } from '@src/features/idols/hooks/useGetCurrentPhase'
import { useCompetition } from '../../admin/useCompetition'
import { URLS } from '@src/constants/urls'
import { useFinalRound } from './FinalRound/hooks/useFinalRound.hooks'
import { useGetSeasonWinner } from '@src/features/idols/hooks/useGetSeasonWinner'

const WinnerTitle = () => {
    const { loading, winnerProfile } = useGetSeasonWinner()

    if (loading || !winnerProfile) return null

    return (
        <p className="font-medium text-base md:text-xl md:leading-[30px]">
            The Season 1 winner of #TheSearch is <br className=" md:hidden" />
            {winnerProfile?.display_name}
        </p>
    )
}

const Timeline = () => {
    const { currentPhase } = useCompetition()
    const { hasVotingEnded } = useFinalRound()

    const isLive = (phase: CURRENT_PHASE) => phase === currentPhase
    return (
        <div className="max-w-[1368px] mx-auto px-4 py-8 md:py-16 xl:px-8 space-y-5 md:space-y-8">
            <h3 className="text-center font-bold text-3xl leading-8 sm:text-[56px] sm:leading-[67.2px] sm:-tracking-[0.0425em]">
                Season 1 Schedule
            </h3>

            <div className="flex flex-col gap-2 md:flex-row md:gap-0 items-stretch">
                <div className="bg-brandBeige-500 md:text-center rounded-2xl w-full flex flex-col justify-between  md:w-80 p-4 md:py-6 space-y-4">
                    <div className="flex md:block items-start gap-4 md:space-y-4">
                        <img
                            className="w-14 h-16  md:mx-auto object-cover"
                            src={hashImg}
                            alt="Round 1"
                        />
                        <div className="space-y-4">
                            <div className="font-bold text-xl  leading-[30px] md:leading-[36px]">
                                Round 1
                                <p className="font-medium text-base md:text-xl md:leading-6">
                                    Dec 4, 2022 - Jan 9, 2023
                                </p>
                            </div>

                            <p className="font-medium text-base md:text-xl md:leading-6">
                                First and second place in each group advance to
                                Round 2
                            </p>
                        </div>
                    </div>
                    <Link
                        to={
                            isLive(CURRENT_PHASE.FIRST_ROUND)
                                ? URLS.THESEARCH_GROUPS
                                : `${URLS.THESEARCH_GROUPS}/round/${CURRENT_PHASE.FIRST_ROUND}`
                        }
                        className="ml-[72px] md:ml-0 inline-block uppercase text-brandGreen-700 text-base  md:text-xl font-bold"
                    >
                        Browse
                    </Link>
                </div>
                <HiArrowSmRight className="mx-auto rotate-90  md:rotate-0 text-2xl flex-shrink-0 self-center" />

                <div className="bg-brandBeige-500 md:text-center rounded-2xl flex flex-col justify-between  md:w-80 p-4 md:py-6 space-y-4">
                    <div className="flex md:block items-start gap-4 md:space-y-4">
                        <img
                            className="w-14 h-16  md:mx-auto object-cover"
                            src={giftImg}
                            alt="Round 2"
                        />
                        <div className="space-y-4">
                            <div className="font-bold text-xl  leading-[30px] md:leading-[36px]">
                                Round 2
                                <p className="font-medium text-base md:text-xl md:leading-6">
                                    Jan 10, 2023 - Jan 16, 2023
                                </p>
                            </div>

                            <p className="font-medium text-base md:text-xl md:leading-6">
                                First and second place in each group advance to
                                the Semi Finals
                            </p>
                        </div>
                    </div>
                    <Link
                        to={
                            isLive(CURRENT_PHASE.SECOND_ROUND)
                                ? URLS.THESEARCH_GROUPS
                                : `${URLS.THESEARCH_GROUPS}/round/${CURRENT_PHASE.SECOND_ROUND}`
                        }
                        className="ml-[72px] md:ml-0 inline-block uppercase text-brandGreen-700 text-base  md:text-xl font-bold"
                    >
                        Browse
                    </Link>
                </div>
                <HiArrowSmRight className="mx-auto rotate-90  md:rotate-0 text-2xl flex-shrink-0 self-center" />

                <div className="bg-brandBeige-500 md:text-center rounded-2xl w-full flex flex-col justify-between  md:w-80 p-4 md:py-6 space-y-4">
                    <div className="flex md:block items-start gap-4 md:space-y-4">
                        <img
                            className="w-14 h-16  md:mx-auto object-cover"
                            src={flameImg}
                            alt="Round 3"
                        />
                        <div className="space-y-4">
                            <div className="font-bold text-xl  leading-[30px] md:leading-[36px]">
                                Round 3
                                <p className="font-medium text-base md:text-xl md:leading-6">
                                    Jan 17, 2023 - Jan 23, 2023
                                </p>
                            </div>
                            <p className="font-medium text-base md:text-xl md:leading-6">
                                First place in each group advances to the Finals
                            </p>
                        </div>
                    </div>

                    <Link
                        to={
                            isLive(CURRENT_PHASE.SEMI_FINALS)
                                ? URLS.THESEARCH_GROUPS
                                : `${URLS.THESEARCH_GROUPS}/round/${CURRENT_PHASE.SEMI_FINALS}`
                        }
                        className="ml-[72px] md:ml-0 inline-block uppercase text-brandGreen-700 text-base  md:text-xl font-bold"
                    >
                        Browse
                    </Link>
                </div>
                <HiArrowSmRight className="mx-auto rotate-90  md:rotate-0 text-2xl flex-shrink-0 self-center" />

                <div className="bg-brandBeige-500 md:text-center rounded-2xl w-full flex flex-col justify-between  md:w-80 p-4 md:py-6 space-y-4">
                    <div className="flex md:block items-start gap-4 md:space-y-4">
                        <img
                            className="w-14 h-16  md:mx-auto object-cover"
                            src={tropyImg}
                            alt="Finals"
                        />
                        <div className="space-y-4">
                            <div className="font-bold text-xl  leading-[30px] md:leading-[36px]">
                                Finals{' '}
                                <p className="font-medium text-base md:text-xl md:leading-6">
                                    Starting Jan 24, 2023
                                </p>
                            </div>

                            <p className="font-medium text-base md:text-xl md:leading-6">
                                Finalists from Semi Finals will compete to be
                                the winner
                            </p>
                        </div>
                    </div>
                    <Link
                        to={
                            isLive(CURRENT_PHASE.FINALS)
                                ? URLS.THESEARCH_GROUPS
                                : `${URLS.THESEARCH_GROUPS}/round/${CURRENT_PHASE.FINALS}`
                        }
                        className="ml-[72px] md:ml-0 inline-block uppercase text-brandGreen-700 text-base  md:text-xl font-bold"
                    >
                        Browse
                    </Link>
                </div>
            </div>
            <div className="bg-brandBeige-500 text-center rounded-2xl  px-4 py-6 space-y-3">
                <img
                    className="w-[296px] mx-auto"
                    src={winImg}
                    alt="Winner announced!"
                />
                <p className="font-bold text-xl leading-[36px]">
                    Winner announced!
                </p>
                {hasVotingEnded ? (
                    <WinnerTitle />
                ) : (
                    <p className="font-medium text-base md:text-xl md:leading-[30px]">
                        The Season 1 winner of #TheSearch will be{' '}
                        <br className=" md:hidden" /> announced after the final{' '}
                        <br className="hidden md:block" /> decision has{' '}
                        <br className=" md:hidden" /> been made by our
                        investors, DAO <br className=" md:hidden" /> members and
                        more
                    </p>
                )}
            </div>
        </div>
    )
}

export default Timeline
