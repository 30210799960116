import * as React from 'react'
import { clsx } from 'clsx'

import tiktokIconURL from '@src/assets/images/icons/TikTokSmall.svg'
import instagramIconURL from '@src/assets/images/icons/InstagramSmall.svg'
import youtubeIconURL from '@src/assets/images/icons/YoutubeSmall.svg'
import twitterIconURL from '@src/assets/images/icons/TwitterSmall.svg'

interface SocialMenuHeaderProps {
    title: string
}

const SocialMenuHeader = ({ title }: SocialMenuHeaderProps) => {
    return (
        <div
            className={clsx(
                'font-bold text-black',
                'text-sm',
                'px-[15px] pt-[16px]',
            )}
        >
            {title}
        </div>
    )
}

interface SocialMenuLinkProps {
    iconURL: string
    icon?: React.ReactNode
    label: string
    url: string
}

const SocialMenuLink = ({ iconURL, label, url }: SocialMenuLinkProps) => {
    return (
        <a
            className={clsx(
                'p-[10px]',
                'flex items-center gap-2',
                'cursor-pointer',
            )}
            href={url}
            target="_blank"
            rel="noreferrer"
        >
            <img src={iconURL} alt={label} />
            <div className="text-sm font-bold pt-1">{label}</div>
        </a>
    )
}

const SOCIAL_LINKS = [
    {
        label: '@creatordao',
        url: 'https://www.tiktok.com/@creatordao',
        iconURL: tiktokIconURL,
    },
    {
        label: '@creatordao',
        url: 'https://www.youtube.com/@creatordao',
        iconURL: youtubeIconURL,
    },
    {
        label: '@joincreatordao',
        url: 'https://www.instagram.com/joincreatordao',
        iconURL: instagramIconURL,
    },
    {
        label: '@joincreatordao',
        url: 'https://twitter.com/joincreatordao',
        iconURL: twitterIconURL,
    },
]

const SocialMenu = () => {
    return (
        <div
            className="
      flex flex-col gap-2
    "
        >
            <SocialMenuHeader title="Follow the contest" />
            {SOCIAL_LINKS.map(({ iconURL, label, url }, idx) => (
                <SocialMenuLink
                    key={`social-link-${idx}`}
                    iconURL={iconURL}
                    label={label}
                    url={url}
                />
            ))}
        </div>
    )
}

export default SocialMenu
