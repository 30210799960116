import React from 'react'

const AppReferralActivated = ({ referralCode }: { referralCode: string }) => {
    return (
        <div className="mx-auto text-center border-dashed border-2 pt-2 pb-1 px-2 rounded rounded-xl border-[#51E577]">
            <p className={'leading-7'}>
                Referral code "{referralCode}" activated
            </p>
        </div>
    )
}

export default AppReferralActivated
