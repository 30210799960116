import { useCallback, useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import getSortedMemberListByUserId from '../helper/getSortedMemberListByUserId'
import _sortGroupByVotes from '../helper/getSortMembersByVotes'
import redirectToUserProfile from '../helper/redirectToUserProfile'

interface UseEnableRedirectForDesktopProps {
    competitionGroups: any
    groupId: string | undefined
    redirectOnGroupSelect: boolean
    previousPhase?: any
    winningUserId?: string | null
}

export function useEnableRedirectForDesktop({
    competitionGroups,
    groupId,
    redirectOnGroupSelect,
    previousPhase,
    winningUserId,
}: UseEnableRedirectForDesktopProps) {
    const navigate = useNavigate()

    const redirectForDesktop = useCallback(() => {
        if (window?.innerWidth > 768 && groupId) {
            const filterSelectedGroup =
                competitionGroups?.find((group: any) => group.id == +groupId) ??
                null

            if (
                filterSelectedGroup &&
                Number(
                    filterSelectedGroup?.competition_group_memberships?.length,
                ) > 0
            ) {
                let sortedGroupList = _sortGroupByVotes(filterSelectedGroup)

                if (winningUserId) {
                    sortedGroupList = getSortedMemberListByUserId(
                        sortedGroupList,
                        winningUserId,
                    )
                }

                redirectToUserProfile({
                    phaseId: previousPhase?.phase_id,
                    selectedGroupId: groupId,
                    displayNameSlug: sortedGroupList[0].user?.display_name_slug,
                    navigate,
                })
            }
        }
    }, [competitionGroups, groupId, navigate, previousPhase, winningUserId])

    // Only redirect from group page to user profile page when desktop screen
    const autoRedirectOnMobile =
        typeof window !== 'undefined' && !redirectOnGroupSelect

    useEffect(() => {
        if (autoRedirectOnMobile) {
            redirectForDesktop()
        }
    }, [autoRedirectOnMobile, redirectForDesktop])

    useLayoutEffect(() => {
        if (autoRedirectOnMobile) {
            window.addEventListener('resize', redirectForDesktop)
        }

        return () => {
            if (autoRedirectOnMobile) {
                window.removeEventListener('resize', redirectForDesktop)
            }
        }
    }, [autoRedirectOnMobile, redirectForDesktop])
}
