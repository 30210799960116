import {
    Button,
    ButtonPrimary,
    ButtonVariant,
} from '@src/app/common/components/Button'
import React from 'react'
import leftUsers from '@src/assets/images/launchpad/hero_left_users.png'
import rightUsers from '@src/assets/images/launchpad/hero_right_users.png'

const Hero = () => {
    return (
        <div className="pt-16 pb-80 lg:pt-40 lg:pb-32 bg-white px-5 relative">
            <div className="space-y-8 relative z-40">
                <h1 className="text-5xl leading-[44px] md:text-6xl md:leading-[64px] -tracking-[0.04em] text-center font-bold">
                    Hey Creators! <br />
                    Launch your <br className="sm:hidden" /> dream business.
                    <br />
                    No experience <br className="sm:hidden" /> required.
                </h1>
                <p className="text-base md:text-xl md:leading-7 -tracking-[0.04em] text-center">
                    Creator Launchpad is a community designed to help{' '}
                    <br className="sm:hidden" /> you leverage your{' '}
                    <br className="hidden md:block" /> content to launch a
                    highly <br className="sm:hidden" /> profitable business.
                </p>
                <div className="text-center">
                    <a
                        href="https://buy.stripe.com/eVaaIJcAc991glqeUY"
                        target="_blank"
                        rel="noreferrer"
                        className="inline-block mx-auto"
                    >
                        <Button
                            variant={ButtonVariant.SLIM}
                            primary={ButtonPrimary.BLACK}
                            className="mx-auto text-base px-5 h-11 md:text-xl md:leading-5 font-normal md:h-14 md:px-6"
                        >
                            Apply Now
                        </Button>
                    </a>
                </div>
            </div>

            <img
                className="absolute -bottom-28 hidden xl:block  max-w-xs 2xl:max-w-md left-0 z-30"
                src={leftUsers}
                alt=""
            />
            <img
                className="absolute -bottom-24 hidden xl:block max-w-xs 2xl:max-w-md right-0 z-30"
                src={rightUsers}
                alt=""
            />
        </div>
    )
}

export default Hero
