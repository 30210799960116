import React from 'react'
import { Button, ButtonPrimary } from '@src/app/common/components/Button'

const AuthSubmitButton = ({
    isSubmitting = false,
    disabled = false,
    type = 'submit',
    title = 'Continue',
    ...rest
}) => {
    return (
        <Button
            primary={ButtonPrimary.GREEN}
            className="h-12 sm:h-14 w-full shadow-sm text-sm"
            data-testid="login-button"
            type={type}
            disabled={disabled}
            loading={isSubmitting}
            {...rest}
        >
            {title}
        </Button>
    )
}

export default AuthSubmitButton
