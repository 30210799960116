import React from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import FormField from '../../../app/common/form/FormField'
import { EVENTS, logEvent } from '../../../app/common/logging/logging'
import {
    getFanRedirectFrom,
    setFanRedirectFrom,
} from '../../../app/common/util/util'
import { signInWithEmail } from '../../../app/hooks/authentication.hooks'
import getInitialValuesAndValidationSchema, {
    FieldType,
} from '../../../helper/getInitialValuesAndValidationSchema'
import { LOGIN_FORM } from '../constants'
import AuthSubmitButton from './AuthSubmitButton'
import CommonAuthHeader from './CommonAuthHeader'
import Link from '@src/app/common/components/Link/Link'

interface LoginFormProps {
    userFormData: any
    handleChangeStep: any
    goBackTo: string
}

const LoginForm: React.FC<LoginFormProps> = ({
    userFormData,
    handleChangeStep,
    goBackTo,
}) => {
    const fields: FieldType[] = [
        {
            name: 'password',
            displayName: 'Password',
            isRequired: true,
            type: 'password',
            testId: 'password',
            initialValue: '',
            placeholder: 'Password',
            validationSchema: Yup.string()
                .min(6, 'At least 6 characters long')
                .required('Password is required'),
            autoFocus: true,
        },
    ]

    const { initialValues, validationSchema } =
        getInitialValuesAndValidationSchema(fields)

    const handleFormSubmit = async (
        values: any,
        { setSubmitting, setErrors }: FormikHelpers<any>,
    ) => {
        try {
            logEvent(EVENTS.AUTH.LOGIN)
            await signInWithEmail({
                email: userFormData?.email,
                password: values.password,
            })
            setSubmitting(false)
            if (getFanRedirectFrom()) setFanRedirectFrom(null)
        } catch (error) {
            setErrors({
                auth: 'Email or password incorrect.',
            })
            setSubmitting(false)
        }
    }

    const handleGoBack = () => {
        handleChangeStep(goBackTo)
    }

    return (
        <React.Fragment>
            <div className="space-y-6">
                <CommonAuthHeader
                    emailOrPhone={userFormData?.email}
                    handleGoBack={handleGoBack}
                    formType={LOGIN_FORM}
                />
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object(validationSchema)}
                    validateOnBlur={false}
                    onSubmit={handleFormSubmit}
                >
                    {({ isSubmitting, errors, values }) => (
                        <Form className="space-y-6">
                            {fields && fields?.length > 0 && (
                                <>
                                    {fields
                                        .filter(field => {
                                            if (!field.shouldDisplayField) {
                                                return true
                                            }
                                            return field.shouldDisplayField(
                                                values,
                                            )
                                        })
                                        .map(field =>
                                            field.customField ? (
                                                field.element
                                            ) : (
                                                <div
                                                    key={field.name}
                                                    className="flex flex-col"
                                                >
                                                    <label
                                                        htmlFor={field.name}
                                                        className="block font-bold  text-brandBlack-500"
                                                    >
                                                        {field.displayName}
                                                        {field.isRequired && (
                                                            <span className="text-idolRed-700 ">
                                                                *
                                                            </span>
                                                        )}
                                                    </label>

                                                    <FormField
                                                        field={field}
                                                        variant={'fanx'}
                                                    />
                                                </div>
                                            ),
                                        )}
                                </>
                            )}

                            {errors.auth && (
                                <div className={'text-red-500 mt-2 text-sm'}>
                                    {errors.auth.toString()}
                                </div>
                            )}

                            <div>
                                <AuthSubmitButton
                                    disabled={isSubmitting}
                                    isSubmitting={isSubmitting}
                                    title={'Sign in'}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className="text-center mt-8">
                    <Link
                        to="/forgot-password"
                        className="text-base font-normal underline"
                    >
                        Forgot your password?
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}

export default LoginForm
