import * as React from 'react'
import { Switch } from '@headlessui/react'
import { useField } from 'formik'

import FormToggle from './FormToggle'

interface ToggleInput {
    label: string
    name: string
}

export default function ToggleInput({ label, ...props }: ToggleInput) {
    const [field] = useField(props)

    return (
        <Switch.Group as="div" className="flex items-center justify-between">
            <span className="flex-grow flex flex-col">
                <Switch.Label
                    as="span"
                    className="text-sm font-medium text-gray-900"
                    passive
                ></Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-700">
                    {label}
                </Switch.Description>
            </span>

            <FormToggle key={field.name} name={field.name} />
        </Switch.Group>
    )
}
