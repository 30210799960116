import React from 'react'
import { FieldHookConfig, useField } from 'formik'
import InputDropdown from './InputDropdown'

interface InputFieldProps {
    name: string
    placeholder: string
    type: string
    fieldtype: 'text' | 'textarea' | 'select-menu' | 'email'
    disabled?: boolean
}

const InputField = ({
    name,
    placeholder,
    type,
    fieldtype,
    disabled,
    ...props
}: InputFieldProps & FieldHookConfig<string>) => {
    const [field, { error, touched }] = useField(name)
    return (
        <div className="flex flex-col">
            {fieldtype === 'text' || fieldtype === 'email' ? (
                <input
                    id={name}
                    {...field}
                    type={type}
                    autoComplete={name}
                    className="bg-[#F6F9F0] w-full px-8 sm:px-10 py-5 border border-black shadow-sm placeholder-gray-800 rounded-full focus:ring-0 focus:border-black"
                    placeholder={placeholder}
                    disabled={disabled}
                />
            ) : fieldtype === 'select-menu' ? (
                <InputDropdown name={name} />
            ) : (
                <textarea
                    id={name}
                    {...field}
                    className="bg-[#F6F9F0] w-full px-6 sm:px-8 py-5 border border-black shadow-sm placeholder-gray-800 rounded-2xl focus:ring-0 focus:border-black"
                    placeholder={placeholder}
                    rows={8}
                    disabled={disabled}
                />
            )}
            {error && touched && (
                <span
                    className={`mt-2 text-xs text-red-500 font-normal ${
                        fieldtype === 'text' ? 'ml-8 sm:ml-10' : 'ml-6 sm:ml-8'
                    }`}
                >
                    {error}
                </span>
            )}
        </div>
    )
}

export default InputField
