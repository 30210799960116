import * as React from 'react'
import { useRef, useEffect } from 'react'
import { useField } from 'formik'

const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY
const mapApiJs = 'https://maps.googleapis.com/maps/api/js'

function loadAsyncScript(src: string) {
    return new Promise(resolve => {
        const script = document.createElement('script')
        Object.assign(script, {
            type: 'text/javascript',
            async: true,
            src,
        })
        script.addEventListener('load', () => resolve(script))
        document.head.appendChild(script)
    })
}

interface LocationTextInputProps {
    label: string
    name: string
}

export const LocationTextInput: React.FunctionComponent<
    LocationTextInputProps
> = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props)
    const searchInput = useRef<HTMLInputElement>(null)

    const initMapScript = () => {
        if (window.google) {
            return Promise.resolve()
        }
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`
        return loadAsyncScript(src)
    }

    const onChangeAddress = (autocomplete: any) => {
        const place = autocomplete.getPlace()
        searchInput.current!.value = place.formatted_address
        helpers.setValue(place.formatted_address)
    }

    const initAutocomplete = () => {
        if (!searchInput.current) return

        const autocomplete = new window.google.maps.places.Autocomplete(
            searchInput.current,
        )
        autocomplete.setTypes(['locality', 'sublocality'])
        autocomplete.setFields(['address_component', 'formatted_address'])
        autocomplete.addListener('place_changed', () =>
            onChangeAddress(autocomplete),
        )
    }

    useEffect(() => {
        initMapScript().then(() => initAutocomplete())
    }, [])

    return (
        <div>
            {label && (
                <label className="appearance-none block text-base font-medium text-gray-700 select-none mb-2">
                    {label}
                </label>
            )}
            <div className="search">
                <input
                    ref={searchInput}
                    {...field}
                    {...props}
                    className="w-full px-3 py-3 border border-[#D1D5DB] sm:text-sm focus:outline-none rounded-md"
                />
            </div>

            {meta.touched && meta.error ? (
                <div className="text-sm text-red-400 mt-1.5">{meta.error}</div>
            ) : null}
        </div>
    )
}

export default LocationTextInput
