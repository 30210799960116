import React from 'react'
import { useField } from 'formik'

interface Props {
    label?: string
    badge?: string
    key?: string
    name: string
    placeholder?: string
    type?: string
}

export default function TextSocialInput({ label, badge, ...props }: Props) {
    const [field, meta, helpers] = useField(props)
    return (
        <div>
            {label && (
                <label className="appearance-none block text-base font-medium text-gray-700 select-none mb-2">
                    {label}
                </label>
            )}
            <div className="w-full px-1 border border-[#D1D5DB] rounded-md">
                {badge && (
                    <span className="items-center px-3 py-2.5 rounded bg-[#F3F4F6] text-[#9CA3AF] sm:text-xs">
                        {badge}
                    </span>
                )}
                <input
                    {...field}
                    {...props}
                    className="px-2 w-[80%] inline-block py-3 sm:text-sm focus:outline-none"
                />
            </div>
            {meta.touched && meta.value != meta.initialValue && meta.error ? (
                <div className="text-sm text-red-400 mt-1.5">{meta.error}</div>
            ) : null}
        </div>
    )
}
