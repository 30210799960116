import Link from '@src/app/common/components/Link/Link'
import React from 'react'
import applyNow from '../../../../assets/images/launchpad/applynow.png'
import CountdownTimer from './CountdownTimer'

interface LaunchpadHeroProps {}

const LaunchpadHero = ({}: LaunchpadHeroProps) => {
    return (
        <div className="overflow-x-hidden px-0 sm:px-[20px]">
            <div className="pt-[70px] pb-[150px] md:pt-[80px] md:pb-[80px] xl:pb-[120px] xl:pt-[120px] 2xl:pb-[150px] 2xl:pt-[150px] px-0 bg-brandBeige-500 bg-[url('./assets/images/launchpad/mobile-ban.png')] md:bg-none lg:bg-[url('./assets/images/launchpad/second-bg.png')] bg-no-repeat bg-bottom 2xl:bg-center bg-cover 2xl:bg-contain rounded-[32px]">
                <div className="flex text-center justify-center">
                    <div className="max-w-6xl px-2">
                        <h1 className="text-center text-[70px] leading-[70px] mb-4 font-semibold">
                            YouTubers, meet the launchpad designed just for you.
                        </h1>
                        <div className="grid grid-cols-8 justify-center">
                            <div className="col-start-2 col-span-6">
                                <h2 className="text-[24px] lg:text-[32px] font-light mb-12">
                                    Supercharge your YouTube channel in just 6
                                    weeks with the Creator Launchpad program.{' '}
                                    <br />
                                    Season 1 runs September 13 - October 21.
                                </h2>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 justify-center">
                            <div className="col-start-2 col-span-10 lg:col-start-4 lg:col-span-6">
                                <h6 className="mb-6 font-bold">
                                    Applications close in
                                </h6>
                                <CountdownTimer />
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row justify-center relative mt-12">
                            <Link
                                to="/apply"
                                className="self-center py-[20px] px-[40px] lg:py-[30px] lg:px-[50px] text-white text-[16px] rounded-[100px] bg-black hover:bg-brandPink-500 focus:bg-brandGreen-500 inline-block border-2 border-transparent mb-[20px] lg:mb-0"
                            >
                                Apply Now
                            </Link>
                            <img
                                src={applyNow}
                                className="absolute max-w-[150px] right-[-30px] sm:right-[70px] md:right-[120px] lg:right-[240px] xl:right-[320px] rotate-[-10deg] top-[-30px]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LaunchpadHero
