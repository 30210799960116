import React, { Suspense } from 'react'
import { useFeatureFlag } from '@src/features/shared/useFeatureFlag'
import { useReferralCode } from '@src/app/hooks/useReferralCode.hooks'
import FAQ from '@src/features/pages/application/components/FAQ'
import Loading from '@src/app/layout/Loading'
import SubmissionDetails from './components/SubmissionDetails'
import Timeline from './components/Timeline'
import Hero from './components/Hero'
import FinalRound from './components/FinalRound'
import { useFinalRound } from './components/FinalRound/hooks/useFinalRound.hooks'
import SeasonWinner from './components/FinalRound/components/SeasonWinner'

const SpotlightSubmissions = React.lazy(
    () => import('./components/SpotlightSubmissions'),
)
const LatestPosts = React.lazy(() => import('./components/LastestPosts'))

const Home = () => {
    useReferralCode()
    const ShowHardcodedWinnerSection = useFeatureFlag('show_hardcoded_winner')
    const { showFinalRound } = useFinalRound()
    return (
        <div className="bg-brandOffWhite-500 h-full">
            <SeasonWinner />

            <Suspense fallback={<Loading />}>
                <SpotlightSubmissions />
            </Suspense>
            <Timeline />
            <FAQ background="light" />
            {/* <Suspense fallback={<Loading />}>
                <LatestPosts />
            </Suspense> */}
        </div>
    )
}

export default Home
