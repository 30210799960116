import * as React from 'react'
import { clsx } from 'clsx'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import documentIconURL from '@src/images/icons/document.svg'
import { ButtonRoundedFull } from '@src/features/profiles/components/custom-buttons'
import { Users } from '@src/generated/graphql-operations'

interface Props {
    user: Users
}

export const getSavedSubmissionKey = (userID: string) => `draft-${userID}`

const AppFormDraft = ({ user }: Props) => {
    // Get the form values from the formik context
    const { values } = useFormikContext()

    // Get previously saved submission from the browser's local storage
    const savedSubmissionKey = getSavedSubmissionKey(user.id)

    const locallySavedSubmission = localStorage.getItem(savedSubmissionKey)

    // We use the values here to detect changes against the submission
    const [savedSubmission, setSavedSubmission] = React.useState(
        locallySavedSubmission ? JSON.parse(locallySavedSubmission) : undefined,
    )

    return (
        <div
            className={clsx(
                'flex justify-between items-center w-full',
                'px-4 py-2',
            )}
        >
            <div className={clsx('flex gap-2 items-center')}>
                <img
                    src={documentIconURL}
                    alt="Application Draft Icon"
                    width={24}
                    height={24}
                />
                <div>
                    {!savedSubmission ? (
                        <div>
                            <strong>Submission</strong> <em>is not saved</em>
                        </div>
                    ) : null}
                    {savedSubmission && !_.isEqual(values, savedSubmission) ? (
                        <div>
                            <strong>Unsaved</strong> <em>changes</em>
                        </div>
                    ) : null}
                    {savedSubmission && _.isEqual(values, savedSubmission) ? (
                        <div>
                            <strong>Submission</strong> <em>is saved</em>
                        </div>
                    ) : null}
                </div>
            </div>
            <ButtonRoundedFull
                className="font-bold"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault()
                    e.stopPropagation()

                    setSavedSubmission(values)
                    localStorage.setItem(
                        savedSubmissionKey,
                        JSON.stringify(values),
                    )
                }}
                btnText="Finish later"
                disabled={_.isEqual(values, savedSubmission)}
            />
        </div>
    )
}

export default AppFormDraft
