import * as React from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { HiLogout } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { signOutFirebase } from '@src/app/hooks/authentication.hooks'
import { toast } from 'react-toastify'
import { EVENTS, logEvent } from '@src/app/common/logging/logging'
import { CurrentUser, CurrentUserForCompetition } from '@src/types/users'
import { Button, ButtonVariant } from '@src/app/common/components/Button'

export const useHandleSignOut = (
    currentUserProfile?: CurrentUser | CurrentUserForCompetition,
) => {
    const navigate = useNavigate()

    async function handleSignOut() {
        logEvent(EVENTS.AUTH.LOGOUT_CLICKED)
        try {
            await signOutFirebase()
            navigate('/')
        } catch (error) {
            toast.error(error.message)
        }
    }
    return handleSignOut
}

export default function UserMenu({
    currentUserProfile,
}: {
    currentUserProfile: CurrentUser | CurrentUserForCompetition
}) {
    const handleSignOut = useHandleSignOut(currentUserProfile)

    return (
        <Menu as="div" className="relative flex items-center h-12 w-full">
            <Menu.Button as="div" className="w-full">
                <Button
                    variant={ButtonVariant.FAT}
                    className="border-gray-200 border-[3px] w-full justify-start"
                    leftSideIcon={
                        <img
                            referrerPolicy="no-referrer"
                            className="inline-block h-6 w-6 rounded-full object-cover"
                            src={
                                currentUserProfile?.photo_url ||
                                '/images/user.png'
                            }
                            alt={
                                currentUserProfile?.display_name || 'User Image'
                            }
                        />
                    }
                >
                    {currentUserProfile?.display_name}
                </Button>
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="-top-2 transform -translate-y-full absolute right-0 w-56
                origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1
                ring-black ring-opacity-5 focus:outline-none"
                >
                    <div className="px-1 py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={handleSignOut}
                                    className={`${
                                        active
                                            ? 'bg-brand-500 text-white'
                                            : 'text-gray-900'
                                    } group flex rounded-md items-center w-full px-2 py-2 text-sm focus:ring-0 focus:outline-none hover:bg-gray-50 hover:text-gray-900`}
                                >
                                    <HiLogout
                                        className="w-5 h-5 mr-2 "
                                        aria-hidden="true"
                                    />
                                    Sign out
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
