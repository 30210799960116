export default function ({
    phaseId,
    selectedGroupId,
    displayNameSlug,
    navigate,
}: {
    phaseId: any
    selectedGroupId: string
    displayNameSlug: string
    navigate: any
}) {
    navigate(
        `/thesearch/group/${selectedGroupId}${
            phaseId ? `/${phaseId}` : ''
        }/member/${displayNameSlug}`,
        {
            replace: true,
        },
    )
}
