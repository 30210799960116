import _ from 'lodash'
import { ButtonRoundedFull } from '@src/features/profiles/components/custom-buttons'
import React, { MouseEvent, useMemo, useRef, useState } from 'react'
import checkSuccessIconURL from '@src/assets/images/idols/check-success.svg'
import checkFailedIconURL from '@src/assets/images/idols/check-failure.svg'
import checkPendingIconURL from '@src/assets/images/idols/check-pending.svg'
import { useField } from 'formik'
import { v4 as uuid } from 'uuid'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import storage from '@src/app/config/firebase'
import { toast } from 'react-toastify'
import documentIconURL from '@src/images/icons/document.svg'
import { isStillUploading } from '@src/app/common/util/util'

const VIDEO_SUBMISSION_STORAGE_VALUE = 'video_submissions'

interface VideoCheckRowProps {
    label: string
    status: 'passed' | 'failed' | 'pending'
}

const VideoCheckRow = ({ label, status }: VideoCheckRowProps) => {
    const iconURL = useMemo(() => {
        if (status === 'passed') {
            return checkSuccessIconURL
        }

        if (status === 'failed') {
            return checkFailedIconURL
        }

        return checkPendingIconURL
    }, [status])

    return (
        <div className="flex flex-row gap-2 w-full items-center font-bold">
            <img
                src={iconURL}
                alt="Video validation status icon"
                width={24}
                height={24}
            />
            {label}
        </div>
    )
}

interface AppVideoUploadFieldProps {
    disabled: boolean
    name: string
    showErrorBorder?: boolean
}

const AppVideoUploadField = ({
    name,
    showErrorBorder,
    disabled,
}: AppVideoUploadFieldProps) => {
    const [field, { error, touched }, helpers] = useField(name)
    const inputFieldRef = useRef<HTMLInputElement>(null)
    const [video, setVideo] = useState<File | null>(null)
    const [errors, setErrors] = useState<Array<string | null>>([null, null])

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()

        if (inputFieldRef.current) {
            inputFieldRef.current.click()
        }
    }

    const checkIsVideoDurationValid = async (file: File) => {
        const loadVideo = (file: File): Promise<HTMLVideoElement> => {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video')
                video.onloadedmetadata = () => {
                    resolve(video)
                }
                video.onerror = e => {
                    reject(e)
                }
                video.src = window.URL.createObjectURL(file)
            })
        }

        const video = await loadVideo(file)

        return video.duration > 2
    }

    const validateVideo = async (file: File) => {
        const errors = ['', '']

        if (file.size > 500000000) {
            errors[0] = 'File size is too large.'
        }

        const isVideoDurationValid = await checkIsVideoDurationValid(file)

        if (!isVideoDurationValid) {
            errors[1] = 'Video is too long.'
        }

        setErrors(errors)

        return errors.every(e => e === '')
    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]

        if (!file) {
            return
        }

        const isValid = await validateVideo(file)

        if (isValid) {
            setVideo(file)
            handleUpload(file)
        }
    }

    const handleUpload = (file: File) => {
        const id = uuid()
        const name = [`${id}`, file.type.split('/')[1]].join('.')

        const storageRef = ref(
            storage,
            `/${VIDEO_SUBMISSION_STORAGE_VALUE}/${name}`,
        )

        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
            'state_changed',
            snapshot => {
                const percent =
                    snapshot.totalBytes > 0
                        ? Math.round(
                              (snapshot.bytesTransferred /
                                  snapshot.totalBytes) *
                                  100,
                          )
                        : 0
                helpers.setValue('_progress:' + percent, true)
            },
            err => {
                helpers.setError(err.message)
                setVideo(null)
                helpers.setValue(null, false)
                toast.error(
                    err.message || 'Error uploading video. Please try again.',
                )
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    helpers.setValue(url, true)
                })
            },
        )
    }

    const selectedFile =
        video?.name ??
        (field.value
            ? 'video_submission.mp4'
            : inputFieldRef.current?.files?.[0]?.name)

    return (
        <>
            {selectedFile ? (
                <div className="flex items-center justify-between w-full">
                    <div className="flex flex-row items-center gap-1 text-xs">
                        <img
                            src={documentIconURL}
                            alt="Document icon"
                            width={24}
                            height={24}
                        />
                        {selectedFile}
                    </div>
                    <button
                        className="border border-black rounded-full px-4 py-2 font-bold text-sm"
                        onClick={handleClick}
                    >
                        Upload
                    </button>
                </div>
            ) : (
                <div className="w-full flex justify-center">
                    <ButtonRoundedFull
                        btnText={`Upload`}
                        className="w-full sm:w-28 font-bold"
                        customWidth
                        onClick={handleClick}
                        isSubmitting={isStillUploading(field.value)}
                        disabled={disabled}
                    />
                </div>
            )}
            <input
                id="input_video"
                type="file"
                className="hidden"
                ref={inputFieldRef}
                accept="video/mp4,video/x-msvideo,video/quicktime,video/x-ms-wmv"
                onChange={handleChange}
            />
            <input {...field} name={name} type="hidden" />
            <div className="w-full flex-col flex gap-1">
                <div className="font-bold">Your video upload must:</div>
                <VideoCheckRow
                    status={
                        errors[0] === null
                            ? 'pending'
                            : errors[0] !== ''
                            ? 'failed'
                            : 'passed'
                    }
                    label="not exceed 100 MB"
                />
                <VideoCheckRow
                    status={
                        errors[1] === null
                            ? 'pending'
                            : errors[1] !== ''
                            ? 'failed'
                            : 'passed'
                    }
                    label="not be more than 1 minute"
                />
            </div>
            {error && touched ? (
                <span className="py-1 text-xs text-red-500 font-normal">
                    {error}
                </span>
            ) : (
                <></>
            )}
        </>
    )
}

export default AppVideoUploadField
