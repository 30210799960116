import { initializeApp } from 'firebase/app'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator } from 'firebase/auth'

import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export const app = initializeApp(firebaseConfig)
const storage = getStorage(app)
if (process.env.REACT_APP_USE_AUTH_EMULATOR === 'true') {
    connectAuthEmulator(getAuth(), 'http://localhost:9099', {
        disableWarnings: true,
    })
}
if (process.env.REACT_APP_USE_FUNCTIONS_EMULATOR === 'true') {
    connectFunctionsEmulator(getFunctions(app), 'localhost', 5001)
}
if (process.env.REACT_APP_USE_STORAGE_EMULATOR === 'true') {
    connectStorageEmulator(storage, 'localhost', 9199)
}

export const googleAnalytics = getAnalytics(app)

if (process.env.REACT_APP_FOR_DEPLOY !== 'true') {
    console.log('Using firebase: ' + process.env.REACT_APP_FIREBASE_AUTH_DOMAIN)
    console.log('Use emulator: ', process.env.REACT_APP_USE_FUNCTIONS_EMULATOR)
}

export default storage
