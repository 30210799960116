import { useCompetition } from '@src/features/idols/pages/admin/useCompetition'
import { useCurrentUserProfile } from '@src/features/shared/useCurrentUserProfile'
import {
    Order_By,
    useGetCompetitionGroupsViewQuery,
    useGetCompetitionGroupsWithVotesQuery,
} from '@src/generated/graphql-operations'
import { useEffect, useMemo, useState } from 'react'
import getSortedMemberListByUserId from '../helper/getSortedMemberListByUserId'
import _sortGroupByVotes from '../helper/getSortMembersByVotes'
import { useEnableRedirectForDesktop } from './useEnableReidrectForDesktop.hooks'

interface UseGroupMembersListProps {
    groupId: string | undefined
    phaseId: string | undefined
    redirectOnGroupSelect: boolean
    previousPhase?: any
}

interface GroupMembersList {
    currentUserVotes: Array<any>
    competitionGroups: any
    loading: boolean
    sortedMembers: any
    selectedGroup: any
    refetchGroupWithVotes: any
    refetchCompetitionGroups: any
}

export function useGroupMembersList({
    groupId,
    phaseId,
    redirectOnGroupSelect,
    previousPhase,
}: UseGroupMembersListProps): GroupMembersList {
    const [selectedGroup, setSelectedGroup] = useState<object | null>(null)
    const { winningUserId } = useCompetition()
    const { currentUserProfile } = useCurrentUserProfile('cache-first', true)

    const {
        data: competitionGroupsWithVotesData,
        loading,
        refetch: refetchGroupWithVotes,
    } = useGetCompetitionGroupsWithVotesQuery({
        variables: {
            orderBy: {
                competition_votes_aggregate: { count: Order_By.Desc },
            },
            where: { phase_id: { _eq: phaseId }, id: { _eq: Number(groupId) } },
        },
        skip: !phaseId,
        fetchPolicy: 'cache-and-network',
    })

    const {
        data: competitionGroupsViewData,
        refetch: refetchCompetitionGroups,
    } = useGetCompetitionGroupsViewQuery({
        variables: {
            orderBy: { created_at: Order_By.Asc },
            where: { phase_id: { _eq: phaseId } },
            groupMembershipsLimit: 4,
        },
    })

    const groupsWithVotesData = useMemo(
        () => competitionGroupsWithVotesData?.competition_groups || [],
        [competitionGroupsWithVotesData?.competition_groups],
    )

    const competitionGroups = useMemo(
        () => competitionGroupsViewData?.competition_groups || [],
        [competitionGroupsViewData?.competition_groups],
    )

    // Enable hooks for desktop redirect from group list to profile
    useEnableRedirectForDesktop({
        competitionGroups: groupsWithVotesData,
        groupId,
        redirectOnGroupSelect,
        previousPhase,
        winningUserId,
    })

    const sortedMembers = useSortedMembersList({ selectedGroup, winningUserId })

    useEffect(() => {
        if (groupId) {
            const findSelectGroup =
                groupsWithVotesData.find(group => group.id == +groupId) ?? null
            setSelectedGroup(findSelectGroup)
        }
    }, [groupId, groupsWithVotesData])

    return {
        currentUserVotes: currentUserProfile?.competition_votes || [],
        competitionGroups: competitionGroups || [],
        loading,
        sortedMembers,
        selectedGroup,
        refetchGroupWithVotes,
        refetchCompetitionGroups,
    }
}

/*****************************/
/*****************************/
/********** UTILS ************/
/*****************************/
/*****************************/

const useSortedMembersList = ({
    selectedGroup,
    winningUserId,
}: {
    selectedGroup: object | null
    winningUserId?: string | null
}) => {
    const sortedMembersList = useMemo(
        () => _sortGroupByVotes(selectedGroup),
        [selectedGroup],
    )
    // if the winner's user id is present then show the winner user at the top of the list
    if (winningUserId) {
        return getSortedMemberListByUserId(sortedMembersList, winningUserId)
    }

    return sortedMembersList
}
