import React from 'react'
import ApplicationForm from './components/ApplicationForm'
import FAQ from './components/FAQ'

interface ApplicationProps {}

const Application = ({}: ApplicationProps) => {
    return (
        <div className="space-y-16 sm:space-y-24 py-12 sm:py-20">
            <ApplicationForm />
            {/* <FAQ background="light" /> */}
        </div>
    )
}

export default Application
