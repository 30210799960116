import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAuth } from '../../interfaces/auth.interface'
import { AppState } from '../store'

const initialState: IAuth = {
    authenticated: false,
    currentUser: null,
    idToken: null,
    initialized: false,
    fetchedFeatureFlags: false,
    unauthenticatedEmail: null,
}

export const selectCurrentUser = (state: AppState) => state.auth.currentUser

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signInUser: (state, action: PayloadAction<any>) => {
            state.authenticated = true
            state.idToken = action.payload.idToken
            state.currentUser = {
                emailVerified: action.payload.emailVerified,
                email: action.payload.email,
                photoURL: action.payload.photoURL,
                uid: action.payload.uid,
                displayName: action.payload.displayName,
                timezone: action.payload.timezone,
            }
        },
        signOutUser: state => {
            state.authenticated = false
            state.currentUser = null
            state.idToken = null
        },
        appLoaded: state => {
            state.initialized = true
        },
        setFetchedFeatureFlags: (
            state,
            action: PayloadAction<any>,
        ) => {
            // If the fetched variables aren't updated at all, then "activated"
            // is false, thus the "maybe" in the variable name.
            state.fetchedFeatureFlags = action.payload.fetchedFeatureFlags
        },
    },
})

export const {
    signInUser,
    signOutUser,
    appLoaded,
    setFetchedFeatureFlags,
} = authSlice.actions

export default authSlice.reducer
