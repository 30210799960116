import { useGetUserWithPrivateByIdForCompetitionQuery } from '@src/generated/graphql-operations'
import { CurrentUserForCompetition } from '@src/types/users'
import { useCompetition } from '../pages/admin/useCompetition'

type GetSeasonWinner = {
    loading: boolean
    winnerProfile?: CurrentUserForCompetition
}

export function useGetSeasonWinner(): GetSeasonWinner {
    const { winningUserId } = useCompetition()
    const { loading, data } = useGetUserWithPrivateByIdForCompetitionQuery({
        variables: {
            id: winningUserId || '',
        },
        skip: !winningUserId,
    })

    return {
        loading,
        winnerProfile: data?.users_by_pk,
    }
}
