import { ApolloError } from '@apollo/client'
import {
    CURRENT_PHASE,
    useGetCurrentPhase,
} from '@src/features/idols/hooks/useGetCurrentPhase'
import {
    GetCompetitionQuery,
    useGetCompetitionQuery,
} from '@src/generated/graphql-operations'
import { getCurrentRound } from '@src/helper/idolCounterDateValidation'
import { useMemo } from 'react'

interface UseGetCurrentTimeForCompetition {
    competition: GetCompetitionQuery['competitions_by_pk']
    competitionTime: Date
    loading: boolean
    error?: ApolloError
    currentRound?: any
    currentPhase: CURRENT_PHASE
    isApplicationsOpen: boolean
    isBufferPeriod: boolean
    upcomingPhase: string
    winningUserId?: string | null
}

export function useCompetition(): UseGetCurrentTimeForCompetition {
    const { data, loading, error } = useGetCompetitionQuery({
        variables: { id: 1 },
        fetchPolicy: 'cache-and-network',
    })
    const competition = data?.competitions_by_pk

    // The competition is divided up into phases, some of which
    // are voting rounds, while others are phases representing other
    // non-voting periods of the competition like the application
    // period, the buffer between voting rounds, etc. This variable
    // lets you access the current phase of the competition, in
    // contrast to current round below, which is the closest voting
    // round. Also note that this is just the phase id, e.g.,
    // "second-buffer"
    const currentPhase = useGetCurrentPhase(competition)

    // The closest voting round - e.g., Round 1, Semi-finals, etc.
    // This includes all the fields for this phase in the competition,
    // and is not just a phase id like currentPhase above.
    const currentRound = useMemo(
        () => getCurrentRound(data?.competitions_by_pk),
        [data?.competitions_by_pk],
    )

    const upcomingPhase =
        Object.values(CURRENT_PHASE)[
            Object.values(CURRENT_PHASE).findIndex(e => e === currentPhase) + 1
        ]

    return {
        competition,
        competitionTime:
            data?.competitions_by_pk?.datetime_for_testing || new Date(),
        loading,
        currentRound,
        error,
        currentPhase,
        isApplicationsOpen: [
            CURRENT_PHASE.APPLICATIONS_OPEN,
            CURRENT_PHASE._1ST_GROUPING_BUFFER,
            CURRENT_PHASE.FIRST_ROUND,
        ].includes(currentPhase as CURRENT_PHASE),
        isBufferPeriod: currentPhase.includes('buffer'),
        upcomingPhase,
        winningUserId: data?.competitions_by_pk?.winning_user_id,
    }
}
