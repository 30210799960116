import React from 'react'
import { useFeatureFlag } from '@src/features/shared/useFeatureFlag'
import { Form, Formik, FormikHelpers } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import TextInput from '../../../app/common/form/TextInput'
import { getFirebaseErrorString } from '../../../app/common/util/util'
import { sendResetEmail } from '../../../app/hooks/authentication.hooks'
import getInitialValuesAndValidationSchema from '../../../helper/getInitialValuesAndValidationSchema'
import AuthSubmitButton from './AuthSubmitButton'

interface ForgetPasswordFormProps {
    initialValue: string
    handleForgetPassword: any
}

const ForgetPasswordForm: React.FC<ForgetPasswordFormProps> = ({
    initialValue,
    handleForgetPassword,
}) => {
    const emailSignUpEnabled = useFeatureFlag('email_pw_signup_enabled')

    const fields = [
        {
            name: 'email',
            placeholder: 'Email address',
            initialValue: initialValue,
            validationSchema: Yup.string()
                .required()
                .email('Enter a valid email (jane@example.com)'),

            className: '',
            autoFocus: true,
        },
    ]

    const { initialValues, validationSchema } =
        getInitialValuesAndValidationSchema(fields)

    const handleFormSubmit = (
        values: any,
        { setSubmitting, setErrors }: FormikHelpers<any>,
    ) => {
        try {
            sendResetEmail(values.email)
            toast(
                'If there was an account associated with that email, a link to reset your password has been emailed to you.',
            )
            setSubmitting(false)
            handleForgetPassword(values)
        } catch (error) {
            setErrors({
                auth: getFirebaseErrorString(error),
            })
            setSubmitting(false)
        }
    }

    return (
        <div className="space-y-4">
            <h3 className="text-2xl font-bold leading-5">Forgot Password</h3>
            <p className="text-base leading-6 font-bold">
                Tell us your email and we’ll send a link to reset your password.
            </p>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object(validationSchema)}
                onSubmit={handleFormSubmit}
                validateOnBlur={false}
            >
                {({ isSubmitting, errors }) => (
                    <Form className="space-y-4">
                        <>
                            {emailSignUpEnabled && (
                                <div className="select-none">
                                    <div className="text-base font-bold flex items-center justify-between gap-2 select-none">
                                        <label
                                            htmlFor={'email'}
                                            className="block  text-brandBlack-500"
                                        >
                                            Email address
                                        </label>
                                    </div>
                                    {fields.map(field => (
                                        <TextInput
                                            key={field.name}
                                            name={field.name}
                                            placeholder={field.placeholder}
                                            variant={'fanx'}
                                            autoFocus={field.autoFocus}
                                        />
                                    ))}
                                </div>
                            )}
                            {errors.auth && (
                                <div className={'text-red-500 mt-2 text-sm'}>
                                    {errors.auth.toString()}
                                </div>
                            )}
                            <div>
                                <AuthSubmitButton
                                    disabled={isSubmitting}
                                    isSubmitting={isSubmitting}
                                    title="Send link"
                                />
                            </div>
                        </>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ForgetPasswordForm
