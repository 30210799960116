import { EVENTS, logPageViewEvent } from '@src/app/common/logging/logging'
import { setFanRedirectFrom, setRedirectPath } from '@src/app/common/util/util'
import { useReferralCode } from '@src/app/hooks/useReferralCode.hooks'
import Loading from '@src/app/layout/Loading'
import { useAppSelector } from '@src/app/redux/store'
import { useCompetition } from '@src/features/idols/pages/admin/useCompetition'
import { useGetUserByIdQuery, Users } from '@src/generated/graphql-operations'
import * as React from 'react'
import RedditPixel from 'react-reddit-pixel'
import { useNavigate } from 'react-router-dom'
import AppComplete from './AppComplete'
import AppForm from './AppForm'
import AppGuide from './AppGuide'
import AppSubmissionClosed from './AppSubmissionClosed'

export const REFERRAL_CODE_KEY = 'creatordao-referral-code'

interface UserApplicationProps {}

const UserApplication: React.FC<UserApplicationProps> = ({}) => {
    const { referralCode } = useReferralCode()
    const navigate = useNavigate()
    const { currentUser } = useAppSelector(state => state.auth)
    const { isAgreeingToTerms } = useAppSelector(
        state => state.competitionApplication,
    )
    const { data, loading, refetch } = useGetUserByIdQuery({
        variables: { id: currentUser?.uid || '' },
    })
    const profile = data?.users_by_pk

    const { isApplicationsOpen, loading: competitionLoading } = useCompetition()

    React.useEffect(() => {
        logPageViewEvent(EVENTS.FANX.PAGE.APPLICATION)
        RedditPixel.pageVisit()
    }, [])

    React.useEffect(() => {
        if (isAgreeingToTerms && !loading && !profile?.id) {
            setRedirectPath(location.pathname)
            setFanRedirectFrom('join')
            navigate('/signup')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAgreeingToTerms])

    if (loading || competitionLoading) {
        return <Loading />
    }
    return !isApplicationsOpen ? (
        <AppSubmissionClosed />
    ) : (
        <div className="max-w-lg flex flex-col space-y-[32px] items-center">
            {isAgreeingToTerms &&
            profile?.id &&
            !profile.competition_applications?.id ? (
                <AppForm user={profile as Users} refetchUser={refetch} />
            ) : profile?.id && profile.competition_applications?.id ? (
                <AppComplete user={profile as Users} />
            ) : (
                <AppGuide
                    profile={profile as Users}
                    referralCode={referralCode}
                />
            )}
        </div>
    )
}

export default UserApplication
