import React from 'react'
import {
    getDayLabel,
    getFormatedDate,
    getTodayDate,
} from '../../../../helper/dateFormatter'
import BuildHourChips from './BuildHourChips'

interface BuildWeekDaysAndHoursProps {
    error: any
    setErrors: (any: any) => any
    officeHours: Array<any> // TODO replace with correct Office hours type
    slots: Array<any>
    selectedId: string
    originalTz: string
    showMoreClicked: boolean
    currentTz: string
    duration: number
    previouslyBookedSessions: Array<object>
    clickedShowMore: string
    setClickedShowMore: (any: any) => any
    setUserSelectedDate: (any: any) => any
    setSelectedId: (any: any) => any
    setUserSelectedSlot: (any: any) => any
}

const BuildWeekDaysAndHours = ({
    error,
    setErrors,
    officeHours,
    slots,
    selectedId,
    originalTz,
    showMoreClicked,
    currentTz,
    duration,
    previouslyBookedSessions,
    clickedShowMore,
    setClickedShowMore,
    setUserSelectedDate,
    setSelectedId,
    setUserSelectedSlot,
}: BuildWeekDaysAndHoursProps) => {
    return (
        <div className="col-span-2 sm:col-span-1 sm:gap-4 sm:items-start office-hours__wrapper sm:pt-4 md:pt-10">
            {officeHours.map((hours_by_day, idx) => {
                if (!hours_by_day.enabled) return ''
                const today = idx + 1
                const dayLabel = getDayLabel({
                    day: today,
                    timeZone: currentTz,
                })
                const formatedDate = getFormatedDate({
                    day: today,
                    timeZone: currentTz,
                })
                const todayDate = getTodayDate({ timeZone: currentTz })
                return (
                    formatedDate >= todayDate && (
                        <div key={`oh_${idx}_${dayLabel}`}>
                            <h3 className="mb-2 text-sm font-bold">
                                {hours_by_day.day} {dayLabel}
                            </h3>
                            <div className="flex gap-3 justify-start flex-wrap mt-2">
                                {Array.isArray(hours_by_day.hours) &&
                                    hours_by_day.hours?.map(
                                        (times: {
                                            starts: string
                                            ends: string
                                        }) => (
                                            <BuildHourChips
                                                key={`_${times.starts}_${dayLabel}_${hours_by_day.day}`}
                                                error={error}
                                                setErrors={setErrors}
                                                userDate={formatedDate}
                                                from={times.starts}
                                                to={times.ends}
                                                showTitle={false}
                                                limit={4}
                                                slots={slots}
                                                selectedId={selectedId}
                                                originalTz={originalTz}
                                                showMoreClicked={
                                                    showMoreClicked
                                                }
                                                currentTz={currentTz}
                                                duration={duration}
                                                previouslyBookedSessions={
                                                    previouslyBookedSessions
                                                }
                                                clickedShowMore={
                                                    clickedShowMore
                                                }
                                                setClickedShowMore={
                                                    setClickedShowMore
                                                }
                                                setUserSelectedDate={
                                                    setUserSelectedDate
                                                }
                                                setSelectedId={setSelectedId}
                                                setUserSelectedSlot={
                                                    setUserSelectedSlot
                                                }
                                            />
                                        ),
                                    )}
                            </div>
                        </div>
                    )
                )
            })}
        </div>
    )
}

export default BuildWeekDaysAndHours
