import { useEffect, useState } from 'react'
import moment from 'moment'
import { useCompetition } from '@src/features/idols/pages/admin/useCompetition'

interface UseCountdownProps {
    targetDate: Date
}

interface Countdown {
    hours: string | number
    minutes: string | number
    seconds: string | number
    timeString: string
}

export function useCountdown({ targetDate }: UseCountdownProps): Countdown {
    const { competitionTime } = useCompetition()
    const currentTime = moment(competitionTime)
    const time = targetDate ? moment(targetDate) : currentTime
    const countDownDate = time.valueOf()

    const [countDown, setCountDown] = useState(
        countDownDate - currentTime.valueOf(),
    )

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - currentTime.valueOf())
        }, 1000)

        return () => clearInterval(interval)
    }, [countDownDate, currentTime])

    return getReturnValues(countDown)
}

/*****************************/
/********** UTILS ************/
/*****************************/
const showInTwoDigits = (count: any) => (count < 10 ? `0${count}` : count)

const getReturnValues = (countDown: any) => {
    const hours = showInTwoDigits(Math.floor(countDown / (1000 * 60 * 60)))
    const minutes = showInTwoDigits(
        Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)),
    )
    const seconds = showInTwoDigits(
        Math.floor((countDown % (1000 * 60)) / 1000),
    )
    const timeString = `${hours} : ${minutes} : ${seconds}`
    return { hours, minutes, seconds, timeString }
}
