import { mergeAttributes } from '@tiptap/core'
import Mention from '@tiptap/extension-mention'

export const CustomMention = Mention.extend({
    renderHTML({ node, HTMLAttributes }) {
        return [
            'span',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            `@${node.attrs.id.display_name}`,
        ]
    },
    addAttributes() {
        return {
            type: {
                default: 'mention',
                parseHTML: element => {
                    return {
                        name: element.getAttribute('data-type'),
                    }
                },
                renderHTML: attributes => {
                    return {
                        'data-type': 'mention',
                    }
                },
            },
            name: {
                default: null,
                parseHTML: element => {
                    return {
                        name: element.getAttribute('data-mention-name'),
                    }
                },
                renderHTML: attributes => {
                    return {
                        'data-mention-name': attributes.id.display_name,
                    }
                },
            },
            id: {
                default: null,
                parseHTML: element => {
                    return {
                        id: element.getAttribute('data-mention-id'),
                    }
                },
                renderHTML: attributes => {
                    return {
                        'data-mention-id': attributes.id.id,
                    }
                },
            },
        }
    },
})
