import React from 'react'

import SocialMediaLinks from './components/SocialMediaLinks'
import AdditionalRewards from './components/AdditionalRewards'
import DaoMembers from './components/DaoMembers'
import JudgingInfo from './components/JudgingInfo'

import leftHeartsImg from '@src/assets/images/idols/new_hearts_left.png'
import rightHeartsImg from '@src/assets/images/idols/money_heart_right.png'
import zoeImg from '@src/assets/images/winner/zoe.png'
import dollarImg from '@src/assets/images/idols/dollar.png'

import CongratulationCard from './components/CongratulationCard'
import DollarBgImages from './components/DollarBgImages'
import SuccessImages from './components/SuccessImages'

const SeasonWinner: React.FC = () => {
    const name = '@ZoeUnlimited!'

    const socialMedia = {
        tiktok: 'https://www.tiktok.com/@zoeunlimited',
        twitter: '',
        instagram: 'https://www.instagram.com/zoeunlimited',
        youtube: 'https://www.youtube.com/c/zoeunlimited',
    }

    return (
        <div className="px-4 py-8 md:py-[74px] relative overflow-hidden bg-winner-bg-gradient bg-cover bg-no-repeat">
            <img
                className="absolute left-0 top-1 max-w-[80px] lg:max-w-full"
                src={leftHeartsImg}
                alt="hearts"
            />
            <img
                className="absolute right-0 top-3 max-w-[80px] lg:max-w-full"
                src={rightHeartsImg}
                alt="hearts"
            />

            <div className="max-w-[1072px] mx-auto relative">
                <SuccessImages />

                <div className="bg-white  pb-[17px] md:pb-6 rounded-2xl relative overflow-hidden">
                    <div className="h-56 sm:h-80 md:h-full md:absolute top-0 bottom-0 right-0 md:max-w-[350px] lg:max-w-[489px]  w-full">
                        <img
                            className="w-full md:h-full object-cover"
                            src={zoeImg}
                            alt={name}
                        />
                    </div>
                    <DollarBgImages />
                    <div className="bg-gradientAMob md:bg-gradientA absolute top-0 bottom-0 left-0 md:max-w-[610px] medium:max-w-[740px]   xmedium:max-w-[836px] w-full z-10"></div>

                    <CongratulationCard
                        name={name}
                        about="We’re excited to be a part of Zoe’s creator journey and grow her community through direct investment and access to industry-leading mentors. Thanks to the 2,000+ creators that participated in Season 1!
                        "
                    />

                    <div className="mt-5 md:mt-6 lg:mt-8 flex flex-col md:flex-row md:flex-wrap items-start md:items-center justify-between gap-4 relative z-20 px-[14px] md:pl-7 2xl:pl-9">
                        <div className="pl-1 md:pl-0 flex  items-center justify-between gap-2">
                            <img
                                className="w-14 md:w-16 lg:w-20"
                                src={dollarImg}
                                alt="dollar"
                            />
                            <div className="mt-2">
                                <p className="text-[13px] leading-5 md:text-sm lg:text-base tracking-[0.05em] uppercase text-brandOrange-1000 font-bold">
                                    Investment Earned
                                </p>
                            </div>
                        </div>
                        <SocialMediaLinks
                            name={name}
                            socialLinks={socialMedia}
                        />
                    </div>
                </div>
            </div>

            <AdditionalRewards />
            <DaoMembers />
            <JudgingInfo />
        </div>
    )
}

export default SeasonWinner
