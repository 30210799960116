import React from 'react'
import globeImg from '@src/assets/images/idols/globe.png'
import doorImg from '@src/assets/images/idols/door.png'
import handshakeImg from '@src/assets/images/idols/handshake.png'
import tickImg from '@src/assets/images/idols/tick.png'
import { twMerge } from 'tailwind-merge'

interface IRewardCard {
    src: string
    className?: string
    title: React.ReactNode
}

const RewardCard: React.FC<IRewardCard> = ({ src, className = '', title }) => {
    return (
        <div className="w-full md:max-w-[331px] space-y-3 md:space-y-4">
            <div
                className={twMerge(
                    'bg-white rounded-2xl h-48 relative flex items-center justify-center overflow-hidden',
                    className,
                )}
            >
                <img src={src} alt={src} />
                <img
                    className="absolute top-4 right-4 w-[26px]"
                    src={tickImg}
                    alt="tick"
                />
            </div>
            <p className="text-xl leading-5 md:text-2xl md:leading-8 font-bold text-center">
                {title}
            </p>
        </div>
    )
}

const AdditionalRewards: React.FC = () => {
    return (
        <div className="max-w-[1072px] mx-auto mt-3">
            <div className="bg-brandBeige-500 px-4 pt-6 pb-8 md:p-6 rounded-2xl relative overflow-hidden space-y-4">
                <p className="uppercase text-brandIdolGreen-400 font-bold text-center md:text-left text-base">
                    Additional Rewards
                </p>
                <div className="flex items-center justify-center flex-wrap gap-7 md:gap-3">
                    <RewardCard
                        src={globeImg}
                        title={
                            <>
                                Collab with a Community
                                <br className="hidden md:block" /> Member
                            </>
                        }
                    />
                    <RewardCard
                        src={doorImg}
                        className="items-end justify-start"
                        title={
                            <>
                                Access Mentorship with <br /> an Industry
                                Council
                            </>
                        }
                    />
                    <RewardCard
                        src={handshakeImg}
                        title={
                            <>
                                Get help starting a <br /> Business
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default AdditionalRewards
