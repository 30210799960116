import React, { useState } from 'react'
import { useAppDispatch } from '../../redux/store'
import { HiExclamationCircle } from 'react-icons/hi'
import { Dialog } from '@headlessui/react'
import { closeModal } from '../../redux/slices/modal.slice'
import ModalWrapper from './ModalWrapper'
import Spinner from '../../layout/Spinner'

interface ConfirmationModalProps {
    title: string
    description: string
    confirmButtonText: string
    handleConfirm: () => void
    useDeleteColors: boolean
}

export default function ConfirmationModal({
    title,
    description,
    confirmButtonText,
    handleConfirm,
    useDeleteColors,
}: ConfirmationModalProps) {
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()

    function closeTapped() {
        dispatch(closeModal())
    }

    async function confirmTapped() {
        setLoading(true)
        await handleConfirm()
        dispatch(closeModal())
        setLoading(false)
    }

    const confirmButtonColors = useDeleteColors
        ? ` bg-red-600 ${loading ? '' : 'hover:bg-red-700'}`
        : ` bg-py-accent   ${loading ? '' : 'hover:bg-py-accent-hover'}`
    const exclamationBackgroundColor = useDeleteColors
        ? '  bg-red-100  '
        : ' bg-brand-100'
    const exclamationForegroundColor = useDeleteColors
        ? 'text-red-600  '
        : ' text-brand-600 '

    return (
        <ModalWrapper>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div
                            className={
                                exclamationBackgroundColor +
                                ' mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10'
                            }
                        >
                            <HiExclamationCircle
                                className={
                                    exclamationForegroundColor + ' h-6 w-6 '
                                }
                                aria-hidden="true"
                            />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title
                                as="h3"
                                className="text-lg leading-6 font-medium text-gray-900"
                            >
                                {title}
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    {description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className={
                            confirmButtonColors +
                            'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60 sm:ml-3 sm:w-auto sm:text-sm'
                        }
                        disabled={loading}
                        onClick={confirmTapped}
                    >
                        {loading ? <Spinner /> : confirmButtonText || 'Yes'}
                    </button>
                    <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-brand-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={closeTapped}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </ModalWrapper>
    )
}
