import React from 'react'
import { FaTwitter, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa'

const Follow = () => {
    return (
        <div className="container mx-auto my-0">
            <div className="lg:hidden text-center">
                <div className="block my-4 mx-auto max-w-[25rem] min-h-[32rem] relative">
                    <a
                        className="flex items-center justify-center w-48 h-48 bg-[#48C96C] rounded-full absolute top-0 left-20"
                        href="https://www.youtube.com/@creatordao"
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label="Follow us on YouTube"
                    >
                        <FaYoutube className="w-16 h-16" />
                    </a>
                    <a
                        className="flex items-center justify-center w-32 h-32 bg-[#EAEEE3] rounded-full absolute top-40 right-12"
                        href="https://www.instagram.com/joincreatordao/"
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label="Follow us on Instagram"
                    >
                        <FaInstagram className="w-16 h-16" />
                    </a>
                    <a
                        className="flex items-center justify-center w-44 h-44 bg-[#FC6ADC] rounded-full absolute top-48 left-6"
                        href="https://www.tiktok.com/@creatordao"
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label="Follow us on TikTok"
                    >
                        <FaTiktok className="w-16 h-16" />
                    </a>
                    <a
                        className="flex items-center justify-center w-52 h-52 bg-[#FFA767] rounded-full absolute top-72 right-4"
                        href="https://twitter.com/joincreatordao"
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label="Follow us on Twitter"
                    >
                        <FaTwitter className="w-16 h-16" />
                    </a>
                </div>
                <div className="font-bold text-3xl">FOLLOW US</div>
            </div>
            <div className="hidden lg:block relative text-center py-52">
                <a
                    className="flex items-center justify-center w-64 h-64 bg-[#48C96C] rounded-full md:absolute md:top-0 md:right-0 lg:top-0 lg:right-[20%]"
                    href="https://www.youtube.com/@creatordao"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <FaYoutube className="w-16 h-16" />
                </a>
                <a
                    className="flex items-center justify-center w-44 h-44 bg-[#EAEEE3] rounded-full md:absolute md:bottom-0 md:right-0 lg:bottom-8 lg:right-[10%] 2xl:top-[40%]"
                    href="https://www.instagram.com/joincreatordao/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <FaInstagram className="w-16 h-16" />
                </a>
                <a
                    className="flex items-center justify-center w-44 h-44 bg-[#FC6ADC] rounded-full md:absolute md:top-16 md:left-10 lg:top-16 lg:left-6 xl:top-[35%]"
                    href="https://www.tiktok.com/@creatordao"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <FaTiktok className="w-16 h-16" />
                </a>
                <a
                    className="flex items-center justify-center w-72 h-72 bg-[#FFA767] rounded-full md:absolute md:bottom-[-13%] left-0 lg:bottom-[-10%] lg:left-[10%]"
                    href="https://twitter.com/joincreatordao"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <FaTwitter className="w-16 h-16" />
                </a>
                <div className="font-bold text-6xl lg:text-7xl">FOLLOW US</div>
            </div>
        </div>
    )
}

export default Follow
