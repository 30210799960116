import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppDispatch } from '../../redux/store'
import { closeModal } from '../../redux/slices/modal.slice'

interface ModalWrapperProps {
    maskClosable?: boolean
    children: React.ReactNode
}
export default function ModalWrapper({
    maskClosable = true,
    children,
}: ModalWrapperProps) {
    const dispatch = useAppDispatch()

    function handleOnClose(openValue: boolean) {
        if (maskClosable) {
            setOpen(openValue)
            dispatch(closeModal())
        }
    }

    const [open, setOpen] = useState(true)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-[60] inset-0 overflow-y-auto"
                //   initialFocus={cancelButtonRef}
                open={open}
                onClose={handleOnClose}
            >
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        {children}
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
