import * as React from 'react'
import { ImBug } from 'react-icons/im'

export function ErrorFallback() {
    return (
        <div className="text-center relative top-[30%] translate-y-[50%]">
            <ImBug className="text-6xl text-red-500 block mx-auto pb-5" />
            <h1 className="text-5xl font-bold">It&apos;s a bug</h1>
            <p>Something went wrong.</p>
            <p>Refresh the page or try again later.</p>
        </div>
    )
}
