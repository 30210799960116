import React from 'react'
import ModalWrapper from '@src/app/common/modals/ModalWrapper'
import AddContractForm from './AddContractForm'

interface AddContractModalProps {}

const AddContractModal: React.FC<AddContractModalProps> = ({}) => {
    return (
        <ModalWrapper>
            <div
                className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 
                text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle 
                w-full sm:max-w-md sm:p-6"
            >
                <div className="flex items-center mb-4">
                    <p className="text-xl font-semibold">New User Contract</p>
                </div>
                <AddContractForm />
            </div>
        </ModalWrapper>
    )
}

export default AddContractModal
