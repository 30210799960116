import React from 'react'
import { useFeatureFlag } from '@src/features/shared/useFeatureFlag'
import { Button } from '@src/app/common/components/Button'
import { logEvent } from '../../../app/common/logging/logging'
import { socialLogin } from '../../../app/hooks/authentication.hooks'

interface SocialButtonInterface {
    title: string
    imgSrc: string
    // For the rest props passed down
    [x: string]: any
}

const SocialButton = ({ title, imgSrc, ...rest }: SocialButtonInterface) => {
    return (
        <Button
            className="w-full h-14 shadow-sm"
            leftSideIcon={<img className="w-6 h-6" src={imgSrc} alt={title} />}
            {...rest}
        >
            {title}
        </Button>
    )
}

export default function NewSocialLogin({ isSignUp }: { isSignUp?: boolean }) {
    const emailSignUpEnabled = useFeatureFlag('email_pw_signup_enabled')

    function handleSocialLogin(provider: string) {
        socialLogin(provider)
        logEvent(
            isSignUp ? 'SignUpSSOFormSubmitted' : 'SignInSSOFormSubmitted',
            {
                provider: provider,
            },
        )
    }

    return (
        <React.Fragment>
            {(emailSignUpEnabled || !isSignUp) && (
                <div className="relative mt-6">
                    <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-400"></div>
                    </div>
                    <div className="relative flex items-center justify-center ">
                        <span className="px-2 text-base leading-4 mt-1 bg-white text-gray-500">
                            OR
                        </span>
                    </div>
                </div>
            )}

            {!navigator.userAgent?.includes('Instagram') &&
                !navigator.userAgent?.includes('Bytedance') && (
                    <SocialButton
                        title={'Continue with Google'}
                        imgSrc={'/images/google.svg'}
                        onClick={() => handleSocialLogin('google')}
                    />
                )}

            {/* <SocialButton
                        title={'Continue with Instagram'}
                        imgSrc={'/images/instagram.svg'}
                    /> */}

            <SocialButton
                title={'Continue with Facebook'}
                imgSrc={'/images/facebook.svg'}
                onClick={() => handleSocialLogin('facebook')}
            />
        </React.Fragment>
    )
}
