import posthog from 'posthog-js'
import { useMemo } from 'react'
import { useAppSelector } from '../../app/redux/store'

export const FEATURE_FLAG_LOCAL_DEFAULTS: any = {
    enable_services_tab: true,
    show_office_hours: true,
    enable_youtube_connection: true,
    show_competition_video: true,
    show_competition_join_button: true,
    show_competition_tiktok_button: true,
    show_leaderboard: true,
    vote_button_text: true,
    show_competition_creator_carousel: true,
}

export function useFeatureFlag(keyName: string): boolean {
    const { fetchedFeatureFlags } = useAppSelector(state => state.auth)

    const value = useMemo(() => {
        if (posthog.__loaded && fetchedFeatureFlags) {
            return posthog.isFeatureEnabled(keyName)
        } else {
            return FEATURE_FLAG_LOCAL_DEFAULTS[keyName]
        }
    }, [keyName, fetchedFeatureFlags])

    return value
}
