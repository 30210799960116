import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IOfficeHours } from '../../interfaces/office-hours.interface'

const initialState: IOfficeHours = {
    showMoreBookTime: '',
    showCancelledMeetings: false,
}

export const officeHoursSlice = createSlice({
    name: 'officehours',
    initialState,
    reducers: {
        setShowMoreBookTime: (
            state,
            action: PayloadAction<{ key: string }>,
        ) => {
            state.showMoreBookTime = action.payload.key
        },
        setShowCancelledMeetings: (
            state,
            action: PayloadAction<{ status: boolean }>,
        ) => {
            state.showCancelledMeetings = action.payload.status
        },
    },
})

export const { setShowMoreBookTime, setShowCancelledMeetings } =
    officeHoursSlice.actions

export default officeHoursSlice.reducer
