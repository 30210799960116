import React from 'react'

import { Switch } from '@headlessui/react'

export default function CustomToggle({ value, handleChange }: any) {
    return (
        <Switch
            checked={value}
            onChange={handleChange}
            className={`${
                value ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full transition-all duration-200`}
        >
            <span className="sr-only">Enable notifications</span>
            <span
                className={`${
                    value ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition-all duration-200`}
            />
        </Switch>
    )
}
