import React from 'react'
import { getAuthSubTitle } from '../../../app/common/util/util'
import {
    LOGIN_FORM,
    RESET_PASSWORD,
    RESET_PASSWORD_SMS_VERIFY_FORM,
    SMS_VERIFY_FORM,
} from '../constants'

interface CommonAuthHeaderProps {
    emailOrPhone: string
    handleGoBack?: () => void
    formType: string
    isSignUp?: boolean
}

const CommonAuthHeader: React.FC<CommonAuthHeaderProps> = ({
    emailOrPhone,
    handleGoBack,
    formType,
    isSignUp = false,
}) => {
    return (
        <React.Fragment>
            {formType === RESET_PASSWORD ? (
                <p className="hidden sm:block text-base font-bold leading-[14px]">
                    Reset password
                </p>
            ) : (
                <p className="hidden sm:block  text-base font-bold leading-[14px]">
                    {formType === RESET_PASSWORD_SMS_VERIFY_FORM
                        ? 'Verify to reset password'
                        : isSignUp
                        ? `Sign up ${getAuthSubTitle()}`
                        : `Log in ${getAuthSubTitle()}`}
                </p>
            )}

            <div>
                <p className="text-base font-normal">{emailOrPhone}</p>
                {handleGoBack && (
                    <p
                        className="inline-block text-xs font-normal underline cursor-pointer"
                        onClick={() => handleGoBack()}
                    >
                        Not you? Go back
                    </p>
                )}
            </div>
            {formType !== RESET_PASSWORD &&
                ([LOGIN_FORM, RESET_PASSWORD_SMS_VERIFY_FORM].includes(
                    formType,
                ) || !isSignUp ? (
                    <div className="space-y-2">
                        <h3 className="text-2xl font-bold leading-5">
                            {formType === RESET_PASSWORD_SMS_VERIFY_FORM
                                ? 'Verify Code'
                                : '  Welcome back!'}
                        </h3>
                        <p className="text-base leading-6 font-normal">
                            {formType === SMS_VERIFY_FORM
                                ? 'Verify the code we sent to your phone.'
                                : formType === RESET_PASSWORD_SMS_VERIFY_FORM
                                ? 'We sent the code to your email.'
                                : 'Enter your password to continue.'}
                        </p>
                    </div>
                ) : (
                    <h3 className="text-2xl font-bold leading-[22px]">
                        {formType === SMS_VERIFY_FORM
                            ? ' We sent the code to your phone.'
                            : 'Create your account'}
                    </h3>
                ))}
        </React.Fragment>
    )
}

export default CommonAuthHeader
