import { Dialog, Menu, Transition } from '@headlessui/react'
import { EVENTS, logEvent } from '@src/app/common/logging/logging'
import { setRedirectPath } from '@src/app/common/util/util'
import { resetCompetitionApplication } from '@src/app/redux/slices/competition-application.slice'
import { useAppDispatch } from '@src/app/redux/store'
import { URLS } from '@src/constants/urls'
import { useHandleSignOut } from '@src/features/dashboard/alternative/UserMenu'
import { useCurrentUserProfile } from '@src/features/shared/useCurrentUserProfile'
import { GetCompetitionQuery, Users } from '@src/generated/graphql-operations'
import { CurrentUser, CurrentUserForCompetition } from '@src/types/users'
import React, { Fragment, useState } from 'react'
import {
    FaCog,
    FaSignOutAlt,
    FaStream,
    FaUser,
    FaAsterisk,
} from 'react-icons/fa'
import { HiMenu } from 'react-icons/hi'
import { IoArrowBack } from 'react-icons/io5'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ButtonPrimary } from '../../components/Button'
import SocialMenu from './SocialMenu'
import { useCompetition } from '@src/features/idols/pages/admin/useCompetition'
import { CURRENT_PHASE } from '@src/features/idols/hooks/useGetCurrentPhase'
import { clsx } from 'clsx'
import { isAfter } from 'date-fns'
import Link from '../../components/Link/Link'

interface NavbarProps {
    currentUserProfile: CurrentUser | CurrentUserForCompetition
    showBackArrow: boolean
    title?: string
    isFromAuthPages?: boolean
    isSignUp?: boolean
}

function BrandLogo({
    isMobile = false,
    onClick,
    user,
}: {
    isMobile?: boolean
    onClick?: () => void
    user: Users | undefined
}) {
    return (
        <Link
            to={user?.id ? URLS.THESEARCH_GROUPS : URLS.ROOT}
            className={`${isMobile ? 'block px-5' : ''}`}
            onClick={onClick}
        >
            <img
                className="h-8 w-16"
                src="/images/cdao_logo.png"
                alt="creator app"
            />
        </Link>
    )
}

type SidebarLink = {
    title: string
    subTitle?: string
    path: string
    event: string
    eventMeta: {
        round: number
        location: string
    }
    className: string
    isActive: boolean
    isLive: boolean
}

const getSubtitleByPhase = (
    currentPhase: CURRENT_PHASE,
    competition: GetCompetitionQuery['competitions_by_pk'],
) => {
    const phase = competition?.phases.find(
        (phase: { phase_id: CURRENT_PHASE }) =>
            (phase.phase_id as CURRENT_PHASE) === currentPhase,
    )

    if (!phase) return undefined

    const startDate = new Date(phase.start_date)
    const endDate = new Date(phase.end_date)

    const startDateString = startDate.toLocaleDateString('default', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    })

    const endDateString =
        currentPhase === CURRENT_PHASE.FINALS
            ? "Judge's Vote"
            : endDate.toLocaleDateString('default', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
              })

    return `${startDateString} - ${endDateString}`
}

const sidebarLinks = (
    hasSubmittedApplication: boolean,
    currentPhase: CURRENT_PHASE,
    competition: GetCompetitionQuery['competitions_by_pk'],
): SidebarLink[] => {
    function findPhase(thresholdPhase: CURRENT_PHASE) {
        return competition?.phases.find(
            (phase: { phase_id: CURRENT_PHASE }) => {
                return (phase.phase_id as CURRENT_PHASE) === thresholdPhase
            },
        )
    }

    const isOrWasActive = (thresholdPhase: CURRENT_PHASE) => {
        const phase = findPhase(thresholdPhase)
        const now = new Date()
        const startDate = new Date(phase?.start_date)
        const endDate = new Date(phase?.end_date)
        return isAfter(now, startDate) || isAfter(now, endDate)
    }

    const isLive = (phase: CURRENT_PHASE) => phase === currentPhase

    const links: SidebarLink[] = [
        {
            title: 'Round 1',
            subTitle: getSubtitleByPhase(
                CURRENT_PHASE.FIRST_ROUND,
                competition,
            ),
            path: isLive(CURRENT_PHASE.FIRST_ROUND)
                ? URLS.THESEARCH_GROUPS
                : `${URLS.THESEARCH_GROUPS}/round/${CURRENT_PHASE.FIRST_ROUND}`,
            event: EVENTS.FANX.GROUPS.ROUND_SELECTED,
            eventMeta: { round: 1, location: 'sidebar' },
            className: '',
            isActive: isOrWasActive(CURRENT_PHASE.FIRST_ROUND),
            isLive: isLive(CURRENT_PHASE.FIRST_ROUND),
        },
        {
            title: 'Round 2',
            subTitle: getSubtitleByPhase(
                CURRENT_PHASE.SECOND_ROUND,
                competition,
            ),
            path: isLive(CURRENT_PHASE.SECOND_ROUND)
                ? URLS.THESEARCH_GROUPS
                : `${URLS.THESEARCH_GROUPS}/round/${CURRENT_PHASE.SECOND_ROUND}`,
            event: EVENTS.FANX.GROUPS.ROUND_SELECTED,
            eventMeta: { round: 2, location: 'sidebar' },
            className: '',
            isActive: isOrWasActive(CURRENT_PHASE.SECOND_ROUND),
            isLive: isLive(CURRENT_PHASE.SECOND_ROUND),
        },
        {
            title: 'Semi-finals',
            subTitle: getSubtitleByPhase(
                CURRENT_PHASE.SEMI_FINALS,
                competition,
            ),
            path: isLive(CURRENT_PHASE.SEMI_FINALS)
                ? URLS.THESEARCH_GROUPS
                : `${URLS.THESEARCH_GROUPS}/round/${CURRENT_PHASE.SEMI_FINALS}`,
            event: EVENTS.FANX.GROUPS.ROUND_SELECTED,
            eventMeta: { round: 3, location: 'sidebar' },
            className: '',
            isActive: isOrWasActive(CURRENT_PHASE.SEMI_FINALS),
            isLive: isLive(CURRENT_PHASE.SEMI_FINALS),
        },
        {
            title: 'Finals',
            subTitle: getSubtitleByPhase(CURRENT_PHASE.FINALS, competition),
            path: URLS.THESEARCH_GROUPS,
            event: EVENTS.FANX.GROUPS.ROUND_SELECTED,
            eventMeta: { round: 4, location: 'sidebar' },
            className: '',
            isLive: currentPhase === CURRENT_PHASE.FINALS,
            isActive: isOrWasActive(CURRENT_PHASE.FINALS),
        },
    ]
    if (
        !hasSubmittedApplication &&
        [
            CURRENT_PHASE.APPLICATIONS_OPEN,
            CURRENT_PHASE._1ST_GROUPING_BUFFER,
            CURRENT_PHASE.FIRST_ROUND,
        ].includes(currentPhase)
    ) {
        links.push({
            title: 'Join #TheSearch',
            path: URLS.THESEARCH_APPLICATION,
            event: EVENTS.FANX.APPLICATION.CLICKED,
            eventMeta: { round: 1, location: 'sidebar' },
            className: '',
            isLive: false,
            isActive: false,
        })
    }

    return links
}

export default function Navbar({
    currentUserProfile,
    showBackArrow,
    title,
    isFromAuthPages = false,
}: NavbarProps) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { isApplicationsOpen } = useCompetition()
    const navigate = useNavigate()
    const handleSignOut = useHandleSignOut(currentUserProfile)
    const location = useLocation()
    const dispatch = useAppDispatch()

    const { currentPhase, competition } = useCompetition()

    const { currentUserProfile: profile } = useCurrentUserProfile()

    function handleGoBack() {
        navigate(-1)
    }

    const handleRedirectPath = () => {
        if (
            ![URLS.LOGIN, URLS.SIGN_UP, URLS.ROOT, URLS.THESEARCH].includes(
                location.pathname,
            )
        ) {
            setRedirectPath(location.pathname)
        }
    }

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 flex z-40"
                    onClose={() => setSidebarOpen(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-[50%]" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex z-40">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel
                                className={`relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none`}
                            >
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2"></div>
                                </Transition.Child>
                                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                    <BrandLogo
                                        isMobile={true}
                                        onClick={() =>
                                            logEvent(
                                                EVENTS.FANX.NAVBAR
                                                    .BRAND_LOGO_CLICKED,
                                            )
                                        }
                                        user={profile as Users}
                                    />
                                    <nav aria-label="Sidebar" className="p-4">
                                        <div className="flex gap-6 p-4 items-center">
                                            <span className="mt-1.5 font-bold leading-[90%]">
                                                #TheSearch Season 1
                                            </span>
                                        </div>
                                        {sidebarLinks(
                                            !!profile?.competition_applications,
                                            currentPhase as CURRENT_PHASE,
                                            competition,
                                        ).map((item, idx) => (
                                            <Link
                                                key={idx}
                                                to={
                                                    item.isActive
                                                        ? item.path
                                                        : '#'
                                                }
                                                className={clsx(
                                                    `gap-6 p-4 flex justify-between items-center ${item.className}`,
                                                    {
                                                        'pointer-events-none':
                                                            !item.isActive,
                                                    },
                                                )}
                                                onClick={() => {
                                                    setSidebarOpen(false)

                                                    if (!item.event) return
                                                    logEvent(
                                                        item.event,
                                                        item.eventMeta
                                                            ? {
                                                                  meta: item.eventMeta,
                                                              }
                                                            : undefined,
                                                    )
                                                }}
                                            >
                                                <div>
                                                    <span className="mt-1.5 block text-[#111] font-bold leading-[90%]">
                                                        {item.title}
                                                    </span>
                                                    {item.subTitle && (
                                                        <span className="text-sm block">
                                                            {item.subTitle}
                                                        </span>
                                                    )}
                                                </div>
                                                <div>
                                                    {item.isLive && (
                                                        <span className="px-1 inline text-white font-bold text-sm bg-red-500 rounded">
                                                            LIVE
                                                        </span>
                                                    )}
                                                </div>
                                            </Link>
                                        ))}
                                        <hr className="my-4" />
                                        <Link
                                            to={URLS.THESEARCH_LEADERBOARD}
                                            className="text-sm gap-6 p-4 flex justify-between items-center"
                                        >
                                            Leaderboard
                                        </Link>
                                        <hr className="my-4" />
                                        <SocialMenu />
                                        <hr className="my-4" />
                                        {/* TODO: About page */}
                                        {/*<Link to={URLS.ABOUT}>About Us</Link>*/}
                                        <a
                                            className="text-sm gap-6 p-4 flex justify-between items-center"
                                            target="_blank"
                                            rel="noreferrer noopener"
                                            href={URLS.BLOG}
                                        >
                                            Blog
                                        </a>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            <div
                className={`flex sticky top-0 z-50 h-[60px] items-center justify-between space-x-4 border-b border-gray-100 bg-white px-5 py-2.5`}
            >
                <div className="flex items-center space-x-4">
                    {showBackArrow && (
                        <IoArrowBack
                            className="h-6 w-6 text-gray-600 hover:text-brandBlack-600 cursor-pointer"
                            onClick={handleGoBack}
                        />
                    )}
                    {!isFromAuthPages && (
                        <>
                            {sidebarOpen && (
                                <button
                                    type="button"
                                    className="h-6 w-6 inline text-black hover:text-gray-900 focus:outline-none focus:ring-0 focus:ring-inset focus:ring-pink-600"
                                    onClick={() => setSidebarOpen(false)}
                                >
                                    <span className="sr-only">
                                        Close sidebar
                                    </span>
                                    <HiMenu
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}
                            {!sidebarOpen && (
                                <button
                                    type="button"
                                    className="h-6 w-6 inline text-black hover:text-gray-900 focus:outline-none focus:ring-0 focus:ring-inset focus:ring-pink-600"
                                    onClick={() => setSidebarOpen(true)}
                                >
                                    <span className="sr-only">
                                        Open sidebar
                                    </span>
                                    <HiMenu
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}
                        </>
                    )}
                    <BrandLogo
                        onClick={() =>
                            logEvent(EVENTS.FANX.NAVBAR.BRAND_LOGO_CLICKED)
                        }
                        user={profile as Users}
                    />
                </div>

                {title && (
                    <p className="sm:hidden text-base font-bold leading-[14px]">
                        {title}
                    </p>
                )}

                <div className="flex items-center gap-4 shrink-0">
                    {!profile?.competition_applications && isApplicationsOpen && (
                        <Link
                            to={URLS.THESEARCH_APPLICATION}
                            className="hidden sm:inline font-medium text-base leading-[14.4px] -tracking-[0.0425em]"
                            onClick={() => {
                                logEvent(EVENTS.FANX.APPLICATION.CLICKED, {
                                    location: 'navbar button',
                                })
                            }}
                        >
                            Join #TheSearch
                        </Link>
                    )}

                    {currentUserProfile ? (
                        <Menu as="div" className="relative">
                            <div data-testid="profile-icon">
                                <Menu.Button className="flex rounded-full focus:outline-none focus:ring-0">
                                    <span className="sr-only">
                                        Open user menu
                                    </span>
                                    <img
                                        className="inline-block h-10 w-10 rounded-full object-cover"
                                        src={
                                            profile?.photo_url ||
                                            '/images/default_dp.png'
                                        }
                                        alt=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null
                                            currentTarget.src =
                                                '/images/user.png'
                                        }}
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={React.Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className={`absolute right-0 z-50 mt-2 p-4 space-y-2 w-[272px] origin-top-right rounded-[16px] bg-white shadow-lg ring-2 ring-black ring-opacity-5 focus:outline-none`}
                                >
                                    {profile?.display_name_slug && (
                                        <Menu.Item>
                                            <Button
                                                className="w-full h-14"
                                                onClick={() => {
                                                    navigate(
                                                        `${URLS.THESEARCH_PROFILE}/${profile?.display_name_slug}`,
                                                    )
                                                    logEvent(
                                                        EVENTS.FANX.NAVBAR
                                                            .VIEW_PROFILE_CLICKED,
                                                    )
                                                }}
                                                leftSideIcon={
                                                    <FaUser className="h-5 w-5" />
                                                }
                                            >
                                                View Profile
                                            </Button>
                                        </Menu.Item>
                                    )}
                                    {profile?.private?.portal_access && (
                                        <Menu.Item>
                                            <Button
                                                className="w-full h-14"
                                                onClick={() => {
                                                    navigate(URLS.HOME)
                                                    logEvent(
                                                        EVENTS.FANX.NAVBAR
                                                            .CREATOR_DASHBOARD_CLICKED,
                                                    )
                                                }}
                                                leftSideIcon={
                                                    <FaStream className="h-5 w-5" />
                                                }
                                            >
                                                Creator Dashboard
                                            </Button>
                                        </Menu.Item>
                                    )}
                                    <Menu.Item>
                                        <Button
                                            className="w-full h-14"
                                            onClick={() => {
                                                navigate(
                                                    URLS.THESEARCH_LEADERBOARD,
                                                )
                                                logEvent(
                                                    EVENTS.FANX.NAVBAR
                                                        .LEADERBOARD_CLICKED,
                                                )
                                            }}
                                            leftSideIcon={
                                                <FaAsterisk className="h-5 w-5" />
                                            }
                                        >
                                            Leaderboard
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button
                                            data-testid="settings-button"
                                            className="w-full h-14"
                                            onClick={() => {
                                                navigate(
                                                    URLS.THESEARCH_SETTINGS,
                                                )
                                                logEvent(
                                                    EVENTS.FANX.NAVBAR
                                                        .SETTINGS_CLICKED,
                                                )
                                            }}
                                            leftSideIcon={
                                                <FaCog className="h-5 w-5" />
                                            }
                                        >
                                            Settings
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button
                                            className="w-full h-14"
                                            onClick={async () => {
                                                dispatch(
                                                    resetCompetitionApplication(),
                                                )
                                                await handleSignOut()
                                            }}
                                            leftSideIcon={
                                                <FaSignOutAlt className="h-5 w-5" />
                                            }
                                        >
                                            Log out
                                        </Button>
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    ) : (
                        <React.Fragment>
                            {location.pathname !== URLS.LOGIN && (
                                <Button
                                    className="text-sm leading-3 font-bold rounded-full h-auto py-[11px] px-4 border-2 border-black"
                                    onClick={() => {
                                        handleRedirectPath()

                                        logEvent(EVENTS.AUTH.LOGIN, {
                                            meta: {
                                                location: 'Navbar',
                                            },
                                        })
                                        navigate(URLS.LOGIN)
                                    }}
                                >
                                    Log in
                                </Button>
                            )}
                            {location.pathname !== URLS.SIGN_UP && (
                                <Button
                                    primary={ButtonPrimary.BLACK}
                                    className={` ${
                                        location.pathname == URLS.LOGIN
                                            ? ''
                                            : 'hidden sm:block '
                                    }  text-sm leading-3 font-bold rounded-full h-auto py-[11px] px-4 border-2 border-black bg-brandBlack-500 text-white`}
                                    onClick={() => {
                                        handleRedirectPath()

                                        logEvent(EVENTS.AUTH.SIGNUP_CLICKED, {
                                            meta: {
                                                location: 'Navbar',
                                            },
                                        })
                                        navigate(URLS.SIGN_UP)
                                    }}
                                >
                                    Sign up
                                </Button>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </>
    )
}
