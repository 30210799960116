import React, { useEffect, useState } from 'react'
import { useCompetition } from '@src/features/idols/pages/admin/useCompetition'

const DateTimeDisplay = ({ value, type }: any) => {
    return (
        <div>
            <div className="flex items-center">
                <h1
                    className={`text-[30px] md:text-[70px] lg:text-[60px] xl:text-[70px] mb-0 ${
                        type === 'Days'
                            ? 'text-brandPink-500'
                            : type === 'Hours'
                            ? 'text-brandOrange-500'
                            : type === 'Mins'
                            ? 'text-brandGreen-500'
                            : 'text-black'
                    }`}
                >
                    {value}
                </h1>
                <span className="ml-1 md:ml-5 lg:ml-2 2xl:ml-5">{type}</span>
            </div>
        </div>
    )
}

interface CountdownTimerProps {}

const CountdownTimer = ({}: CountdownTimerProps) => {
    const { competitionTime } = useCompetition()

    const EXPIRE_DATE = new Date('2022-09-07').getTime()

    const useCountdown = (targetDate: any) => {
        const countDownDate = new Date(targetDate).getTime()

        const [countDown, setCountDown] = useState(
            countDownDate - competitionTime.getTime(),
        )

        useEffect(() => {
            const interval = setInterval(() => {
                setCountDown(countDownDate - competitionTime.getTime())
            }, 1000)

            return () => clearInterval(interval)
        }, [countDownDate])

        return getReturnValues(countDown)
    }

    const getReturnValues = (countDown: any) => {
        // calculate time left
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
        const hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        )
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

        return [days, hours, minutes, seconds]
    }

    const [days, hours, minutes, seconds] = useCountdown(EXPIRE_DATE)

    if (days + hours + minutes + seconds <= 0) {
        return (
            <div>
                <span className="text-[30px] md:text-[70px] lg:text-[60px] xl:text-[70px] font-bold">
                    Closed!
                </span>
            </div>
        )
    } else {
        return (
            <div className="show-counter">
                <div className="flex items-center justify-between">
                    <DateTimeDisplay value={days} type={'Days'} />
                    <DateTimeDisplay value={hours} type={'Hours'} />
                    <DateTimeDisplay value={minutes} type={'Mins'} />
                    <DateTimeDisplay value={seconds} type={'Seconds'} />
                </div>
            </div>
        )
    }
}

export default CountdownTimer
