import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { BothCurrentUser } from '@src/types/users'
import { useAppSelector } from '../redux/store'
import { URLS } from '@src/constants/urls'

interface UnauthenticatedRouteProps {
    user?: BothCurrentUser
}

const UnauthenticatedRoute = ({ user }: UnauthenticatedRouteProps) => {
    const { authenticated } = useAppSelector(state => state.auth)
    const redirectPath = localStorage.getItem('redirectPath')

    const authenticatedPath = user?.private?.portal_access
        ? URLS.HOME
        : user?.competition_applications?.id
        ? `/thesearch/profile/${user.display_name_slug}`
        : '/thesearch/groups'

    return !authenticated ? (
        <Outlet />
    ) : (
        <Navigate to={redirectPath || authenticatedPath} replace />
    )
}

export default UnauthenticatedRoute
