import * as React from 'react'
import clsx from 'clsx'

interface MarqueeStripProps {
    bgColor: 'bg-brandGreen-500' | 'bg-brandOrange-500' | 'bg-brandPink-500'
    className?: string
    title: string
}

const MarqueeStrip: React.FC<MarqueeStripProps> = ({
    bgColor,
    className = 'animate-marquee',
    title,
}) => {
    return (
        <div
            className={clsx(
                'h-11 md:h-16 flex items-center overflow-x-hidden w-full relative -rotate-3 ',
                bgColor,
            )}
        >
            <div className={clsx('absolute flex ', className)}>
                {[...Array(20)].map((_, index) => (
                    <div
                        key={index}
                        className="flex items-center animate  shrink-0 gap-4 pl-4"
                    >
                        <img
                            className="w-12 md:w-20"
                            src="images/logo.png"
                            alt="logo"
                        />
                        <p className="uppercase text-3xl md:text-5xl font-bold">
                            {title}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}

const JoinUsBanner = () => {
    return (
        <div className="bg-white space-y-4 py-8 md:py-14 overflow-x-hidden">
            <MarqueeStrip bgColor="bg-brandGreen-500" title={'Join us'} />
            <MarqueeStrip
                bgColor="bg-brandOrange-500"
                className="animate-marquee2"
                title={'Meet Creators'}
            />
            <MarqueeStrip
                bgColor="bg-brandPink-500"
                className="animate-marquee3"
                title={'LAUNCH YOUR BUSINESS'}
            />
        </div>
    )
}

export default JoinUsBanner
