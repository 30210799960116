import React from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { BsFillCheckCircleFill, BsXCircleFill } from 'react-icons/bs'
import * as Yup from 'yup'
import FormField from '../../../app/common/form/FormField'
import { logEvent } from '../../../app/common/logging/logging'
import {
    getFanRedirectFrom,
    getFirebaseErrorString,
    setDisplayName,
    setFanRedirectFrom,
} from '../../../app/common/util/util'
import { registerInFirebase } from '../../../app/hooks/authentication.hooks'
import getInitialValuesAndValidationSchema, {
    FieldType,
} from '../../../helper/getInitialValuesAndValidationSchema'
import { CREATE_ACCOUNT_FORM, CREATE_PHONE_ACCOUNT_FORM } from '../constants'
import AuthSubmitButton from './AuthSubmitButton'
import CommonAuthHeader from './CommonAuthHeader'

interface AccountFormProps {
    currentStep: string
    userFormData: any
    handleChangeStep: any
    goBackTo: string
    isSignUp?: boolean
}

const AccountForm: React.FC<AccountFormProps> = ({
    currentStep,
    userFormData,
    handleChangeStep,
    goBackTo,
    isSignUp,
}) => {
    const CreateAccountFields = [
        {
            name: 'display_name',
            displayName: 'Display name',
            isRequired: true,
            type: 'text',
            initialValue: '',
            placeholder: 'Display name',
            validationSchema: Yup.string()
                .min(2, 'Display name needs to be between 2 to 40 characters')
                .max(40, 'Display name needs to be between 2 to 40 characters')
                .required('Display name is required'),
            autoFocus: true,
        },
        {
            name: 'password',
            displayName: 'Password',
            isRequired: true,
            type: 'password',
            initialValue: '',
            placeholder: 'Password',
            validationSchema: Yup.string()
                .min(6, 'At least 6 characters long')
                .required('Password is required'),
        },
    ]

    const resetPasswordFields = [
        {
            name: 'password',
            displayName: 'New Password',
            isRequired: true,
            type: 'password',
            initialValue: '',
            placeholder: 'New Password',
            validationSchema: Yup.string()
                .min(6, 'At least 6 characters long')
                .required('New Password is required'),
            autoFocus: true,
        },
    ]

    const fields: FieldType[] = [
        CREATE_ACCOUNT_FORM,
        CREATE_PHONE_ACCOUNT_FORM,
    ].includes(currentStep)
        ? CreateAccountFields
        : resetPasswordFields

    const { initialValues, validationSchema } =
        getInitialValuesAndValidationSchema(fields)

    const createAcount = async (values: {
        email: string
        password: string
        display_name: string
    }) => {
        logEvent('SignUpNameFormSubmitted')
        setDisplayName(values.display_name)
        await registerInFirebase(values)

        if (getFanRedirectFrom()) setFanRedirectFrom(null)
    }

    const createAcountWithPhone = (values: {
        phoneNumber: string
        password: string
        display_name: string
    }) => {
        console.log(values)
    }

    const resetPassword = (values: { password: string }) => {
        console.log('reset', values)
    }

    const handleFormSubmit = async (
        values: any,
        { setSubmitting, setErrors }: FormikHelpers<any>,
    ) => {
        try {
            if (
                [CREATE_ACCOUNT_FORM, CREATE_PHONE_ACCOUNT_FORM].includes(
                    currentStep,
                )
            ) {
                if (currentStep === CREATE_ACCOUNT_FORM)
                    await createAcount({
                        email: userFormData?.email,
                        ...values,
                    })
                else
                    createAcountWithPhone({
                        phoneNumber: userFormData?.phoneNumberWithCode,
                        ...values,
                    })
            } else {
                resetPassword(values)
            }
        } catch (error) {
            setErrors({
                auth: getFirebaseErrorString(error),
            })
            setSubmitting(false)
        }
    }

    const handleGoBack = () => {
        handleChangeStep(goBackTo)
    }

    return (
        <React.Fragment>
            <div className="space-y-6">
                <CommonAuthHeader
                    emailOrPhone={
                        currentStep === CREATE_PHONE_ACCOUNT_FORM
                            ? userFormData?.phoneNumberWithCode
                            : userFormData?.email
                    }
                    handleGoBack={handleGoBack}
                    formType={currentStep}
                    isSignUp={isSignUp}
                />

                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object(validationSchema)}
                    validateOnBlur={false}
                    onSubmit={handleFormSubmit}
                >
                    {({ isSubmitting, touched, isValid, errors, values }) => (
                        <Form className="space-y-6">
                            {fields && fields?.length > 0 && (
                                <>
                                    {fields
                                        .filter(field => {
                                            if (!field.shouldDisplayField) {
                                                return true
                                            }
                                            return field.shouldDisplayField(
                                                values,
                                            )
                                        })
                                        .map(field =>
                                            field.customField ? (
                                                field.element
                                            ) : (
                                                <div
                                                    key={field.name}
                                                    className="flex flex-col"
                                                >
                                                    <label
                                                        htmlFor={field.name}
                                                        className="block font-bold  text-brandBlack-500"
                                                    >
                                                        {field.displayName}
                                                        {field.isRequired && (
                                                            <span className="text-idolRed-700 ">
                                                                *
                                                            </span>
                                                        )}
                                                    </label>

                                                    <FormField
                                                        field={field}
                                                        variant={'fanx'}
                                                    />
                                                </div>
                                            ),
                                        )}
                                </>
                            )}

                            <div className="space-y-2">
                                <p className="block font-bold  text-brandBlack-500">
                                    Password must contain:
                                </p>
                                <div className="flex gap-2">
                                    {!touched?.password && !values?.password ? (
                                        <BsFillCheckCircleFill className="w-5  text-[#D9D9D9] h-5" />
                                    ) : values?.password?.length < 6 ? (
                                        <BsXCircleFill className="w-5 h-5 text-idolRed-700" />
                                    ) : (
                                        <BsFillCheckCircleFill className="w-5 text-brandIdolGreen-700  h-5" />
                                    )}

                                    <p className="block font-bold  text-brandBlack-500">
                                        At least 6 characters long
                                    </p>
                                </div>
                            </div>

                            {errors.auth && (
                                <div className={'text-red-500 mt-2 text-sm'}>
                                    {errors.auth.toString()}
                                </div>
                            )}

                            <div>
                                <AuthSubmitButton
                                    disabled={!isValid || isSubmitting}
                                    isSubmitting={isSubmitting}
                                    title={
                                        [
                                            CREATE_ACCOUNT_FORM,
                                            CREATE_PHONE_ACCOUNT_FORM,
                                        ].includes(currentStep)
                                            ? 'Create account'
                                            : 'Reset Password'
                                    }
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    )
}

export default AccountForm
