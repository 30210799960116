import * as React from 'react'
import RedditPixel from 'react-reddit-pixel'
import { toast } from 'react-toastify'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Form, Formik } from 'formik'
import { clsx } from 'clsx'
import * as Yup from 'yup'
import _ from 'lodash'
import { EVENTS, logEvent } from '../../../../app/common/logging/logging'
import Spinner from '../../../../app/layout/Spinner'
import { Users } from '../../../../generated/graphql-operations'
import AppHeroTitle from './components/AppHeroTitle'
import AppInputField from './components/AppInputField'
import AppPhotoInput from './components/AppPhotoInput'
import AppReferralActivated from './components/AppReferralActivated'
import AppVideoURLField from './components/AppVideoURLField'
import { useAppSelector } from '../../../../app/redux/store'
import ScrollToFieldError from '@src/app/common/form/ScrollToFieldError'
import { REFERRAL_CODE_KEY } from '@src/features/idols/pages/application/UserApplication'
import AppVideoUploadField from './components/AppVideoUploadField'
import AppFormDraft, { getSavedSubmissionKey } from './AppFormDraft'
import { isStillUploading } from '@src/app/common/util/util'
import { youtubeOrTikTokRegex } from './constants'
import { testUrl } from './helpers'
import validateSocialLinkIsNotAUrl from '@src/helper/validateSocialLinkIsNotAUrl'
import getSocialMediaValueWithAt from '@src/helper/getSocialUserNameWithAt'

interface AppFormProps {
    user: Users
    refetchUser: any
}

const AppForm: React.FC<AppFormProps> = ({ user, refetchUser }) => {
    const functions = getFunctions()
    const { isValidating } = useAppSelector(
        state => state.competitionApplication.videoURL,
    )
    const referralCode = localStorage.getItem(REFERRAL_CODE_KEY)

    const commonSocialLinkValidationSchema = Yup.string().test(
        'isnt-contain-at-sign',
        'Please enter your username without @ sign',
        (value: any) => {
            if (!value) return true
            return value && value.indexOf('@') == -1
        },
    )

    return (
        <React.Fragment>
            <AppHeroTitle>
                Join
                <img
                    src="/images/thesearch.png"
                    className="-ml-.5 pb-2 w-[220px] inline-block"
                    alt="#TheSearch"
                />
            </AppHeroTitle>
            {referralCode && (
                <AppReferralActivated referralCode={referralCode} />
            )}
            <Formik
                enableReinitialize
                initialValues={_.merge(
                    {
                        photo_url: '',
                        display_name: user?.display_name || '',
                        about: '',
                        video_url: '',
                        video_upload: '',
                        youtube: '',
                        tiktok: '',
                        twitter: '',
                        instagram: '',
                        referral_code: referralCode || '',
                    },
                    JSON.parse(
                        localStorage.getItem(getSavedSubmissionKey(user.id)) ??
                            '{}',
                    ),
                )}
                validationSchema={Yup.object().shape(
                    {
                        photo_url: Yup.string()
                            .required('Profile photo is required')
                            .test(
                                'cannot-be-uploading',
                                'Uploading image...',
                                value => {
                                    return !isStillUploading(value!)
                                },
                            ),
                        display_name: Yup.string()
                            .min(
                                2,
                                'Display name needs to be between 2 to 40 characters',
                            )
                            .max(
                                40,
                                'Display name needs to be between 2 to 40 characters',
                            )
                            .required('Display name is required'),
                        about: Yup.string()
                            .min(5, 'Too short!')
                            .max(1500, 'Too long! (1500 character limit)')
                            .required('Field is required'),
                        video_url: Yup.string().when('video_upload', {
                            is: '',
                            then: Yup.string()
                                .required(
                                    'Please specify either a video URL or upload a video',
                                )
                                .matches(
                                    youtubeOrTikTokRegex,
                                    'Invalid link. Only TikTok or YouTube Shorts links are accepted.',
                                ),
                            otherwise: Yup.string().nullable(),
                        }),
                        video_upload: Yup.string().when('video_url', {
                            is: '',
                            then: Yup.string()
                                .required(
                                    'Please specify either a video URL or upload a video',
                                )
                                .test(
                                    'cannot-be-uploading',
                                    'Uploading video...',
                                    value => {
                                        return !isStillUploading(value!)
                                    },
                                ),
                            otherwise: Yup.string().nullable(),
                        }),
                        youtube: commonSocialLinkValidationSchema
                            .test(
                                'isnt-url',
                                'Please enter your username only!',
                                value => {
                                    if (!value) return true
                                    return validateSocialLinkIsNotAUrl(
                                        value,
                                        'youtu',
                                    )
                                },
                            )
                            .nullable(),
                        tiktok: commonSocialLinkValidationSchema
                            .test(
                                'isnt-url',
                                'Please enter your username only!',
                                value => {
                                    if (!value) return true
                                    return validateSocialLinkIsNotAUrl(
                                        value,
                                        'tiktok',
                                    )
                                },
                            )
                            .nullable(),
                        twitter: commonSocialLinkValidationSchema
                            .test(
                                'isnt-url',
                                'Please enter your username only!',
                                value => {
                                    if (!value) return true
                                    return validateSocialLinkIsNotAUrl(
                                        value,
                                        'twitter',
                                    )
                                },
                            )
                            .nullable(),
                        instagram: commonSocialLinkValidationSchema
                            .test(
                                'isnt-url',
                                'Please enter your username only!',
                                value => {
                                    if (!value) return true
                                    return validateSocialLinkIsNotAUrl(
                                        value,
                                        'instagr',
                                    )
                                },
                            )
                            .nullable(),
                        referral_code: Yup.string().nullable(),
                    },
                    // @ts-ignore
                    ['video_url', 'video_upload'],
                )}
                onSubmit={async values => {
                    const social_media = {
                        youtube: getSocialMediaValueWithAt(values.youtube),
                        tiktok: getSocialMediaValueWithAt(values.tiktok),
                        twitter: getSocialMediaValueWithAt(values.twitter),
                        instagram: getSocialMediaValueWithAt(values.instagram),
                    }

                    const data = {
                        uid: user.id,
                        display_name: values.display_name,
                        about: values.about,
                        photo_url: values.photo_url,
                        video_url: values.video_url || values.video_upload,
                        social_media: social_media,
                        referral_code: values.referral_code,
                    }

                    try {
                        if (values.video_url && values.video_upload) {
                            throw Error(
                                'You can either specify a video URL or upload a video.',
                            )
                        }

                        if (!values.video_upload && !testUrl(data.video_url)) {
                            throw Error(
                                'Please specify either a correct video URL or upload a video.',
                            )
                        }

                        if (
                            values.video_upload &&
                            isStillUploading(values.video_upload)
                        ) {
                            throw Error(
                                'Video is still uploading. Please wait a few seconds and try again.',
                            )
                        }

                        const result: any = await httpsCallable(
                            functions,
                            'competitionApplications',
                        )(data)

                        if (result?.data?.user_id) {
                            refetchUser()
                        }
                    } catch (error: any) {
                        toast.error(
                            error.message || 'Error submitting the form',
                        )
                    }
                    logEvent(EVENTS.FANX.APPLICATION.SUBMITTED)
                    RedditPixel.rdt(EVENTS.FANX.APPLICATION.SUBMITTED)
                }}
            >
                {({ isSubmitting, isValid, dirty, values }) => (
                    <>
                        <AppFormDraft user={user} />
                        <Form className="flex flex-col space-y-[24px] w-full">
                            <ScrollToFieldError />
                            <AppPhotoInput name="photo_url" showErrorBorder />
                            <AppInputField
                                label={`Display name`}
                                description={`Help people discover your account by using
                            the name you're known by: either your full
                            name, nickname or business name.`}
                                name="display_name"
                                placeholder="Anastasia Cruickshank"
                                fieldtype="text"
                                isRequired
                                showErrorBorder
                            />
                            <AppInputField
                                label={`Tell us about yourself and why you think
                            people should vote for you.`}
                                name="about"
                                placeholder={`What’s your story? Why do you love creating content? What could you do with a million dollar investment?`}
                                fieldtype="textarea"
                                isRequired
                                showErrorBorder
                                countCharacters
                                maxCharacters={1500}
                            />
                            <div className="flex flex-col space-y-0.5">
                                <label className="font-bold">
                                    Why should people vote for you? Submit a
                                    one-minute vertical video showcasing your
                                    creativity or demonstrating a talent.
                                </label>
                                <p>
                                    You can introduce yourself, tell us a story,
                                    or explain what you would do with a
                                    million-dollar investment.
                                </p>
                                <div className="pt-3">
                                    <div
                                        className={clsx(
                                            'border-4 border-black border-dashed rounded-xl',
                                            'p-6',
                                            'flex flex-col items-center gap-2',
                                        )}
                                    >
                                        <AppVideoURLField
                                            label={`Paste a link to your submission video`}
                                            description={`Must be a TikTok or YouTube Short link, tagged @creatordao and includes the hashtags #TheSearch and #CreatorDAO`}
                                            name="video_url"
                                            placeholder="Link to submission video"
                                            isRequired
                                            showErrorBorder
                                            disabled={!!values.video_upload}
                                        />
                                        <div className="uppercase font-bold">
                                            or
                                        </div>
                                        <AppVideoUploadField
                                            name="video_upload"
                                            showErrorBorder
                                            disabled={!!values.video_url}
                                        />
                                    </div>
                                </div>
                            </div>
                            <AppInputField
                                label={`YouTube username`}
                                name="youtube"
                                placeholder="username"
                                fieldtype="text"
                                badge="@"
                            />
                            <AppInputField
                                label={`TikTok username`}
                                name="tiktok"
                                placeholder="username"
                                fieldtype="text"
                                badge="@"
                            />
                            <AppInputField
                                label={`Twitter username`}
                                name="twitter"
                                placeholder="username"
                                fieldtype="text"
                                badge="@"
                            />
                            <AppInputField
                                label={`Instagram username`}
                                name="instagram"
                                placeholder="username"
                                fieldtype="text"
                                badge="@"
                            />
                            <AppInputField
                                label={`Referral code`}
                                name="referral_code"
                                placeholder="Referral code"
                                fieldtype="text"
                            />
                            <p className="self-center text-sm">
                                Double check your info! Once submitted, your
                                creator profile <strong>cannot</strong> be
                                edited.
                            </p>
                            <button
                                type="submit"
                                disabled={isSubmitting || isValidating}
                                className={`mt-[24px] flex items-center justify-center font-bold text-center 
                            ${
                                isValid ? 'bg-[#51E577]' : 'bg-[#F2F2F2]'
                            } text-black py-4 px-16 rounded-[50px]`}
                            >
                                {isSubmitting ? (
                                    <Spinner />
                                ) : (
                                    'Submit application'
                                )}
                            </button>
                        </Form>
                    </>
                )}
            </Formik>
        </React.Fragment>
    )
}

export default AppForm
