import React from 'react'
import rocketImg from '@src/assets/images/launchpad/rocket.png'
import greenArchImg from '@src/assets/images/launchpad/green_arch_bg.svg'

import leftMobUsers from '@src/assets/images/launchpad/hero_left_users_mob.png'

import rightMobUsers from '@src/assets/images/launchpad/hero_right_users_mob.png'

const Superboost = () => {
    return (
        <div className="pt-10 xs:pt-14 md:p-0 relative bg-white">
            <img
                className="absolute -top-72 xl:hidden  left-0 z-10"
                src={leftMobUsers}
                alt=""
            />
            <img
                className="absolute -top-72 xl:hidden right-0 z-10"
                src={rightMobUsers}
                alt=""
            />
            <img
                className="md:hidden absolute top-0 left-0 right-0 w-full"
                src={greenArchImg}
                alt=""
            />
            <div className="bg-brandGreen-500 px-5 pt-28 xs:pt-24 pb-10 md:px-8 md:py-20 relative ">
                <div className="max-w-4xl mx-auto space-y-8">
                    <img
                        className="w-36 md:w-auto mx-auto"
                        src={rocketImg}
                        alt="Super-boost your revenue"
                    />
                    <h3 className="text-2xl leading-6 -tracking-[0.02em] md:text-4xl md:-tracking-[0.04em] font-bold text-center">
                        Super-boost your revenue
                    </h3>
                    <p className="text-base md:text-xl -tracking-[1px]  md:leading-7 md:-tracking-[0.04em] ">
                        It’s no secret that ad revenue from platforms like
                        YouTube, TikTok, and Instagram doesn't pay that well (if
                        at all). While most creators simply just accept this
                        fate, the more savvy creators will launch their own
                        businesses and leverage their audience to super-boost
                        their revenue.
                    </p>
                    <p className="text-base md:text-xl -tracking-[1px] md:leading-7 md:-tracking-[0.04em]">
                        Mr. Beast launched Feastables, Logan Paul made his own
                        energy drink, Charlie D’amelio has her own perfume and
                        clothing brand… you get the point.That’s why we created
                        Creator Launchpad to walk you through how to grow a
                        business from scratch… even if you have no experience.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Superboost
