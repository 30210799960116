import React from 'react'
import CommonPhotoInput from '@src/app/common/form/CommonPhotoInput'
import { PhotoInputVariant } from '@src/app/common/form/CommonPhotoInput/hooks/useCommonPhotoInput'

interface AppPhotoInputProps {
    name: string
    showErrorBorder?: boolean
}

const AppPhotoInput: React.FC<AppPhotoInputProps> = ({
    name,
    showErrorBorder,
}) => {
    return (
        <div className="flex flex-col space-y-0.5">
            <label className="font-bold">
                Upload a profile photo<span className="text-black">*</span>
            </label>
            <span className="text-sm">
                Choose one that’s high-quality and with your face clearly
                visible.
            </span>
            <CommonPhotoInput
                name={name}
                showErrorBorder={showErrorBorder}
                variant={PhotoInputVariant.THE_SEARCH}
            />
        </div>
    )
}

export default AppPhotoInput
