import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Loading from '@src/app/layout/Loading'
import {
    Elements,
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import ModalWrapper from './ModalWrapper'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!)

interface StripePaymentModalProps {
    clientSecret: string
}

const StripePaymentModal: React.FC<StripePaymentModalProps> = (
    props: StripePaymentModalProps,
) => {
    return (
        <ModalWrapper>
            <div
                className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 
                pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 
                sm:align-middle sm:max-w-md sm:w-full sm:p-6"
            >
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret: props.clientSecret,
                        appearance: {
                            variables: {
                                colorPrimary: '#000',
                                borderRadius: '12px',
                                fontSizeBase: '0.875rem',
                                spacingTab: '0.375rem',
                                colorBackground: '#f9fafb',
                            },
                        },
                    }}
                >
                    <SetupForm />
                </Elements>
            </div>
        </ModalWrapper>
    )
}

interface SetupFormProps {}

const SetupForm: React.FC<SetupFormProps> = ({}) => {
    const stripe = useStripe()
    const elements = useElements()
    const [isReady, setIsReady] = useState<boolean>(false)

    const handleSubmit = async (event: any) => {
        event.preventDefault()

        if (!stripe || !elements) {
            return
        }

        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/profile`,
            },
        })

        if (error) {
            toast.error(error.message)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement
                options={{ paymentMethodOrder: ['us_bank_account', 'card'] }}
                onReady={() => setIsReady(true)}
            />
            {isReady ? (
                <button
                    disabled={!stripe}
                    className="mt-4 flex justify-center w-full rounded-full 
                    border-[2px] border-black shadow-sm px-4 py-2 
                    bg-white hover:bg-gray-100 text-sm font-bold 
                    text-black sm:text-sm outline-0"
                >
                    Submit
                </button>
            ) : (
                <Loading />
            )}
        </form>
    )
}

interface StripeIntentStatusProps {}

export const StripeIntentStatus: React.FC<StripeIntentStatusProps> = ({}) => {
    const stripe = useStripe()

    useEffect(() => {
        if (!stripe) {
            return
        }

        const clientSecret =
            new URLSearchParams(window.location.search).get(
                'setup_intent_client_secret',
            ) || ''

        stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
            switch (setupIntent?.status) {
                case 'succeeded':
                    toast.success(
                        'Success! Your payment method has been saved.',
                    )
                    break

                case 'processing':
                    toast.success(
                        "Processing payment details. We'll update you when processing is complete.",
                    )
                    break

                case 'requires_payment_method':
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again
                    toast.error(
                        'Failed to process payment details. Please try another payment method.',
                    )
                    break
            }
        })
    }, [stripe])

    return <></>
}

export default StripePaymentModal
