import React from 'react'
import initializedImage from '../../../../assets/images/investors/initialized.png'
import a16zImage from '../../../../assets/images/investors/a16z_crypto.png'
import abstractImage from '../../../../assets/images/investors/abstract.png'
import audaciousImage from '../../../../assets/images/investors/audacious.png'
import liquid2Image from '../../../../assets/images/investors/liquid2.png'
import m13Image from '../../../../assets/images/investors/m13.png'
import svAngelImage from '../../../../assets/images/investors/sv_angel.png'
import mantisImage from '../../../../assets/images/investors/mantis.png'
import antifundImage from '../../../../assets/images/investors/antifund.png'

interface InvestorsProps {}

const primaryInvestors = [
    {
        name: 'a16z crypto',
        image: a16zImage,
    },
    {
        name: 'Initialized',
        image: initializedImage,
    },
]

const secondaryInvestors = [
    {
        name: 'Liquid2 Ventures',
        image: liquid2Image,
    },
    {
        name: 'M13 Ventures',
        image: m13Image,
    },
    {
        name: 'Mantis',
        image: mantisImage,
    },
    {
        name: 'Audacious Ventures',
        image: audaciousImage,
    },
    {
        name: 'Antifund',
        image: antifundImage,
    },
    {
        name: 'SV Angel',
        image: svAngelImage,
    },
]

const Investors = ({}: InvestorsProps) => {
    return (
        <div className="border-t-2 border-brandBlack-500">
            <div className="font-bold pt-16 lg:pt-24 space-y-8 lg:space-y-[82px] flex flex-col items-center">
                <h2 className="text-brandBlack-500 text-[28px] lg:text-[56px] leading-8 px-4 lg:py-4">
                    Backed by leading investors
                </h2>
                <div className="grid grid-cols-2 gap-y-[40px] gap-x-[40px] lg:gap-x-[80px] px-[32px] max-w-[720px]">
                    {primaryInvestors.map((investor, index) => (
                        <div key={index} className="flex justify-center">
                            <img
                                className="w-full max-w-[250px] h-auto object-contain"
                                src={investor.image}
                                alt={investor.name + 'logo'}
                            />
                        </div>
                    ))}
                </div>
                <div className="grid grid-cols-3 lg:grid-cols-6 gap-y-[40px] gap-x-[40px] lg:gap-x-[80px] px-[32px] max-w-[1400px]">
                    {secondaryInvestors.map((investor, index) => (
                        <div key={index} className="flex justify-center">
                            <img
                                className="w-full max-w-[66px] lg:max-w-[90px] h-auto object-contain"
                                src={investor.image}
                                alt={investor.name + 'logo'}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Investors
