import { useAppSelector } from '../../app/redux/store'
import {
    useGetUserWithPrivateByIdQuery,
    useGetUserWithPrivateByIdForCompetitionQuery,
} from '../../generated/graphql-operations'
import { CurrentUser, CurrentUserForCompetition } from '../../types/users'
import { sentryTrack } from '../../utils/sentry-track'

export function useCurrentUserProfile(
    fetchPolicy = 'cache-first',
    forCompetition = false,
) {
    const { currentUser } = useAppSelector(state => state.auth)

    const query = forCompetition
        ? useGetUserWithPrivateByIdForCompetitionQuery
        : useGetUserWithPrivateByIdQuery
    const { loading, data, error, refetch } = query({
        variables: {
            id: currentUser?.uid || '',
        },
        skip: !currentUser?.uid,
        fetchPolicy: fetchPolicy as any,
    })

    const currentUserProfile:
        | CurrentUser
        | CurrentUserForCompetition
        | undefined
        | null = data?.users_by_pk

    if (error) {
        console.log('Error loading current user profile: ', error)
        sentryTrack({ error })
    }

    return { currentUser, currentUserProfile, error, loading, refetch }
}
