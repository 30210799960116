import React from 'react'

import { useField } from 'formik'
import CustomToggle from './CustomToggle'

export default function FormToggle(props: any) {
    const [field, _meta, helpers] = useField(props)

    const handleChange = (newValue: any) => {
        helpers.setValue(newValue, true)
    }

    return <CustomToggle value={field.value} handleChange={handleChange} />
}
