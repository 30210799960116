import * as React from 'react'
import { Blockquote } from '@tiptap/extension-blockquote'
import Bold from '@tiptap/extension-bold'
import { BulletList } from '@tiptap/extension-bullet-list'
import Document from '@tiptap/extension-document'
import Italic from '@tiptap/extension-italic'
import { Link } from '@tiptap/extension-link'
import { ListItem } from '@tiptap/extension-list-item'
import { OrderedList } from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import { Strike } from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text'
import { generateHTML } from '@tiptap/html'
import { CustomMention } from '../../../app/common/components/CustomMention'

export default function RichTextSection({ richText }: any) {
    const htmlString =
        typeof richText !== 'string'
            ? generateHTML(richText, [
                  CustomMention.configure({
                      HTMLAttributes: {
                          class: 'font-bold',
                      },
                      renderLabel({ options, node }) {
                          return `${options.suggestion.char}${node.attrs.id.display_name}`
                      },
                  }),
                  Link,
                  Blockquote,
                  Document,
                  Paragraph,
                  Text,
                  Bold,
                  Italic,
                  BulletList,
                  OrderedList,
                  Strike,
                  ListItem,
              ])
            : richText
    return (
        <>
            {richText && (
                <div
                    className="prose prose-sm prose-p:my-0 prose-li:my-0 prose-ul:my-0 max-w-none"
                    dangerouslySetInnerHTML={{
                        __html: htmlString,
                    }}
                ></div>
            )}
        </>
    )
}
