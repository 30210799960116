export const AUTH_FORM = 'AUTH_FORM'
export const LOGIN_FORM = 'LOGIN_FORM'
export const CREATE_ACCOUNT_FORM = 'CREATE_ACCOUNT_FORM'
export const CREATE_PHONE_ACCOUNT_FORM = 'CREATE_PHONE_ACCOUNT_FORM'
export const SMS_VERIFY_FORM = 'SMS_VERIFY_FORM'
export const ACCOUNT_ACTIVATION = 'ACCOUNT_ACTIVATION'
export const FORGET_PASSWORD = 'FORGET_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SMS_VERIFY_FORM = 'RESET_PASSWORD_SMS_VERIFY_FORM'

export type AuthFlowSteps =
    | typeof AUTH_FORM
    | typeof LOGIN_FORM
    | typeof CREATE_ACCOUNT_FORM
    | typeof CREATE_PHONE_ACCOUNT_FORM
    | typeof SMS_VERIFY_FORM
    | typeof ACCOUNT_ACTIVATION
    | typeof FORGET_PASSWORD
    | typeof RESET_PASSWORD
    | typeof RESET_PASSWORD_SMS_VERIFY_FORM

export const PHONE_AUTH = 'PHONE_AUTH'
export const EMAIL_AUTH = 'EMAIL_AUTH'

export type AuthFormSteps = typeof PHONE_AUTH | typeof EMAIL_AUTH
