import React from 'react'

interface HeroDescriptionProps {}

const HeroDescription = ({}: HeroDescriptionProps) => {
    return (
        <div
            id="launchpad"
            className="pt-[70px] lg:pt-[100px] pb-[100px] border-b-2 border-black"
        >
            <div className="max-w-7xl mx-auto">
                <div className="text-center">
                    <h1 className="text-[50px] lg:text-[70px] leading-[50px] lg:leading-[70px] mb-12 font-bold">
                        Discover Creator Launchpad
                    </h1>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-3 gap-7 px-4">
                    <div className="p-[30px] border-2 border-black rounded-[20px] h-full hover:bg-brandPink-500">
                        <h1 className="text-[35px] leading-[40px] font-bold mb-3">
                            Fuel your growth with access to capital and
                            community.
                        </h1>
                        <h2 className="text-[25px] font-light">
                            We invest in creators by unlocking future income.
                            Launchpad participants receive immediate funding in
                            exchange for 15% of their AdSense revenue over the
                            next 3 years.
                        </h2>
                    </div>
                    <div className="p-[30px] border-2 border-black rounded-[20px] h-full hover:bg-brandGreen-500">
                        <h1 className="text-[35px] leading-[40px] font-bold mb-3">
                            Build a content creation system that works for you.
                        </h1>
                        <h2 className="text-[25px] font-light">
                            Learn the system that top YouTubers use to create
                            videos through live lectures, Q&A, office hours,
                            video reviews, and more all taught by some of the
                            world’s top YouTubers!
                        </h2>
                    </div>

                    <div className="p-[30px] border-2 border-black rounded-[20px] h-full hover:bg-brandOrange-500">
                        <h1 className="text-[35px] leading-[40px] font-bold mb-3">
                            Personalized attention from the best in the game.
                        </h1>
                        <h2 className="text-[25px] font-light">
                            Want to be mentored by the best of the best? Learn
                            from YouTubers with 1MM+ subscribers and surprise
                            guests.
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroDescription
