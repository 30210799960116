import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import heic2any from 'heic2any'
import { useField } from 'formik'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import storage from '@src/app/config/firebase'

const PROFILE_PHOTO_STORAGE_VALUE = 'profile_images'
const THE_SEARCH_DEFAULT_IMG = '/images/default_dp.png'
const PORTAL_DEFAULT_IMG = '/images/user.png'

interface CommonPhotoInputProps {
    name: string
    variant: string
    storagePath?: string
}

interface UseCommonPhotoInput {
    previewImgUrl: string
    field: any
    meta: any
    handleChange: (data: any) => void
    changeFile: () => void
}

export enum PhotoInputVariant {
    PORTAL = 'portal',
    THE_SEARCH = 'the_search',
}

export const useCommonPhotoInput = ({
    variant,
    name,
    storagePath = PROFILE_PHOTO_STORAGE_VALUE,
}: CommonPhotoInputProps): UseCommonPhotoInput => {
    const [field, meta, helpers] = useField(name)
    const [image, setImage] = useState<{ preview: any; raw: any }>({
        preview: '',
        raw: null,
    })

    const defaultImageUrl =
        variant === PhotoInputVariant.PORTAL
            ? PORTAL_DEFAULT_IMG
            : THE_SEARCH_DEFAULT_IMG

    const handleChange = async (e: any) => {
        if (e.target.files.length) {
            const filename = e.target.files[0].name

            if (filename.endsWith('.heic') || filename.endsWith('.HEIC')) {
                await heic2any({
                    blob: e.target.files[0],
                    toType: 'image/jpeg',
                    quality: 1,
                }).then((blob: any) => {
                    const file = new File([blob], `${filename}.jpg`, {
                        type: 'image/jpeg',
                    })
                    setImage({
                        preview: URL.createObjectURL(file),
                        raw: file,
                    })
                    handleUpload(file)
                })
            } else {
                setImage({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                })
                handleUpload(e.target.files[0])
            }
        }
    }

    const handleUpload = (file: any) => {
        const id = uuid()
        const name = id + '.' + file.type.split('/')[1]

        const storageRef = ref(storage, `/${storagePath}/${name}`)

        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
            'state_changed',
            snapshot => {
                const percent =
                    snapshot.totalBytes > 0
                        ? Math.round(
                              (snapshot.bytesTransferred /
                                  snapshot.totalBytes) *
                                  100,
                          )
                        : 0
                helpers.setValue('_progress:' + percent, true)
            },
            err => helpers.setError(err.message),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    helpers.setValue(url, true)
                })
            },
        )
    }

    const changeFile = () => {
        document.getElementById('input_file')?.click()
    }

    return {
        previewImgUrl: image.preview || field.value || defaultImageUrl,
        field,
        meta,
        handleChange,
        changeFile,
    }
}
