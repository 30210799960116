import * as React from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useField } from 'formik'
import debounce from 'lodash.debounce'
import Spinner from '../../../../../app/layout/Spinner'
import {
    resetVideoURLState,
    setVideoURLState,
} from '../../../../../app/redux/slices/competition-application.slice'
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store'
import { inputStyles } from './AppInputField'
import { testUrl } from '../helpers'

interface AppVideoURLFieldProps {
    label: string
    description?: string
    name: string
    placeholder: string
    disabled?: boolean
    isRequired?: boolean
    showErrorBorder?: boolean
}

const AppVideoURLField: React.FC<AppVideoURLFieldProps> = ({
    label,
    description,
    name,
    placeholder,
    isRequired,
    showErrorBorder,
}) => {
    const functions = getFunctions()
    const dispatch = useAppDispatch()
    const { videoURL } = useAppSelector(state => state.competitionApplication)
    const [field, { error, touched }, { setValue }] = useField(name)

    const validateURL = React.useCallback(
        async (val: string) => {
            await httpsCallable(
                functions,
                'validateVideoURL',
            )(val)
                .then(res => {
                    if (res.data) {
                        setValue(res.data)
                        dispatch(
                            setVideoURLState({
                                isValidating: false,
                                isLoading: false,
                                isError: false,
                                isVerified: true,
                            }),
                        )
                    }
                })
                .catch(err => {
                    if (err) {
                        dispatch(
                            setVideoURLState({
                                isValidating: false,
                                isLoading: false,
                                isError: true,
                                isVerified: false,
                            }),
                        )
                    }
                })
        },
        [dispatch, setValue, functions],
    )

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(resetVideoURLState())
        setValue(e.target.value)
        if (testUrl(e.target.value)) {
            dispatch(
                setVideoURLState({
                    isValidating: true,
                    isLoading: true,
                    isError: false,
                    isVerified: false,
                }),
            )
            debouncedValidateURL(e.target.value)
        }
    }

    const debouncedValidateURL = React.useMemo(() => {
        return debounce(validateURL, 500)
    }, [validateURL])

    return (
        <div className="flex flex-col space-y-0.5">
            <label className="font-bold">
                {label}
                {!isRequired ? (
                    ' (optional)'
                ) : (
                    <span className="text-black">*</span>
                )}
            </label>
            <input
                id={name}
                {...field}
                type="text"
                autoComplete={name}
                placeholder={placeholder}
                className={`${inputStyles} ${
                    showErrorBorder && error && touched
                        ? 'border-red-500 focus:border-red-500'
                        : ''
                }`}
                onChange={handleChange}
            />
            {videoURL.isValidating && videoURL.isLoading && (
                <span className="flex py-1 text-xs text-[#777777] font-normal gap-2 items-center">
                    <Spinner size="h-4 w-4" />{' '}
                    <span className="mt-1">Verifying link...</span>
                </span>
            )}
            {(error && touched) || videoURL.isError ? (
                <span className="py-1 text-xs text-red-500 font-normal">
                    {error ||
                        'Invalid link. Only TikTok or YouTube Shorts links are accepted.'}
                </span>
            ) : (
                <></>
            )}
            {description && <span className="text-sm">{description}</span>}
        </div>
    )
}

export default AppVideoURLField
