import React from 'react'
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getApolloClient } from '@src/ApolloClient'
import ModalWrapper from '@src/app/common/modals/ModalWrapper'
import Spinner from '@src/app/layout/Spinner'
import { closeModal } from '@src/app/redux/slices/modal.slice'
import { useAppDispatch } from '@src/app/redux/store'
import { User_Contracts } from '@src/generated/graphql-operations'
dayjs.extend(relativeTime)

const functions = getFunctions()

interface AddPastInvoiceModalProps {
    contract: User_Contracts
}

const AddPastInvoiceModal: React.FC<AddPastInvoiceModalProps> = (
    props: AddPastInvoiceModalProps,
) => {
    const dispatch = useAppDispatch()
    return (
        <ModalWrapper>
            <div
                className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 
                text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle 
                w-full sm:max-w-sm sm:p-6"
            >
                <div className="flex items-center mb-4">
                    <p className="text-xl font-semibold">Add Past Invoice</p>
                </div>
                <Formik
                    enableReinitialize
                    validateOnMount
                    initialValues={{
                        invoiceDate: dayjs()
                            .subtract(1, 'month')
                            .format('YYYY-MM-DD'),
                    }}
                    validationSchema={Yup.object({
                        invoiceDate: Yup.string().required(
                            'Invoice date is required',
                        ),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            await httpsCallable(
                                functions,
                                'userInvoice',
                            )({
                                operation: 'CREATE_PAST_INVOICE',
                                contractId: props.contract.id,
                                invoiceDate: dayjs(values.invoiceDate).format(
                                    'YYYY-MM',
                                ),
                            })
                            setSubmitting(false)
                            await getApolloClient().refetchQueries({
                                include: ['GetUserContract'],
                            })
                            dispatch(closeModal())
                        } catch (error: any) {
                            toast.error(error.message)
                            setSubmitting(false)
                        }
                    }}
                >
                    {({
                        isSubmitting,
                        isValid,
                        errors,
                        setFieldValue,
                        values,
                        touched,
                    }) => (
                        <Form className="flex flex-col min-h-[200px] justify-between space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Invoice Date
                                </label>
                                <div className="mt-1">
                                    <DatePicker
                                        showMonthYearPicker
                                        maxDate={dayjs()
                                            .subtract(1, 'month')
                                            .toDate()}
                                        dateFormat="MMMM, yyyy"
                                        selected={
                                            values.invoiceDate
                                                ? dayjs(
                                                      values.invoiceDate,
                                                  ).toDate()
                                                : dayjs().toDate()
                                        }
                                        popperClassName="-mt-10"
                                        onChange={(date: Date) => {
                                            setFieldValue(
                                                'invoiceDate',
                                                dayjs(date),
                                            )
                                        }}
                                        autoFocus={false}
                                        className="w-full rounded-md border border-gray-200 bg-white py-2 shadow-sm text-sm
                                        focus:outline-none focus:ring-0 focus:border-gray-200"
                                        placeholderText="Select Start Date"
                                    />
                                </div>
                                {touched.invoiceDate && errors.invoiceDate && (
                                    <p className="pt-1 text-xs text-red-500">
                                        {errors.invoiceDate}
                                    </p>
                                )}
                            </div>
                            <button
                                type="submit"
                                className={`flex self-end ${
                                    isValid ? 'bg-[#51E577]' : 'bg-[#F2F2F2]'
                                } text-black py-2 rounded-full w-full 
                                text-sm shadow-sm border border-transparent
                                justify-center`}
                                disabled={!isValid || isSubmitting}
                            >
                                {isSubmitting ? <Spinner /> : 'Add'}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </ModalWrapper>
    )
}

export default AddPastInvoiceModal
