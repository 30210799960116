import { Dialog } from '@headlessui/react'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { closeModal } from '../../redux/slices/modal.slice'
import { useAppDispatch } from '../../redux/store'
import TextInput from '../form/TextInput'
import TextSocialInput from '../form/TextSocialInput'
import ModalWrapper from './ModalWrapper'
import { HiOutlineX } from 'react-icons/hi'

import { NavyButton } from '../../../features/profiles/components/custom-buttons'
import { addDateUtcTime, getTzUtcTime } from '../../../helper/dateFormatter'
import BuildHourChips from './components/BuildHourChips'
import BuildWeekDaysAndHours from './components/BuildWeekDaysAndHours'
import getInitialValuesAndValidationSchema, {
    FieldType,
} from '../../../helper/getInitialValuesAndValidationSchema'
import { Moment } from 'moment'

interface BookSessionModalProps {
    title: string
    submitText: string
    handleSubmission: (values: { start_time: Moment; end_time: Moment }) => void
    fields: Array<FieldType>
    allowNondirtySubmission: boolean
    hideFieldLabel: boolean
    timeSlot: string
    selectedDate: string
    dayLabel: string
    startTime: string
    endTime: string
    officeHours: Array<object>
    showMoreClicked: boolean
    previouslyBookedSessions: Array<object>
    currentTz: string
    originalTz: string
    type: 'dayAndTime' | 'justDay' | 'none'
    slots: Array<string>
    duration: number
}

export default function BookSessionModal({
    title,
    submitText,
    handleSubmission,
    fields,
    allowNondirtySubmission,
    hideFieldLabel,
    timeSlot = '',
    selectedDate = '',
    startTime = '',
    endTime = '',
    officeHours = [],
    showMoreClicked = false,
    previouslyBookedSessions = [],
    currentTz = '',
    originalTz = '',
    type = 'dayAndTime',
    slots = [],
    duration = 30,
}: BookSessionModalProps) {
    const dispatch = useAppDispatch()
    const [selectedId, setSelectedId] = useState('')
    const [userSelectedDate, setUserSelectedDate] = useState(selectedDate)
    const [userSelectedSlot, setUserSelectedSlot] = useState(
        timeSlot ? `${selectedDate} ${timeSlot}` : '',
    )

    const [clickedShowMore, setClickedShowMore] = useState('')

    function closeTapped() {
        dispatch(closeModal())
    }

    const { initialValues, validationSchema } =
        getInitialValuesAndValidationSchema(fields)

    async function handleSubmitForm(
        values: { start_time: Moment; end_time: Moment },
        { setSubmitting, setErrors }: any,
    ) {
        try {
            if (
                typeof userSelectedSlot === 'string' &&
                userSelectedSlot.trim() !== ''
            ) {
                const utcTime = getTzUtcTime({
                    dateTime: userSelectedSlot,
                    timeZone: currentTz,
                })
                values.start_time = utcTime
                values.end_time = addDateUtcTime({
                    utcTime,
                    duration,
                })

                await handleSubmission(values)
            } else {
                setSubmitting(false)
                setErrors({
                    general: 'Please choose a time for you session',
                })
            }
        } catch (error) {
            if (error.code && error.code.includes('auth/')) {
                setErrors({ general: error.message })
            } else {
                setErrors({
                    general: 'Unable to submit successfully.',
                })
            }

            setSubmitting(false)
        }
    }

    return (
        <ModalWrapper>
            <div className="inline-block align-bottom bg-white rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full max-w-3xl h-full md:h-auto ">
                <div>
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                            type="button"
                            className="bg-transparent text-gray-900 focus:outline-none"
                            onClick={closeTapped}
                        >
                            <span className="sr-only">Close</span>
                            <HiOutlineX
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                    <div className="text-left">
                        {title && (
                            <Dialog.Title
                                as="h3"
                                className="text-2xl font-bold text-gray-900 p-6 bg-gray-100"
                            >
                                {title}
                            </Dialog.Title>
                        )}
                    </div>
                </div>
                <div className="px-4">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object(validationSchema)}
                        onSubmit={handleSubmitForm}
                    >
                        {({
                            isSubmitting,
                            dirty,
                            isValid,
                            errors,
                            setErrors,
                        }) => (
                            <Form className="space-y-4">
                                <div
                                    className={
                                        timeSlot === ''
                                            ? 'grid grid-cols-2 gap-6 '
                                            : 'grid'
                                    }
                                >
                                    <div className="col-span-2 sm:col-span-1 sm:gap-4 sm:items-start sm:pt-5">
                                        {fields &&
                                            fields.map(
                                                field =>
                                                    field.type != 'radio' && (
                                                        <div
                                                            key={field.name}
                                                            className="col-span-6 sm:col-span-3 sm:gap-4 sm:items-start sm:pt-5"
                                                        >
                                                            {!hideFieldLabel && (
                                                                <label
                                                                    htmlFor={
                                                                        field.name
                                                                    }
                                                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                                                >
                                                                    {
                                                                        field.displayName
                                                                    }
                                                                </label>
                                                            )}
                                                            <div className="mt-1 sm:mt-0 ">
                                                                {field.type ===
                                                                'url' ? (
                                                                    <TextSocialInput
                                                                        badge="http://"
                                                                        key={
                                                                            field.name
                                                                        }
                                                                        name={
                                                                            field.name
                                                                        }
                                                                        placeholder={
                                                                            field.placeholder
                                                                                ? field.placeholder
                                                                                : ''
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <TextInput
                                                                        key={
                                                                            field.name
                                                                        }
                                                                        name={
                                                                            field.name
                                                                        }
                                                                        placeholder={
                                                                            field.placeholder
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    ),
                                            )}
                                    </div>
                                    {type === 'justDay' && (
                                        <BuildHourChips
                                            error={errors?.general}
                                            setErrors={setErrors}
                                            userDate={userSelectedDate}
                                            from={startTime}
                                            to={endTime}
                                            slots={slots}
                                            selectedId={selectedId}
                                            originalTz={originalTz}
                                            showMoreClicked={showMoreClicked}
                                            currentTz={currentTz}
                                            duration={duration}
                                            previouslyBookedSessions={
                                                previouslyBookedSessions
                                            }
                                            clickedShowMore={clickedShowMore}
                                            setClickedShowMore={
                                                setClickedShowMore
                                            }
                                            setUserSelectedDate={
                                                setUserSelectedDate
                                            }
                                            setSelectedId={setSelectedId}
                                            setUserSelectedSlot={
                                                setUserSelectedSlot
                                            }
                                        />
                                    )}
                                    {type === 'none' && (
                                        <BuildWeekDaysAndHours
                                            error={errors?.general}
                                            setErrors={setErrors}
                                            officeHours={officeHours}
                                            slots={slots}
                                            selectedId={selectedId}
                                            originalTz={originalTz}
                                            showMoreClicked={showMoreClicked}
                                            currentTz={currentTz}
                                            duration={duration}
                                            previouslyBookedSessions={
                                                previouslyBookedSessions
                                            }
                                            clickedShowMore={clickedShowMore}
                                            setClickedShowMore={
                                                setClickedShowMore
                                            }
                                            setUserSelectedDate={
                                                setUserSelectedDate
                                            }
                                            setSelectedId={setSelectedId}
                                            setUserSelectedSlot={
                                                setUserSelectedSlot
                                            }
                                        />
                                    )}
                                </div>
                                {errors?.general && (
                                    <div
                                        className={'text-red-500 mt-2 text-sm'}
                                    >
                                        {errors.general.toString()}
                                    </div>
                                )}

                                <div className="pt-5 ">
                                    <div className="flex justify-end">
                                        <NavyButton
                                            disabled={
                                                !isValid ||
                                                (!dirty &&
                                                    !allowNondirtySubmission) ||
                                                isSubmitting
                                            }
                                            className="mx-3 my-3"
                                            isSubmitting={isSubmitting}
                                            btnText={submitText ?? 'Submit'}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </ModalWrapper>
    )
}
