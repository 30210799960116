import { googleAnalytics } from '../../config/firebase'
import { logEvent as firebaseLogEvent } from 'firebase/analytics'
import posthog from 'posthog-js'

interface LogProperties {
    [key: string]: string | number | boolean | object | undefined
    meta?: Record<string, any>
}
export function logEvent(eventName: string, properties: LogProperties = {}) {
    firebaseLogEvent(googleAnalytics, eventName, properties)
    posthog.capture(eventName, properties)
}

export function logPageViewEvent(
    eventName: string,
    properties: LogProperties = {},
) {
    posthog.capture('$pageview', { eventName, ...properties })
}

export const EVENTS = {
    PORTAL: {},
    FANX: {
        NAVBAR: {
            VIEW_PROFILE_CLICKED: 'FANX Navbar - View Profile Clicked',
            CREATOR_DASHBOARD_CLICKED:
                'FANX Navbar - Creator Dashboard Clicked',
            SETTINGS_CLICKED: 'FANX Navbar - Settings Clicked',
            LOG_OUT_CLICKED: 'FANX Navbar - Log Out Clicked',
            BRAND_LOGO_CLICKED: 'FANX Navbar - Brand Logo Clicked',
            LEADERBOARD_CLICKED: 'FANX Navbar - Leaderboard Clicked',
        },
        PAGE: {
            SETTINGS: 'Page - Settings Visited',
            APPLICATION: 'Page - Application Visited',
            PROFILE: 'Page - Profile Visited',
            GROUPS: 'Page - Groups Visited',
            SEASON: 'Page - Season Visited',
            NOT_FOUND: 'Page - Not Found Visited',
        },
        LEADERBOARD: {
            SHARE_COPY_LINK_CLICKED: 'Leaderboard - Share Copy Link Clicked',
        },
        GLOBAL: {},
        SETTINGS: {
            PERSONAL_INFO: {
                FULL_NAME_CHANGED:
                    'Settings > Personal Info > Full Name Changed',
                EMAIL_CHANGED: 'Settings > Personal Info > Email Changed',
                PHONE_CHANGED: 'Settings > Personal Info > Phone Changed',
            },
            NOTIFICATION: {
                PREFERENCES_CHANGED:
                    'Settings > Notification > Preferences Changed',
            },
            SECURITY: {
                PASSWORD_CHANGED: 'Settings > Security > Password Changed',
                GOOGLE_AUTH_CHANGED:
                    'Settings > Security > Google Auth Changed',
                FACEBOOK_AUTH_CHANGED:
                    'Settings > Security > Facebook Auth Changed',
            },
        },
        APPLICATION: {
            AGREEMENT: 'Application - Agreement Accepted',
            CLICKED: 'Application - Clicked',
            SUBMITTED: 'Application - Submitted',
            VIEW_PROFILE: 'Application - View Profile',
            COPY_LINK: 'Application - Copy Link',
        },
        VOTING: {
            VOTED: 'Voting - Voted',
            VOTE_CONF_MODAL_VIEW: 'Voting - Conf Modal View',
            VOTE_CONF_MODAL_CLICK: 'Voting - Conf Modal Click',
        },
        PROFILE: {
            COPY_LINK: 'Profile - Copy Link',
        },
        SEASON: {
            SEASON_SELECT_CHANGED: 'Season - Select Changed',
        },
        GROUPS: {
            GROUP_SELECTED: 'Groups - Group Selected',
            ROUND_SELECTED: 'Groups - Round Selected',
        },
        ROSTER: {},
    },
    AUTH: {
        LOGIN: 'Auth - Login',
        SIGNIN: 'Auth - Signin',
        SIGNUP_CLICKED: 'Auth - Signup Clicked',
        LOGOUT_CLICKED: 'Auth - Logout Clicked',
        SSO_LOGIN: 'Auth - SSO Login',
        SSO_SIGNUP: 'Auth - SSO Signup',
    },
}
