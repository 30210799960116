import {
    communityMemberCategory,
    communityMembers,
} from '@src/mocks/communityMembers'
import * as React from 'react'
import clsx from 'clsx'

function getTagColor(category: string): string {
    switch (category) {
        case communityMemberCategory.MEMBER:
            return 'bg-brandGreen-500 text-black'

        case communityMemberCategory.ADVISOR:
            return 'bg-brandPink-500 text-black'

        case communityMemberCategory.MENTOR:
            return 'bg-[#354CFF] text-white'

        default:
            return 'bg-brandOrange-500 text-black'
    }
}

interface MemberCategoryTagProps {
    category: communityMemberCategory
}

const MemberCategoryTag: React.FC<MemberCategoryTagProps> = ({ category }) => {
    const color = getTagColor(category)

    return (
        <div className="absolute -bottom-4 left-0 right-0 grid place-content-center">
            <p
                className={clsx(
                    'text-sm leading-5 px-4 py-1  rounded-full',
                    color,
                )}
            >
                {category}
            </p>
        </div>
    )
}

const OurCommunity = () => {
    return (
        <div className="py-10 px-4 bg-white space-y-8 md:space-y-10">
            <h3 className="text-3xl leading-8 font-bold -tracking-[0.06em] md:text-5xl md:leading-[48px] md:mb-3 text-center">
                Meet <br className="sm:hidden" /> our community
            </h3>
            <div className="flex items-stretch justify-center flex-wrap max-w-7xl mx-auto gap-2 xs:gap-4">
                {communityMembers.map(member => (
                    <div
                        key={member.name}
                        className="space-y-8 w-[167px] sm:w-[185px]"
                    >
                        <div className="border-2 border-black rounded-full w-[167px] sm:w-[185px] h-[167px] sm:h-[185px] grid place-content-center relative">
                            <img
                                className="w-[127px] h-[127px] sm:w-[137px] sm:h-[137px]"
                                src={member.imgUrl}
                                alt={member.name}
                            />
                            <MemberCategoryTag category={member.category} />
                        </div>

                        <div className="space-y-2 md:space-y-3">
                            <p className="text-base text-center leading-5 md:text-xl md:-tracking-tighter font-bold">
                                {member.name}
                            </p>
                            <p className="text-sm leading-5 md:text-base -tracking-tighter text-center">
                                {member.tagline}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default OurCommunity
