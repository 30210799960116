import React from 'react'

import bgDollarTopImg from '@src/assets/images/idols/bg_dollar_top.png'
import bgDollarBottomImg from '@src/assets/images/idols/bg_dollar_bottom.png'
import bgDollarCenterImg from '@src/assets/images/idols/bg_dollar_center.png'
import bgDollarLeftImg from '@src/assets/images/idols/bg_dollar_left.png'

const DollarBgImages: React.FC = () => {
    return (
        <React.Fragment>
            <img
                className="hidden lg:block absolute top-0 left-[343px] z-[11]"
                src={bgDollarTopImg}
                alt="dollar"
            />
            <img
                className="hidden lg:block absolute top-1/2  left-1/2 z-[11]"
                src={bgDollarCenterImg}
                alt="dollar"
            />
            <img
                className="hidden md:block absolute bottom-0  left-[250px] z-[11]"
                src={bgDollarBottomImg}
                alt="dollar"
            />
            <img
                className="hidden md:block absolute top-[122px]  left-0 z-[11]"
                src={bgDollarLeftImg}
                alt="doller"
            />
        </React.Fragment>
    )
}

export default DollarBgImages
