import * as React from 'react'
import { useField } from 'formik'

export default function TextArea({ label, ...props }: any) {
    const [field, meta, helpers] = useField(props)
    return (
        <div>
            {label && (
                <label className="appearance-none block text-base font-medium text-gray-700 select-none mb-2">
                    {label}
                </label>
            )}
            <textarea
                {...field}
                {...props}
                className="-mb-1 w-full px-3 py-3 border border-[#D1D5DB] sm:text-sm focus:outline-none rounded-md"
            />
            {meta.touched && meta.error ? (
                <div className="text-sm text-red-400 mt-1.5">{meta.error}</div>
            ) : null}
        </div>
    )
}
