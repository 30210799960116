import { useField } from 'formik'
import * as React from 'react'
import Select from 'react-select'

export default function SelectField({
    label,
    allValues,
    isMulti,
    variant,
    ...props
}: any) {
    const [field, meta, helpers] = useField(props)

    const handleChange = (newValue: string) => {
        helpers.setValue(newValue, true)
    }

    const handleBlur = (e: any) => {
        helpers.setTouched(true)
        field.onBlur(e)
    }

    const customStyles = {
        option: (provided: any) => ({
            ...provided,
            color: 'black',
        }),
        control: (provided: any) => ({
            ...provided,
            boxShadow: 'none',
            border: variant === 'fanx' ? 'none' : '1px solid',
            borderColor: 'rgb(209 213 219) !important',
            borderRadius: 6,
            minHeight: '50px',
            backgroundColor: variant === 'fanx' ? 'rgb(242 242 242)' : 'white',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: 'rgb(156 163 175)',
        }),
    }

    return (
        <div className="rounded-none">
            {label && (
                <label className="appearance-none block text-base font-medium text-gray-700 select-none mb-2">
                    {label}
                </label>
            )}
            <Select
                isMulti={isMulti}
                closeMenuOnSelect={!isMulti}
                defaultValue={meta.initialValue}
                onChange={handleChange}
                onBlur={handleBlur}
                options={allValues}
                styles={customStyles}
                className="w-full text-sm text-gray-400 rounded-md"
                classNamePrefix="rs"
                placeholder={props.placeholder}
                {...(props.excludePortalTarget
                    ? {}
                    : { menuPortalTarget: document.querySelector('body') })}
            />
            {meta.touched && meta.error ? (
                <div className="text-sm text-red-400 mt-1.5">{meta.error}</div>
            ) : null}
        </div>
    )
}
