import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HiOutlineExclamation, HiX } from 'react-icons/hi'

export type AlertContent = {
    title: string
    message: string | JSX.Element
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
    confirmBtnLabel?: string
    customIcon?: React.ReactNode
}

interface AlertProps {
    content: AlertContent
    open: boolean
    handleOk: () => void
    handleCancel: () => void
}

const Alert: React.FC<AlertProps> = ({
    content,
    open,
    handleOk,
    handleCancel,
}) => {
    return (
        <Transition.Root show={open} as={React.Fragment}>
            <Dialog
                as="div"
                className="fixed z-50 inset-0 overflow-y-auto"
                onClose={handleCancel}
            >
                <div className="flex items-center justify-center min-h-screen text-center sm:block sm:p-0">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className={`relative inline-block align-bottom bg-white rounded-lg 
                            px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform 
                            transition-all sm:my-8 sm:align-middle sm:max-w-${
                                content.size || 'md'
                            } 
                            sm:w-full sm:p-6`}
                        >
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    className="bg-white rounded-md text-gray-600 hover:text-black"
                                    onClick={handleCancel}
                                >
                                    <span className="sr-only">Close</span>
                                    <HiX
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-14 w-14 rounded-full bg-gray-50 sm:mx-0 sm:h-10 sm:w-10">
                                    {content.customIcon ? (
                                        content.customIcon
                                    ) : (
                                        <HiOutlineExclamation
                                            className="h-7 w-7 text-red-600"
                                            aria-hidden="true"
                                        />
                                    )}
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-xl font-medium text-black"
                                    >
                                        {content.title}
                                    </Dialog.Title>
                                    <div className="mt-2 text-sm text-gray-600">
                                        {content.message}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 flex flex-row-reverse gap-x-2">
                                <button
                                    type="button"
                                    className="flex items-center justify-center bg-[#51E577] 
                                    text-center text-sm text-black py-2 px-6 rounded-[50px]"
                                    onClick={handleOk}
                                >
                                    {content.confirmBtnLabel || 'Confirm'}
                                </button>
                                <button
                                    type="button"
                                    className="flex items-center justify-center bg-white 
                                    border border-gray-200 text-center text-sm text-black 
                                    py-2 px-6 rounded-[50px]"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default Alert
