import React from 'react'
import { useAppDispatch } from '../../redux/store'
import { closeModal } from '../../redux/slices/modal.slice'
import ModalWrapper from './ModalWrapper'
import { Dialog } from '@headlessui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import getInitialValuesAndValidationSchema, {
    FieldType,
} from '../../../helper/getInitialValuesAndValidationSchema'
import FormField from '../form/FormField'

interface FormModalProps {
    title: string
    description: string
    submitText: string
    handleSubmission: (values: { [key: string]: any }) => void
    fields: Array<FieldType>
    allowNondirtySubmission: boolean
    hideFieldLabel: boolean
    leftAlignTitle: boolean
}

export default function FormModal({
    title,
    description,
    submitText,
    handleSubmission,
    fields,
    allowNondirtySubmission,
    hideFieldLabel,
    leftAlignTitle,
}: FormModalProps) {
    const dispatch = useAppDispatch()

    function closeTapped() {
        dispatch(closeModal())
    }

    const { initialValues, validationSchema } =
        getInitialValuesAndValidationSchema(fields)

    return (
        <ModalWrapper>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                    <div
                        className={leftAlignTitle ? 'text-left' : 'text-center'}
                    >
                        {title && (
                            <Dialog.Title
                                as="h3"
                                className="text-lg leading-6 font-medium text-gray-900"
                            >
                                {title}
                            </Dialog.Title>
                        )}
                        {description && (
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    {description}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-5">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object(validationSchema)}
                        validateOnMount
                        onSubmit={async (
                            values,
                            { setSubmitting, setErrors },
                        ) => {
                            try {
                                await handleSubmission(values)
                                setSubmitting(false)
                                dispatch(closeModal())
                            } catch (error) {
                                if (
                                    error.code &&
                                    error.code.includes('auth/')
                                ) {
                                    setErrors({ general: error.message })
                                } else {
                                    setErrors({
                                        general:
                                            'Unable to submit successfully.',
                                    })
                                }

                                setSubmitting(false)
                            }
                        }}
                    >
                        {({ isSubmitting, dirty, isValid, errors }) => (
                            <Form className="space-y-4">
                                {fields &&
                                    fields.map(field => (
                                        <FormField
                                            key={field.name}
                                            field={field}
                                        />
                                    ))}

                                {errors.general && (
                                    <div
                                        className={'text-red-500 mt-2 text-sm'}
                                    >
                                        {errors.general.toString()}
                                    </div>
                                )}

                                <div className="pt-5 ">
                                    <div className="flex justify-end">
                                        <button
                                            onClick={closeTapped}
                                            type="button"
                                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-brand-500"
                                        >
                                            Cancel
                                        </button>

                                        <button
                                            disabled={
                                                !isValid ||
                                                (!dirty &&
                                                    !allowNondirtySubmission) ||
                                                isSubmitting
                                            }
                                            type="submit"
                                            className="bg-brand-500 hover:bg-brand-600 text-white \
                      disabled:text-gray-300 disabled:bg-gray-100 \
                       ml-3 inline-flex justify-center py-2 px-4 border 
                       border-transparent shadow-sm text-sm font-medium rounded-md 
                        focus:outline-none focus:ring-0 focus:ring-offset-2 
                        focus:ring-brand-500"
                                        >
                                            {submitText ?? 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </ModalWrapper>
    )
}
