import {
    DataAttributes,
    DataAttributesAvatar,
    DataAttributesCompany,
    IntercomProps,
    MessengerAttributes,
} from './types'

export function mapIntercomPropsToRawIntercomProps(props: IntercomProps) {
    return removeUndefined({
        ...mapMessengerAttributesToRawMessengerAttributes(props),
        ...mapDataAttributesToRawDataAttributes(props),
    })
}

function mapMessengerAttributesToRawMessengerAttributes(
    attributes: MessengerAttributes,
) {
    return {
        custom_launcher_selector: attributes.customLauncherSelector,
        alignment: attributes.alignment,
        vertical_padding: attributes.verticalPadding,
        horizontal_padding: attributes.horizontalPadding,
        hide_default_launcher: attributes.hideDefaultLauncher,
        session_duration: attributes.sessionDuration,
        action_color: attributes.actionColor,
        background_color: attributes.backgroundColor,
    }
}

function mapDataAttributesToRawDataAttributes(attributes: DataAttributes) {
    return {
        email: attributes.email,
        user_id: attributes.userId,
        created_at: attributes.createdAt,
        name: attributes.name,
        phone: attributes.phone,
        last_request_at: attributes.lastRequestAt,
        unsubscribed_from_emails: attributes.unsubscribedFromEmails,
        language_override: attributes.languageOverride,
        utm_campaign: attributes.utmCampaign,
        utm_content: attributes.utmContent,
        utm_medium: attributes.utmMedium,
        utm_source: attributes.utmSource,
        utm_term: attributes.utmTerm,
        avatar:
            attributes.avatar &&
            mapDataAttributesAvatarToRawDataAttributesAvatar(attributes.avatar),
        user_hash: attributes.userHash,
        company:
            attributes.company &&
            mapDataAttributesCompanyToRawDataAttributesCompany(
                attributes.company,
            ),
        companies: attributes.companies?.map(
            mapDataAttributesCompanyToRawDataAttributesCompany,
        ),
        ...attributes.customAttributes,
    }
}

function mapDataAttributesCompanyToRawDataAttributesCompany(
    attributes: DataAttributesCompany,
) {
    return {
        company_id: attributes.companyId,
        name: attributes.name,
        created_at: attributes.createdAt,
        plan: attributes.plan,
        monthly_spend: attributes.monthlySpend,
        user_count: attributes.userCount,
        size: attributes.size,
        website: attributes.website,
        industry: attributes.industry,
        ...attributes.customAttributes,
    }
}

function mapDataAttributesAvatarToRawDataAttributesAvatar(
    attributes: DataAttributesAvatar,
) {
    return {
        type: attributes.type,
        image_url: attributes.imageUrl,
    }
}

function removeUndefined(obj: any) {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') removeUndefined(obj[key])
        else if (obj[key] === undefined) delete obj[key]
    })
    return obj
}
