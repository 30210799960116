import * as React from 'react'

export default function LoadingFullScreen() {
    return (
        <div className="h-screen w-screen flex flex-col justify-center items-center	 bg-white">
            <img
                src="/images/logo.png"
                style={{ height: 'auto', width: 100 }}
                alt=""
            />
        </div>
    )
}
