import React from 'react'

import brainImg from '@src/assets/images/launchpad/brain.png'
import handsImg from '@src/assets/images/launchpad/hands.png'
import userMsgImg from '@src/assets/images/launchpad/user_msg.png'
import userGroupImg from '@src/assets/images/launchpad/user_group.png'
import capitalImg from '@src/assets/images/launchpad/capital.png'
import {
    Button,
    ButtonPrimary,
    ButtonVariant,
} from '@src/app/common/components/Button'
import { Link } from 'react-router-dom'
import { URLS } from '@src/constants/urls'

const features = [
    {
        title: 'Mentorship',
        imgUrl: userGroupImg,
        description:
            'From day 1, you’ll meet with successful creators and entrepreneurs who have grown successful businesses before. We’ll answer your questions, introduce you to the right people, and give you a big butt-kick in the right direction.',
    },
    {
        title: 'Find a co-founder',
        imgUrl: handsImg,
        description:
            'Statistically speaking, businesses with 2 or more founders are far more likely to succeed. We’ll introduce you to our network of hungry rockstar entrepreneurs and help you find someone to run the business side of things so you can continue to be creative through your content.',
    },
    {
        title: 'Meet with like-minded creators',
        imgUrl: brainImg,
        description:
            'We know that being an ambitious creator can sometimes feel lonely, like you’re on an island in the vast world of social media. Creator Launchpad will allow you to connect with other hand-selected, business-savvy creators who will share insights with you and support you as you grow.',
    },
    {
        title: 'Learn from experts in different industries',
        imgUrl: userMsgImg,
        description:
            'Whether you want to create your own daily newsletter, launch an educational course, or start your own fashion brand… we’ll have experts from these industries (and more) come in to share their insights with you.',
    },
    {
        title: 'Access to capital',
        imgUrl: capitalImg,
        description:
            'If you have a great idea, we’d love to invest! Whether you need $1,000 or $100,000… we have a huge war chest that we’re ready to deploy to help you out.*',
        description_sec:
            '*While we will take all members into consideration, investments are not guaranteed.',
    },
]

const YouWillGet = () => {
    return (
        <div className="px-5 py-8  md:py-[72px] space-y-6 md:space-y-12 bg-white">
            <h3 className="text-3xl leading-8  md:text-4xl -tracking-[0.06em] font-bold text-center">
                Here's what <br className="sm:hidden" /> you'll get
            </h3>

            <div className="flex items-stretch justify-center flex-wrap gap-4">
                {features.map(item => (
                    <div
                        key={item.title}
                        className="border-2 border-black rounded-2xl p-6 md:p-8 space-y-4 md:space-y-8 max-w-sm"
                    >
                        <img
                            className="max-w-[48px]"
                            src={item.imgUrl}
                            alt={item.title}
                        />
                        <div className="space-y-4">
                            <p className="font-bold text-2xl -tracking-[1px]  leading-6 md:-tracking-[0.06em] ">
                                {item.title}
                            </p>
                            <p className="text-base md:text-xl md:leading-7 md:-tracking-tighter">
                                {item.description}
                            </p>
                            {item.description_sec && (
                                <p className="text-base md:text-xl md:leading-7 md:-tracking-tighter">
                                    {item.description_sec}
                                </p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className="text-center">
                <Link
                    to={URLS.LAUNCHPAD_APPLICATION}
                    className="inline-block mx-auto"
                >
                    <Button
                        variant={ButtonVariant.SLIM}
                        primary={ButtonPrimary.BLACK}
                        className="mx-auto text-base px-5 h-11 md:text-xl md:leading-5 font-normal md:h-14 md:px-6"
                    >
                        Apply Now
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default YouWillGet
