import { getApolloClient } from '@src/ApolloClient'
import { closeModal } from '@src/app/redux/slices/modal.slice'
import { useAppDispatch } from '@src/app/redux/store'
import checkImg from '@src/assets/images/idols/check.png'
import { GROUP_SIZE } from '@src/constants/competition'
import { CURRENT_PHASE } from '@src/features/idols/hooks/useGetCurrentPhase'
import { useCompetition } from '@src/features/idols/pages/admin/useCompetition'
import {
    Order_By,
    useGetCompetitionGroupsViewQuery,
} from '@src/generated/graphql-operations'
import { numberWithCommas } from '@src/helper/NumberFormatter'
import React, { useEffect } from 'react'
import { HiOutlineX } from 'react-icons/hi'
import { EVENTS, logEvent } from '../logging/logging'
import ModalWrapper from './ModalWrapper'
interface VoteConfirmationModalProps {
    selectedCompetitor: any
    selectedCompetitionGroup: any
    votes: number
    navigate: any
}

const VoteConfirmationModal: React.FC<VoteConfirmationModalProps> = ({
    selectedCompetitor,
    selectedCompetitionGroup,
    votes,
    navigate,
}) => {
    const dispatch = useAppDispatch()
    const { data } = useGetCompetitionGroupsViewQuery({
        variables: {
            orderBy: { created_at: Order_By.Desc },
            where: { id: { _eq: selectedCompetitionGroup?.id } },
            groupMembershipsLimit: 4,
        },
    })

    const competitionGroup = data?.competition_groups[0]

    const { isApplicationsOpen } = useCompetition()

    // Boolean to determine if we want to prompt the voter to create their own application
    const isPromptApplication = isApplicationsOpen

    function handleCloseModal() {
        dispatch(closeModal())
        getApolloClient().refetchQueries({
            include: ['GetUserWithPrivateById'],
        })
    }

    function redirectToApplicationPage() {
        logEvent(EVENTS.FANX.VOTING.VOTE_CONF_MODAL_CLICK)
        navigate('/thesearch/application')
        handleCloseModal()
    }

    useEffect(() => {
        logEvent(EVENTS.FANX.VOTING.VOTE_CONF_MODAL_VIEW)
    }, [])

    return (
        <ModalWrapper maskClosable={false}>
            <div className="relative inline-block align-bottom bg-white rounded-2xl p-4 text-left overflow-hidden shadow-xl transform transition-all w-full max-w-[358px] sm:my-8 sm:align-middle">
                <button
                    type="button"
                    className="bg-transparent text-gray-900 focus:outline-none text-3xl"
                    onClick={handleCloseModal}
                >
                    <span className="sr-only">Close</span>
                    <HiOutlineX aria-hidden="true" />
                </button>

                <div className="p-4 divide-y">
                    <div className="space-y-4 pb-6">
                        <p className=" text-center text-xl leading-7 font-bold">
                            Thanks for voting!
                        </p>
                        <div className="flex flex-wrap gap-2 items-center justify-center">
                            <div className="w-16 h-16 relative ">
                                <img
                                    className="w-16 h-16 rounded-full object-cover"
                                    src={
                                        selectedCompetitor?.photo_url
                                            ? selectedCompetitor?.photo_url
                                            : '/images/user.png'
                                    }
                                    alt={selectedCompetitor?.display_name}
                                    referrerPolicy="no-referrer"
                                />

                                <img
                                    src={checkImg}
                                    className="absolute bottom-0 w-5 h-5 right-0"
                                    alt="check"
                                />
                            </div>
                            <div className="text-base leading-5">
                                <p className="font-bold">
                                    {selectedCompetitor?.display_name}
                                </p>

                                <p>
                                    now has {numberWithCommas(votes)}
                                    {Number(votes) === 1 ? ' vote' : ' votes'}
                                </p>
                            </div>
                        </div>
                    </div>
                    {isPromptApplication && (
                        <div className="pt-6 space-y-4">
                            <p className=" text-center text-xl leading-7 font-bold">
                                Also, it's not too late to <br /> join
                                #TheSearch!
                            </p>

                            <p className="text-center text-base leading-5">
                                Multiple prizes will be given out to <br />{' '}
                                creators of all sizes during the contest <br />{' '}
                                including the $1,000,000 investment.
                            </p>

                            <div className="max-w-xs mx-auto">
                                <button
                                    onClick={() => redirectToApplicationPage()}
                                    className={`w-full text-brandBlack-600 text-center bg-brandIdolGreen-500 border border-transparent py-[15px] lg:py-[18px] px-5
                                disabled:text-gray-300 disabled:bg-gray-100
                                    justify-center text-base font-bold  hover:bg-brand-600 focus:outline-none
                                    focus:ring-0 focus:ring-offset-0 rounded-full `}
                                >
                                    Enter the contest
                                </button>
                            </div>
                            {competitionGroup &&
                                competitionGroup
                                    .competition_group_memberships_aggregate
                                    ?.aggregate?.count === GROUP_SIZE && (
                                    <p className="text-center text-base leading-5">
                                        P.S. We’ll make sure you are not <br />{' '}
                                        in the same group as{' '}
                                        {selectedCompetitor?.display_name}
                                    </p>
                                )}
                        </div>
                    )}
                </div>
            </div>
        </ModalWrapper>
    )
}

export default VoteConfirmationModal
