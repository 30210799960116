import React from 'react'
import { useNavigate } from 'react-router-dom'
import { resetApplications } from '../../../../app/redux/slices/applications.slice'
import { useAppDispatch } from '../../../../app/redux/store'

interface SubmittedProps {}

const Submitted = ({}: SubmittedProps) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const resetState = () => {
        dispatch(resetApplications())
        navigate('/')
    }

    return (
        <div className="bg-[#FC6ADC] py-32 mx-5 lg:mx-12 rounded-3xl -mt-5 lg:-mt-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-0">
                <div className="text-center">
                    <p className="max-w-5xl px-0.5 lg:px-0 mx-auto mt-1 text-5xl font-bold text-black sm:text-5xl lg:text-[124px] lg:leading-[104px] select-none">
                        Your application was submitted
                    </p>
                    <p className="max-w-2xl mt-5 mb-16 mx-auto font-normal text-lg lg:text-xl sm:text-xl text-black">
                        Thanks for your interest in CreatorDAO. We'll review
                        your application and be in touch!
                    </p>
                    <button
                        className="bg-black text-white py-6 px-20 rounded-full"
                        onClick={resetState}
                    >
                        Done!
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Submitted
