import andrewEastImg from '@src/assets/images/community-members/andrew_east.png'
import austinEvansImg from '@src/assets/images/community-members/austin_evans.png'
import chainsmokersImg from '@src/assets/images/community-members/chainsmokers.png'
import garryTanImg from '@src/assets/images/community-members/garry_tan.png'
import johnGannonImg from '@src/assets/images/community-members/john_gannon.png'
import jomaTechImg from '@src/assets/images/community-members/joma_tech.png'
import josephineLeeImg from '@src/assets/images/community-members/josephine_lee.png'
import kongPhamImg from '@src/assets/images/community-members/kong_pham.png'
import kygoImg from '@src/assets/images/community-members/kygo.png'
import lubaYudasinaImg from '@src/assets/images/community-members/luba_yudasina.png'
import meghanaDarImg from '@src/assets/images/community-members/meghana_dhar.png'
import michaelMaImg from '@src/assets/images/community-members/michael_ma.png'
import mikeEinzigerImg from '@src/assets/images/community-members/mike_einziger.png'
import natalieBarbuImg from '@src/assets/images/community-members/natalie_barbu.png'
import nateOBrienImg from '@src/assets/images/community-members/nate_o_brien.png'
import parisHilton from '@src/assets/images/community-members/paris_hilton.png'

export enum communityMemberCategory {
    MENTOR = 'Mentor',
    MEMBER = 'Member',
    ADVISOR = 'Advisor',
    INVESTOR = 'Investor',
}

export const communityMembers = [
    {
        name: 'Michael Ma',
        tagline:
            'Invested and advised dozens of successful startups including Whatnot, Karat and Rippling',
        category: communityMemberCategory.MENTOR,
        imgUrl: michaelMaImg,
    },
    {
        name: 'Kong Pham',
        tagline:
            '4 million subscribers on YouTube. Generated over $25M in revenue',
        category: communityMemberCategory.MENTOR,
        imgUrl: kongPhamImg,
    },
    {
        name: 'Josephine Lee',
        tagline: '185,000 subscribers on YouTube',
        category: communityMemberCategory.MEMBER,
        imgUrl: josephineLeeImg,
    },
    {
        name: 'Andrew East',
        tagline: '1.5 million subscribers on YouTube',
        category: communityMemberCategory.MENTOR,
        imgUrl: andrewEastImg,
    },
    {
        name: 'Meghana Dhar',
        tagline:
            'Forbes 30 under 30. Invested and advised multiple successful startups',
        category: communityMemberCategory.ADVISOR,
        imgUrl: meghanaDarImg,
    },
    {
        name: 'Austin Evans',
        tagline: '5.3 million subscribers on YouTube',
        category: communityMemberCategory.MENTOR,
        imgUrl: austinEvansImg,
    },
    {
        name: 'Joma Tech',
        tagline: '2.1 million subscribers on YouTube',
        category: communityMemberCategory.MENTOR,
        imgUrl: jomaTechImg,
    },
    {
        name: 'Natalie Barbu',
        tagline: 'Founder of Rella. 300k subscribers on YouTube',
        category: communityMemberCategory.MEMBER,
        imgUrl: natalieBarbuImg,
    },
    {
        name: 'John Gannon',
        tagline:
            'Generated 10 billion views in 2022 across all platforms for BuzzFeed',
        category: communityMemberCategory.MENTOR,
        imgUrl: johnGannonImg,
    },
    {
        name: 'Luba Yudasina',
        tagline:
            '100k subscribers on YouTube. Raised $5.7 million from investors',
        category: communityMemberCategory.MEMBER,
        imgUrl: lubaYudasinaImg,
    },
    {
        name: 'Nate O’Brien',
        tagline: '1.5 million subscribers on YouTube',
        category: communityMemberCategory.INVESTOR,
        imgUrl: nateOBrienImg,
    },
    {
        name: 'Garry Tan',
        tagline: 'President/CEO of Y-Combinator',
        category: communityMemberCategory.INVESTOR,
        imgUrl: garryTanImg,
    },
    {
        name: 'Mike Einziger',
        tagline: 'World-famous musician, songwriter, and producer',
        category: communityMemberCategory.INVESTOR,
        imgUrl: mikeEinzigerImg,
    },
    {
        name: 'Kygo',
        tagline: 'World-famous DJ and music producer',
        category: communityMemberCategory.INVESTOR,
        imgUrl: kygoImg,
    },
    {
        name: 'Paris Hilton',
        tagline:
            'Media personality, businesswoman, socialite, model, DJ, and entertainer',
        category: communityMemberCategory.INVESTOR,
        imgUrl: parisHilton,
    },
    {
        name: 'Chainsmokers',
        tagline: 'World-famous DJ and music producer',
        category: communityMemberCategory.INVESTOR,
        imgUrl: chainsmokersImg,
    },
]
