import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export const isEmpty = (value: string | number | object): boolean => {
    if (value === null) {
        return true
    } else if (typeof value !== 'number' && value === '') {
        return true
    } else if (typeof value === 'undefined' || value === undefined) {
        return true
    } else if (
        value !== null &&
        typeof value === 'object' &&
        !Object.keys(value).length
    ) {
        return true
    } else {
        return false
    }
}

export const nFormatter = (num: number, digits: number) => {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'B' },
        { value: 1e12, symbol: 'T' },
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value
        })
    return item
        ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
        : '0'
}

export const dFormatter = (date: string) => {
    return dayjs(date).fromNow()
}

export const round = (value: number, decimals: number) => {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals)
}

// 641 --> 10:41
export const secondsToTimestamp = (seconds: number) => {
    const h = Math.floor(seconds / 3600)
            .toString()
            .padStart(2, '0'),
        m = Math.floor((seconds % 3600) / 60)
            .toString()
            .padStart(2, '0'),
        s = Math.floor(seconds % 60)
            .toString()
            .padStart(2, '0')

    return Number(h) > 0 ? `${h}:${m}:${s}` : `${m}:${s}`
}

// 10:41 --> 641
export const TimestampToSeconds = (time: string) => {
    return time
        .split(':')
        .reverse()
        .reduce((prev, curr: any, i) => prev + curr * Math.pow(60, i), 0)
}

// 0.1 --> 10%
export const fractionToPercentage = (value: number) => {
    return Math.round((value / 1) * 100)
}

// 0.1 (duration 641) --> 64.10000000000001
export const fractionToSecondsBasedOnDuration = (
    value: number,
    duration: number,
) => {
    return (value / 1) * duration
}

// 10% (duration 641) --> 0.10000000000000002
export const percentageToFractionBasedOnDuration = (
    value: number,
    duration: number,
) => {
    return ((Number(value) / 100) * duration) / duration
}

export const secondsToFractionBasedOnDuration = (
    seconds: number,
    duration: number,
) => {
    return seconds / duration
}

export const secondsToPercentageBasedOnDuration = (
    seconds: number,
    duration: number,
) => {
    return secondsToFractionBasedOnDuration(seconds, duration) * 100
}
