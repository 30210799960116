import {
    getTzCurrentDateTime,
    getTzTime,
    getTzUtcTime,
    getUtcTime,
    isSameOrBefore,
    MINUTES,
    TIME_FORMAT,
} from './dateFormatter'

const guessIfThisSessionIsBooked = (
    dateOfTheSession,
    session,
    bookedSessions,
    currentUserTz,
) => {
    if (bookedSessions && bookedSessions.length > 0) {
        const dateAndTime = `${dateOfTheSession} ${session}`
        const bookedArr = bookedSessions.filter(session => {
            return (
                getUtcTime(session.start_time).isSame(
                    getTzUtcTime({
                        dateTime: dateAndTime,
                        timeZone: currentUserTz,
                    }),
                ) && !session.cancelled
            )
        })
        return bookedArr.length > 0
    }
    return false
}

export function getAvailableTimeSlots({
    formatedDate,
    startTime,
    endTime,
    originalTz,
    currentUserTz,
    duration,
    previouslyBookedSessions,
}) {
    let availableTimeSlots = []

    const originalStartTime = getTzTime({
        date: formatedDate,
        time: startTime,
        timeZone: originalTz,
    })

    const originalEndTime = getTzTime({
        date: formatedDate,
        time: endTime,
        timeZone: originalTz,
    })

    const currentUserStart = originalStartTime.clone().tz(currentUserTz)

    const currentUserEndUtc = originalEndTime.clone().tz(currentUserTz)

    let currentUserStartTimeSlot = currentUserStart

    while (currentUserStartTimeSlot.isSameOrBefore(currentUserEndUtc)) {
        if (
            !currentUserStartTimeSlot.isSame(
                getTzCurrentDateTime(currentUserTz),
                'day',
            )
        ) {
            availableTimeSlots.push(
                currentUserStartTimeSlot.format(TIME_FORMAT),
            )
        } else {
            if (
                isSameOrBefore({
                    startTime:
                        getTzCurrentDateTime(currentUserTz).format(TIME_FORMAT),
                    endTime: currentUserStartTimeSlot.format(TIME_FORMAT),
                })
            ) {
                availableTimeSlots.push(
                    currentUserStartTimeSlot.format(TIME_FORMAT),
                )
            }
        }

        currentUserStartTimeSlot = currentUserStartTimeSlot.add(
            duration,
            MINUTES,
        )
    }

    availableTimeSlots = availableTimeSlots.filter(
        slot =>
            !guessIfThisSessionIsBooked(
                formatedDate,
                slot,
                previouslyBookedSessions,
                currentUserTz,
            ),
    )

    return availableTimeSlots
}
