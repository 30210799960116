import React from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Form as FormikForm, Formik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import Spinner from '../../../../app/layout/Spinner'
import { setApplications } from '../../../../app/redux/slices/applications.slice'
import { useAppDispatch, useAppSelector } from '../../../../app/redux/store'
import Hero from './Hero'
import InputField from './InputField'
import Submitted from './Submitted'

interface ApplicationFormProps {}

const data = [
    {
        title: 'Personal Info',
        inputs: [
            {
                name: 'display_name',
                type: 'text',
                placeholder: 'First & Last Name',
            },
            {
                name: 'email',
                type: 'email',
                placeholder: 'Email',
            },
        ],
    },
    {
        title: 'Social',
        inputs: [
            {
                name: 'youtube',
                type: 'text',
                placeholder: 'YouTube (optional)',
            },
            {
                name: 'tiktok',
                type: 'text',
                placeholder: 'TikTok (optional)',
            },
            {
                name: 'twitter',
                type: 'text',
                placeholder: 'Twitter (optional)',
            },
            {
                name: 'instagram',
                type: 'text',
                placeholder: 'Instagram (optional)',
            },
        ],
    },
    {
        title: 'Your Best Content',
        inputs: [
            {
                name: 'contentexample',
                type: 'text',
                placeholder: 'Link to your best piece of content',
            },
        ],
    },
    {
        title: 'Getting to know you',
        inputs: [
            {
                name: 'whyjoin',
                type: 'select-menu',
                placeholder: 'Why do you want to join Creator DAO?',
            },
            {
                name: 'whatvalue',
                type: 'textarea',
                placeholder: 'What value can you bring to our community?',
            },
        ],
    },
]

const dotColors = ['#000000', '#48C96C', '#FC6ADC', '#FFA767']

const ApplicationForm = ({}: ApplicationFormProps) => {
    const dispatch = useAppDispatch()
    const functions = getFunctions()
    const { isComplete, tempEmail } = useAppSelector(
        state => state.applications,
    )

    const completingForm = async (data: any) => {
        const result = await httpsCallable(
            functions,
            'completeWaitlistForm',
        )(data)
        return result.data
    }

    return (
        <React.Fragment>
            {isComplete ? (
                <Submitted />
            ) : (
                <React.Fragment>
                    <Hero />
                    <Formik
                        enableReinitialize
                        initialValues={{
                            display_name: '',
                            email: tempEmail || '',
                            youtube: '',
                            tiktok: '',
                            twitter: '',
                            instagram: '',
                            contentexample: '',
                            whyjoin: '',
                            whatvalue: '',
                        }}
                        validationSchema={Yup.object().shape({
                            display_name: Yup.string()
                                .min(
                                    2,
                                    'Display name needs to be between 2 to 40 characters',
                                )
                                .max(
                                    40,
                                    'Display name needs to be between 2 to 40 characters',
                                )
                                .required('Display name is required'),
                            email: Yup.string()
                                .email('Must be a valid email')
                                .required('Email is required'),
                            youtube: Yup.string().nullable(),
                            tiktok: Yup.string().nullable(),
                            twitter: Yup.string().nullable(),
                            instagram: Yup.string().nullable(),
                            contentexample: Yup.string()
                                .min(2, 'Too Short!')
                                .required('Field is required'),
                            whyjoin: Yup.string().required('Field is required'),
                            whatvalue: Yup.string()
                                .min(5, 'Too Short!')
                                .required('Field is required'),
                        })}
                        onSubmit={async values => {
                            const social_media = {
                                youtube: values.youtube,
                                tiktok: values.tiktok,
                                twitter: values.twitter,
                                instagram: values.instagram,
                            }

                            const data = {
                                display_name: values.display_name,
                                email: values.email,
                                social_media: social_media,
                                content_example: values.contentexample,
                                why_join: values.whyjoin,
                                what_value: values.whatvalue,
                            }

                            try {
                                const response = await completingForm(data)
                                if (response) {
                                    dispatch(
                                        setApplications({
                                            isComplete: true,
                                        }),
                                    )
                                }
                            } catch (error) {
                                toast.error('Error submitting the form!')
                            }

                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            })
                        }}
                    >
                        {({ isSubmitting }) => (
                            <FormikForm>
                                <div className="space-y-6 px-4 sm:px-6 lg:px-8">
                                    {data.map((field, index) => (
                                        <div key={index} className="space-y-6">
                                            <div className="max-w-5xl mx-auto">
                                                <div className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8">
                                                    <div className="flex flex-col md:col-span-5 items-center pb-4 sm:items-start sm:pb-0">
                                                        <span className="uppercase text-xl font-bold text-black leading-7">
                                                            {field.title}
                                                        </span>
                                                        <span className="inline-flex mt-1 space-x-1">
                                                            {[
                                                                ...Array(
                                                                    index + 1,
                                                                ),
                                                            ].map(
                                                                (
                                                                    val,
                                                                    index,
                                                                ) => (
                                                                    <svg
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={`h-4 w-4 text-${dotColors[index]}`}
                                                                        fill={`${dotColors[index]}`}
                                                                        viewBox="0 0 8 8"
                                                                    >
                                                                        <circle
                                                                            cx={
                                                                                4
                                                                            }
                                                                            cy={
                                                                                4
                                                                            }
                                                                            r={
                                                                                3
                                                                            }
                                                                        />
                                                                    </svg>
                                                                ),
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="flex flex-col space-y-4 mt-2 md:mt-0 md:col-span-7 w-full">
                                                        {field.inputs.map(
                                                            (input, index) => (
                                                                <InputField
                                                                    key={index}
                                                                    name={
                                                                        input.name
                                                                    }
                                                                    placeholder={
                                                                        input.placeholder
                                                                    }
                                                                    type={
                                                                        input.type
                                                                    }
                                                                    fieldtype={
                                                                        input.type as any
                                                                    }
                                                                    disabled={
                                                                        isSubmitting
                                                                    }
                                                                />
                                                            ),
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {index !== data.length - 1 && (
                                                <hr className="border-[#D5D7D3]" />
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="flex sm:block max-w-5xl mx-auto justify-center sm:justify-start">
                                    <div className="pb-8 md:grid md:grid-cols-12 md:gap-8">
                                        <div className="md:col-span-5"></div>
                                        <div className="space-y-4 mt-2 md:mt-0 md:col-span-7">
                                            <button
                                                type="submit"
                                                className={`relative shrink-0 w-[172px] h-[68px] flex items-center justify-center text-[16px] leading-6 font-normal rounded-full text-white bg-black focus:outline-none focus:ring-0 ${
                                                    isSubmitting
                                                        ? 'pointer-events-none'
                                                        : ''
                                                }`}
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? (
                                                    <Spinner />
                                                ) : (
                                                    'Submit'
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </FormikForm>
                        )}
                    </Formik>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default ApplicationForm
