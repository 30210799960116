import * as React from 'react'
import Spinner from './Spinner'

const Loading = () => {
    return (
        <div
            className="h-full w-full flex flex-col justify-center items-center opacity-10"
            data-testid="loading-indicator"
        >
            <div className="flex justify-center py-4">
                <Spinner size={'h-20 w-20'} />
            </div>
        </div>
    )
}

export default Loading
