import React from 'react'

interface AppHeroTitleProps {
    children: React.ReactNode
}

const AppHeroTitle: React.FC<AppHeroTitleProps> = ({ children }) => {
    return (
        <p
            className="max-w-5xl px-0.5 lg:px-0 mx-auto mt-1 text-[32px] font-bold text-black leading-[100%]  
                sm:text-5xl lg:text-[40px] select-none text-center"
        >
            {children}
        </p>
    )
}

export default AppHeroTitle
