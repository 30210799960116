import * as React from 'react'
import { useField } from 'formik'
import CreatableSelect from 'react-select/creatable'

interface ComboboxTagProps {
    label: string
    name: string
    [props: string]: any
}

export const ComboboxTag: React.FunctionComponent<ComboboxTagProps> = ({
    label,
    ...props
}) => {
    const [field, meta, helpers] = useField(props)

    const handleChange = (newValue: any) => {
        helpers.setValue(newValue)
    }

    const customStyles = {
        option: (provided: any) => ({
            ...provided,
            color: 'black',
        }),
        control: (provided: any) => ({
            ...provided,
            minHeight: '50px',
            boxShadow: 'none',
            borderRadius: '6px',
            borderColor: 'rgb(209 213 219) !important',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: 'rgb(156 163 175)',
        }),
    }

    return (
        <div>
            <label className="appearance-none block text-base font-medium text-gray-700 select-none mb-2">
                {label}
            </label>
            <CreatableSelect
                isMulti
                defaultValue={meta.initialValue ?? []}
                onChange={handleChange}
                options={convertTagsToOptions(suggestedTags)}
                styles={customStyles}
                className="w-full text-sm text-gray-400"
                classNamePrefix="rs"
                placeholder={label}
            />
        </div>
    )
}

const suggestedTags = [
    'General',
    'Content',
    'Collabs',
    'New members',
    'Marketing & Growth',
    'Service Providers',
    'Intro Requests',
    'Launches',
]

export function convertTagsToOptions(tags: any) {
    return tags.map((tag: any) => ({
        value: tag,
        label: tag,
    }))
}

export default ComboboxTag
