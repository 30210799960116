import * as React from 'react'
import { ImSpinner11 } from 'react-icons/im'
import { twMerge } from 'tailwind-merge'
import { clsx } from 'clsx'

export enum ButtonPrimary {
    BLACK = 'black',
    GREEN = 'green',
    DARK_GREEN = 'darkGreen',
    BORDER = 'border',
    NO_BORDER = 'noBorder',
}

export enum ButtonVariant {
    SLIM = 'slim',
    FAT = 'fat',
}

/**
 * If you pass icon down through props instead children, button will enlarge slightly
 * and you will have spinner + text Loading
 */
export interface ButtonProps {
    primary?: ButtonPrimary
    variant?: ButtonVariant
    className?: string // Additional classes if needs to be passed down
    disabled?: boolean
    loading?: boolean
    loadingText?: string
    /** `True` - To have spinner and loading text
     *
     * `False` - To have only spinner
     */
    expandedLoading?: boolean
    children?: React.ReactNode
    leftSideIcon?: React.ReactNode
    rightSideIcon?: React.ReactNode
    onClick?: () => void
    // All other props
    [x: string]: any
}

type SpinnerProps = {
    /** If expanded there will be text as well shown if not only spinner icon. */
    expanded: boolean
    /** Text is default 'Loading', pass this prop if you want custom text */
    text?: string
}

// Extracted to separate function to improve readability of code
const Spinner = ({ expanded = false, text = 'Loading' }: SpinnerProps) => {
    if (expanded) {
        return (
            <>
                <ImSpinner11 size={18} className="animate-spin mr-2" />
                {text}
            </>
        )
    } else {
        return <ImSpinner11 size={18} className="animate-spin" />
    }
}

export const Button = ({
    primary = ButtonPrimary.BORDER,
    variant = ButtonVariant.SLIM,
    className = '',
    disabled = false,
    loading = false,
    loadingText,
    expandedLoading = false,
    style,
    children,
    leftSideIcon,
    rightSideIcon,
    onClick,
    ...rest
}: ButtonProps) => {
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        // Check if button is not in loading state and if there is onClick handler
        if (!loading && onClick) {
            onClick()
        }
    }

    // Construct class name for button according to props
    const constructedClassName = React.useMemo(() => {
        let name =
            'rounded-full flex justify-center items-center px-4 font-bold disabled:text-neutralGray-200'
        // Set height of button according to type
        name += variant === ButtonVariant.SLIM ? ' h-8' : ' h-14'
        // Setting extra padding for loading state of button and setting correct cursor
        if (loading) {
            name += ' px-8 cursor-default'
        }
        // Coloring buttons correctly according to primary of a button
        switch (primary) {
            case ButtonPrimary.BLACK:
                name +=
                    ' bg-black text-white disabled:bg-idolCream-100 disabled:text-neutralGray-100 '
                break
            case ButtonPrimary.GREEN:
                name +=
                    ' bg-brandIdolGreen-500 text-black disabled:bg-idolCream-100 disabled:text-neutralGray-100 hover:bg-hoverGreen-300'
                break
            case ButtonPrimary.DARK_GREEN:
                name +=
                    ' bg-brand-500 text-white disabled:bg-gray-100 disabled:text-gray-300 hover:bg-brand-600'
                break
            case ButtonPrimary.BORDER:
                name +=
                    ' border-2 border-black hover:bg-hoverGreen-100 disabled:border-idolCream-100 disabled:text-neutralGray-100'
                break
            case ButtonPrimary.NO_BORDER:
                name += ' hover:bg-hoverGreen-100 disabled:text-neutralGray-100'
                break

            default:
                break
        }
        return name
    }, [primary, variant, loading])

    return (
        <button
            disabled={disabled}
            className={twMerge(`${constructedClassName} ${className}`)}
            onClick={handleClick}
            {...rest}
        >
            {loading ? (
                <Spinner expanded={expandedLoading} text={loadingText} />
            ) : (
                <>
                    {leftSideIcon}
                    <div
                        className={clsx('mb-[2px]', {
                            'ml-2': leftSideIcon,
                            'mr-2': rightSideIcon,
                        })}
                    >
                        {children}
                    </div>
                    {rightSideIcon}
                </>
            )}
        </button>
    )
}
