import React, { useRef, useEffect } from 'react'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'
import { getAuth, RecaptchaVerifier } from 'firebase/auth'

import FormField from '../../../app/common/form/FormField'
import { getFirebaseErrorString } from '../../../app/common/util/util'
import { signInWithPhone } from '../../../app/hooks/authentication.hooks'
import countryCodes from '../../../countryCodes'
import getInitialValuesAndValidationSchema from '../../../helper/getInitialValuesAndValidationSchema'
import AuthSubmitButton from './AuthSubmitButton'

declare global {
    interface Window {
        grecaptcha: any
        recaptchaVerifier: any
    }
}

interface NewPhoneFormProps {
    initialValue: {
        countryCode: object
        phoneNumber: string
    }
    resendPhoneCode: boolean
    handleToggleAuthForm: () => void
    handlePhoneFormSubmit: any
    setResendPhoneCode: (value: any) => any
}

const NewPhoneForm: React.FC<NewPhoneFormProps> = ({
    initialValue,
    resendPhoneCode,
    handleToggleAuthForm,
    handlePhoneFormSubmit,
    setResendPhoneCode,
}) => {
    const auth = getAuth()
    const formRef: any = useRef(null)
    const phoneRegExp =
        /^(\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
    const fields = [
        {
            name: 'countryCode',
            type: 'select',
            isMulti: false,
            initialValue: initialValue.countryCode,
            allValues: countryCodes.map(item => ({
                value: item.dial_code,
                label: `${item.code}  ${item.dial_code}`,
            })),
            validationSchema: Yup.object()
                .shape({
                    value: Yup.string(),
                    label: Yup.string(),
                })
                .label('Dial Code'),
            className: 'w-[120px]',
        },
        {
            name: 'phoneNumber',
            type: 'text',
            initialValue: initialValue.phoneNumber,
            placeholder: 'Phone Number',
            validationSchema: Yup.string().matches(
                phoneRegExp,
                'Phone number is not valid',
            ),
            className: 'flex-1',
            autoFocus: true,
            isTrimRequired: true,
        },
    ]

    const { initialValues, validationSchema }: any =
        getInitialValuesAndValidationSchema(fields)

    const onSubmitForm = () => {
        if (formRef?.current) {
            formRef.current?.handleSubmit()
        }
    }

    const handleFormSubmit = async (
        values: any,
        { setSubmitting, setErrors }: FormikHelpers<any>,
    ) => {
        try {
            setSubmitting(true)
            const appVerifier = window.recaptchaVerifier as any

            const phoneNumberWithCode =
                values.countryCode.value + values.phoneNumber

            const confirmationResult = await signInWithPhone({
                phoneNumber: phoneNumberWithCode,
                appVerifier: appVerifier,
            })
            handlePhoneFormSubmit({
                ...values,
                phoneNumberWithCode,
                confirmationResult,
            })
        } catch (error) {
            if (window.recaptchaVerifier) {
                window.recaptchaVerifier.render().then((widgetId: string) => {
                    handleResetCaptcha(widgetId)
                })
            }
            setErrors({
                auth: getFirebaseErrorString(error, 'NewPhoneForm'),
            })
            setSubmitting(false)
        }
    }

    const handleResetCaptcha = (widgetId: string) => {
        if (window?.grecaptcha) {
            window.grecaptcha?.reset(widgetId)
        }
    }

    useEffect(() => {
        if (resendPhoneCode && formRef) {
            setResendPhoneCode(false)
            onSubmitForm()
        }
    }, [resendPhoneCode, formRef])

    useEffect(() => {
        window.recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {
                size: 'invisible',
                callback: () => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    onSubmitForm()
                },
            },
            auth,
        )

        return () => {
            if (window && window?.recaptchaVerifier) {
                window.recaptchaVerifier.render().then((widgetId: string) => {
                    handleResetCaptcha(widgetId)
                })
            }
        }
    }, [])

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={Yup.object(validationSchema)}
            onSubmit={handleFormSubmit}
        >
            {({ isSubmitting, errors }: any) => (
                <Form className="space-y-4">
                    <>
                        <div className="space-y-2 select-none">
                            <div className="text-base font-bold flex items-center justify-between gap-2">
                                <label
                                    htmlFor={'phoneNumber'}
                                    className="block  text-brandBlack-500"
                                >
                                    Phone Number
                                </label>
                                <p
                                    className="text-brandIdolGreen-700 select-none  cursor-pointer"
                                    onClick={() => handleToggleAuthForm()}
                                >
                                    Use email address instead
                                </p>
                            </div>

                            <div className={'flex gap-4'}>
                                {fields.map(field => (
                                    <div
                                        key={field.name}
                                        className={field.className}
                                    >
                                        <FormField
                                            field={field}
                                            variant={'fanx'}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        {errors?.auth && (
                            <div className={'text-red-500 mt-2 text-sm'}>
                                {errors?.auth}
                            </div>
                        )}
                        <div id="recaptcha-container"></div>
                        <div>
                            <AuthSubmitButton
                                disabled={isSubmitting}
                                isSubmitting={isSubmitting}
                            />
                        </div>
                    </>
                </Form>
            )}
        </Formik>
    )
}

export default NewPhoneForm
