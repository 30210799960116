import React, { useEffect, useState } from 'react'
import { URLS } from '@src/constants/urls'
import { useLocation } from 'react-router-dom'

import { BothCurrentUser } from '@src/types/users'
import AccountForm from './components/AccountForm'
import AuthCommonWrapper from './components/AuthCommonWrapper'
import AuthForm from './components/AuthForm'
import LoginForm from './components/LoginForm'
import NewSmsVerificationForm from './components/NewSmsVerificationForm'
import {
    AuthFlowSteps,
    AuthFormSteps,
    AUTH_FORM,
    CREATE_ACCOUNT_FORM,
    CREATE_PHONE_ACCOUNT_FORM,
    EMAIL_AUTH,
    LOGIN_FORM,
    PHONE_AUTH,
    SMS_VERIFY_FORM,
} from './constants'

interface AuthFlowWrapperProps {
    currentUserProfile: BothCurrentUser
    isSignUp?: boolean
}

const initialUserFormData = {
    email: '',
    countryCode: {
        value: '+1',
        label: 'US +1',
    },
    phoneNumber: '',
    phoneNumberWithCode: '',
    confirmationResult: null,
}

const AuthFlowWrapper: React.FC<AuthFlowWrapperProps> = ({
    currentUserProfile,
    isSignUp = false,
}) => {
    const [currentStep, setCurrentStep] = useState<AuthFlowSteps>(AUTH_FORM)
    const [userFormData, setUserFormData] = useState(initialUserFormData)
    const [resendPhoneCode, setResendPhoneCode] = useState<boolean>(false)
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === URLS.LOGIN) {
            setCurrentStep(AUTH_FORM)
        }
        if (location.pathname === URLS.SIGN_UP) {
            setCurrentStep(AUTH_FORM)
        }
    }, [location.pathname])

    const [authFormCurrentStep, setAuthFormCurrentStep] =
        useState<AuthFormSteps>(EMAIL_AUTH)

    const handleToggleAuthForm = () => {
        setAuthFormCurrentStep(prev =>
            prev === PHONE_AUTH ? EMAIL_AUTH : PHONE_AUTH,
        )
    }

    const handleChangeStep = (step: any) => {
        setCurrentStep(step)
    }

    const handleChangeUserFormData = (values: any) => {
        setUserFormData({
            ...initialUserFormData,
            ...values,
        })
    }

    const onResendPhoneCode = () => {
        setCurrentStep(AUTH_FORM)
        setResendPhoneCode(true)
    }

    const GetActiveForm = () => {
        switch (currentStep) {
            case AUTH_FORM:
                return (
                    <AuthForm
                        authFormCurrentStep={authFormCurrentStep}
                        userFormData={userFormData}
                        redirectTo={isSignUp ? CREATE_ACCOUNT_FORM : LOGIN_FORM}
                        resendPhoneCode={resendPhoneCode}
                        isSignUp={isSignUp}
                        handleChangeUserFormData={handleChangeUserFormData}
                        handleChangeStep={handleChangeStep}
                        handleToggleAuthForm={handleToggleAuthForm}
                        setResendPhoneCode={setResendPhoneCode}
                    />
                )

            case LOGIN_FORM:
                return (
                    <LoginForm
                        userFormData={userFormData}
                        handleChangeStep={handleChangeStep}
                        goBackTo={AUTH_FORM}
                    />
                )

            case CREATE_ACCOUNT_FORM:
            case CREATE_PHONE_ACCOUNT_FORM:
                return (
                    <AccountForm
                        currentStep={currentStep}
                        userFormData={userFormData}
                        handleChangeStep={handleChangeStep}
                        goBackTo={AUTH_FORM}
                        isSignUp={isSignUp}
                    />
                )

            case SMS_VERIFY_FORM:
                return (
                    <NewSmsVerificationForm
                        currentStep={currentStep}
                        userFormData={userFormData}
                        handleChangeStep={handleChangeStep}
                        redirectTo={
                            isSignUp ? CREATE_PHONE_ACCOUNT_FORM : AUTH_FORM
                        }
                        goBackTo={AUTH_FORM}
                        isSignUp={isSignUp}
                        onResendPhoneCode={onResendPhoneCode}
                    />
                )

            default:
                return null
        }
    }

    return (
        <AuthCommonWrapper
            currentUserProfile={currentUserProfile}
            currentStep={currentStep}
            isSignUp={isSignUp}
        >
            <GetActiveForm />
        </AuthCommonWrapper>
    )
}

export default AuthFlowWrapper
