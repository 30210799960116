import moment from 'moment'

export function validateIfActiveRound(competition, phase) {
    const competitionTime = competition?.datetime_for_testing || new Date()

    return (
        moment(moment(phase?.start_date)).isSameOrBefore(competitionTime) &&
        moment(moment(phase?.end_date)).isSameOrAfter(competitionTime)
    )
}

export function validateIfUpcomingRound(competition, phase) {
    const competitionTime = competition?.datetime_for_testing || new Date()
    return (
        moment(moment(phase?.end_date)).isAfter(competitionTime) &&
        moment(moment(phase?.start_date)).isSameOrAfter(competitionTime)
    )
}

export function validateIfPreviousRound(competition, phase) {
    const competitionTime = competition?.datetime_for_testing || new Date()
    return (
        moment(moment(phase?.start_date)).isBefore(competitionTime) &&
        moment(moment(phase?.end_date)).isSameOrBefore(competitionTime)
    )
}

export function validateIfCompletedRound(competition, phase) {
    const competitionTime = competition?.datetime_for_testing || new Date()
    return (
        moment(moment(phase?.end_date)).isBefore(competitionTime) &&
        moment(moment(phase?.start_date)).isBefore(competitionTime)
    )
}

export function getCurrentRound(competition) {
    if (Number(competition?.phases?.length) > 0) {
        const findActiveRound = getAllVotingPhases(competition.phases).find(
            phase => validateIfActiveRound(competition, phase),
        )

        if (findActiveRound) return findActiveRound

        const findUpcomingRound = getUpcomingRound(competition)

        if (findUpcomingRound) return findUpcomingRound

        const allCompletedRound = getAllVotingPhases(competition.phases).filter(
            phase => validateIfCompletedRound(competition, phase),
        )

        return allCompletedRound.length > 0
            ? allCompletedRound.slice(-1)[0]
            : null
    }
    return null
}

export function getUpcomingRound(competition) {
    if (Number(competition?.phases?.length) > 0) {
        return getAllVotingPhases(competition.phases).find(phase =>
            validateIfUpcomingRound(competition, phase),
        )
    }
    return null
}

export function getPreviousRound(competition) {
    if (Number(competition?.phases?.length) > 0) {
        const allPreviousRounds = getAllVotingPhases(competition.phases).filter(
            phase => validateIfPreviousRound(competition, phase),
        )

        return allPreviousRounds.length > 0
            ? allPreviousRounds.slice(-1)[0]
            : null
    }
    return null
}

function getAllVotingPhases(phases) {
    return phases.filter(phase => phase.is_voting_phase)
}
