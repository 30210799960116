import React from 'react'
import { Dispatch } from '@reduxjs/toolkit'
import {
    ApplicationVerifier,
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInAnonymously,
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    signInWithPopup,
    signOut,
    User,
    applyActionCode,
    sendEmailVerification,
} from 'firebase/auth'

import { getFunctions, httpsCallable } from 'firebase/functions'
import { fetchAndActivate } from 'firebase/remote-config'
import { toast } from 'react-toastify'
import { clearApolloClient } from '../../ApolloClient'
import { sentryTrack } from '../../utils/sentry-track'
import { getFirebaseErrorString } from '../common/util/util'
import { app } from '../config/firebase'
import { getUser } from '../operations/user'
import {
    appLoaded,
    setFetchedFeatureFlags,
    signInUser,
    signOutUser,
} from '../redux/slices/auth.slice'
import { refreshYoutubeChannel } from './youtube.hooks'
import posthog from 'posthog-js'

const auth = getAuth(app)
const functions = getFunctions()

export async function registerInFirebaseAnonymously(email: string) {
    await signInAnonymously(auth)
        .then(async data => {
            console.log('Signed in!')
        })
        .catch(error => {
            const errorMessage = error.message
            console.log('Error signing anonymously' + errorMessage)
        })
}

export async function getIdTokenAndSetClaimsIfNecessary(user: User) {
    let idToken = await user.getIdToken()

    const idTokenResult = await user.getIdTokenResult()
    if (!idTokenResult.claims['https://hasura.io/jwt/claims']) {
        const refreshToken = httpsCallable(functions, 'refreshToken')
        try {
            await refreshToken()
            idToken = await user.getIdToken(true)
        } catch {
            console.error(
                "Unable to refresh token, which doesn't have Hasura claim.",
            )
            signOutFirebase()
            return
        }
    }

    return idToken
}

export function verifyAuth() {
    return function (dispatch: Dispatch) {
        return onAuthStateChanged(auth, async user => {
            if (user) {
                // Serialized token, needs to set Hasura id thus the
                // code below. If the Hasura-necessary claims are not
                // present in the token, then we request them.
                const idToken = await getIdTokenAndSetClaimsIfNecessary(user)

                dispatch(signInUser({ idToken, ...user }))
                dispatch(appLoaded())
                const prismaUser = await getUser(user, idToken)

                if (process.env.REACT_APP_FOR_DEPLOY === 'true') {
                    posthog.identify(user.uid, {
                        name: user.displayName,
                        email: user.email,
                        phone_number: user.phoneNumber,
                        competition_application_id:
                            prismaUser.competition_applications?.id || null,
                        portal_access: prismaUser.portal_access,
                    })
                }

                fetchFeatureFlags(dispatch)
            } else {
                console.log('User is logged out.')
                fetchFeatureFlags(dispatch)
                dispatch(signOutUser())
                dispatch(appLoaded())
                clearApolloClient()
            }
        })
    }
}

async function fetchFeatureFlags(dispatch: Dispatch) {
    try {
        // Returns true if the fetched were used (i.e., unseen or new),
        // otherwise false
        posthog.onFeatureFlags(function () {
            // feature flags are guaranteed to be available at this point
            dispatch(
                setFetchedFeatureFlags({
                    fetchedFeatureFlags: true,
                }),
            )
        })
    } catch (error) {
        console.error('Error with fetch and activate: ', error)
        sentryTrack({ error })
    }
}

export async function socialLogin(selectedProvider: string) {
    let provider
    if (selectedProvider === 'facebook') {
        provider = new FacebookAuthProvider()
    }
    if (selectedProvider === 'google') {
        provider = new GoogleAuthProvider()
    }
    try {
        if (!provider) throw 'Provider is neither facebook nor google.'
        const result = await signInWithPopup(auth, provider)

        // @ts-ignore this '_tokenResponse' is provided under the hood we can access it
        // but ts is going to cry about this as it doesn't exist on UserCredential type
        if (!result._tokenResponse.isNewUser) {
            refreshYoutubeChannel('user', result.user.uid)
        }
    } catch (error) {
        // toast.error(error?.code)
        if (
            [
                'auth/cancelled-popup-request',
                'auth/popup-closed-by-user',
            ].includes(error.code)
        ) {
            return
        }

        toast.error(getFirebaseErrorString(error))
    }
}

export async function signInWithEmail(creds: {
    email: string
    password: string
}) {
    const result = await signInWithEmailAndPassword(
        auth,
        creds.email,
        creds.password,
    )
    if (result.user.uid) {
        refreshYoutubeChannel('user', result.user.uid)
    }
    return result
}

export async function signInWithPhone({
    phoneNumber,
    appVerifier,
}: {
    phoneNumber: string
    appVerifier: ApplicationVerifier
}) {
    const result = await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    return result
}

export async function registerInFirebase(creds: {
    email: string
    password: string
}) {
    const result = await createUserWithEmailAndPassword(
        auth,
        creds.email,
        creds.password,
    )
    if (!result.user.emailVerified) {
        await sendEmailVerification(result.user)
    }

    return result
}

export function sendResetEmail(email: string) {
    sendPasswordResetEmail(auth, email)
}

export function signOutFirebase() {
    return signOut(auth)
}

export function useEmailVerification() {
    const auth = getAuth()
    const verifyEmail = async () => {
        const queryParams = new URLSearchParams(window.location.search)
        if (
            queryParams.get('mode') === 'verifyEmail' &&
            !auth.currentUser?.emailVerified
        ) {
            await applyActionCode(auth, queryParams.get('oobCode') as string)
            const continue_url = queryParams.get('continueUrl')

            if (continue_url) {
                window.location.href = continue_url
            }
        }
    }
    React.useEffect(() => {
        verifyEmail()
    }, [auth])
}
