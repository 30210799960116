import React, { useState } from 'react'
import { BothCurrentUser } from '@src/types/users'
import AccountForm from './components/AccountForm'
import AuthCommonWrapper from './components/AuthCommonWrapper'
import ForgetPasswordForm from './components/ForgetPasswordForm'
import NewSmsVerificationForm from './components/NewSmsVerificationForm'
import {
    AuthFlowSteps,
    FORGET_PASSWORD,
    RESET_PASSWORD,
    RESET_PASSWORD_SMS_VERIFY_FORM,
} from './constants'

interface ForgotPasswordFlowProps {
    currentUserProfile: BothCurrentUser
    isSignUp?: boolean
}

const initialUserFormData = {
    email: '',
    countryCode: {
        value: '+1',
        label: 'US +1',
    },
    phoneNumber: '',
    phoneNumberWithCode: '',
    confirmationResult: null,
}

const ForgotPasswordFlow: React.FC<ForgotPasswordFlowProps> = ({
    currentUserProfile,
    isSignUp = false,
}) => {
    const [currentStep, setCurrentStep] =
        useState<AuthFlowSteps>(FORGET_PASSWORD)
    const [userFormData, setUserFormData] = useState(initialUserFormData)

    const handleChangeStep = (step: any) => {
        setCurrentStep(step)
    }

    const handleForgetPassword = (values: any) => {
        // setCurrentStep(RESET_PASSWORD_SMS_VERIFY_FORM)
    }

    const GetActiveForm = () => {
        switch (currentStep) {
            case RESET_PASSWORD:
                return (
                    <AccountForm
                        currentStep={currentStep}
                        userFormData={userFormData}
                        handleChangeStep={handleChangeStep}
                        goBackTo={FORGET_PASSWORD}
                    />
                )

            case RESET_PASSWORD_SMS_VERIFY_FORM:
                return (
                    <NewSmsVerificationForm
                        currentStep={currentStep}
                        userFormData={userFormData}
                        handleChangeStep={handleChangeStep}
                        redirectTo={RESET_PASSWORD}
                        goBackTo={FORGET_PASSWORD}
                        isSignUp={isSignUp}
                    />
                )

            case FORGET_PASSWORD:
                return (
                    <ForgetPasswordForm
                        initialValue={userFormData?.email}
                        handleForgetPassword={handleForgetPassword}
                    />
                )

            default:
                return null
        }
    }

    return (
        <AuthCommonWrapper currentUserProfile={currentUserProfile}>
            <GetActiveForm />
        </AuthCommonWrapper>
    )
}

export default ForgotPasswordFlow
