import moment from 'moment'
import { CURRENT_PHASE } from '@src/features/idols/hooks/useGetCurrentPhase'
import { useCompetition } from '@src/features/idols/pages/admin/useCompetition'

interface FinalRound {
    showFinalRound: boolean
    isVotingOpen: boolean
    hasVotingEnded: boolean
    roundEndDate: string
}

export function useFinalRound(): FinalRound {
    const {
        currentPhase,
        currentRound: currentLiveRound,
        competitionTime,
        winningUserId,
    } = useCompetition()

    const currentTime = moment(competitionTime)
    const isVotingOpen =
        currentTime.isAfter(currentLiveRound?.start_date) &&
        currentTime.isSameOrBefore(currentLiveRound?.end_date)

    const hasVotingEnded = currentTime.isAfter(currentLiveRound?.end_date)

    return {
        showFinalRound:
            hasVotingEnded && !winningUserId
                ? false
                : [
                      CURRENT_PHASE.FINALS,
                      CURRENT_PHASE.COMPETITION_OVER,
                  ].includes(currentPhase),
        isVotingOpen,
        hasVotingEnded,
        roundEndDate: currentLiveRound?.end_date,
    }
}
