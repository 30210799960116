import React, { ReactNode } from 'react'
import { Link as ReactRouterLink, To } from 'react-router-dom'

interface LinkProps {
    to: To
    className?: string
    onClick?: () => void
    children?: ReactNode | ReactNode[] | string | null
    ariaCurrent?:
        | boolean
        | 'time'
        | 'false'
        | 'true'
        | 'page'
        | 'step'
        | 'location'
        | 'date'
}
const Link = ({ to, className, onClick, children, ariaCurrent }: LinkProps) => {
    return (
        <ReactRouterLink
            to={to}
            className={className}
            onClick={onClick}
            aria-current={ariaCurrent}
        >
            {children}
        </ReactRouterLink>
    )
}

export default Link
