import React from 'react'
import { FieldHookConfig, useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext()
    const [field] = useField(props as FieldHookConfig<string>)
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="MMMM d, yyyy h:mm aa"
            withPortal
            portalId={'body'}
            onChange={val => {
                setFieldValue(field.name, val)
            }}
        />
    )
}
