import React from 'react'
import ModalWrapper from './ModalWrapper'
import { Deals } from '../../../generated/graphql-operations'
import { closeModal } from '../../redux/slices/modal.slice'
import { useAppDispatch } from '../../redux/store'
import RichTextSection from '../../../features/dashboard/forum/RichTextSection'

interface DealsModalProps {
    deal: Deals
}

export default function DealsModal(props: DealsModalProps) {
    const dispatch = useAppDispatch()

    return (
        <ModalWrapper>
            <div className="inline-block align-bottom bg-white rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full max-w-lg h-full md:h-auto ">
                <div className="p-8">
                    <div className="text-center flex flex-col space-y-3 mb-4">
                        <img
                            className="h-16 w-16 rounded-full object-fill mx-auto"
                            src={
                                props.deal.logo_url
                                    ? props.deal.logo_url
                                    : '/images/user.png'
                            }
                            alt={props.deal.partner_name}
                        />
                        <div className="font-medium text-lg">
                            {props.deal.partner_name}
                        </div>
                        <div className="text-sm leading-4">
                            <RichTextSection
                                richText={props.deal.partner_description}
                            />
                        </div>
                        <div className="border border-dashed border-[#0D7129] p-3 text-[#0D7129] text-sm rounded-lg">
                            {props.deal.offer}
                        </div>
                    </div>
                    {props.deal.instructions ? (
                        <div className="border rounded-xl border-[#D0D5DD] p-4 mb-4">
                            <div className="font-medium">
                                How to claim offer
                            </div>
                            <div className="text-sm leading-4">
                                <RichTextSection
                                    richText={props.deal.instructions}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="flex justify-around align-center flex-wrap whitespace-nowrap mt-8">
                        <button
                            className="border border-black items-center p-3 leading-5 rounded-full font-bold text-center basis-[45%]"
                            onClick={() => dispatch(closeModal())}
                        >
                            Cancel
                        </button>
                        <a
                            className="border border-black bg-black text-white items-center p-3 leading-5 rounded-full font-bold text-center basis-[45%]"
                            href={props.deal.redemption_url}
                        >
                            Claim deal
                        </a>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    )
}
