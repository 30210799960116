import React from 'react'
import { useFeatureFlag } from '@src/features/shared/useFeatureFlag'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import NewFooter from './NewFooter'
import NewHeader from './NewHeader'

interface MarketingLayoutProps {}

export const MarketingLayout: React.FunctionComponent<
    MarketingLayoutProps
> = () => {
    const showNewLaunchpad = useFeatureFlag('show_new_launchpad')
    return (
        <div className="bg-brandOffWhite-500 min-h-full">
            {showNewLaunchpad ? <NewHeader /> : <Header />}
            <Outlet />
            {showNewLaunchpad ? <NewFooter /> : <Footer />}
        </div>
    )
}
