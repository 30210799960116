import * as React from 'react'
import { EVENTS, logPageViewEvent } from '../common/logging/logging'

export default function NotFound() {
    React.useEffect(() => logPageViewEvent(EVENTS.FANX.PAGE.NOT_FOUND), [])
    return (
        <div className="flex items-center min-h-[59vh] lg:min-h-[73vh] px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
            <div className="max-w-max mx-auto">
                <main className="sm:flex">
                    <p className="text-center md:text-left text-4xl font-extrabold text-brand-600 sm:text-5xl">
                        404
                    </p>
                    <div className="sm:ml-6">
                        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                            <h1 className="text-center md:text-left text-4xl font-extrabold text-gray-900 sm:text-5xl">
                                Page not found
                            </h1>
                            <p className="text-center md:text-left mt-1 text-base text-gray-500">
                                Please check the URL in the address bar and try
                                again.
                            </p>
                        </div>
                        <div className="mt-10 flex space-x-3 justify-center md:justify-start sm:border-l sm:border-transparent sm:pl-6">
                            <a
                                href="/"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-brand-500"
                            >
                                Go back home
                            </a>
                            <a
                                href="mailto:support@creatordao.com"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-brand-700 bg-brand-100 hover:bg-brand-200 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-brand-500"
                            >
                                Contact support
                            </a>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
