import React from 'react'

import successLeftImg from '@src/assets/images/idols/success_left.svg'
import successLeftMobImg from '@src/assets/images/idols/success_left_mob.svg'
import successRightImg from '@src/assets/images/idols/success_right.svg'
import successRightMobImg from '@src/assets/images/idols/success_right_mob.svg'

const SuccessImages: React.FC = () => {
    return (
        <React.Fragment>
            <img
                className=" hidden lg:block absolute -top-1 lg:max-w-[80px] xl:max-w-full lg:-left-3.5 xl:-left-[11%] z-20"
                src={successLeftImg}
                alt="success logo"
            />
            <img
                className="hidden lg:block absolute -top-2 lg:max-w-[80px] xl:max-w-full  lg:-right-3.5 xl:-right-[14%] z-10"
                src={successRightImg}
                alt="success logo"
            />
            <img
                className="lg:hidden w-10 lg:w-auto absolute top-2 -left-3.5 z-20"
                src={successLeftMobImg}
                alt="success logo"
            />
            <img
                className="lg:hidden w-10 lg:w-auto absolute top-2 -right-3.5 z-10"
                src={successRightMobImg}
                alt="success logo"
            />
        </React.Fragment>
    )
}

export default SuccessImages
