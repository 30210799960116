import moment from 'moment'
import Moment from 'moment-timezone'
const DAY_LABEL_FORMAT = 'MMMM Do'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'h:mm A'
export const MINUTES = 'minutes'
const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm:ss A'
const MONTH_DATE_TIME_FORMAT = 'MMM Do, h:mmA'
export const MONTH_DATE_FORMAT_WITH_TZ = 'MMM DD, h:mmA z'

export function getTzCurrentDateTime(timeZone) {
    return Moment().tz(timeZone)
}

function getDay({ day, timeZone }) {
    return getTzCurrentDateTime(timeZone).day(day)
}

export function getCustomDateFormat({
    dateTime,
    dateTimeFormat = `${DATE_FORMAT} ${TIME_FORMAT}`,
    format = MONTH_DATE_TIME_FORMAT,
}) {
    return moment(dateTime, dateTimeFormat).format(format)
}

export function isDaySameOrAfter({ day, timeZone, dateTime }) {
    return getDay({ day, timeZone }).isSameOrAfter(dateTime)
}

export function getTodayDate({ timeZone, format = DATE_FORMAT }) {
    return getTzCurrentDateTime(timeZone).format(format)
}

export function getFormatedDate({ day, timeZone }) {
    return getDay({ day, timeZone }).format(DATE_FORMAT)
}

export function getDayLabel({ day, timeZone }) {
    return getDay({ day, timeZone }).format(DAY_LABEL_FORMAT)
}

export function isSameOrBefore({ startTime, endTime, format = TIME_FORMAT }) {
    return moment(startTime, format).isSameOrBefore(moment(endTime, format))
}

export function isUTCSameOrBefore(dateTime) {
    return moment.utc().isSameOrBefore(dateTime)
}

export function addDateUtcTime({ utcTime, duration, format = MINUTES }) {
    return moment(utcTime).add(duration, format)
}

export function getUtcTime(time) {
    return moment.utc(moment(time))
}

export function getDifference({ fromDate, toDate, format = MINUTES }) {
    return moment(fromDate).diff(toDate, format)
}

export function getTzTime({ date, time, timeZone }) {
    return Moment.tz(`${date} ${time}`, timeZone)
}

export function getTzUtcTime({ dateTime, timeZone }) {
    return Moment.tz(dateTime, DATE_TIME_FORMAT, timeZone).utc()
}

export function getMomentUtc(dateTime) {
    return Moment.utc(dateTime)
}

export function guessUserTimezone() {
    return Moment.tz.guess()
}

export function getPstTime(date, format = 'ddd (MM/DD) hh:mm A z') {
    return Moment(date).tz('America/Los_Angeles').format(format)
}
