import React from 'react'
import { getCustomDateFormat } from '../../../../helper/dateFormatter'

type DateTimeButtonType = {
    btnText?: string
    dateTime?: string
} & React.ComponentProps<'button'>

const DateTimeButton = ({
    btnText,
    dateTime,
    className = '',
    disabled,
    ...rest
}: DateTimeButtonType) => {
    return (
        <button
            type="button"
            className={`text-center px-4 py-3 rounded-full text-sm font-normal border-2 border-gray-900 text-gray-800 leading-none ${
                disabled ? 'opacity-70' : ' hover:bg-gray-300'
            } ${className}`}
            disabled={disabled}
            {...rest}
        >
            {btnText
                ? btnText
                : getCustomDateFormat({
                      dateTime: dateTime,
                  })}
        </button>
    )
}

export default DateTimeButton
