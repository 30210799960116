import { Scope } from '@sentry/types'

interface ConfigValues {
    error?: object
    category?: string
    message?: string
    id?: string
    location?: string
    email?: string
    level?: any
}

export function sentryTrack({
    error,
    category,
    message,
    id,
    location,
    level,
    email,
}: ConfigValues) {
    const isProduction = process.env.REACT_APP_FOR_DEPLOY === 'true'
    if (isProduction) {
        window.Sentry?.configureScope((scope: Scope) => {
            scope.addBreadcrumb({
                category: category || undefined,
                message: message || undefined,
            })
            id && email && scope.setUser({ id, email })
            location && scope.setTag('location', location)
            level && scope.setLevel(level)
            error && window.Sentry.captureException(error)
        })
    }
}
