import Link from '@src/app/common/components/Link/Link'
import React from 'react'

interface InterstitialHeroProps {}

const InterstitialHero = ({}: InterstitialHeroProps) => {
    return (
        <div className="pb-10 pt-10 sm:pb-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-0">
                <div className="flex flex-col space-y-5 items-center">
                    <p className="max-w-5xl px-0.5 lg:px-0 mx-auto mt-1 text-5xl font-bold text-black sm:text-5xl lg:text-[108px] lg:leading-[104px] select-none text-center">
                        You’re on the waitlist!
                    </p>
                    <div className="max-w-2xl w-full px-6 py-5 rounded-2xl mt-5 mb-16 mx-auto font-normal text-lg sm:text-xl text-black bg-[#E9E9EB]">
                        BTW.... we’re looking for YouTubers to join Launchpad,
                        our first accelerator program for creators.
                    </div>
                    <div className="max-w-2xl w-full px-6 py-5 rounded-2xl mt-5 mb-16 mx-auto font-normal text-lg sm:text-xl text-black bg-[#E9E9EB]">
                        It’s a 6-week program designed to teach you how to
                        create content, think strategically about your brand,
                        and leverage advice from your fellow creators
                    </div>
                    <div className="max-w-2xl w-full px-6 py-5 rounded-2xl mt-5 mb-16 mx-auto font-normal text-lg sm:text-xl text-black bg-[#E9E9EB]">
                        Applications for Season 0 are open now.
                    </div>
                    <Link
                        to="/apply"
                        className="max-w-2xl w-full text-center bg-black text-white py-6 px-20 rounded-full"
                    >
                        Apply now
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default InterstitialHero
