import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import {
    AiFillInstagram,
    AiFillYoutube,
    AiOutlineTwitter,
} from 'react-icons/ai'
import { FaTiktok } from 'react-icons/fa'
import { URLS } from '@src/constants/urls'

const SocialLinks = [
    {
        url: URLS.YOUTUBE,
        label: 'Follow us on YouTube',
        className: 'bg-black',
        Icon: () => (
            <AiFillYoutube className="text-white text-3xl group-hover:scale-105 transition-all duration-150 ease-in-out" />
        ),
    },
    {
        url: URLS.INSTAGRAM,
        label: 'Follow us on Instagram',
        className: 'bg-brandGreen-500',
        Icon: () => (
            <AiFillInstagram className="text-black text-3xl group-hover:scale-105 transition-all duration-150 ease-in-out" />
        ),
    },
    {
        url: URLS.TIKTOK,
        label: 'Follow us on TikTok',
        className: 'bg-brandOrange-500',
        Icon: () => (
            <FaTiktok className="text-black text-2xl group-hover:scale-105 transition-all duration-150 ease-in-out" />
        ),
    },

    {
        url: URLS.TWITTER,
        label: 'Follow us on Twitter',
        className: 'bg-brandPink-500',
        Icon: () => (
            <AiOutlineTwitter className="text-black text-3xl group-hover:scale-105 transition-all duration-150 ease-in-out" />
        ),
    },
]

const NewFooter = () => {
    return (
        <div className="p-5 md:py-16 bg-white">
            <div className="py-2 space-y-4">
                <div className="flex flex-col-reverse sm:flex-row items-stretch gap-4">
                    <div className="flex flex-col md:flex-row items-start justify-between gap-6 p-6 md:p-8 border-2 border-black rounded-2xl flex-1">
                        <Link to="/">
                            <img
                                className="w-16"
                                src="/images/logo.png"
                                alt="logo"
                            />
                        </Link>

                        <div className="space-y-4 flex flex-col">
                            <Link
                                to={URLS.LAUNCHPAD}
                                className="w-fit text-base leading-4"
                            >
                                Creator Launchpad
                            </Link>
                            <Link
                                to={URLS.THESEARCH}
                                className="w-fit text-base leading-4"
                            >
                                #TheSearch
                            </Link>
                            <a
                                href={URLS.CAREERS}
                                target="_blank"
                                rel="noreferrer noopener"
                                className="w-fit text-base leading-4"
                            >
                                Careers
                            </a>
                            <a
                                href={URLS.BLOG}
                                target="_blank"
                                rel="noreferrer noopener"
                                className="w-fit text-base leading-4"
                            >
                                Blog
                            </a>
                        </div>
                    </div>
                    <div className=" p-6 md:p-8 border-2 border-black rounded-2xl space-y-4 flex-1 sm:max-w-sm">
                        <p className="text-xl leading-6 font-bold -tracking-tight text-center">
                            Check out <br /> our latest updates
                        </p>
                        <div className="flex items-start justify-center gap-6 md:gap-4">
                            {SocialLinks.map(
                                ({ url, label, className, Icon }: any) => (
                                    <a
                                        className={clsx(
                                            'w-14 h-14 flex-shrink-0 grid place-content-center rounded-full group',
                                            className,
                                        )}
                                        href={url}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        aria-label={label}
                                        key={label}
                                    >
                                        <Icon />
                                    </a>
                                ),
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col-reverse md:flex-row md:items-center justify-between gap-4 p-6 md:py-6 md:px-8 border-2 border-black rounded-2xl">
                    <p className="text-base leading-4">
                        © 2022 CreatorDAO. All rights reserved.
                    </p>
                    <div className="flex flex-col md:flex-row md:items-center gap-4 md:gap-8">
                        <Link className="text-base leading-4" to="/terms">
                            Terms of services
                        </Link>

                        <Link className="text-base leading-4" to="/privacy">
                            Privacy policy
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewFooter
