import Link from '@src/app/common/components/Link/Link'
import React from 'react'
import arrowGreen from '../../../../assets/images/launchpad/arrow-green.png'
import arrowOrange from '../../../../assets/images/launchpad/arrow-orange.png'
import arrowPink from '../../../../assets/images/launchpad/arrow-pink.png'

const keys = [
    {
        arrowImage: arrowOrange,
        title: 'Weekly lectures and Q&A with some of the world’s top YouTubers.',
    },
    {
        arrowImage: arrowGreen,
        title: 'Create 5 videos in 6 weeks and have the chance to have them evaluated.',
    },
    {
        arrowImage: arrowPink,
        title: 'Optional weekly office hours to get personalized feedback with the CreatorDAO team.',
    },
    {
        arrowImage: arrowGreen,
        title: 'Access to a community of YouTubers all invested in each other’s success.',
    },
    {
        arrowImage: arrowOrange,
        title: 'Potential to pitch collaboration ideas to top creators!',
    },
]

interface KeyExperienceProps {}

const KeyExperience = ({}: KeyExperienceProps) => {
    return (
        <div className="py-[100px]">
            <div className="max-w-7xl mx-auto px-4">
                <div className="text-center">
                    <h1 className="text-center text-[70px] leading-[70px] mb-[50px] font-bold">
                        What You’ll Experience
                    </h1>
                    <div className="justify-center">
                        <div className="px-4 lg:px-24">
                            {keys.map((key, index) => (
                                <div key={index} className="flex mb-[50px]">
                                    <div className="mr-5 max-w-[35px] md:max-w-[60px] w-full">
                                        <img src={key.arrowImage} />
                                    </div>
                                    <div>
                                        <h2 className="text-[30px] mb-0 leading-[40px] text-left">
                                            {key.title}
                                        </h2>
                                    </div>
                                </div>
                            ))}
                            <div className="mt-5">
                                <Link
                                    to="/apply"
                                    className="py-[20px] px-[40px] lg:py-[30px] lg:px-[50px] text-white text-[16px] ml-3 rounded-[100px] bg-black hover:bg-brandPink-500 focus:bg-brandGreen-500 inline-block border-2 border-transparent mb-[20px] lg:mb-0"
                                >
                                    Apply Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KeyExperience
