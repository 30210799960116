import React from 'react'
import { useLocation } from 'react-router-dom'
import { REFERRAL_CODE_KEY } from '@src/features/idols/pages/application/UserApplication'

export function useReferralCode() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const referralCode =
        params.get('code') || localStorage.getItem(REFERRAL_CODE_KEY)

    React.useEffect(() => {
        if (referralCode) {
            localStorage.setItem(REFERRAL_CODE_KEY, referralCode)
        }
    }, [referralCode])
    return { referralCode }
}
