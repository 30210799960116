import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    Youtube_Video,
    IRetentionTool,
    IRetentionGraphData,
    IAnalyticsVideoData,
    IVideo,
} from '../../interfaces/analytics.interface'

const initialState: IRetentionTool = {
    analyticsVideos: {
        video1: undefined,
        video2: undefined,
    },
    controls: {
        maxVideoDuration: 0,
        isTimePercentage: false,
        selectedVideoIdx: 0,
        video1Ready: false,
        video1FirstLoad: false,
        video1IsPlaying: false,
        video1CurrentTime: 0,
        video1CurrentTimePercentage: 0,
        video1TimeUpdate: false,
        video2Ready: false,
        video2FirstLoad: false,
        video2IsPlaying: false,
        video2CurrentTime: 0,
        video2CurrentTimePercentage: 0,
        video2TimeUpdate: false,
    },
    retentionGraph: [],
}

export const retentionToolSlice = createSlice({
    name: 'retentiontool',
    initialState,
    reducers: {
        addVideoToAnalytics: (
            state,
            action: PayloadAction<{ idx: 1 | 2; videoData: Youtube_Video }>,
        ) => {
            const { idx, videoData } = action.payload
            state.analyticsVideos[`video${idx}`] = videoData as any
        },
        removeVideoFromAnalytics: (
            state,
            action: PayloadAction<{ videoIdx: 1 | 2 }>,
        ) => {
            const { videoIdx } = action.payload
            state.analyticsVideos[`video${videoIdx}`] = undefined
        },
        removeAllVideosFromAnalytics: state => {
            state.analyticsVideos.video1 = undefined
            state.analyticsVideos.video2 = undefined
        },
        setRetentionGraph: (
            state,
            action: PayloadAction<IRetentionGraphData[]>,
        ) => {
            state.retentionGraph = action.payload
        },
        clearRetentionGraph: state => {
            state.retentionGraph = []
        },
        updateVideoPlayingState: (
            state,
            action: PayloadAction<{ videoIdx: 1 | 2; isPlaying: boolean }>,
        ) => {
            state.controls[`video${action.payload.videoIdx}IsPlaying`] =
                action.payload.isPlaying
        },
        updateAllVideoPlayingState: (state, action: PayloadAction<boolean>) => {
            state.controls.video1IsPlaying = action.payload
            state.controls.video2IsPlaying = action.payload
        },
        updateVideoProgress: (
            state,
            action: PayloadAction<{
                videoIdx: 1 | 2
                currentTime: number
                currentTimePercentage: number
                isTimeUpdate: boolean
            }>,
        ) => {
            state.controls[`video${action.payload.videoIdx}CurrentTime`] =
                action.payload.currentTime
            state.controls[
                `video${action.payload.videoIdx}CurrentTimePercentage`
            ] = action.payload.currentTimePercentage
            state.controls[`video${action.payload.videoIdx}TimeUpdate`] =
                action.payload.isTimeUpdate
            state.controls[`video${action.payload.videoIdx}FirstLoad`] = false
        },
        updateVideoCurrentTime: (
            state,
            action: PayloadAction<{
                videoIdx: 1 | 2
                currentTime: number
                currentTimePercentage: number
                isTimeUpdate: boolean
            }>,
        ) => {
            state.controls[`video${action.payload.videoIdx}CurrentTime`] =
                action.payload.currentTime
            state.controls[
                `video${action.payload.videoIdx}CurrentTimePercentage`
            ] = action.payload.currentTimePercentage
            state.controls[`video${action.payload.videoIdx}TimeUpdate`] =
                action.payload.isTimeUpdate
        },
        updateVideoOnReady: (
            state,
            action: PayloadAction<{
                videoIdx: 1 | 2
                isReady: boolean
                isFirstLoad: boolean
            }>,
        ) => {
            state.controls[`video${action.payload.videoIdx}Ready`] =
                action.payload.isReady
            state.controls[`video${action.payload.videoIdx}FirstLoad`] =
                action.payload.isFirstLoad
        },
        updateMaxVideoDuration: (state, action: PayloadAction<number>) => {
            state.controls.maxVideoDuration = action.payload
        },
        updateIsTimePercentage: (state, action: PayloadAction<boolean>) => {
            state.controls.isTimePercentage = action.payload
        },
        updateVideoTimeUpdateState: (
            state,
            action: PayloadAction<{ videoIdx: 1 | 2; isTimeUpdate: boolean }>,
        ) => {
            state.controls[`video${action.payload.videoIdx}TimeUpdate`] =
                action.payload.isTimeUpdate
        },
        updateAllVideoTimeUpdateState: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.controls.video1TimeUpdate = action.payload
            state.controls.video2TimeUpdate = action.payload
        },
        resetControls: state => {
            state.controls.maxVideoDuration = 0
            state.controls.isTimePercentage = false
            state.controls.selectedVideoIdx = 0
            state.controls.video1Ready = false
            state.controls.video2Ready = false
            state.controls.video1FirstLoad = false
            state.controls.video2FirstLoad = false
            state.controls.video1IsPlaying = false
            state.controls.video2IsPlaying = false
            state.controls.video1CurrentTime = 0
            state.controls.video2CurrentTime = 0
            state.controls.video1CurrentTimePercentage = 0
            state.controls.video2CurrentTimePercentage = 0
            state.controls.video1TimeUpdate = false
            state.controls.video2TimeUpdate = false
        },
        updateSelectedVideo: (
            state,
            action: PayloadAction<{ videoIdx: number }>,
        ) => {
            state.controls.selectedVideoIdx = action.payload.videoIdx
        },
    },
})

export const {
    addVideoToAnalytics,
    removeVideoFromAnalytics,
    removeAllVideosFromAnalytics,
    setRetentionGraph,
    clearRetentionGraph,
    updateVideoPlayingState,
    updateAllVideoPlayingState,
    updateVideoProgress,
    updateVideoCurrentTime,
    updateVideoOnReady,
    updateMaxVideoDuration,
    updateIsTimePercentage,
    updateVideoTimeUpdateState,
    updateAllVideoTimeUpdateState,
    resetControls,
    updateSelectedVideo,
} = retentionToolSlice.actions

export default retentionToolSlice.reducer
