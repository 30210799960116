import React from 'react'
import clsx from 'clsx'

import { getDifference, getPstTime } from '@src/helper/dateFormatter'
import { useCountdown } from './hooks/useCountdown.hooks'

interface CountdownProps {
    startDate: Date
    endDate: Date
    name: string
    currentTime: any
}

const Countdown: React.FC<CountdownProps> = ({
    startDate,
    endDate,
    name,
    currentTime,
}) => {
    const daysUntilStartDate = getDifference({
        fromDate: startDate,
        toDate: currentTime,
        format: 'DAYS',
    })

    const daysUntilEndDate = getDifference({
        fromDate: endDate,
        toDate: currentTime,
        format: 'DAYS',
    })

    const isVotingOpen =
        currentTime.isAfter(startDate) && currentTime.isSameOrBefore(endDate)
    const isVotingEnd = currentTime.isAfter(endDate)

    const { timeString } = useCountdown({
        targetDate: !isVotingOpen ? startDate : endDate,
    })

    return (
        <React.Fragment>
            <p
                className={clsx(
                    isVotingEnd ? 'text-idolGray-400' : 'text-idolRed-700',
                    'uppercase',
                )}
            >
                {name}
                {isVotingEnd
                    ? ` ENDED ON ${getPstTime(endDate, 'MMM Do, YYYY')}`
                    : isVotingOpen
                    ? ` ENDS ${daysUntilEndDate > 0 ? 'ON' : 'IN'}`
                    : ` STARTS ${daysUntilStartDate > 0 ? 'ON' : 'IN'}`}
            </p>

            {!isVotingEnd && (
                <p className="uppercase">
                    {isVotingOpen
                        ? daysUntilEndDate > 0
                            ? getPstTime(endDate)
                            : timeString
                        : daysUntilStartDate > 0
                        ? getPstTime(startDate)
                        : timeString}
                </p>
            )}
        </React.Fragment>
    )
}

export default Countdown
