import { FieldArray, useField } from 'formik'
import React, { useEffect, useState } from 'react'

interface OriginalStateProps {
    hours: {
        starts: string
        ends: string
    }[]
    enabled: boolean
    day: string
}
interface OfficeHoursInterface {
    label: string

    // Rest of the props that are passed down
    [x: string]: any
}
export default function OfficeHours({
    label,

    ...props
}: OfficeHoursInterface) {
    const [field, meta, helpers] = useField(props as any)
    const [originalState, setOriginalState] = useState<OriginalStateProps[]>([])

    const handleChange = (newValue: {
        session_duration: string
        schedule: any
    }) => {
        helpers.setValue(newValue, true)
    }

    const basedHours = [
        {
            starts: '09:00',
            ends: '18:00',
        },
    ]
    useEffect(() => {
        setOriginalState(field?.value?.schedule)
    }, [])

    useEffect(() => {
        const officeHoursObj = {
            session_duration: field?.value?.session_duration,
            schedule: originalState,
        }
        handleChange(officeHoursObj)
    }, [originalState])

    const handleOnTimeChange = ({
        index,
        hourIndex,
        event,
        isStartTime,
    }: {
        index: number
        hourIndex: number
        event: any
        isStartTime: boolean
    }) => {
        setOriginalState(prevState =>
            prevState.map((obj, idx) => {
                if (idx === index) {
                    const newHours = isStartTime
                        ? {
                              starts: event.target.value,
                              ends: obj.hours[hourIndex].ends,
                          }
                        : {
                              starts: obj.hours[hourIndex].starts,
                              ends: event.target.value,
                          }

                    return {
                        ...obj,
                        hours: [newHours],
                    }
                }
                return obj
            }),
        )
    }

    return (
        <div key={`${field.name}`}>
            <label className="appearance-none block text-base font-medium text-gray-700 select-none mb-2">
                {label}
            </label>

            <FieldArray
                name={`${field.name}`}
                render={() => (
                    <div className="grid grid-cols-1 sm:grid-cols-12 gap-x-1 gap-y-3">
                        {originalState.map((office_hour, index) => {
                            const hours = office_hour?.hours
                            const dayEnabled = office_hour.enabled
                            const key = index + 'office_hour'
                            return (
                                <React.Fragment key={field.name + key}>
                                    <div
                                        key={key}
                                        data-key={key}
                                        className="col-span-4"
                                    >
                                        <input
                                            type="checkbox"
                                            name={office_hour.day}
                                            value={office_hour.day}
                                            checked={dayEnabled}
                                            onChange={() => {
                                                setOriginalState(prevState => {
                                                    const newState =
                                                        prevState.map(
                                                            (obj, idx) => {
                                                                if (
                                                                    idx ===
                                                                    index
                                                                ) {
                                                                    if (
                                                                        dayEnabled
                                                                    ) {
                                                                        return {
                                                                            ...obj,
                                                                            enabled:
                                                                                !dayEnabled,
                                                                            hours: basedHours,
                                                                        }
                                                                    }
                                                                    return {
                                                                        ...obj,
                                                                        enabled:
                                                                            !dayEnabled,
                                                                    }
                                                                }
                                                                return obj
                                                            },
                                                        )
                                                    return newState
                                                })
                                            }}
                                            className="inline-flex w-4 h-4 border border-[#D1D5DB] text-[#111827] sm:text-sm focus:ring-0 cursor-pointer"
                                        />
                                        <label
                                            htmlFor={`${office_hour.day}`}
                                            className="appearance-none text-[#111827] inline-flex px-3 py-3 sm:text-sm disabled:text-gray-200"
                                        >
                                            {office_hour.day}
                                        </label>
                                    </div>
                                    {hours.map((hour, hourIndex) => {
                                        const key = index + hourIndex + 'hour'
                                        return (
                                            <div
                                                key={key}
                                                data-key={key}
                                                className="col-span-8"
                                            >
                                                <div className="grid grid-cols-2 lg:grid-cols-6 gap-x-1 gap-y-3">
                                                    <div className="col-span-12 sm:col-span-8 lg:col-span-4 flex justify-start lg:justify-end py-2">
                                                        <input
                                                            disabled={
                                                                !dayEnabled
                                                            }
                                                            type="time"
                                                            className="px-2 py-1 flex w-full max-w-[100px] border border-[#D1D5DB] sm:text-sm focus:outline-none disabled:bg-white disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none cursor-pointer disabled:cursor-default"
                                                            value={hour.starts}
                                                            onChange={event =>
                                                                handleOnTimeChange(
                                                                    {
                                                                        index,
                                                                        hourIndex,
                                                                        event,
                                                                        isStartTime:
                                                                            true,
                                                                    },
                                                                )
                                                            }
                                                        />{' '}
                                                        <span className="px-2 py-1">
                                                            -
                                                        </span>
                                                        <input
                                                            type="time"
                                                            disabled={
                                                                !dayEnabled
                                                            }
                                                            className="px-2 py-1 flex w-full max-w-[100px] border border-[#D1D5DB]  sm:text-sm focus:outline-none disabled:bg-white disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none cursor-pointer disabled:cursor-default"
                                                            value={hour.ends}
                                                            onChange={event =>
                                                                handleOnTimeChange(
                                                                    {
                                                                        index,
                                                                        hourIndex,
                                                                        event,
                                                                        isStartTime:
                                                                            false,
                                                                    },
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                    </div>
                )}
            />

            {meta.touched && meta.value != meta.initialValue && meta.error ? (
                <div className="text-sm text-red-400 mt-1.5">{meta.error}</div>
            ) : null}
        </div>
    )
}
