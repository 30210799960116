import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()

export const getYoutubeAuthUrl = async () => {
    const result = await httpsCallable(
        functions,
        'authenticate',
    )({ redirect: window.location.href })

    try {
        const data = result.data as any
        return data.url
    } catch (error) {
        return ''
    }
}

export const refreshYoutubeChannel = async (
    operation: 'admin' | 'user' | 'channel',
    uid?: string,
    youtubeId?: string,
) => {
    const params = {
        operation,
        forUid: uid || '',
        forYoutubeId: youtubeId || '',
    }
    httpsCallable(functions, 'refreshChannel')(params)
}

export const refreshAsyncYoutubeChannel = async (
    operation: 'user' | 'channel',
    uid: string,
    youtubeId?: string,
) => {
    const params = {
        operation,
        forUid: uid,
        forYoutubeId: youtubeId || '',
    }
    await httpsCallable(
        functions,
        'refreshChannel',
    )(params).catch(error => {
        // Do something to make user relogin
        console.log('Error on refreshAsyncYoutubeChannel', error)
    })
}

export const revokeYoutubeChannel = async (uid: string, youtubeId: string) => {
    const params = { forUid: uid, forYoutubeId: youtubeId }
    await httpsCallable(
        functions,
        'revokeChannel',
    )(params).catch(error => {
        // Do something to make user relogin
        console.log('Error on refreshAsyncYoutubeChannel', error)
    })
}
