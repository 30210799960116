import * as React from 'react'
import Spinner from '../../../../app/layout/Spinner'

export const ButtonRoundedFull = ({
    disabled = false,
    isSubmitting,
    btnText = 'Save',
    className = '',
    type = 'submit',
    customWidth = false,
    ...rest
}: any) => {
    return (
        <button
            disabled={disabled}
            type={type}
            className={`${
                !customWidth ? 'w-28' : ''
            } bg-white border border-[#111827] rounded-full shadow-sm py-2 px-4 inline-flex
                        disabled:text-[#979797] disabled:bg-transparent disabled:border-[#979797]
                        justify-center text-sm font-medium text-black hover:bg-gray-100 ${className}`}
            {...rest}
        >
            {isSubmitting ? <Spinner /> : btnText}
        </button>
    )
}

export const CommonButton = ({
    disabled = false,
    isSubmitting,
    btnText = 'Save',
    ...rest
}: any) => {
    return (
        <button
            disabled={disabled}
            type="submit"
            className="w-24 bg-brand-600 border border-transparent shadow-sm py-2 px-4 inline-flex
                    disabled:text-gray-300 disabled:bg-gray-100
                    justify-center text-sm font-medium text-white hover:bg-brand-700 focus:outline-none
                    focus:ring-0 focus:ring-offset-0 rounded-md"
            {...rest}
        >
            {isSubmitting ? <Spinner /> : btnText}
        </button>
    )
}

export const NavyButton = ({
    disabled = false,
    isSubmitting = false,
    btnText = 'Save',
    className = '',
    ...rest
}) => {
    return (
        <button
            disabled={disabled}
            type="submit"
            className={`w-full bg-gray-800 border border-gray-800 rounded-md py-3 px-4 grid place-content-center text-sm font-medium text-white  hover:bg-gray-900 disabled:bg-gray-600 disabled:border-gray-600 ${className}`}
            {...rest}
        >
            {isSubmitting ? <Spinner /> : btnText}
        </button>
    )
}
