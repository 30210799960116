export const URLS = {
    ROOT: '/',
    APPLY: '/apply',
    APPLICATION: '/application',
    INTERSTITIAL: '/interstitial',
    LAUNCHPAD: '/launchpad',
    LAUNCHPAD_APPLICATION: '/launchpad/application',
    PRIVACY: '/privacy',
    TERMS: '/terms',
    OFFER: '/offer',
    PROSPECTIVE_MEMBER_SUBMITTED: '/prospectiveMemberSubmitted',
    LOGIN: '/login',
    SIGN_UP: '/signup',
    VERIFY_EMAIL: '/verify-email',
    FORGOT_PASSWORD: '/forgot-password',
    PHONE_LOGIN: '/phone-login',
    PHONE_SIGN_UP: '/phone-signup',
    PROSPECTIVE_MEMBER: '/prospective-member',
    SEASON: '/season',
    FAN_PROFILE: '/fan-profile',
    GROUPS: '/groups',
    GROUP: '/group',
    LEADERBOARD: '/leaderboard',
    MEMBER: '/member',
    HOME: '/profile',
    MEMBERS: '/members',
    COLLABS: '/collabs',
    OFFICE_HOURS: '/office-hours',
    PROFILE: '/profile',
    SETTINGS: '/settings',
    ACCOUNT: '/account',
    ADMIN: '/admin',
    SUBMIT: '/submit',
    DEALS: '/deals',
    EDIT: '/edit',
    CREATE_JOB: '/createJob',
    JOBS: '/jobs',
    SERVICES: '/services',
    ANALYTICS: '/analytics',
    POSTS: '/posts',
    COMMENTS: '/comments',
    COMMENT: '/comment',
    RETENTION_TOOL: '/retention-tool',
    SEARCH: '/search',
    GROUPS_HOME: '/home',
    THESEARCH: '/thesearch',
    THESEARCH_APPLICATION: '/thesearch/application',
    THESEARCH_PROFILE: '/thesearch/profile',
    THESEARCH_GROUPS: '/thesearch/groups',
    THESEARCH_LEADERBOARD: '/thesearch/leaderboard',
    THESEARCH_GROUPS_HOME: '/thesearch/home',
    THESEARCH_GROUP: '/thesearch/group',
    THESEARCH_SETTINGS: '/thesearch/settings',
    BLOG: 'https://creatordao.com/blog/',
    ABOUT: '', // Todo: About page url required
    CAREERS: 'https://boards.greenhouse.io/creatordao',
    TWITTER: 'https://twitter.com/joincreatordao',
    TIKTOK: 'https://www.tiktok.com/@creatordao',
    INSTAGRAM: 'https://www.instagram.com/joincreatordao/',
    YOUTUBE: 'https://www.youtube.com/@creatordao',
}
