import React from 'react'
import moment from 'moment'
import clsx from 'clsx'

import { useCompetition } from '@src/features/idols/pages/admin/useCompetition'
import Countdown from './components/Countdown'
import { CURRENT_PHASE } from '@src/features/idols/hooks/useGetCurrentPhase'

export type PhaseType =
    | {
          applications_open: boolean
          end_date: string
          is_voting_phase: boolean
          name: string
          phase_id: string
          start_date: string
      }
    | undefined
    | null

interface CountdownTimerProps {
    inline?: boolean
    showCountDownTime?: boolean
    phase?: PhaseType
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
    inline = false,
    showCountDownTime = false,
    phase,
}) => {
    const {
        competitionTime,
        currentRound: currentLiveRound,
        loading,
    } = useCompetition()

    const round = phase ?? currentLiveRound

    if (!showCountDownTime || loading) return null

    if (round && !round?.is_voting_phase) return null

    const currentTime = moment(competitionTime)
    const isVotingEnd = currentTime.isAfter(round?.end_date)

    const roundName =
        CURRENT_PHASE.FINALS === round?.phase_id ? 'Voting' : round?.name

    return (
        <div
            className={clsx(
                inline ? 'w-fit mx-auto rounded-full' : '',
                isVotingEnd
                    ? 'bg-idolCream-100'
                    : 'bg-brandPink-500 bg-opacity-[16%]',
                'font-bold text-base text-black text-center px-4',
                'pb-2 pt-[11px] sm:flex flex-wrap items-center justify-center sm:gap-2',
            )}
        >
            <Countdown
                endDate={round?.end_date}
                startDate={round?.start_date}
                name={inline ? '' : roundName}
                currentTime={currentTime}
            />
        </div>
    )
}

export default CountdownTimer
