import { ref } from 'firebase/storage'
import * as React from 'react'
import { sentryTrack } from '../../../utils/sentry-track'
import storage from '../../config/firebase'

export function abbreviateNumber(value: any) {
    let newValue = value
    if (value >= 1000) {
        const suffixes = ['', 'k', 'm', 'b', 't']
        const suffixNum = Math.floor(('' + value).length / 3)
        let shortValue = ''
        for (let precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat(
                (suffixNum != 0
                    ? value / Math.pow(1000, suffixNum)
                    : value
                ).toPrecision(precision),
            ).toFixed()
            const dotLessShortValue = (shortValue + '').replace(
                /[^a-zA-Z 0-9]+/g,
                '',
            )
            if (dotLessShortValue.length <= 2) {
                break
            }
        }
        if (parseInt(shortValue) % 1 != 0)
            shortValue = parseInt(shortValue).toFixed(1)
        newValue = shortValue + suffixes[suffixNum]
    }
    return newValue
}

export function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export const useSessionStorage = (storageKey: string, fallbackState: any) => {
    const [value, setValue] = React.useState(
        JSON.parse(sessionStorage.getItem(storageKey) || '') ?? fallbackState,
    )

    React.useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(value))
    }, [value, storageKey])

    return [value, setValue]
}

export function getFirebaseErrorString(error: any, location?: any) {
    const sentryLog: any = { error }
    if (location) sentryLog.location = location

    sentryTrack(sentryLog)

    switch (error.code) {
        case 'auth/email-already-in-use':
            return 'Email already in use.'

        case 'auth/requires-recent-login':
            return 'Please log in again before retrying this request.'

        case 'auth/weak-password':
            return 'Password should be at least 6 characters.'

        case 'auth/invalid-verification-code':
            return 'Verification code is invalid.'

        case 'auth/account-exists-with-different-credential':
            return 'An account already exists with the same email address but different sign-in credentials.'

        case 'auth/invalid-phone-number':
            return 'This phone number is invalid.'

        case 'auth/phone-number-already-exists':
            return 'Phone Number is already in use'

        case 'auth/captcha-check-failed':
            return 'Captcha verification failed, try again!'

        case 'auth/too-many-requests':
            return 'We have blocked all requests from this device due to unusual activity. Try again later!'
    }

    // This parsing is sadly necessary because Firebase sends bad
    // errors down to the frontend, when you use the throw Error
    // in blocking functions that is recommended in the thread.
    // Specifically the problem is that the error that gets
    // sent down to the front end is an internal-error with
    // the actual error encoded in the message. Problematically that
    // error is not even cleanly stringified JSON.
    if (error?.code == 'auth/internal-error') {
        if (typeof error?.message === 'string') {
            if (error?.message.includes('PERMISSION_DENIED')) {
                return 'Email is not whitelisted.'
            } else if (error?.message.includes('ALREADY_EXISTS')) {
                return error?.message.includes('phone')
                    ? 'This phone number is already associated with an account.'
                    : 'Email is already associated with an account.'
            }
        }
    }

    return 'Error with authentication service.'
}

export function getURLForDisplay(url: any) {
    const withoutHttp = url.replace(/^https?:\/\//, '')
    if (withoutHttp.startsWith('www.')) {
        return withoutHttp.substring(4)
    } else {
        return withoutHttp
    }
}

export function isImage(url: string) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
}

export function getStorageURLForFile(file: any) {
    return (
        'https://storage.googleapis.com' +
        `/${file.storage_bucket}/${file.storage_directory_path}/${file.name}`.replace(
            '//',
            '/',
        )
    )
}

export function getResizedStorageURLForImageFile(file: any, large: boolean) {
    const size = large ? 800 : 200
    return (
        'https://storage.googleapis.com' +
        `/${file.storage_bucket}/${file.storage_directory_path}/${file.id}_${size}x${size}.jpeg`.replace(
            '//',
            '/',
        )
    )
}

// Gets the file and directory name of an image from a firebase storage URL.
export const getFileAndDirFromURL = (
    fileURL: string,
): { dir: string; file: string } => {
    const fSlashes = fileURL.split('/')
    const fQuery = fSlashes[fSlashes.length - 1].split('?')
    const segments = fQuery[0].split('%2F')
    const dir = segments[0]
    let file = segments[1]
    if (file) {
        file = file.substring(0, file.lastIndexOf('.'))
    }
    return { dir, file }
}

export function getResizedStorageURLFromFirebaseImageURL(
    photo_url?: string | null,
    large?: boolean,
): string {
    if (!photo_url) {
        return '/images/user.png'
    }

    // Don't try to resize images that are Google hosted
    // profile images or are already resized
    // for .NEF File link is not working so return original url
    if (
        photo_url.includes('googleuser') ||
        photo_url.includes('00x') ||
        photo_url.toLowerCase().includes('.nef')
    ) {
        return photo_url
    }

    try {
        const storageRef = ref(storage, photo_url || '')

        return getResizedStorageURLForImageFile(
            {
                storage_bucket: storageRef.bucket,
                storage_directory_path: getFileAndDirFromURL(photo_url || '')
                    .dir,
                id: getFileAndDirFromURL(photo_url || '').file,
            },
            large || false,
        )
    } catch {
        console.error('Invalid url: ', photo_url)
    }
    return photo_url
}

export function convertStringsToOptionsForSelectInput(strings: any) {
    return strings.map((string: string) => ({
        value: string,
        label: string,
    }))
}

export function setRedirectPath(path: string) {
    localStorage.setItem('redirectPath', path)
}

export function getDisplayName() {
    const display_name = localStorage.getItem('display_name')
    return display_name ?? ''
}

export function setDisplayName(name: string | null) {
    if (name == null) {
        localStorage.removeItem('display_name')
    } else {
        localStorage.setItem('display_name', name)
    }
}

export function getFanRedirectFrom() {
    const display_name = sessionStorage.getItem('redirectFrom')
    return display_name ?? ''
}

export function setFanRedirectFrom(name: string | null) {
    if (name == null) {
        sessionStorage.removeItem('redirectFrom')
    } else {
        sessionStorage.setItem('redirectFrom', name)
    }
}

export const getAuthSubTitle = () => {
    const redirectedFrom = getFanRedirectFrom()

    switch (redirectedFrom) {
        case 'join':
            return 'to join'

        case 'vote':
            return 'to vote'

        default:
            return ''
    }
}

export function isStillUploading(str: string, prefix = '_progress:'): boolean {
    return str?.substring(0, prefix.length) == prefix
}

export const hasArrayChanged = (
    a: Array<unknown> = [],
    b: Array<unknown> = [],
) =>
    a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]))

export const percentageDifference = (a: number, b: number) => {
    return (((a - b) / ((a + b) / 2)) * 100 || 0).toFixed(2)
}
