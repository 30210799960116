const getSortedMemberListByUserId = (
    membersList: Array<object>,
    winningUserId: string,
) => {
    const winnerUserFromList = membersList?.find(
        (memberships: any) => memberships?.user_id == winningUserId,
    )

    if (winnerUserFromList) {
        const filterOtherUserFromList = membersList?.filter(
            (memberships: any) => memberships?.user_id != winningUserId,
        )

        return [winnerUserFromList, ...filterOtherUserFromList]
    }

    return membersList
}

export default getSortedMemberListByUserId
