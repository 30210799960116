import React, { useEffect, useState } from 'react'
import { AiOutlineTwitter } from 'react-icons/ai'
import { useLocation } from 'react-router-dom'
import logo from '@src/assets/images/logo.png'
import { useHandleSignOut } from '@src/features/dashboard/alternative/UserMenu'
import { removeTrailingSlashes } from '@src/helper/removeTrailingSlashes'
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa'
import Link from '@src/app/common/components/Link/Link'

interface HeaderProps {}

const Header = ({}: HeaderProps) => {
    const location = useLocation()
    const [isShow, setIsShow] = useState<boolean>(false)
    const isHomepage = location.pathname === '/'

    const handleSignOut = useHandleSignOut()

    const scrollNavigation = () => {
        const doc = document.documentElement
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
        if (top > 700) {
            setIsShow(true)
        } else {
            setIsShow(false)
        }
    }

    useEffect(() => {
        function watchScroll() {
            window.addEventListener('scroll', scrollNavigation)
        }
        if (isHomepage) {
            watchScroll()
            return () => {
                window.removeEventListener('scroll', scrollNavigation)
            }
        }
    })

    return (
        <div
            className={`sticky ${
                isShow && isHomepage ? 'bg-[#F6F9F0]' : 'bg-transparent'
            } top-0 w-full h-16 z-50 transition-all`}
        >
            <div
                className={`${
                    (isShow || !isHomepage) && 'bg-[#F6F9F0]'
                } grid grid-cols-3 relative z-30 w-full mx-auto items-center px-4 lg:px-[64px] py-5 transition-all`}
            >
                <div className="inline-flex gap-x-2 sm:gap-x-4 items-center">
                    {/* This pathname check is here because this can be rendered
                    with FinishOnboarding component when the user
                    has finished onboarding and is logged but cannot do anything else.
                    If they click on login in that case, they go nowhere. */}
                    {isHomepage && (
                        <>
                            <span className="inline text-sm select-none">
                                <Link to="/login">Login</Link>
                            </span>
                            <span className="inline text-sm select-none">
                                ·
                            </span>
                        </>
                    )}
                    {/* If we're home and this is showing, then the user
                     // is blocked from portal access and should be able
                     // to log out. */}
                    {removeTrailingSlashes(location.pathname) ==
                        '/prospectiveMemberSubmitted' && (
                        <>
                            <span
                                onClick={handleSignOut}
                                className="cursor-pointer inline text-sm select-none"
                            >
                                Log out
                            </span>
                            <span className="inline text-sm select-none">
                                ·
                            </span>
                        </>
                    )}
                    <span className="hidden lg:inline text-sm select-none">
                        <a
                            rel="noreferrer noopener"
                            href={'https://creatordao.com/blog'}
                        >
                            Blog
                        </a>
                    </span>
                    <span className="hidden lg:inline text-sm select-none">
                        ·
                    </span>
                    <span className="hidden lg:inline text-sm select-none">
                        <a
                            rel="noreferrer noopener"
                            target="_blank"
                            href={'https://boards.greenhouse.io/creatordao'}
                        >
                            Jobs
                        </a>
                    </span>
                    <span className="hidden lg:inline text-sm select-none">
                        ·
                    </span>
                    <a
                        rel="noreferrer noopener"
                        target="_blank"
                        href={'https://twitter.com/joincreatordao'}
                        aria-label="Follow us on twitter"
                    >
                        <AiOutlineTwitter className="w-6 h-6" />
                    </a>
                    <span className="hidden lg:inline text-sm select-none">
                        ·
                    </span>
                    <a
                        rel="noreferrer noopener"
                        target="_blank"
                        href={'https://www.tiktok.com/@creatordao'}
                        aria-label="Follow us on twitter"
                    >
                        <FaTiktok className="w-6 h-6" />
                    </a>
                    <span className="hidden lg:inline text-sm select-none">
                        ·
                    </span>
                    <a
                        rel="noreferrer noopener"
                        target="_blank"
                        href={'https://www.instagram.com/joincreatordao/'}
                        aria-label="Follow us on twitter"
                    >
                        <FaInstagram className="w-6 h-6" />
                    </a>
                    <span className="hidden lg:inline text-sm select-none">
                        ·
                    </span>
                    <a
                        rel="noreferrer noopener"
                        target="_blank"
                        href={'https://www.youtube.com/@creatordao'}
                        aria-label="Follow us on twitter"
                    >
                        <FaYoutube className="w-6 h-6" />
                    </a>
                </div>
                {(isShow || !isHomepage) && (
                    <>
                        <div className="flex justify-center">
                            <Link to="/">
                                <img src={logo} className="h-6" />
                            </Link>
                        </div>
                        <div className="flex justify-end"></div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Header
