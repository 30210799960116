export function numberWithCommas(number) {
    return number
        ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : '0'
}

export function numberWithNotations(number, isSpaceRequired = false) {
    if (isNaN(number)) return '0'
    let formatter = Intl.NumberFormat('en', { notation: 'compact' })

    const formattedString = formatter.format(number)

    if (!isSpaceRequired) return formattedString

    try {
        const symbols = ['K', 'M', 'B', 'T', 'Q', 'S', 'O']

        const filteredMatchedSymbol = symbols.find(symbol =>
            formattedString.includes(symbol),
        )

        if (!filteredMatchedSymbol) return formattedString

        const numberStringWithoutSymbol = formattedString
            ?.split(filteredMatchedSymbol)
            ?.join('')

        return `${numberStringWithoutSymbol} ${filteredMatchedSymbol}`
    } catch (_) {
        return formattedString
    }
}
