import React from 'react'
import {
    IntercomProps,
    IntercomProviderValueType,
    ProviderProps,
} from './types'
import { IntercomContext } from './context'
import { initialize } from './initialize'
import { IntercomAPI } from './api'
import { mapIntercomPropsToRawIntercomProps } from './mappers'

export function IntercomProvider(providerProps: ProviderProps) {
    const {
        apiBase,
        shouldInitialize = true,
        appId,
        onHide,
        onShow,
        onUnreadCountChange,
        autoBoot = false,
        autoBootProps,
        children,
    } = providerProps
    const isBooted = React.useRef(false)
    const isInitialized = React.useRef(false)

    const boot = React.useCallback(
        (props?: IntercomProps) => {
            if (isBooted.current) {
                return
            }

            if (!window.Intercom && !shouldInitialize) {
                return
            }

            const metadata = {
                app_id: appId,
                ...(apiBase && { api_base: apiBase }),
                ...(props && mapIntercomPropsToRawIntercomProps(props)),
                hide_default_launcher: true,
            }

            window.intercomSettings = metadata
            IntercomAPI('boot', metadata)
        },
        [apiBase, appId, shouldInitialize],
    )

    if (shouldInitialize && !isInitialized.current) {
        initialize(appId)

        // attach listeners
        if (onHide) IntercomAPI('onHide', onHide)
        if (onShow) IntercomAPI('onShow', onShow)
        if (onUnreadCountChange)
            IntercomAPI('onUnreadCountChange', onUnreadCountChange)

        if (autoBoot) {
            boot(autoBootProps)
        }

        isInitialized.current = true
    }

    const ensureIntercom = React.useCallback(
        (
            functionName = 'No function',
            callback: (() => void) | (() => string),
        ) => {
            if (!window.Intercom && !shouldInitialize) {
                return
            }
            return callback()
        },
        [shouldInitialize],
    )

    const shutdown = React.useCallback(() => {
        if (!isBooted.current) return

        IntercomAPI('shutdown')
        delete window.intercomSettings
        isBooted.current = false
    }, [])

    const hardShutdown = React.useCallback(() => {
        if (!isBooted.current) return

        IntercomAPI('shutdown')
        delete window.Intercom
        delete window.intercomSettings
        isBooted.current = false
    }, [])

    const refresh = React.useCallback(() => {
        ensureIntercom('update', () => {
            const lastRequestedAt = new Date().getTime()
            IntercomAPI('update', {
                last_requested_at: lastRequestedAt,
            })
        })
    }, [ensureIntercom])

    const update = React.useCallback(
        (props?: IntercomProps) => {
            ensureIntercom('update', () => {
                if (!props) {
                    refresh()
                    return
                }
                const rawProps = mapIntercomPropsToRawIntercomProps(props)
                window.intercomSettings = {
                    ...window.intercomSettings,
                    ...rawProps,
                }
                IntercomAPI('update', rawProps)
            })
        },
        [ensureIntercom, refresh],
    )

    const hide = React.useCallback(() => {
        ensureIntercom('hide', () => {
            IntercomAPI('hide')
        })
    }, [ensureIntercom])

    const show = React.useCallback(() => {
        ensureIntercom('show', () => IntercomAPI('show'))
    }, [ensureIntercom])

    const showMessages = React.useCallback(() => {
        ensureIntercom('showMessages', () => {
            IntercomAPI('showMessages')
        })
    }, [ensureIntercom])

    const showNewMessage = React.useCallback(
        (message?: string) => {
            ensureIntercom('showNewMessage', () => {
                if (!message) {
                    IntercomAPI('showNewMessage')
                } else {
                    IntercomAPI('showNewMessage', message)
                }
            })
        },
        [ensureIntercom],
    )
    const providerValue: IntercomProviderValueType = React.useMemo(() => {
        return {
            boot,
            shutdown,
            hardShutdown,
            refresh,
            update,
            hide,
            show,
            showMessages,
            showNewMessage,
        }
    }, [
        boot,
        shutdown,
        hardShutdown,
        refresh,
        update,
        hide,
        show,
        showMessages,
        showNewMessage,
    ])

    return (
        <IntercomContext.Provider value={providerValue}>
            {children}
        </IntercomContext.Provider>
    )
}

export function useIntercomContext() {
    const context = React.useContext(IntercomContext)

    return context
}
