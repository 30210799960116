import React, { useEffect } from 'react'
import { EVENTS, logEvent } from '@src/app/common/logging/logging'
import {
    resetCompetitionApplication,
    setIsAgreeingToTerms,
} from '@src/app/redux/slices/competition-application.slice'
import { useAppDispatch } from '@src/app/redux/store'
import { Users } from '@src/generated/graphql-operations'
import AppHeroTitle from './components/AppHeroTitle'
import RedditPixel from 'react-reddit-pixel'
import AppReferralActivated from '@src/features/idols/pages/application/components/AppReferralActivated'
import Link from '@src/app/common/components/Link/Link'

interface AppGuideProps {
    profile: Users
    referralCode: string | null
}

const guideList = [
    {
        text: 'Login or create a CreatorDAO account - it only takes a few seconds!',
    },
    {
        text: 'Post a one-minute TikTok or YouTube Short about anything - you can showcase your creativity, demonstrate a talent, or give us a taste of the type of content you would make if you had a million-dollar investment. You can even pick a video you’ve already posted or something in your camera roll. You must tag @creatordao and include #TheSearch #CreatorDAO in your post.',
    },
    {
        text: 'Copy and paste the link from your TikTok or YouTube Short into the application form or upload your video. You’ll also be required to share a profile photo and enter your name.',
    },
    {
        text: 'Your profile will go live after successfully submitting your application. Share your unique link to your audience to get the most votes.',
    },
]

const AppGuide: React.FC<AppGuideProps> = ({ profile, referralCode }) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!profile?.id) {
            dispatch(resetCompetitionApplication())
        }
    }, [profile])

    return (
        <React.Fragment>
            {referralCode && (
                <AppReferralActivated referralCode={referralCode} />
            )}
            <AppHeroTitle>
                How to join{' '}
                <img
                    src="/images/thesearch.png"
                    className="-ml-1.5 pb-1.5 w-[220px] inline-block"
                    alt="#TheSearch"
                />
            </AppHeroTitle>
            <div className="text-[16px] text-center text.sm">
                Follow the steps below to submit your application.
            </div>
            {guideList.map((guide, idx) => (
                <div key={idx} className="relative max-w-lg">
                    <span
                        className={
                            'absolute flex h-12 w-12 items-center justify-center rounded-full text-white bg-black'
                        }
                    >
                        {idx + 1}
                    </span>
                    <p className="ml-16 text-[16px] leading-[24px]">
                        {guide.text}
                    </p>
                </div>
            ))}
            <div className="max-w-lg text-center text-sm leading-[150%]">
                By continuing, you agree to CreatorDAO’s{' '}
                <Link to="/terms" className="font-bold underline">
                    Terms of Services
                </Link>{' '}
                .
            </div>
            <button
                className="font-bold text-center bg-[#51E577] text-black py-4 px-16 rounded-full w-full"
                onClick={() => {
                    logEvent(EVENTS.FANX.APPLICATION.AGREEMENT)
                    RedditPixel.track('Lead')
                    dispatch(setIsAgreeingToTerms(true))
                }}
            >
                I understand, continue
            </button>
        </React.Fragment>
    )
}

export default AppGuide
