import React from 'react'
import FAQ from '../application/components/FAQ'
import Follow from '../home/components/Follow'
import Investors from '../home/components/Investors'
import InterstitialHero from './components/InterstitialHero'

interface InterstitialProps {}

const Interstitial = ({}: InterstitialProps) => {
    return (
        <div className="space-y-16 sm:space-y-24 pb-10 lg:pb-16">
            <InterstitialHero />
            <Follow />
            <Investors />
            <FAQ background="dark" />
        </div>
    )
}

export default Interstitial
