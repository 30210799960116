import React from 'react'
import launchpadLogo from '@src/assets/images/launchpad/creator_launchpad.svg'
import launchpadLogoMob from '@src/assets/images/launchpad/creator_launchpad_mob.png'
import launchpadLogoXsMob from '@src/assets/images/launchpad/creator_launchpad_xs_mob.png'
import bulbImg from '@src/assets/images/launchpad/bulb.png'
import handsImg from '@src/assets/images/launchpad/hands.png'
import userMsgImg from '@src/assets/images/launchpad/user_msg.png'
import goalImg from '@src/assets/images/launchpad/goal.png'
import userGroupImg from '@src/assets/images/launchpad/user_group.png'
import pollImg from '@src/assets/images/launchpad/poll.png'

const programList = [
    {
        title: '1-on-1 brainstorming intensive',
        imgUrl: bulbImg,
        desciption:
            "We'll work with you 1-on-1 to develop a strategy for any business ideas that you want to pursue. If you don’t have one yet, we’ll brainstorm different models that would work for your audience.",
    },
    {
        title: 'Co-founder matching',
        imgUrl: handsImg,
        desciption:
            'We’ll introduce you to some of the smartest business minds in the world to help you find a co-founder. Even if you decide to continue doing things on your own, you’ll still get to meet some amazing people.',
    },
    {
        title: 'Weekly talks with industry experts',
        imgUrl: userMsgImg,
        desciption:
            'Each week, we’ll invite a prominent person from a specific industry to speak. These speakers are all in creator-relevant industries with proven track records of success. They’ll give away tons of insights that you can apply to your business.',
    },
    {
        title: 'Weekly group check-ins',
        imgUrl: goalImg,
        desciption:
            'Every week we’ll have group check-ins where we’ll keep you accountable for your goals from the previous week and set goals for the next week as well. Our goal is to keep you on track and help you work through any issues that arise.',
    },
    {
        title: 'Office hours with mentors',
        imgUrl: userGroupImg,
        desciption:
            "Take advantage of our mentors' expertise and schedule a 1-on-1 session for personalized assistance with content strategy, hiring, and deal negotiation during their open office hours.",
    },
    {
        title: 'Beyond the 8-weeks',
        imgUrl: pollImg,
        desciption:
            'We know building a new business takes time, which is why the program doesn’t end after 8 weeks. You’ll still be able to schedule office hours with mentors, attend future speaker events, and come to alumni events as our network grows.',
    },
]

const AboutProgram = () => {
    return (
        <div className="relative py-10 px-4 bg-white overflow-hidden">
            <h3 className="text-3xl leading-8 font-bold -tracking-[0.02em] md:-tracking-[0.03em] md:text-5xl md:leading-[48px] md:mb-3 text-center">
                About <br className="sm:hidden" /> the program
            </h3>

            <img
                className="xs:hidden absolute top-36 left-0 right-0 mx-auto"
                src={launchpadLogoXsMob}
                alt="Creator Launchpad"
            />
            <div className="relative pt-36 md:pt-24">
                <img
                    className="mx-auto hidden md:block absolute top-11 lg:top-10 xl:top-3.5 left-0 right-0 w-full  max-w-md  sm:max-w-2xl  md:max-w-4xl xl:max-w-7xl"
                    src={launchpadLogo}
                    alt="Creator Launchpad"
                />
                <img
                    className="hidden xs:block md:hidden absolute top-9 left-0 right-0 mx-auto"
                    src={launchpadLogoMob}
                    alt="Creator Launchpad"
                />

                <div className=" bg-brandBeige-500 p-6 sm:px-8 md:p-12 rounded-2xl space-y-8 md:space-y-12 relative z-10 max-w-3xl mx-auto">
                    <p className="text-xl text-center -tracking-[0.04em] md:text-3xl md:-tracking-[1px]">
                        Creator Launchpad starts as an 8-week intensive program
                        and continues far beyond that. Here’s what you can
                        expect.
                    </p>
                    <div className="space-y-8 md:px-5">
                        {programList.map(item => (
                            <div
                                key={item.title}
                                className="flex flex-col gap-4 md:gap-8 items-center md:flex-row"
                            >
                                <img
                                    src={item.imgUrl}
                                    className="w-12"
                                    alt={item.title}
                                />

                                <div className="space-y-4 text-center md:text-left max-w-xl">
                                    <p className="text-xl leading-6 font-bold  -tracking-[0.04em] md:-tracking-[0.06em]">
                                        {item.title}
                                    </p>
                                    <p className="text-base leading-6 -tracking-[0.04em] md:-tracking-[0.06em]">
                                        {item.desciption}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutProgram
