import React from 'react'
import { Field } from 'formik'

export type InputField = {
    name: string
    description?: string
    displayName?: string
    values: {
        name: string
        value: string
    }[]
} & React.InputHTMLAttributes<HTMLInputElement>

interface Props {
    field: InputField
    className?: string
    spaceBetween?: string
}

export default function RadioInput({
    field,
    className = '',
    spaceBetween = '',
}: Props) {
    return (
        <div className={`${spaceBetween === '' ? 'space-y-2' : spaceBetween}`}>
            {field.values.map(value => (
                <div
                    key={value.name}
                    className={`flex items-center ${className}`}
                >
                    <Field
                        id={value.value}
                        name={field.name}
                        value={value.value}
                        type="radio"
                        className="text-brand-500 focus:ring-brand-500 h-4 w-4 border-gray-300"
                    />
                    <label
                        htmlFor={value.value}
                        className="ml-3 block text-sm font-medium text-gray-500"
                    >
                        {value.name}
                    </label>
                </div>
            ))}
        </div>
    )
}
