import { Button, ButtonVariant } from '@src/app/common/components/Button'
import { URLS } from '@src/constants/urls'
import React from 'react'
import { Link } from 'react-router-dom'
import { HiMenu } from 'react-icons/hi'
import { Dialog, Transition } from '@headlessui/react'

const NewHeader = () => {
    const [sidebarOpen, setSidebarOpen] = React.useState(false)
    return (
        <>
            <Transition.Root show={sidebarOpen} as={React.Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 top-14 md:top-[72px] flex z-40"
                    onClose={() => setSidebarOpen(false)}
                >
                    <Transition.Child
                        as={React.Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 top-16 md:top-[72px] bg-black bg-opacity-[50%]" />
                    </Transition.Child>

                    <div className="fixed inset-0 top-16 md:top-[72px] flex z-40">
                        <Transition.Child
                            as={React.Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel
                                className={`relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none`}
                            >
                                <Transition.Child
                                    as={React.Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2"></div>
                                </Transition.Child>
                                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                    <nav
                                        aria-label="Sidebar"
                                        className="p-4 space-y-6"
                                    >
                                        <Link
                                            to={URLS.ROOT}
                                            className="block text-lg leading-5  outline-none"
                                        >
                                            Home
                                        </Link>
                                        <Link
                                            to={URLS.LAUNCHPAD}
                                            className="block text-lg leading-5 outline-none"
                                        >
                                            Creator Launchpad
                                        </Link>
                                        <Link
                                            to={URLS.THESEARCH}
                                            className="block text-lg leading-5 outline-none"
                                        >
                                            #TheSearch
                                        </Link>
                                        {/* Not required currently
                                        
                                        <Button
                                            variant={ButtonVariant.SLIM}
                                            className="h-10 text-base leading-5 font-normal"
                                        >
                                            Go to Web App
                                        </Button> */}
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="p-4 bg-white sticky top-0 z-50 left-0 right-0 flex items-center justify-between gap-4">
                <Link to="/">
                    <img className="w-16 " src="/images/logo.png" alt="logo" />
                </Link>

                <div className="self-center flex-1 items-center hidden md:flex justify-center">
                    <Link
                        to={URLS.ROOT}
                        className="w-fit text-base leading-5 mx-5 my-2 select-none "
                    >
                        Home
                    </Link>
                    <Link
                        to={URLS.LAUNCHPAD}
                        className="w-fit text-base leading-5 mx-5 my-2 select-none "
                    >
                        Creator Launchpad
                    </Link>
                    <Link
                        to={URLS.THESEARCH}
                        className="w-fit text-base leading-5 mx-5 my-2 select-none "
                    >
                        #TheSearch
                    </Link>
                </div>
                {/* Not required currently
                 <Link to={URLS.LOGIN}>
                    <Button
                        variant={ButtonVariant.SLIM}
                        className="h-10 text-base leading-5 font-normal  hidden md:block"
                    >
                        Go to Web App
                    </Button>{' '}
                </Link> */}
                {sidebarOpen && (
                    <div
                        className="md:hidden cursor-pointer select-none"
                        onClick={() => setSidebarOpen(false)}
                    >
                        <HiMenu className="h-7 w-7" aria-hidden="false" />
                    </div>
                )}
                {!sidebarOpen && (
                    <div
                        className="md:hidden cursor-pointer select-none"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <HiMenu className="h-7 w-7 " aria-hidden="false" />
                    </div>
                )}
            </div>
        </>
    )
}

export default NewHeader
