import React from 'react'
import { Widget } from '@typeform/embed-react'

const LaunchpadApplication = () => {
    return (
        <div className="w-full h-[600px] pb-20 md:h-[900px] relative">
            <Widget id="FOoJfL1r" className="w-full h-full" />
        </div>
    )
}

export default LaunchpadApplication
