import React from 'react'
import { useFeatureFlag } from '@src/features/shared/useFeatureFlag'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'
import TextInput from '@src/app/common/form/TextInput'
import { getFirebaseErrorString } from '@src/app/common/util/util'
import getInitialValuesAndValidationSchema from '@src/helper/getInitialValuesAndValidationSchema'
import AuthSubmitButton from './AuthSubmitButton'

interface NewEmailFormProps {
    initialValue: string
    handleToggleAuthForm: () => void
    handleEmailFormSubmit: any
    isSignUp?: boolean
}

const NewEmailForm: React.FC<NewEmailFormProps> = ({
    handleToggleAuthForm,
    initialValue,
    handleEmailFormSubmit,
    isSignUp,
}) => {
    const emailSignUpEnabled = useFeatureFlag('email_pw_signup_enabled')
    const fields = [
        {
            name: 'email',
            placeholder: 'Email address',
            testId: 'email',
            initialValue: initialValue,
            validationSchema: Yup.string()
                .required()
                .email('Enter a valid email (jane@example.com)'),
            className: '',
            autoFocus: true,
            isTrimRequired: true,
        },
    ]

    const { initialValues, validationSchema }: any =
        getInitialValuesAndValidationSchema(fields)

    const handleFormSubmit = (
        values: any,
        { setSubmitting, setErrors }: FormikHelpers<any>,
    ) => {
        try {
            handleEmailFormSubmit(values)
        } catch (error) {
            setErrors({
                auth: getFirebaseErrorString(error, 'NewEmailForm'),
            })
            setSubmitting(false)
        }
    }

    return emailSignUpEnabled || !isSignUp ? (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object(validationSchema)}
            onSubmit={handleFormSubmit}
            validateOnBlur={false}
        >
            {({ isSubmitting, errors }: any) => (
                <Form className="space-y-4">
                    <>
                        <div className="space-y-2 select-none">
                            <div className="text-base font-bold flex items-center justify-between gap-2 select-none">
                                <label
                                    htmlFor={'email'}
                                    className="block  text-brandBlack-500"
                                >
                                    Email address
                                </label>
                                <span
                                    className="text-brandIdolGreen-700 select-none cursor-pointer"
                                    onClick={() => handleToggleAuthForm()}
                                >
                                    Use phone number instead
                                </span>
                            </div>
                            {fields.map(field => (
                                <TextInput
                                    key={field.name}
                                    name={field.name}
                                    testId={field.testId}
                                    placeholder={field.placeholder}
                                    variant={'fanx'}
                                    autoFocus={field.autoFocus}
                                    isTrimRequired={field.isTrimRequired}
                                />
                            ))}
                        </div>

                        {errors?.auth && (
                            <div className={'text-red-500 mt-2 text-sm'}>
                                {errors?.auth}
                            </div>
                        )}
                        <div>
                            <AuthSubmitButton
                                disabled={isSubmitting}
                                isSubmitting={isSubmitting}
                            />
                        </div>
                    </>
                </Form>
            )}
        </Formik>
    ) : null
}

export default NewEmailForm
