import * as React from 'react'
import RadioInput, { InputField } from '../form/RadioInput'

export default function FormRadio({ field }: { field: InputField }) {
    return (
        <div className="pt-6 sm:pt-5">
            <div role="group" aria-labelledby={'label-' + field.name}>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                    <div>
                        <div
                            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                            id={'label-' + field.name}
                        >
                            {field.displayName}
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <div className="max-w-lg">
                            {field.description && (
                                <p className="text-sm text-gray-500">
                                    {field.description}
                                </p>
                            )}
                            <div className="mt-4">
                                <RadioInput key={field.name} field={field} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function FormBookSessionRadio({ field }: { field: InputField }) {
    return (
        <div className="">
            <div role="group" aria-labelledby={'label-' + field.name}>
                <div>
                    <div
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        id={'label-' + field.name}
                    >
                        {field.displayName}
                    </div>
                </div>
                <div>
                    <div className="max-w-lg ">
                        {field.description && (
                            <p className="text-sm text-gray-500">
                                {field.description}
                            </p>
                        )}
                        <div className="mt-4">
                            <RadioInput
                                key={field.name}
                                field={field}
                                className="border border-black rounded p-4 "
                                spaceBetween="space-y-8"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
