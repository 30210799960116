import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'

interface ScrollToFieldErrorProps {
    scrollIntoViewOptions?: {
        behavior: ScrollBehavior
        block: ScrollLogicalPosition
    }
}

const ScrollToFieldError: React.FC<ScrollToFieldErrorProps> = ({
    scrollIntoViewOptions = { behavior: 'smooth', block: 'center' },
}) => {
    const { submitCount, isValid, errors } = useFormikContext()

    useEffect(() => {
        if (isValid) return
        if (submitCount < 1) return

        const fieldErrorNames = getFieldErrorNames(errors)

        if (fieldErrorNames.length <= 0) return

        const element = document.querySelector(`[name='${fieldErrorNames[0]}']`)

        if (!element) return

        // Scroll to first known error into view
        element.scrollIntoView(scrollIntoViewOptions)

        // Formik doesn't (yet) provide a callback for a client-failed submission,
        // thus why this is implemented through a hook that listens to changes on
        // the submit count.
    }, [submitCount, isValid])

    return null
}

const getFieldErrorNames = (formikErrors: any) => {
    const transformObjectToDotNotation = (
        obj: any,
        prefix = '',
        result: Array<string> = [],
    ) => {
        Object.keys(obj).forEach(key => {
            const value = obj[key]
            if (!value) return

            const nextKey: string = prefix ? `${prefix}.${key}` : key
            if (typeof value === 'object') {
                transformObjectToDotNotation(value, nextKey, result)
            } else {
                result.push(nextKey)
            }
        })

        return result
    }

    return transformObjectToDotNotation(formikErrors)
}

export default ScrollToFieldError
