import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { FieldHookConfig, useField } from 'formik'
import { HiChevronDown, HiCheck, HiOutlineX } from 'react-icons/hi'

const answers = [
    {
        id: 0,
        name: 'Why do you want to join Creator DAO? (Choose all that apply)',
    },
    { id: 1, name: 'Capital - I need money' },
    { id: 2, name: 'Collabs with other members' },
    { id: 3, name: 'Production help (e.g., thumbnail artist)' },
    { id: 4, name: 'Mentorship' },
    { id: 5, name: 'Community - I want to meet other creators' },
    { id: 6, name: 'Other' },
]

interface InputDropdownProps {
    name: string
}

const InputDropdown = ({
    name,
}: InputDropdownProps & FieldHookConfig<string>) => {
    const [selected, setSelected] = useState([answers[0]])
    const [field, meta, helpers] = useField(name)

    const handleChange = (e: any) => {
        const filters = e.filter((v: any) => v.id !== 0)
        let finalVal = ''
        if (filters.length > 0) {
            setSelected(filters)
            if (filters.length === 1) {
                finalVal = filters[0].name
            } else {
                finalVal = filters.map((v: any) => v.name).join(', ')
            }
        } else {
            setSelected([answers[0]])
        }
        helpers.setValue(finalVal)
    }

    return (
        <Listbox value={selected} onChange={handleChange} multiple>
            {({ open }) => (
                <>
                    <div className="mt-1 relative">
                        <Listbox.Button className="flex flex-wrap gap-2 bg-[#F6F9F0] relative w-full border border-black rounded-2xl shadow-sm px-6 sm:px-8 py-5 text-left cursor-default focus:outline-none focus:ring-0">
                            {selected.map((s, idx) =>
                                s.id === 0 ? (
                                    <span key={idx} className="block truncate">
                                        {s.name}
                                    </span>
                                ) : (
                                    <span
                                        key={idx}
                                        className="flex bg-[#D5D7D3] px-3 py-1 truncate rounded-md items-center"
                                    >
                                        {s.name}
                                        <HiOutlineX
                                            className="h-3 w-3 ml-2 cursor-pointer hover:text-red-600"
                                            onClick={() => {
                                                const result = selected.filter(
                                                    v => v.id !== s.id,
                                                )
                                                handleChange(result)
                                            }}
                                        />
                                    </span>
                                ),
                            )}
                            <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <HiChevronDown
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-2xl overflow-auto focus:outline-none">
                                {answers.map(
                                    answer =>
                                        answer.id !== 0 && (
                                            <Listbox.Option
                                                key={answer.id}
                                                className={({
                                                    active,
                                                    selected,
                                                }) =>
                                                    `${
                                                        active || selected
                                                            ? 'bg-[#D5D7D3]'
                                                            : ''
                                                    } cursor-default select-none relative px-6 sm:px-8 py-3`
                                                }
                                                value={answer}
                                            >
                                                {({ selected, active }) => (
                                                    <>
                                                        <span
                                                            className={`block truncate font-normal`}
                                                        >
                                                            {answer.name}
                                                        </span>
                                                        {selected && (
                                                            <span
                                                                className={`${
                                                                    active
                                                                        ? 'text-white'
                                                                        : 'text-green-600'
                                                                }
                                                                    absolute inset-y-0 right-0 flex items-center pr-4`}
                                                            >
                                                                <HiCheck
                                                                    className="h-5 w-5"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ),
                                )}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}

export default InputDropdown
