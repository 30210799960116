import * as React from 'react'
import { FieldHookConfig, useField } from 'formik'
import { clsx } from 'clsx'

export const inputStyles = `bg-[#F2F2F2] rounded-[8px] py-3 px-4 gap-[10px] 
placeholder:text-[#999] border border-transparent focus:outline-none 
focus:border-transparent focus:ring-0 block w-full`

interface AppInputFieldProps {
    label: string
    description?: string
    name: string
    placeholder: string
    fieldtype: 'text' | 'textarea'
    disabled?: boolean
    isRequired?: boolean
    showErrorBorder?: boolean
    countCharacters?: boolean
    maxCharacters?: number
    isReadOnly?: boolean
    badge?: string
}

const AppInputField = ({
    label,
    description,
    name,
    placeholder,
    fieldtype,
    disabled,
    isRequired,
    showErrorBorder,
    countCharacters,
    maxCharacters,
    isReadOnly,
    badge,
}: AppInputFieldProps & FieldHookConfig<string>) => {
    const [field, { error, touched }] = useField(name)
    return (
        <div className="flex flex-col space-y-0.5">
            <label className="font-bold">
                {label}
                {!isRequired ? (
                    ' (optional)'
                ) : (
                    <span className="text-black">*</span>
                )}
            </label>
            {fieldtype === 'text' ? (
                <div className="relative">
                    <input
                        id={name}
                        {...field}
                        type="text"
                        autoComplete={name}
                        placeholder={placeholder}
                        disabled={disabled || isReadOnly}
                        className={clsx(
                            inputStyles,
                            showErrorBorder && error && touched
                                ? 'border-red-500 focus:border-red-500'
                                : '',
                            { 'text-gray-300 bg-gray-100': disabled },
                            badge ? 'pl-8' : '',
                        )}
                    />

                    {badge && (
                        <span className="absolute top-4 mt-0.5 left-4 font-medium text-base leading-4">
                            {badge}
                        </span>
                    )}
                </div>
            ) : (
                <textarea
                    id={name}
                    {...field}
                    rows={5}
                    placeholder={placeholder}
                    disabled={disabled || isReadOnly}
                    className={clsx(
                        inputStyles,
                        showErrorBorder && error && touched
                            ? 'border-red-500 focus:border-red-500'
                            : '',
                        'resize-none',
                        { 'text-gray-300 bg-gray-100': disabled },
                    )}
                />
            )}
            <div className="flex justify-between">
                {error && touched && (
                    <span className="w-full flex py-1 text-xs text-red-500 font-normal justify-start">
                        {error}
                    </span>
                )}
                {countCharacters && maxCharacters && (
                    <span className="w-full flex py-1 text-xs text-gray-500 font-normal justify-end">
                        {field.value.length || 0}/{maxCharacters}
                    </span>
                )}
            </div>
            {description && <span className="text-sm">{description}</span>}
        </div>
    )
}

export default AppInputField
