import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { verifyAuth } from '../hooks/authentication.hooks'
import applicationsReducer from './slices/applications.slice'
import authReducer from './slices/auth.slice'
import competitionApplicationReducer from './slices/competition-application.slice'
import modalReducer from './slices/modal.slice'
import officehoursReducer from './slices/office-hours.slice'
import retentionToolReducer from './slices/retention-tool.slice'

export const rootReducer = {
    auth: authReducer,
    modals: modalReducer,
    retentiontool: retentionToolReducer,
    applications: applicationsReducer,
    officehours: officehoursReducer,
    competitionApplication: competitionApplicationReducer,
}

export const store = (function () {
    const configuredStore = configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    })

    configuredStore.dispatch(verifyAuth())

    return configuredStore
})()

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    Action<string>
>

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export default store
