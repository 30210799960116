import {
    Button,
    ButtonPrimary,
    ButtonVariant,
} from '@src/app/common/components/Button'
import React from 'react'

const ReadyToJoin = () => {
    return (
        <div className="bg-black px-5 py-10 md:py-16">
            <div className="md:py-2 text-center space-y-8">
                <h3 className="text-3xl md:text-5xl -tracking-[0.04em]  md:-tracking-[2px]  font-bold text-white">
                    Ready to join?
                </h3>
                <p className="text-lg leading-6 md:text-2xl md:leading-7 -tracking-[0.04em]  md:-tracking-[1px]  text-white">
                    Now accepting applications for <br className="sm:hidden" />
                    Creator Launchpad
                </p>
                <div className="space-y-4">
                    <div className="text-center">
                        <a
                            href="https://buy.stripe.com/eVaaIJcAc991glqeUY"
                            target="_blank"
                            rel="noreferrer"
                            className="inline-block mx-auto"
                        >
                            <Button
                                primary={ButtonPrimary.GREEN}
                                variant={ButtonVariant.SLIM}
                                className="font-normal h-11 md:h-14 px-5 md:px-6 mx-auto md:text-xl"
                            >
                                Apply Now
                            </Button>
                        </a>
                    </div>
                    <p className="text-base  -tracking-[0.04em]  md:-tracking-[1px] text-white">
                        $79 / month (billed annually)
                    </p>
                </div>

                <p className="text-base md:text-xl -tracking-[0.04em]  md:-tracking-[1px] text-white">
                    Got a question? <br className="sm:hidden" /> Contact us at{' '}
                    <a
                        href="mailto:kong@creatordao.com"
                        className="text-inherit hover:underline"
                    >
                        kong@creatordao.com
                    </a>
                </p>
            </div>
        </div>
    )
}

export default ReadyToJoin
