import Link from '@src/app/common/components/Link/Link'
import React from 'react'
import applyBtn from '../../../../assets/images/launchpad/applybtn.png'

const faqs = [
    {
        hoverColor: 'green',
        question: 'What is the time commitment?',
        answer: 'We expect the weekly time commitment to be approximately 2-4 hours per week. Participants are expected to attend a one hour lecture and one hour video review each week, as well as upload at least one video to their channel each week. There will be many opportunities to get involved beyond this minimum commitment, including office hours and events. Ultimately, participants will get out what they put in.',
    },
    {
        hoverColor: 'pink',
        question: 'What if I don’t have any AdSense revenue?',
        answer: 'Totally okay! If accepted to the program, it’s because we believe in your potential.',
    },
    {
        hoverColor: 'orange',
        question: 'What happens after the program?',
        answer: 'All program participants continue to have access to our exclusive community, including the tools and resources available to all CreatorDAO members. Current CreatorDAO members include Paris Hilton, Joe Montana, Garry Tan, and more.',
    },
    {
        hoverColor: 'pink',
        question: 'How big will the cohort be?',
        answer: 'Creator Launchpad is exclusive. For the inaugural Season 1, we will only accept 10 YouTubers. We want each participant to 10x their channel and receive personalized attention from the world’s top YouTubers.',
    },
    {
        hoverColor: 'green',
        question: 'What if I’m just starting out?',
        answer: 'While we prefer YouTubers who have at least 10K subscribers, we are open to YouTubers of all size and encourage you to apply.',
    },
]

interface LaunchpadFAQProps {}

const LaunchpadFAQ = ({}: LaunchpadFAQProps) => {
    return (
        <div className="py-[100px] bg-[#eaeee3]">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-[50px] leading-[50px] text-center mb-12 font-bold">
                    FAQs
                </h1>
                <div className="px-4 justify-center">
                    {faqs.map((faq, index) => (
                        <div
                            key={index}
                            className={`bg-brandOffWhite-500 p-[40px] mb-[30px] rounded-[20px] 2xl:hover:scale-110 transition-all ${
                                faq.hoverColor === 'green'
                                    ? 'hover:bg-brandGreen-500'
                                    : faq.hoverColor === 'pink'
                                    ? 'hover:bg-brandPink-500'
                                    : 'hover:bg-brandOrange-500'
                            }`}
                        >
                            <h1 className="text-[35px] leading-[40px] mb-4 font-bold">
                                {faq.question}
                            </h1>
                            <h2 className="text-[25px] mb-0 font-light">
                                {faq.answer}
                            </h2>
                        </div>
                    ))}
                </div>

                <div className="mt-2 mb-12 text-center font-bold">
                    <h4 className="text-[20px]">
                        Still have questions?{' '}
                        <a
                            href="mailto:info@creatordao.com"
                            className="text-black hover:text-brandPink-500 underline"
                        >
                            Contact us!
                        </a>{' '}
                    </h4>
                </div>

                <div className="px-4 lg:px-0 mt-12 flex justify-center">
                    <Link to="/apply">
                        <img src={applyBtn} />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LaunchpadFAQ
