import { gql } from '@apollo/client'
import { getAuth } from 'firebase/auth'
import { toast } from 'react-toastify'
import { getApolloClient, setApolloClientIfNecessary } from '../../ApolloClient'
import { app } from '../config/firebase'

const auth = getAuth(app)

export const GET_USERS = gql`
    query GetUsers(
        $orderBy: users_order_by!
        $limit: Int!
        $offset: Int
        $where: users_bool_exp
    ) {
        users(
            limit: $limit
            order_by: [$orderBy]
            offset: $offset
            where: $where
        ) {
            id
            display_name
            display_name_slug
            created_at
            photo_url
            onboarding_complete
            can_help_with
            need_help_with
            headline
            about_me
            tags
            office_hours
            social_media
            is_service_provider
            calendly_url
            allow_booking
            show_your_schedule
            location
            timezone
            youtube_channels {
                youtube_id
                title
                analytics
            }
        }
    }
`

export const GET_USERS_WITH_OFFICE_HOURS = gql`
    query GetUsersWithOfficeHours($limit: Int!, $created_at: timestamptz!) {
        users(
            limit: $limit
            order_by: { created_at: desc }
            where: {
                _and: [
                    { allow_booking: { _eq: true } }
                    {
                        _or: [
                            { calendly_url: { _neq: "" } }
                            { office_hours: { _is_null: false } }
                        ]
                    }
                    { portal_access: { _eq: true } }
                    { created_at: { _lt: $created_at } }
                ]
            }
        ) {
            id
            display_name
            display_name_slug
            created_at
            photo_url
            can_help_with
            need_help_with
            headline
            about_me
            tags
            office_hours
            calendly_url
            allow_booking
            show_your_schedule
            location
            timezone
        }
    }
`

export const GET_IDOL_PROFILE = gql`
    query GetIdolProfile($display_name_slug: String!) {
        users(where: { display_name_slug: { _eq: $display_name_slug } }) {
            id
            display_name
            display_name_slug
            about_me
            photo_url
            competition_group_memberships {
                id
                competition_group_id
                user_id
                competition_group {
                    id
                    index
                    phase_id
                    custom_name
                }
            }
            competition_applications {
                video_url
                about
            }
            social_media
        }
    }
`

export const GET_USER_WITH_PRIVATE_BY_ID = gql`
    query GetUserWithPrivateById($id: String!) {
        users_by_pk(id: $id) {
            id
            display_name
            display_name_slug
            created_at
            photo_url
            onboarding_complete
            can_help_with
            need_help_with
            headline
            about_me
            tags
            office_hours
            calendly_url
            allow_booking
            show_your_schedule
            social_media
            is_service_provider
            location
            timezone
            private {
                email
                phone_number
                notifications_preferences
                portal_access
                valuation_only
                wallet_address
            }
            youtube_channels {
                id
                youtube_id
                title
                description
                subscriber_count
                video_count
                view_count
                thumbnails
                analytics
                topic_ids
                published_at
                updated_at
                user_id
                youtube_videos {
                    id
                    youtube_id
                    title
                    description
                    thumbnails
                    status
                    duration
                    analytics
                    audience_retention
                    published_at
                    youtube_channel_id
                    updated_at
                }
                youtube_token {
                    id
                    refresh_token
                    scope
                    still_valid
                    updated_at
                }
            }
            competition_votes {
                id
                user_id
                competition_group_id
                competition_group_membership_id
            }
            competition_applications {
                id
                about
                video_url
            }
        }
    }
`

export const GET_USER_BY_ID = gql`
    query GetUserById($id: String!) {
        users_by_pk(id: $id) {
            id
            display_name
            display_name_slug
            created_at
            photo_url
            onboarding_complete
            can_help_with
            need_help_with
            headline
            about_me
            tags
            office_hours
            calendly_url
            allow_booking
            show_your_schedule
            social_media
            is_service_provider
            location
            timezone
            youtube_channels {
                id
                youtube_id
                title
                description
                subscriber_count
                video_count
                view_count
                thumbnails
                analytics
                topic_ids
                published_at
                updated_at
                user_id
                youtube_videos {
                    id
                    youtube_id
                    title
                    description
                    thumbnails
                    status
                    duration
                    analytics
                    audience_retention
                    published_at
                    youtube_channel_id
                    updated_at
                }
                youtube_token {
                    id
                    refresh_token
                    scope
                    still_valid
                    updated_at
                }
            }
            competition_votes {
                id
                user_id
                competition_group_id
                competition_group_membership_id
            }
            competition_applications {
                id
                about
                video_url
            }
        }
    }
`

export const ADD_USER = gql`
    mutation AddUser(
        $id: String!
        $displayName: String!
        $email: String!
        $photoURL: String
    ) {
        insert_users(
            objects: {
                id: $id
                display_name: $displayName
                email: $email
                photo_url: $photoURL
            }
        ) {
            affected_rows
            returning {
                id
                email
                created_at
            }
        }
    }
`

export const UPDATE_USER_ONBOARDING_COMPLETE = gql`
    mutation updateUserOnboardingComplete(
        $id: String!
        $onboardingComplete: Boolean!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: { onboarding_complete: $onboardingComplete }
        ) {
            id
            onboarding_complete
        }
    }
`

export const UPDATE_USER_DISPLAY_NAME = gql`
    mutation UpdateUserFullName(
        $id: String!
        $display_name: String!
        $updated_at: timestamptz!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: { display_name: $display_name, updated_at: $updated_at }
        ) {
            display_name
            updated_at
        }
    }
`

export const UPDATE_USER_EMAIL = gql`
    mutation UpdateUserEmail(
        $id: String!
        $email: String!
        $updated_at: timestamptz!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: { email: $email, updated_at: $updated_at }
        ) {
            email
            updated_at
        }
    }
`

export const UPDATE_USER_NOTIFICATIONS_PREFERENCES = gql`
    mutation UpdateUserNotificationPreferences(
        $id: String!
        $notifications_preferences: jsonb!
        $updated_at: timestamptz!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: {
                notifications_preferences: $notifications_preferences
                updated_at: $updated_at
            }
        ) {
            notifications_preferences
            updated_at
        }
    }
`

export const UPDATE_USER_SOCIAL_MEDIA = gql`
    mutation UpdateUserSocialMedia(
        $id: String!
        $social_media: jsonb!
        $updated_at: timestamptz!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: { social_media: $social_media, updated_at: $updated_at }
        ) {
            social_media
            updated_at
        }
    }
`

export const UPDATE_USER_OFFICE_HOURS = gql`
    mutation UpdateUserOfficeHours(
        $id: String!
        $office_hours: jsonb!
        $calendly_url: String!
        $allow_booking: Boolean!
        $show_your_schedule: Boolean!
        $timezone: String!
        $updated_at: timestamptz!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: {
                office_hours: $office_hours
                calendly_url: $calendly_url
                allow_booking: $allow_booking
                show_your_schedule: $show_your_schedule
                timezone: $timezone
                updated_at: $updated_at
            }
        ) {
            office_hours
            calendly_url
            allow_booking
            show_your_schedule
            timezone
            updated_at
        }
    }
`

export const UPDATE_USER_PROFILE_GENERAL = gql`
    mutation UpdateGeneralProfile(
        $id: String!
        $display_name: String!
        $photo_url: String
        $headline: String
        $about_me: String
        $location: String
        $can_help_with: jsonb!
        $need_help_with: jsonb!
        $updated_at: timestamptz!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: {
                display_name: $display_name
                photo_url: $photo_url
                headline: $headline
                about_me: $about_me
                location: $location
                can_help_with: $can_help_with
                need_help_with: $need_help_with
                updated_at: $updated_at
            }
        ) {
            headline
            about_me
            location
            can_help_with
            need_help_with
            updated_at
        }
    }
`

export const GET_USER_WITH_PRIVATE_BY_ID_FOR_COMPETITION = gql`
    query GetUserWithPrivateByIdForCompetition($id: String!) {
        users_by_pk(id: $id) {
            id
            display_name
            display_name_slug
            created_at
            photo_url
            onboarding_complete
            can_help_with
            need_help_with
            headline
            about_me
            tags
            office_hours
            calendly_url
            allow_booking
            show_your_schedule
            social_media
            is_service_provider
            location
            timezone
            private {
                email
                phone_number
                notifications_preferences
                portal_access
                valuation_only
                wallet_address
            }
            competition_votes {
                id
                user_id
                competition_group_id
                competition_group_membership_id
            }
            competition_applications {
                id
                about
                video_url
            }
            competition_group_memberships {
                id
                competition_group_id
                user_id
                competition_group {
                    id
                    index
                    phase_id
                    custom_name
                }
            }
        }
    }
`

export const UPDATE_USER_PROFILE_ONBOARDING = gql`
    mutation UpdateUserProfileOnboarding(
        $id: String!
        $display_name: String
        $photo_url: String
        $headline: String!
        $timezone: String!
        $location: String!
        $updated_at: timestamptz!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: {
                display_name: $display_name
                photo_url: $photo_url
                headline: $headline
                timezone: $timezone
                location: $location
                updated_at: $updated_at
            }
        ) {
            display_name
            photo_url
            headline
            timezone
            location
            updated_at
        }
    }
`

export const UPDATE_FAN_USER_PROFILE_ONBOARDING = gql`
    mutation UpdateFanUserProfileOnboarding(
        $id: String!
        $display_name: String
        $updated_at: timestamptz!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: { display_name: $display_name, updated_at: $updated_at }
        ) {
            display_name
            updated_at
        }
    }
`

export const UPDATE_USER_HELP_ONBOARDING = gql`
    mutation UpdateUserHelpOnboarding(
        $id: String!
        $can_help_with: jsonb!
        $need_help_with: jsonb!
        $updated_at: timestamptz!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: {
                can_help_with: $can_help_with
                need_help_with: $need_help_with
                updated_at: $updated_at
            }
        ) {
            can_help_with
            need_help_with
            updated_at
        }
    }
`

export const UPDATE_USER_PHONE_NUMBER = gql`
    mutation UpdateUserPhoneNumber(
        $id: String!
        $phone_number: String!
        $updated_at: timestamptz!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: { phone_number: $phone_number, updated_at: $updated_at }
        ) {
            phone_number
            updated_at
        }
    }
`

export const UPDATE_USER_WALLET_ADDRESS = gql`
    mutation UpdateUserWalletAddress(
        $id: String!
        $wallet_address: String!
        $updated_at: timestamptz!
    ) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: { wallet_address: $wallet_address, updated_at: $updated_at }
        ) {
            wallet_address
            updated_at
        }
    }
`

// Replace this with an appropriate query once the
// application model is in.
export const GET_ALL_COMPETITORS = gql`
    query GetAllCompetitors {
        users(where: { competition_applications: {} }) {
            id
            display_name
            display_name_slug
            created_at
            photo_url
            social_media
        }
    }
`

export const GET_ALL_UNASSIGNED_COMPETITORS = gql`
    query GetAllUnassignedCompetitors {
        users(where: { _not: { competition_group_memberships: {} } }) {
            id
            display_name
            display_name_slug
            created_at
            photo_url
            social_media
        }
    }
`

export async function getUser(user, idToken) {
    const client = setApolloClientIfNecessary(idToken)
    const { data, networkStatus, error } = await client.query({
        query: GET_USER_BY_ID,
        variables: {
            id: user.uid,
        },
    })

    if (networkStatus === 8) {
        toast.error('You appear to be offline, check your internet connection!')
        return
    }

    if (error) {
        toast.error('Error trying to get user. Refresh the page and try again.')
        console.error('Error trying to get user: ', error)
        return
    }

    return data.users_by_pk
}

export async function setOnboardingComplete() {
    const uid = auth.currentUser.uid

    const client = getApolloClient()

    await client.mutate({
        mutation: UPDATE_USER_ONBOARDING_COMPLETE,
        variables: {
            id: uid,
            onboardingComplete: true,
        },
    })
}
