import React, { useContext, useRef, useState } from 'react'
import Alert, { AlertContent } from './Alert'

type UseModalShowReturnType = {
    show: boolean
    setShow: (value: boolean) => void
    onHide: () => void
}

const useModalShow = (): UseModalShowReturnType => {
    const [show, setShow] = useState(false)

    const handleOnHide = () => {
        setShow(false)
    }

    return {
        show,
        setShow,
        onHide: handleOnHide,
    }
}

type ModalContextType = {
    show: (content: AlertContent) => Promise<boolean>
}

type ConfirmationModalContextProviderProps = {
    children: React.ReactNode
}

const ConfirmationModalContext = React.createContext<ModalContextType>(
    {} as ModalContextType,
)

const ConfirmationModalContextProvider: React.FC<
    ConfirmationModalContextProviderProps
> = props => {
    const { setShow, show, onHide } = useModalShow()
    const [content, setContent] = useState<AlertContent | null>()
    const resolver = useRef<any>()

    const handleShow = (content: AlertContent): Promise<boolean> => {
        setContent(content)
        setShow(true)
        return new Promise(function (resolve) {
            resolver.current = resolve
        })
    }

    const modalContext: ModalContextType = {
        show: handleShow,
    }

    const handleOk = () => {
        resolver.current && resolver.current(true)
        onHide()
    }

    const handleCancel = () => {
        resolver.current && resolver.current(false)
        onHide()
    }

    return (
        <ConfirmationModalContext.Provider value={modalContext}>
            {props.children}

            {content && (
                <Alert
                    content={content}
                    open={show}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                />
            )}
        </ConfirmationModalContext.Provider>
    )
}

const useConfirm = (): ModalContextType => useContext(ConfirmationModalContext)

export { useModalShow, useConfirm }

export default ConfirmationModalContextProvider
