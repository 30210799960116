import {
    Button,
    ButtonPrimary,
    ButtonVariant,
} from '@src/app/common/components/Button'
import { URLS } from '@src/constants/urls'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

interface AppSubmissionClosedProps {}

const AppSubmissionClosed: React.FC<AppSubmissionClosedProps> = ({}) => {
    const navigate = useNavigate()

    return (
        <div className="max-w-2xl h-full flex flex-col space-y-8 text-center text-black items-center pt-20 md:pt-40">
            <h1 className="font-bold text-4xl md:text-5xl">
                The submission period is closed.
            </h1>
            <p className="text-lg md:text-xl">
                Don’t worry. You may have missed this chance, but more
                opportunities are heading your way.
            </p>
            <p className="text-lg md:text-xl">
                In the meantime, support CreatorDAO in finding the next big
                creator by voting in #TheSearch.
            </p>
            <Button
                variant={ButtonVariant.FAT}
                primary={ButtonPrimary.BLACK}
                onClick={() => navigate(URLS.THESEARCH_GROUPS)}
                className="flex place-content-center text-base leading-[14px] md:text-xl md:leading-[18px] w-[208px] md:h-16 bg-brandBlack-500 text-brandOffWhite-500 font-bold focus:outline-none focus:ring-0 focus:ring-offset-0 rounded-full "
            >
                Vote for creators
            </Button>
        </div>
    )
}

export default AppSubmissionClosed
