import {
    Button,
    ButtonPrimary,
    ButtonVariant,
} from '@src/app/common/components/Button'
import { URLS } from '@src/constants/urls'
import React from 'react'

import { FaInstagram, FaPlay, FaTwitter } from 'react-icons/fa'
import { IoLogoTiktok, IoLogoYoutube } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

interface ISocialButton {
    url: string
    label: string
    Icon: React.ReactNode
}

const SocialButton: React.FC<ISocialButton> = ({ url, label, Icon }) => (
    <a
        className="w-[42px] h-[42px] flex-shrink-0 bg-brandBlack-500 grid place-content-center rounded-full group"
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        aria-label={label}
        key={label}
    >
        {Icon}
    </a>
)

interface ISocialMediaLinksProps {
    name: string
    socialLinks?: {
        tiktok?: string
        youtube?: string
        instagram?: string
        twitter?: string
    }
}

const SocialMediaLinks: React.FC<ISocialMediaLinksProps> = ({
    name,
    socialLinks,
}) => {
    const navigate = useNavigate()

    return (
        <div
            className={
                'flex flex-col-reverse sm:flex-row item-start  md:items-center justify-end gap-4 relative  z-10'
            }
        >
            <p className="hidden sm:block ml-3 md:ml-0 text-base font-semibold">
                Follow {name}
            </p>
            <div className="flex items-start justify-start gap-2">
                {socialLinks?.tiktok && (
                    <SocialButton
                        url={socialLinks?.tiktok}
                        label="Follow us on TikTok"
                        Icon={<IoLogoTiktok className="w-5 h-5 text-white" />}
                    />
                )}
                {socialLinks?.instagram && (
                    <SocialButton
                        url={socialLinks?.instagram}
                        label="Follow us on Instagram"
                        Icon={<FaInstagram className="w-5 h-5 text-white" />}
                    />
                )}
                {socialLinks?.twitter && (
                    <SocialButton
                        url={socialLinks?.twitter}
                        label="Follow us on Twitter"
                        Icon={<FaTwitter className="w-5 h-5 text-white" />}
                    />
                )}
                {socialLinks?.youtube && (
                    <SocialButton
                        url={socialLinks?.youtube}
                        label="Follow us on YouTube"
                        Icon={<IoLogoYoutube className="w-5 h-5 text-white" />}
                    />
                )}
            </div>
            <p className="sm:hidden ml-1 text-base font-semibold">
                Follow {name}
            </p>

            <Button
                primary={ButtonPrimary.BLACK}
                variant={ButtonVariant.FAT}
                leftSideIcon={<FaPlay className="w-3 h-3 text-white" />}
                className="border-b-4 border-brandIdolGreen-500 pl-6 pr-5 h-11 font-medium"
                onClick={() =>
                    navigate(
                        `${URLS.THESEARCH_GROUP}/133/finals/member/zoeunlimited`,
                    )
                }
            >
                Application Video
            </Button>
        </div>
    )
}

export default SocialMediaLinks
