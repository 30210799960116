import { URLS } from '@src/constants/urls'
import { useCurrentUserProfile } from '@src/features/shared/useCurrentUserProfile'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EVENTS, logEvent } from '../../../../app/common/logging/logging'
import { Users } from '../../../../generated/graphql-operations'
import { useGroupMembersList } from '../group/components/GroupMembersList/hooks/useGroupMembersList.hooks'
import AppHeroTitle from './components/AppHeroTitle'

interface AppCompleteProps {
    user: Users
}

const AppComplete: React.FC<AppCompleteProps> = ({ user }) => {
    const refetchInterval = useRef<any>()
    const navigate = useNavigate()
    const [copySuccess, setCopySuccess] = useState<boolean>(false)
    const {
        currentUserProfile: profile,
        refetch: refetchProfile,
        loading,
    }: any = useCurrentUserProfile('cache-first', true)
    const { refetchCompetitionGroups } = useGroupMembersList({
        groupId:
            profile?.competition_group_memberships[0]?.competition_group?.id,
        phaseId:
            profile?.competition_group_memberships[0]?.competition_group
                ?.phase_id,
        redirectOnGroupSelect: true,
    })

    useEffect(() => {
        const timeoutID = setTimeout(() => setCopySuccess(false), 1500)

        return () => {
            if (copySuccess) {
                clearTimeout(timeoutID)
            }
        }
    }, [copySuccess])

    useEffect(() => {
        if (!loading && profile?.competition_group_memberships.length === 0) {
            refetchInterval.current = setInterval(() => {
                refetchProfile()
            }, 2500)
        } else {
            clearInterval(refetchInterval.current)
            refetchCompetitionGroups()
        }
    }, [profile, refetchProfile, loading, refetchCompetitionGroups])

    const profilePath = `${URLS.THESEARCH_PROFILE}/${user.display_name_slug}`
    const profileLink = `${window.location.origin}${profilePath}`

    return (
        <React.Fragment>
            <AppHeroTitle>
                You’re officially part of #TheSearch!
                <br />
            </AppHeroTitle>
            <div className="text-[20px] text-center">
                Share your link to get votes.
            </div>
            {user && (
                <div className="flex flex-col space-y-[24px] w-full">
                    <div className="bg-white rounded-[26px] shadow-md border-[0.1px] py-4 px-[14.5px] lg:px-8 space-y-4">
                        <div className="flex space-x-4 items-center">
                            <img
                                className="inline-block h-[96px] w-[96px] rounded-full object-cover"
                                src={user.photo_url!}
                                alt=""
                            />
                            <div className="flex flex-col space-y-1 w-full">
                                <span className="font-bold">Vote for</span>
                                <span className="text-[32px] font-bold">
                                    {user.display_name}
                                </span>
                                <span className="font-bold">
                                    and help them win a $1,000,000 investment
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col space-y-0.5">
                            <label className="font-bold">
                                Your profile link
                            </label>
                            <div className="flex space-x-2">
                                <input
                                    className="flex bg-[#F2F2F2] rounded-[8px] px-1 lg:px-4
                                    border border-transparent focus:outline-none
                                    focus:border-transparent focus:ring-0 w-full items-center"
                                    value={profileLink}
                                    readOnly
                                />
                                <button
                                    className="text-sm font-bold text-center bg-[#51E577]
                                    text-black py-3 px-6 rounded-[50px] w-[130px]"
                                    onClick={() => {
                                        logEvent(
                                            EVENTS.FANX.APPLICATION.COPY_LINK,
                                            {
                                                url: profileLink,
                                            },
                                        )
                                        navigator.clipboard.writeText(
                                            profileLink,
                                        )
                                        setCopySuccess(true)
                                    }}
                                >
                                    {copySuccess ? 'Copied!' : 'Copy'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            logEvent(EVENTS.FANX.APPLICATION.VIEW_PROFILE, {
                                linkTo: profilePath,
                                creatorSlug: user?.display_name_slug as any,
                            })
                            navigate(profilePath)
                        }}
                        className="text-center bg-black text-white py-4 px-16 rounded-[50px]
                        disabled:bg-[#F2F2F2] disabled:text-black"
                        disabled={
                            profile?.competition_group_memberships.length > 0
                                ? false
                                : true
                        }
                    >
                        {loading
                            ? 'Loading...'
                            : profile?.competition_group_memberships.length > 0
                            ? 'View Profile'
                            : 'Adding you to a group. Please wait...'}
                    </button>
                </div>
            )}
        </React.Fragment>
    )
}

export default AppComplete
