import React from 'react'
import MediaInput, { FILE_INPUT_CONTEXT } from './MediaInput'
import TextInput from './TextInput'
import TextArea from './TextArea'
import FormRadio from '../modals/FormRadio'
import SelectField from './SelectField'
import LocationTextInput from './LocationTextInput'
import FormToggle from './FormToggle'
import TextSocialInput from './TextSocialInput'
import { DatePickerField } from './DatePickerField'
import ComboboxTag from './ComboboxTags'
import OfficeHours from './OfficeHours'
import ToggleInput from './ToggleInput'
import CommonPhotoInput from './CommonPhotoInput'
import { PhotoInputVariant } from './CommonPhotoInput/hooks/useCommonPhotoInput'

export default function FormField({ field, variant = 'portal' }: any) {
    if (field?.hideField) {
        return null
    }

    switch (field.type) {
        case 'radio':
            return <FormRadio key={field.name} field={field} />

        case 'location':
            return (
                <LocationTextInput
                    key={field.name}
                    name={field.name}
                    label={field.label}
                />
            )

        case 'files':
            return (
                <MediaInput
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    context={FILE_INPUT_CONTEXT}
                />
            )

        case 'select':
            return (
                <SelectField
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    isMulti={field.isMulti}
                    allValues={field.allValues}
                    variant={variant}
                    excludePortalTarget={field.excludePortalTarget}
                />
            )

        case 'textarea':
            return (
                <TextArea
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    type={field.type}
                />
            )

        case 'text':
        case 'password':
        case 'number':
            return (
                <TextInput
                    key={field.name}
                    name={field.name}
                    placeholder={field.placeholder}
                    type={field.type}
                    label={field.label}
                    testId={field.testId}
                    variant={variant}
                    autoFocus={field.autoFocus}
                    isTrimRequired={field.isTrimRequired}
                />
            )

        case 'social':
            return (
                <TextSocialInput
                    key={field.name}
                    name={field.name}
                    placeholder={field.placeholder}
                    type={field.type}
                    badge={field.badge}
                    label={field.label}
                />
            )

        case 'toggle':
            return (
                <FormToggle
                    key={field.name}
                    name={field.name}
                    initialValue={field.initialValue}
                    type={field.type}
                />
            )

        case 'toggle-input':
            return (
                <ToggleInput
                    key={field.name}
                    name={field.name}
                    label={field.label}
                />
            )

        case 'photo':
            return (
                <CommonPhotoInput
                    name={field.name}
                    variant={
                        variant === PhotoInputVariant.PORTAL
                            ? PhotoInputVariant.PORTAL
                            : PhotoInputVariant.THE_SEARCH
                    }
                    storagePath={field?.storagePath}
                    label={field.label}
                />
            )

        case 'datetime':
            return <DatePickerField key={field.name} name={field.name} />

        case 'creatable-select':
            return (
                <ComboboxTag
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    allValues={field.allValues}
                    variant={variant}
                />
            )
        case 'office-hours':
            return (
                <OfficeHours
                    key={field.name}
                    name={field.name}
                    label={field.label}
                />
            )

        default:
            return null
    }
}